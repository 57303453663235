<template>
  <div class="wrap">
    <div class="general">
    <nav-ber :title="'施工总承包商'"></nav-ber>
    <main>
      <sub-ban :ban-info="banInfo"></sub-ban>
      <section class="gene-npm1">
        <div class="w1440">
          <h3 class="til" v-html="apiData3[0].newsTitle"></h3>
          <div class="p-box">
            <p v-html="apiData3[0].name"></p>
          </div>
          <ul>
            <li v-for="(item, i) in apiData2" :key="item.newsId">
              <img :src="item.newsAccess" alt="">
              <h4 v-html="item.newsTitle"></h4>
              <div v-html="item.newsContent"></div>
            </li>
          </ul>
        </div>
      </section>
      <div v-html="apiData3[0].newsContent"></div>
    </main>


  </div>
  </div>
</template>
  
<script setup >
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const banInfo = reactive({
  banUrl: '',
  tilCn: '',
  tilTxt: '',
  link: '',
  coId: "",
  enlink: true,
  txton: "免费试用",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: "423",  
    typeId:"4389",
  });
  const { data: data2 } = await getInfo({
    coId: "953"
  });
  const { data: data3 } = await getInfo({
    coId: "951"
  });
  apiData1.value = data1.data;
  apiData2.value = data2.data;
  apiData3.value = data3.data;
  banInfo.banUrl = apiData1.value[0].image;
  banInfo.tilCn = apiData1.value[0].newsTitle;
  banInfo.tilTxt = apiData1.value[0].newsContent;
};
onMounted(async () => {
   getList();
});
</script>
<style lang="scss">
.general {
  main{padding: 0;}
  .w1440 {
    max-width: 1150px;
  }
  h3.til{
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 1.5;
    text-decoration: none;
    text-align: center;
    margin-bottom: 20px;
  }
  .p-box{
    p{
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      color: rgb(102, 102, 102);
      background-color: transparent;
      line-height: 34px;
      text-decoration: none;
      text-align: center;
    }
  }
  .w1440{
    & > .wscnph{
      margin-top: 50px;
    }
  }
  .bigImg{
    display: block;
    margin: 50px auto 0;
    img{
      display: block;
      margin: 0 auto;
    }
  }
  .cLump{
    padding: 50px 0;
    .numTil,.til3,.this,.ul{
      margin-bottom: 30px;
    }
    .bigImg{
      margin: 0 auto 120px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .numTil{
    position: relative;
    font-family: 微软雅黑;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 30px;
    text-decoration: none;
    text-align: left;
    padding-left: 50px;
    em{
      position: absolute;
      left: 0;
      top: -5px;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      color: rgb(255, 255, 255);
      background-color: transparent;
      line-height: 42px;
      text-decoration: none;
      text-align: center;
      width: 42px;
      height: 42px;
      background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-05/17152182734002.png) no-repeat center;
      background-size: contain;
    }
  }
  .til3{
    font-family: 微软雅黑;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    color: rgb(0, 80, 170);
    background-color: transparent;
    line-height: 32px;
    text-decoration: none;
    text-align: left;
  }
  .this{
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 34px;
    text-decoration: none;
    text-align: left;
    padding-left: 40px;
    background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141189868956.png) no-repeat;
    background-size: 28px;
  }
  .li{
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 30px;
    text-decoration: none;
    text-align: left;
    padding-left: 25px;
    background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141183305727.png) no-repeat 0 5px;
    background-size: 16px;
    em{
      color: rgb(0, 159, 24);
      font-weight: 600;
    }
    strong{
      font-weight: 600;
    }
  }
  .annotation{
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: rgb(51, 51, 51);
    background-color: transparent;
    line-height: 30px;
    text-decoration: none;
    text-align: center;
    padding-top: 10px;
  }
}

[class^="gene-npm"]{
  padding: 70px 0 120px;
  &:nth-child(odd){
    background: rgb(245, 249, 255);
  }
}

.gene-npm1{
  padding: 70px 0;
  ul{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    li{
      position: relative;
      width: 26%;
      &:nth-child(2){
        &:before,&:after{
          content: "";
          display: block;
          position: absolute;
          height: 300px;
          max-height: 100%;
          top: 0;
          border-right: 1px solid rgb(222, 222, 222);
        }
        &:before{
          left: -21%;
        }
        &:after{
          right: -21%;
        }
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        border-right: 1px solid rgb(222, 222, 222);
      }
      &:last-child:after{
        display: none;
      }
      img{
        display: block;
        margin: 0 auto 30px;
      }
      h4{
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        color: rgb(51, 51, 51);
        background-color: transparent;
        line-height: 26px;
        text-decoration: none;
        text-align: center;
        margin-bottom: 20px;
      }
      p{
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        color: rgb(102, 102, 102);
        background-color: transparent;
        line-height: 30px;
        text-decoration: none;
        text-align: center;
      }
    }
  }
}

.gene-npm2{
  padding: 70px 0;
  background: rgb(245, 249, 255);
}

.gene-npm3{
  dl{
    display: flex;
    dt{
      width: 600px;
    }
    dd{
      padding: 70px 0 0 32px;
      .row{
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        color: rgb(51, 51, 51);
        background-color: transparent;
        line-height: 28px;
        text-decoration: none;
        text-align: left;
        margin-bottom: 88px;
        &:last-child{
          margin-bottom: 0;
        }
        em{
          color: rgb(255, 68, 68);
          padding: 0 5px;
        }
      }
    }
  }
}

.gene-npm4{
  background: #f6f9ff;
  li{
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 30px;
    text-decoration: none;
    text-align: left;
    padding-left: 25px;
    background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141183305727.png) no-repeat 0 5px;
    background-size: 16px;
  }
}
.gene-npm5{
  .p{
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 34px;
    text-decoration: none;
    text-align: left;
    padding-left: 40px;
    background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141189868956.png) no-repeat;
    background-size: 28px;
  }
  dl{
    margin-top: 50px;
    display: flex;
    dt{
      width: 56%;
    }
    dd{
      flex: 1;
      margin-left: 50px;
      h4{
        position: relative;
        font-family: 微软雅黑;
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        color: rgb(34, 34, 34);
        background-color: transparent;
        line-height: 30px;
        text-decoration: none;
        text-align: left;
        margin: 20px 0 10px;
        &:first-child{
          margin-top: 0;
        }
        em{
          position: absolute;
          left: -40px;
          top: 0;
          font-size: 24px;
          font-weight: bold;
          font-style: normal;
          color: rgb(255, 255, 255);
          background-color: transparent;
          line-height: 36px;
          text-decoration: none;
          text-align: center;
          width: 36px;
          height: 36px;
          background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-05/17152182734002.png) no-repeat center;
          background-size: contain;
        }
      }
      p{
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        color: rgb(102, 102, 102);
        background-color: transparent;
        line-height: 24px;
        text-decoration: none;
        text-align: left;
        padding-left: 30px;
        background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-05/17152184763312.png) no-repeat;
        background-size: 22px;
      }
      h5{
        font-size: 26px;
        font-weight: bold;
        font-style: normal;
        color: rgb(29, 81, 164);
        background-color: transparent;
        line-height: 30px;
        text-decoration: none;
        text-align: left;
        margin-top: 10px;
      }
    }
  }
}
.gene-npm6{
  background: rgb(245, 249, 255);
}
.gene-npm9{
  .p{
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 34px;
    text-decoration: none;
    text-align: left;
    padding-left: 40px;
    background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141189868956.png) no-repeat;
    background-size: 28px;
  }
}
.gene-npm10,.gene-npm11{
  h4{
    position: relative;
    font-family: 微软雅黑;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 30px;
    text-decoration: none;
    text-align: left;
    margin: 20px 0 30px 0;
    padding-left: 50px;
    em{
      position: absolute;
      left: 0;
      top: -5px;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      color: rgb(255, 255, 255);
      background-color: transparent;
      line-height: 42px;
      text-decoration: none;
      text-align: center;
      width: 42px;
      height: 42px;
      background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-05/17152182734002.png) no-repeat center;
      background-size: contain;
    }
  }
  li{
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    color: rgb(34, 34, 34);
    background-color: transparent;
    line-height: 30px;
    text-decoration: none;
    text-align: left;
    padding-left: 25px;
    background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141183305727.png) no-repeat 0 5px;
    background-size: 16px;
  }
}
.gene-npm11{
  padding: 50px 0;
}
.gene-npm12, .gene-npm13, .gene-npm14, .gene-npm15, .gene-npm16, .gene-npm17, .gene-npm18, .gene-npm19, .gene-npm20, .gene-npm21, .gene-npm22{
  padding: 50px 0;
  dl{
    display: flex;
  }
  dt{
    width: 52%;
    margin-right: 50px;
    .p{
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      color: rgb(34, 34, 34);
      background-color: transparent;
      line-height: 34px;
      text-decoration: none;
      text-align: left;
      padding-left: 40px;
      background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141189868956.png) no-repeat;
      background-size: 28px;
    }
    h5{
      font-family: 微软雅黑;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      color: rgb(34, 34, 34);
      background-color: transparent;
      line-height: 30px;
      text-decoration: none;
      text-align: left;
      margin: 20px 0 6px;
      &.former{
        color: rgb(102, 102, 102);
      }
    }
    p{
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      color: rgb(34, 34, 34);
      background-color: transparent;
      line-height: 30px;
      text-decoration: none;
      text-align: left;
      padding-left: 25px;
      background: url(https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-04/17141183305727.png) no-repeat 0 5px;
      background-size: 16px;
    }
  }
  dd{
    flex: 1;
  }
}
.gene-npm15, .gene-npm21, .gene-npm22{
  dl{
    display: block;
    dt{
      width: 100%;
      .p{
        margin-bottom: 40px;
      }
    }
    dd{
      margin: 40px auto 0;
    }
  }
}
.gene-npm16, .gene-npm17, .gene-npm18, .gene-npm20, .gene-npm21, .gene-npm22{
  dl{
    display: block;
  }
  dt{
    width: 100%;
  }
  dd{
    margin-top: 50px;
    width: 100%;
  }
  .flx{
    display: flex;
    justify-content: space-between;
    .l{
      max-width: 30%;
    }
    .r{
      width: 61.5%;
    }
  }
}
.gene-npm26{
  ul{
    display: flex;
  }
  li{
    width: 20%;
    padding: 30px 20px;
    background: url('https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2023-10/16970184717499.png') no-repeat top center;
    background-size: 100%;
    &:nth-child(even){
      background-image: url('https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2023-10/16970185001016.png');
    }
    h4{
      font-family: 微软雅黑;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      color: rgb(51, 51, 51);
      background-color: transparent;
      line-height: 26px;
      text-decoration: none;
      text-align: center;
      margin-bottom: 8px;
    }
    p{
      font-family: 微软雅黑;
      font-size: 19px;
      font-weight: normal;
      font-style: normal;
      color: rgb(102, 102, 102);
      background-color: transparent;
      line-height: 30px;
      text-decoration: none;
      text-align: center;
    }
  }
}
.gene-npm30{
  height: 595px;
  background: url('https://uploadfile.ltdcdn.com/uploadfilev2/image/0/508/193/2024-01/17042641933096.png') no-repeat center !important;
  ul{
    position: relative;
    height: 400px;
    li{
      position: absolute;
      width: 400px;
      height: 150px;
      padding: 20px 40px;
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.03);
      &:nth-child(1){
        left: 0;
        top: 230px;
      }
      &:nth-child(2){
        left: 43%;
        top: 20px;
      }
      &:nth-child(3){
        right: 0;
        top: 260px;
      }
      p{
        font-family: 微软雅黑;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        color: rgb(51, 51, 51);
        background-color: transparent;
        line-height: 30px;
        text-decoration: none;
        text-align: center;
      }
    }
  }
}
.gene-npm31{
  h3.til{
    margin-bottom: 40px;
  }
  ul{
    display: flex;
    li{
      padding: 0 20px;
      width: 25%;
      border-right: 1px solid rgb(222, 222, 222);
      &:last-child{
        border-right: 0 none;
      }
      .icon, .wscnph{
        display: block;
        margin: 0 auto 10px;
      }
      h5{
        font-family: 微软雅黑;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        color: rgb(51, 51, 51);
        background-color: transparent;
        line-height: 26px;
        text-decoration: none;
        text-align: center;
        margin: 0 auto 10px;
      }
      p{
        font-family: 微软雅黑;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        color: rgb(102, 102, 102);
        background-color: transparent;
        line-height: 30px;
        text-decoration: none;
        text-align: center;
      }
    }
  }
}
@media (max-width: 600px) {
  .general{
    [class^=gene-npm]{
      padding: .6rem 0;
    }
    h3.til{
      font-size: .3rem !important;
    }
    p{
      font-size: .24rem !important;
      line-height: .44rem !important;
    }
    .li{
      font-size: .24rem;
      line-height: .44rem;
    }
  }
  .general dl{margin-top: .5rem;}
  .general [class^=gene-npm] .this,.general [class^=gene-npm] .p,.general [class^=gene-npm] .dt .p{font-size: .3rem; line-height: .4rem; background-size: .32rem; padding-left: .5rem;}
  .general [class^=gene-npm] dl,.general [class^=gene-npm] .flx{flex-wrap: wrap;}
  .general [class^=gene-npm] .flx .l, .general [class^=gene-npm] .flx .r{width: 100%; max-width: none;}
  .general [class^=gene-npm] dt h5{font-size: .3rem; line-height: 1.5;}
  .general [class^=gene-npm] dt{width: 100%; margin: 0;}
  .general [class^=gene-npm] dd{width: 100%; margin: .4rem 0 0;}
  .general .w1440 > .wscnph{margin-top: .5rem;}
  .general .cLump .bigImg{margin-bottom: 1rem;}
  .gene-npm10 h4, .gene-npm11 h4,.general .numTil{padding-left: .8rem;font-size: .3rem;line-height: .6rem;}
  .gene-npm10 h4:first-child, .gene-npm11 h4:first-child, .general .numTil{margin-top: 0;}
  .gene-npm10 h4 em,.gene-npm11 h4 em,.general .numTil em{top:0; font-size: .26rem; width: .6rem; height: .6rem; line-height: .6rem; left: 0;}
  .gene-npm10 li, .gene-npm11 li{font-size: .24rem; line-height: .44rem; background-size: .24rem; padding-left: .4rem; background-position: 0 .1rem;}
  .general .til3,.general .annotation{font-size: .28rem; line-height: 1.5;}
  .gene-npm1{
    ul{
      flex-wrap: wrap;
      margin-top: .5rem;
      li{
        border-top: 1px solid #dedede;
        padding: .5rem 0;
        width: 100%;
        h4{
          font-size: .28rem;
          line-height: 1.5;
        }
      }
    }
  }
  .gene-npm3{
    dl{
      flex-wrap: wrap;
      dt{
        width: 100%;
      }
      dd{
        width: 100%;
        padding: .4rem 0 0;
        .row{
          font-size: .3rem;
          line-height: 1.5;
          margin-bottom: .2rem;
        }
      }
    }
  }
  .gene-npm4{
    li{
      font-size: .24rem;
      line-height: .44rem;
      background-size: .24rem;
      padding-left: .4rem;
      background-position: 0 .1rem;
    }
  }
  .gene-npm5{
    dl{
      flex-wrap: wrap;
      dt{
        width: 100%;
      }
      dd{
        width: 100%;
        margin-top: .4rem;
        margin-left: .8rem;
        h4{
          padding-left: .8rem;
          font-size: .3rem;
          line-height: .6rem;
          em{
            font-size: .26rem;
            width: .6rem;
            height: .6rem;
            line-height: .6rem;
            left: 0;
          }
        }
        h5{
          font-size: .28rem;
        }
        p{
          background-size: .3rem;
        }
      }
    }
  }
  .gene-npm26{
    ul{
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li{
      width: 3.25rem;
      padding: .4rem .3rem;
      height: 2.8rem;
      margin-bottom: .2rem;
      h4{
        font-size: .28rem;
        line-height: 1.5;
      }
    }
  }
  .gene-npm30{
    height: auto;
    padding-bottom: 2.6rem !important;
    background-color: #f5f9ff !important;
    background-position: center bottom !important;
    background-size: auto 60% !important;
    ul{
      height: auto;
      li{
        position: static;
        width: 100%;
        margin-bottom: .2rem;
        padding: .5rem;
        height: auto;
      }
    }
  }
  .gene-npm31{
    ul{
      flex-wrap: wrap;
      justify-content: space-between;
      li{
        width: 3rem;
        padding: 0;
        margin-bottom: .4rem;
        &:nth-child(odd){
          width: 3.45rem;
          padding-right: .45rem;
        }
        &:nth-child(even){
          border-right: 0 none;
        }
        .wscnph{
          width: 1.6rem;
          margin-bottom: 0
        }
        h5{
          font-size: .28rem;
          line-height: 1.5;
        }
      }
    }
  }
}
.w1440{
  padding: 0 .4rem;
}
</style>
  
  