<template>
  <div class="wrap">
    <nav-ber :title="'建筑工程智慧安全监管平台'"></nav-ber>
    <main>
      <div class="topBox"></div>
      <div class="content">
        <section class="part1">
          <div class="numberData">
            <div class="li">
              <div class="p1"><em>120</em>个</div>
              <div class="p2">累计服务质安站</div>
            </div>
            <div class="li">
              <div class="p1"><em>119+</em>个</div>
              <div class="p2">累计服务项目</div>
            </div>
            <div class="li">
              <div class="p1"><em>1172</em>个</div>
              <div class="p2">累计接入设备</div>
            </div>
          </div>
          <h3 class="cTil"><em>平台优势</em></h3>
          <ul>
            <li>
              <div class="icon"><img src="~@/assets/images/digitization/wisdomIcon1.jpg" alt=""></div>
              <div class="ri">
                <h4>全周期监管</h4>
                <p>所有关键核心是数据均以平台直传方式进行<br>
                中间无人工上传环节，避免数据作假。</p>
              </div>
            </li>
            <li>
              <div class="icon"><img src="~@/assets/images/digitization/wisdomIcon2.jpg" alt=""></div>
              <div class="ri">
                <h4>三级监管体系</h4>
                <p>平台构建了“省、市、区(县)”的三级监管体系<br>
                  实现了省市区的互联互通、智能处理和协·同工作</p>
              </div>
            </li>
            <li>
              <div class="icon"><img src="~@/assets/images/digitization/wisdomIcon3.jpg" alt=""></div>
              <div class="ri">
                <h4>科学化决策</h4>
                <p>平台拥有人机料法环等多种数据，并以大屏图表等可视化呈现，为监管人员提供科学化分析与决策</p>
              </div>
            </li>
          </ul>
        </section>
        <section class="part2">
          <h2 class="cTil"><em>数据互联互通</em></h2>
          <div class="pBox">
            <p>打通施工过程各个系统监管数据<br>
            杜绝成为数据孤岛</p>
          </div>
          <div class="txtSwiper">
            <div class="swiper-container">  
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="txt">
                    <h4>建设工程质量安全监督信息</h4>
                    <p>管平台提供报监工程基础信息、建设状态信息、<br>
                    报监人员信息等;</p>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="txt">
                    <h4>建设工程质量安全监督信息</h4>
                    <p>管平台提供报监工程基础信息、建设状态信息、<br>
                    报监人员信息等;</p>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="txt">
                    <h4>建设工程质量安全监督信息</h4>
                    <p>管平台提供报监工程基础信息、建设状态信息、<br>
                    报监人员信息等;</p>
                  </div>
                </div>
                <!-- 更多 slides -->  
              </div>  
              <!-- Swiper Pagination -->  
              <div class="user-pagination">
                <div :class="['dot', {'on': swiperAct === 0}]"></div>
                <div :class="['dot', {'on': swiperAct === 1}]"></div>
                <div :class="['dot', {'on': swiperAct === 2}]"></div>
              </div>  
            </div>
          </div>
        </section>
        <section class="part3">
          <h3 class="cTil"><em>监管智慧化的意义</em></h3>
          <div class="pBox">
            <p>可以直接便用的应用场景能力封装单元，具备开放能力<br>
            扩展开发能力</p>
          </div>
          <div class="ul">
            <div class="li">
              <div class="til">监管部门</div>
              <h4>提升监管部门监管能力</h4>
              <p>从”人工监管"到“智慧化监管”，可满足更大体量，更多维度的监管需求，提高监管效率</p>
              <br>
              <p>可自动完成周期性巡检，增加监管频率，有效督促建设单位履行安全职责，提升监管效果</p>
            </div>
            <div class="li">
              <div class="til">参建单位</div>
              <h4>优化企业安全管理手段</h4>
              <p>智能设备可对施工机械进多方位、多角度、多时段、多频次的安全监控和预警，填补人工监管漏洞</p>
              <br>
              <p>隐里外理闭环机制，满足企业对安全隐患事前预整，事中告整，事后追责的全讨程监管需求</p>
            </div>
            <div class="li">
              <div class="til">施工人员</div>
              <h4>规范施工人员安全生产行为</h4>
              <p>智能监控设备对项目现场人员不安全行为进行实时监控，有效规范施工人员的安全行为</p>
              <br>
              <p>加强施工人员的安全培训，提高施工让人员的安全防范意识降低隐患风险</p>
            </div>
          </div>
        </section>
        <section class="part4">
          <h3 class="cTil"><em>监管智慧化的意义</em></h3>
          <div class="txt">
            <p>使用平台提供的API和工具集，快速创建丰富的建筑领域应用<br>
          解决不同数字化场景痛点</p>
          </div>
          <div class="pic"><img src="~@/assets/images/digitization/wisdomIconPic1.jpg" alt=""></div>
        </section>
        <section class="part5">
          <h3 class="cTil"><em>应用案例</em></h3>
          <div class="txt">
            <h4>广西建筑工程智慧安全监管平台</h4>
            <p>广西建筑工程智慧安全监管平台解决方案以安全监管为核心，通过构建“省、市、区(县)”的三级监管体系，并依托AI人工智能、物联网大数据分析等先进技术，为监管部门等提供了综合管理解决方案，包括了:数据驾驶舱、安全行为监管、起重机械监控、扬尘噪音监控、人员履职监管、巡检任务监控、隐患整改管理等，通过数字信息化手段有效实现了对项目、人员、设备、环境、安全隐患和质量等全面智能监管，进一步提高了监管人员的管理效率和决策能力。</p>
          </div>
          <div class="img"><img src="~@/assets/images/digitization/wisdomIconPic2.jpg" alt=""></div>
        </section>
        
      </div>
    </main>
  </div>
</template>
<script setup>
import Swiper, { Pagination } from 'swiper';  
import 'swiper/swiper-bundle.css';  
// 如果你需要分页器或导航等插件，也可以这样引入  
// import { Pagination } from 'swiper';


import NavBer from "@/components/NavBer.vue";
import { ref, onMounted } from "vue";
const swiperAct = ref(0);
onMounted(function(){
  const swiperGap = window.innerWidth / 7.5 * 0.4;
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    spaceBetween : 10,
    slidesOffsetBefore: swiperGap,
    slidesOffsetAfter: swiperGap,
    pagination: {  
      el: '.swiper-pagination',  
      clickable: true,
    },
    on: {
      slideChangeTransitionStart: function(){
        swiperAct.value = this.activeIndex;
      }
    }
  });
})
</script>

<style scoped lang="scss">
@import "~@/assets/css/vanTabs.scss";
main {
  padding: 0;
  background: url(~@/assets/images/digitization/wisdomTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
}
.topBox {
  height: 2.44rem;
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
  .cTil{
    margin-bottom: .33rem;
  }
  section{
    padding-bottom: .6rem;
  }
}
h5{
  font-size: .22rem;
  color: #8b8b8b;
  line-height: .36rem;
  margin: .4rem 0 .2rem;
}
.pic{
  overflow: hidden;
  border-radius: var(--border-radius);
  border: .03rem solid #c7c7c7;
  margin-top: .3rem;
}
.part1{
  padding: .6rem .4rem;
  background: linear-gradient(to bottom, #fae4cb, #fff);
  .numberData{
    display: flex;
    justify-content: space-between;
    margin-bottom: .55rem;
    .li{
      width: 2.12rem;
      height: 1.26rem;
      border-radius: var(--border-radius);
      padding: .28rem .2rem;
      background: #fff;
      .p1{
        font-size: .22rem;
        color: #e60010;
        line-height: .36rem;
        em{
          font-size: .48rem;
          font-family: 'Gilroy-Bold';
          margin-right: .06rem;
        }
      }
      .p2{
        font-size: .22rem;
        color: #2d322d;
        line-height: .36rem;
      }
    }
  }
  .cTil{
    margin-bottom: .6rem;
    border-color: #e9ded0;
  }
  li {
    position: relative;
    padding: .3rem;
    padding-left: 1.48rem;
    display: flex;
    min-height: 2.1rem;
    align-items: center;
    margin-bottom: 0.3rem;
    border-radius: 0.28rem;
    background: #fff;
    box-shadow: var(--box-shadow);
    .icon {
      position: absolute;
      left: .3rem;
      top: .48rem;
      width: 0.88rem;
      height: 0.88rem;
      border: 0.01rem solid #e1e1e1;
      overflow: hidden;
      border-radius: 50%;
    }
    .ri {
      h4 {
        font-size: 0.28rem;
        color: #2d322d;
        line-height: 0.42rem;
        font-weight: 600;
        margin-bottom: .1rem;
      }
      p {
        font-size: 0.22rem;
        color: #646464;
        line-height: 0.36rem;
      }
    }
  }
}
.part2{
  position: relative;
  height: 9.1rem;
  padding: .8rem .4rem 0;
  background: url(~@/assets/images/digitization/wisdomIconTowBg.jpg) no-repeat center;
  background-size: 100% 100%;
  .cTil{
    color: #fff;
  }
  .pBox{
    p{
      font-size: .24rem;
      text-align: center;
      color: rgba(255,255,255,.7);
    }
  }
  .txtSwiper{
    position: absolute;
    top: 5.8rem;
    left: 0;
    width: 100%;
    overflow: hidden;
    .swiper-container{
      // width: 6.3rem;
      overflow: visible;
      margin: 0;
    }
    .swiper-slide{
      width: 6.3rem;
      height: 2.14rem;
      margin-right: .2rem;
    }
    .txt{
      border-radius: var(--border-radius);
      height: 100%;
      padding: .5rem .4rem;
      background: #fff;
      h4{
        font-size: .28rem;
        line-height: .4rem;
        color: #2d322d;
        margin-bottom: .1rem;
      }
    }
  }
}
.part3{
  padding: .5rem .4rem;
  .li{
    position: relative;
    padding: .4rem;
    border-radius: var(--border-radius);
    background: #e6e6e6;
    margin-top: .6rem;
    .til{
      position: absolute;
      right: .3rem;
      top: -.38rem;
      font-size: .28rem;
      font-weight: 600;
      color: #48539e;
      line-height: .38rem;
      padding-bottom: .53rem;
      background: url(~@/assets/images/digitization/wisdomIconIconDown.png) no-repeat bottom center;
      background-size: .96rem;
    }
    h4{
      font-size: .28rem;
      color: #2d322d;
      line-height: .38rem;
      margin-bottom: .3rem;
    }
    p{
      font-size: .22rem;
      color: #646464;
      line-height: .36rem;
    }
    &:nth-child(2){
      background: #f0f0f0;
    }
    &:nth-child(3){
      background: #f3f3f3;
    }
  }
}
.part4{
  padding: 0 .4rem;
  .txt{
    padding-bottom: .3rem;
  }
  .pic{
    border-color: #c4d8f1;
  }
}
.part5{
  padding: 0 .4rem;
  .txt{
    padding-bottom: .6rem;
    h4{
      font-size: .26rem;
      color: #343434;
      line-height: .36rem;
      margin-bottom: .3rem;
    }
  }
}
.swiper-container{
  position: relative;
}
.user-pagination{
  margin-top: .3rem;
  display: flex;
  justify-content: center;
  .dot{
    width: .1rem;
    height: .1rem;
    background: #9b9b9b;
    border-radius: 50%;
    margin: 0 .05rem;
    &.on{
      background: #f70000;
    }
  }
}
</style>

