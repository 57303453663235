<template>
  <div class="wrap">
    <nav-ber :title="'建筑工程智慧安全监管平台'"></nav-ber>
    <main class="intelligence">
      <sub-ban :ban-info="banInfo"></sub-ban>
    <section class="constru-npm1">
      <div class="w1440 clear">
        <div class="fl ">
          <ul>
            <li v-for="(item, i) in apiData2" :key="item.newsId">
              <div class="em">
                <em>{{ item.newsTitle }}</em
                ><sub>{{ item.name }}</sub> {{ item.email }}
              </div>
              <p v-html="item.newsContent"></p>
            </li>
          </ul>
        </div>
        <div class="fr ">
          <div class="pagetit ">
            <h5>平台优势</h5>
          </div>
          <ul>
            <li
              v-for="(item, i) in apiData1"
              :key="item.newsId"
              
            >
              <div class="txt">
                <h5 v-html="item.newsTitle"></h5>
                <p v-html="item.newsContent"></p>
              </div>
              <div class="ico"><img :src=" item.image" alt="" /></div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="inte-npm1" v-if="apiData3[0]">
      <div class="w1440">
        <div class="pagetit ">
          <h5 v-html="apiData3[0].newsTitle"></h5> 
        </div>
        <div
          class="text "
          v-html="apiData3[0].newsContent"
        ></div>
        <ul class="">
          <li
            class=""
            v-for="item in apiData4"
            :key="item.newsId"
          >
            <div class="ico"><img :src="item.image" alt="" /></div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <p v-html="item.newsContent"></p>
            </div>
          </li>
        </ul>
      </div>
    </section>
     <section class="inte-npm2" v-if="apiData5[0]">
      <div class="w1440">
        <div class="pagetit ">
          <h5 v-html="apiData5[0].newsTitle"></h5> 
          <p v-html="apiData5[0].newsContent"></p>
        </div>
        <div class="itembox clear ">
          <div class="fl">
            <img
              :src="  apiData5[0].image"
              alt=""
            />
          </div>
          <div class="fr ">
            <div
              class="item"
              v-for="item in apiData6"
              :key="item.newsId"
            >
              <h5 v-html="item.newsTitle"></h5>
              <div v-html="item.newsContent"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="dig-npm2" v-if="apiData7[0]">
      <div class="w1440">
        <div class="h5 ">
          <div class="pagetit ">
            <h5 v-html="apiData7[0].newsTitle"></h5>
          </div>
          <p v-html="apiData7[0].newsContent"></p>
        </div>
        <div class="sildbox clear">
          <div class="fr">
            <div class="tabui ">
              <div
                class="li"
                v-for="(item, i) in apiData8"
                :key="item.newsId" 
                 :class="{ on: selectedIndex === i }"
    @click="handleItemClick(i)"
              >
                <div class="ico">
                  <img
                    :src="  item.image"
                    alt=""
                  />
                </div>
                <h5 v-html="item.email"></h5>
                <i></i>
              </div>
            </div>
          </div>
          <div class="fl ">
            <div class="below">
              <div
                class="item clear "
                v-for="(item, i) in apiData8"
                :key="item.newsId"
              >
                <div class="txt">
                  <h5 v-html="item.email"></h5>
                  <div v-html="item.newsContent"></div>
                </div>
                <div class="img">
                  <img
                    :src="  item.newsAccess"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </section>
     <section class="constru-npm2" v-if="apiData3[0]">
            <div class="w1440 ">
                <div class="pagetit ">
                    <h5>案例成效</h5> 
                </div>
                <div class="clear">
                    <div class="fl ">
                    
                    <div class="img">
                      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000" effect="fade">
        <van-swipe-item >
           <img :src="   apiData9[0].image" alt="">
        </van-swipe-item>
      </van-swipe>
          
                    </div>
                    </div>
                    <div class="fr ">
                        <div class="txt">
                            <h5 v-html="apiData9[0].newsTitle"></h5>
                            <div v-html="apiData9[0].newsContent"></div>
                        </div> 
                    </div>
                </div>
                
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const apiData6 = ref([]);
const apiData7 = ref([]);
const apiData8 = ref([]);
const apiData9 = ref([]);
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
}); 
const selectedIndex = ref(0); 

const handleItemClick = (index) => {
  selectedIndex.value = index;
};
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 477,
    typeId: 127,
  });
  const { data: data2 } = await getInfo({
    coId: 457,
  });
  const { data: data3 } = await getInfo({
    coId: 459,
  });
    const { data: data4 } = await getInfo({
    coId: 461,
  });
   const { data: data5 } = await getInfo({
    coId: 463,
  });
 const { data: data6 } = await getInfo({
    coId: 465,
  });
   const { data: data7 } = await getInfo({
    coId: 467,
  });
  const { data: data8 } = await getInfo({
    coId: 469,
  });
  const { data: data9 } = await getInfo({
    coId: 471,
  });
  const { data: data10 } = await getInfo({
    coId: 473,
  });
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data;
   apiData4.value = data5.data;
   apiData5.value = data6.data;
   apiData6.value = data7.data;
   apiData7.value = data8.data;
   apiData8.value = data9.data;
   apiData9.value = data10.data;
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent; 
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{
    padding: 0;
}
.intelligence {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
 .constru-npm1 {
      padding: 0.9rem 0;
      .fl {
        width: 100%;
        ul {
          display: flex;
          justify-content: space-between;
        }
        li {
          padding: 0;
          border-bottom: none;
          .em {
            font-size: 0.24rem;
            color: #666666;
            em {
              font-size: 0.4rem;
              letter-spacing: -0.02rem;
            }
              em,
          sub { 
            color: #474959;
            font-family: "DIN-Bold";
          }
            sub {
              font-size: 0.24rem;
            }
             p {
          color: #666666;
        }
          }
        }
      }
      .fr {
        width: 100%;
        margin-top: .35rem;
        ul {
          display: block;
          margin-top: 0.35rem;
          li {
            width: 100%;
            margin-bottom: 0.2rem;
            min-height: 2rem;
            padding: 0.4rem 0.3rem;
             background: #f3f4f7; 
             display: flex; 
          justify-content: space-between;
          flex-direction: column;
            h5 {
              font-size: 0.26rem;
              margin-bottom: 0.1rem;
               color: #333333;
            }
            .ico {
              margin-top: 0.25rem;
              width: 0.4rem;
            }
          }
        }
      }
    }
    .inte-npm1 {
      height: auto;
      padding: 0.8rem 0 0.3rem;
      background: url(~@/assets/images/labor/bg4.jpg) no-repeat;
    background-position: center;
    background-size: cover;
     .pagetit {
      h5 {
        text-align: center;
        color: #ffffff;
      } 
    }
      ul {
        position: relative;
        bottom: auto;
        display: block;
        margin-top: 1.5rem;
        li {
          width: 100%;
          margin-bottom: 0.2rem;
           background: #fff;
          padding: 0.4rem 0.3rem;
           .ico{
            width: .4rem;
          }
          .txt {
            width: calc(100% - .5rem);
            h5 {
              font-size: 0.26rem;
              margin-bottom: 0.1rem;
              color: #333333;
            }
          }
        }
      }
      :deep(.text) {
        margin-top: 0.65rem;
        h5 {
          font-size: 0.28rem;
          margin-bottom: 0.1rem;
          color: #135490;
          text-align: center;
        }
        p {
        color: #507aa1;
        text-align: center;
      }
      }
    }
    .inte-npm2 {
      padding: 0.9rem 0;
      background: url(~@/assets/images/labor/xuy.png) no-repeat;
    background-position: bottom left;
      .pagetit {
          h6,
      h5 {
        text-align: center;
      }
        p {
            text-align: center;
          margin-top: 0.2rem;
        }
      }
      .itembox {
        margin-top: 0.5rem;
      }
      :deep(.fr) {
        width: 100%;
        margin-top: 0.35rem;
         border: 1px solid #e1e2e6;
        .item {
          padding: 0.4rem 0.3rem;
          border-bottom: 1px solid #e1e2e6;
        &:last-child {
          border-bottom: 0;
        }
          h5 {
            font-size: 0.26rem;
            margin-bottom: 0.1rem;
            color: #333333;
          }
          p {
            margin-bottom: 0.1rem;
            padding-left: 0.15rem;
             position: relative;
            &::after {
                content: "";
                 left: 0;
                border-radius: 50%;
            background: #d7000f;
            position: absolute;
              width: 0.06rem;
              height: 0.06rem;
              top: 0.13rem;
            }
          }
        }
      }
    }
    .dig-npm2 {
      padding: 0.9rem 0;
      background: #f3f4f7;
      .h5 {
        display: block;
        width: 100%;
        padding-right: 0;
        p {
          text-align: center;
        }
      } 
      .sildbox {
        margin-top: 0.45rem;
        .fr {
          width: 100%;
          .tabui {
            display: -webkit-box;
            overflow-x: scroll;
            overflow-y: hidden;
            .li {
              height: auto;
              padding: .1rem 0;
              margin-right: .3rem;
              display: flex;
          align-items: center;
          position: relative;
          
          &.on {
            h5 {
              color: #d7000f;
            }

            i {
              opacity: 1;
              height: 100%;
            }
          }
              .ico{
                margin-right: .1rem;
                width: .4rem;
              }
              h5{
                font-size: .26rem;
                color: #808080;
              }
              i{
                display: none;
              }
            }
          }
        }
        .fl{
            width: 100%;
            .below{
            height: auto;
            padding: .5rem .3rem;
            background: url(~@/assets/images/labor/bg22.jpg) no-repeat;
        background-position: center left;
        background-size: cover;
            .item {
                display: none;
                 &:nth-child(1) {
            display: block;
          }
                .txt{
                    width: 100%;
                    margin-bottom: .3rem;
                    h5{
                        font-size: .26rem;
                        color: #4c4c4c;
                         margin-bottom: .1rem;
                    }
                }
                .img{
                    width: 100%;
                }
            }
            }
           
        }
      }
    }
     .constru-npm2{
            padding: .9rem 0 .7rem; 
            .soluslid{
              border-radius: 0;
            }
            .clear{
                margin-top: .45rem;
            }
            .fl{
                width: 100%;
                 position: relative;
                  :deep(.van-swipe__indicators){
                    bottom: .3rem;
                    i{
                        width: .52rem;
                        height: .04rem;
                        border-radius: 0;
                        background: #a6a6a6;
          opacity: 1;
                         &.van-swipe__indicator--active{
            background: #d7000f;
          }
                    }
                }
                .img{
                    margin: 0;
                }
            }
            .fr{
                width: 100%;
                .txt{
                    h5{
                        font-size: .26rem;
                        margin-bottom: .15rem;
                        color: #333333;
                    }
                }
                ul{
                    margin-top: .5rem;
                    display: flex;
                flex-wrap: wrap;
                    li{
                        margin-right: .6rem;
                        width: 32%;
                       margin-right: 0;
                        em{
                            font-size: .4rem;
                            letter-spacing: -.02rem;
                            color: #d7000f;
                        font-family: 'DIN-Bold';
                        }
                        p{
                        color: #333333;
                    }
                    }
                }
            }
        }
}
</style>
  
  