<template>
  <div class="wrap">
    <nav-ber :title="'保障性住房全生命周期管理系统'"></nav-ber>
    <main class="suppo">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item wow fadeInUp50" v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit wow fadeInUp50">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
            
            </div>
        </section>
       <section class="constru-npm3" v-if="apiData2[0]">
            <div class="w1440">
                <div class="pagetit wow fadeInUp50">
                    <h5 v-html="apiData2[0].newsTitle"></h5> 
                </div>
                <div v-html="apiData2[0].newsContent"></div>
           
            </div>
        </section>
      <section class="constru-npm2" v-if="apiData3[0]">
            <div class="w1440 ">
                <div class="pagetit ">
                    <h5>案例成效</h5> 
                </div>
                <div class="clear">
                    <div class="fl ">
                    
                    <div class="img">
                      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000" effect="fade">
        <van-swipe-item v-for="image in imageUrlStrings" :key="image">
           <img :src="  image" alt="">
        </van-swipe-item>
      </van-swipe>
          
                    </div>
                    </div>
                    <div class="fr ">
                        <div class="txt">
                            <div v-html="apiData3[0].newsContent"></div>
                        </div> 
                    </div>
                </div>
                
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]); 
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
});
const imageUrlStrings = ref([]);
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 537,
    typeId: 87,
  });
  const { data: data2 } = await getInfo({
    coId: 549,
  });
  const { data: data3 } = await getInfo({
    coId: 551,
  });
    const { data: data4 } = await getInfo({
    coId: 553,
  }); 

  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data; 
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent;
   imageUrlStrings.value = apiData3.value[0].image.split(',')
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{
    padding: 0;
}
.suppo {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0;
             border-bottom: 1px solid #e3e5e8;
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
        }
         .constru-npm3{
            padding: .9rem 0;
            :deep(.txtbox) {
                margin-top: .4rem;
                padding: .4rem .3rem;
                background: #f3f4f7;
                h5{
                    font-size: .28rem;
                    color: #333333;
                position: relative;
                text-align: center;
                    &::after{
                        opacity: 0;
                    }
                }
                ul{
                    margin-top: .5rem;
                    padding-right: 0;
                    display: block;
                    li{
                        width: 100% !important;
                        margin-bottom: .3rem;
                         &:nth-child(1){
                        width: 29.71%;
                        dl{
                            dt{
                                width: 31.7%;
                            }
                        }
                    }
                    &:nth-child(2){
                        width: 19.567%;
                        dl{
                            dt{
                               
                                &:last-child{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &:nth-child(3){
                        width: 19.567%;
                    }
                        h6{
                            font-size: .26rem;
                            color: #333333;
                        text-align: center;
                        }
                        dl{
                            margin-top: .3rem;
                            display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        &::after{
                           content: "";
    width: 31.7%;
                        }
                            dt{
                            font-size: .24rem;
                            margin-bottom: .1rem;
                            border-radius: .6rem;
                             background: #fff;
                             width: 48.148%;
                             text-align: center;
                             color: #808080;
                            height: .8rem;
                            line-height: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        }
                        
                    }
                }
            }
        }
   .constru-npm2{
            padding: .9rem 0 .7rem;
            background: url(~@/assets/images/labor/bg3.jpg);
            background-position: center;
            background-size: cover;
            .soluslid{
              border-radius: 0;
            }
            .clear{
                margin-top: .45rem;
            }
            .fl{
                width: 100%;
                 position: relative;
                  :deep(.van-swipe__indicators){
                    bottom: .3rem;
                    i{
                        width: .52rem;
                        height: .04rem;
                        border-radius: 0;
                        background: #a6a6a6;
          opacity: 1;
                         &.van-swipe__indicator--active{
            background: #d7000f;
          }
                    }
                }
                .img{
                    margin: 0;
                }
            }
            .fr{
                width: 100%;
                :deep(.txt){
                    h5{
                        font-size: .26rem;
                        margin-bottom: .15rem;
                        color: #333333;
                        min-height: 1px;
                        line-height: 1;
                    }
                }
              
            }
        }
}
</style>
  
  