<template>
  <div class="wrap">
    <nav-ber :title="'城市体检系统'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="exami">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item wow fadeInUp50" v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit wow fadeInUp50">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
            <div
          class="below wow fadeInUp50"
          v-html="apiData2[0].newsContent"
        ></div>
            </div>
        </section>
         
      <section class="constru-npm2" v-if="apiData3[0]">
            <div class="w1440 ">
                <div class="pagetit ">
                    <h5 v-html="apiData3[0].newsTitle"></h5>
                </div>
                <div class="clear">
                    <div class="fl ">
                    
                    <div class="img">
                      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000" effect="fade">
        <van-swipe-item >
           <img :src="  apiData3[0].image" alt="">
        </van-swipe-item>
      </van-swipe>
          
                    </div>
                    </div>
                    <div class="fr ">
                        <div class="txte"> 
                            <div v-html="apiData3[0].newsContent"></div>
                        </div> 
                    </div>
                </div>
                
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]); 
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 539,
    typeId: 109,
  });
  const { data: data2 } = await getInfo({
    coId: 615,
  });
  const { data: data3 } = await getInfo({
    coId: 617,
  });
    const { data: data4 } = await getInfo({
    coId: 619,
  }); 

  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data; 
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent;
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.exami {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0; 
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
            :deep(.below) {
        margin-top: 0.4rem;
        .ti1 {
          min-width: 1.8rem;
          padding: 0 0.2rem;
          line-height: 0.8rem;
          font-size: 0.26rem;
          border-radius: 1rem;
          width: auto;
          display: table;
          text-align: center;
        color: #fff;
        margin: auto;
        background: #d7000f;
        }
        ul {
          margin-top: 0.5rem;
          flex-wrap: wrap;
          display: flex;
        justify-content: space-between;
        position: relative;
          &::after {
            content: "";
            width: 90%;
            height: 0.5rem;
            top: -0.5rem;
             background: url(~@/assets/images/labor/line10.png);
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
            background-size: 100%;
            background-repeat: no-repeat;
          }
          li {
            width: 100%;
            padding: 0.4rem 0.3rem !important;
            margin-bottom: 0.1rem;
             background: #f3f4f7;
            h5 {
              font-size: 0.26rem;
              color: #333333;
              text-align: center;
            }
            dl {
              margin-top: 0.3rem;
              display: flex;
            flex-wrap: wrap;
            justify-content: center;
              dt {
                background: #fff;
                line-height: 0.8rem;
                width: 31.6984%;
                font-size: 0.24rem;
                margin:0 .05rem 0.17rem;
                 max-width: 2.5rem;
                border-radius: 1rem;
                width: 48% !important;
                color: #808080;
                text-align: center;
              }
            }
          }
        }
        .box{
          max-width: 100%;
          margin: 0.3rem auto 0.48rem;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
           position: relative;
          &::after {
             content: "";
            width: 80%;
            background: url(~@/assets/images/labor/line12.png);
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
            background-size: 100%;
            background-repeat: no-repeat;
            height: 0.5rem;
            bottom: -0.5rem;
          }
          .item {
            width: 48%;
            font-size: 0.24rem;
            margin-bottom: 4%;
            color: #808080;
            text-align: center;
          background: #f3f4f7;
           line-height: .8rem;
            border-radius: 1rem;
          }
        }
      }
        }
       
   .constru-npm2{
            padding: .9rem 0 .7rem;
            background: url(~@/assets/images/labor/bg3.jpg);
            background-position: center;
            background-size: cover;
            .soluslid{
              border-radius: 0;
            }
            .clear{
                margin-top: .45rem;
            }
            .fl{
                width: 100%;
                 position: relative;
                  :deep(.van-swipe__indicators){
                    bottom: .3rem;
                    i{
                        width: .52rem;
                        height: .04rem;
                        border-radius: 0;
                        background: #a6a6a6;
          opacity: 1;
                         &.van-swipe__indicator--active{
            background: #d7000f;
          }
                    }
                }
                .img{
                    margin: 0;
                }
            }
            :deep(.fr){
                width: 100%;
                .txt{
                     border-bottom: 1px solid #c2d5dd;
                       padding: .3rem 0;
                    &:nth-child(1){
                            padding: 0 0 .3rem;
                    }
                    &:last-child {
          border-bottom: none;
        }
                    h5{
                        font-size: .26rem;
                        margin-bottom: .15rem;
                        line-height: 1.1;
                        color: #333333;
                    }
                }
             
            
            }
        }
}
</style>
  
  