<template>
  <div class="cBanner">
    <Swiper
      :pagination="pagination"
      :autoplay="autoplay"
      :slides-per-view="1"
      :modules="modules"
      :loop="loop"
      class="banner"
      @slideChange="oNslideChange"
    >
      <swiper-slide v-for="item in list" :key="item.newsId" :style="`background-image: url(${$store.state.localHttp + item.image})`">
        <div class="txt" v-html="item.newsContent"></div>
      </swiper-slide>
    </Swiper>
  </div>
</template>
<script setup>
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { defineProps, ref, onMounted, nextTick } from "vue";
const props = defineProps({
  list: Array,
  default: []
});
const loop = ref(false);
const pagination = {
  clickable: true
};
const autoplay = {
  delay: 3000,
  disableOnInteraction: false
}
const modules = [Pagination, Autoplay];

const oNslideChange = (swiper) => {
  // console.log('测试', swiper)
}
onMounted(() => {
  setTimeout(function(){
    loop.value = true;
  },100)
})
</script>
<style scoped lang="scss">
:deep(.banner) {
  position: relative;
  overflow: hidden;
  border-radius: .28rem;
  margin-bottom: .6rem;
  .swiper-slide{
    height: 3.7rem;
    background: no-repeat center;
    background-size: cover;
  }
  .swiper-pagination {
    height: .1rem;
    line-height: .1rem;
    bottom: 0.3rem;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: .1rem;
    font-size: 0;
    .swiper-pagination-bullet {
      vertical-align: top;
      margin: 0 .04rem;
      width: .1rem;
      height: 100%;
      border-radius: 50%;
      opacity: 1;
      transition: all 0.3s;
      background: #fff;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: #f70000;
    }
  }
  .txt{
    position: absolute;
    left: 0;
    top: 50%;
    width: 4.2rem;
    padding: .4rem;
    transform: translateY(-50%);
    h3,h5{
      font-size: .38rem;
      line-height: .48rem;
      font-weight: 600;
      letter-spacing: .04rem;
      margin-bottom: .2rem;
      color: #fff;
    }
    h6{
      display: none;
    }
    p,.p{
      font-size: .22rem;
      line-height: .3rem;
      color: rgba(255,255,255,.7);
    }
  }
}
</style>
  
  