import axios from 'axios';
import md5 from 'js-md5';

// 测试秘钥key值：m69uymmhyw
// 生产秘钥key值：gw3ap7gxw8
let key = md5("m69uymmhyw")
const service = axios.create({
    timeout: 40000,
    // baseURL: 'http://10.21.166.230:8003/api' //生产地址
    // baseURL: 'http://10.95.2.103:8003/api' //测试地址
    baseURL: 'https://www.huajiantong.com/api/' //对外地址
})

// 请求拦截
service.interceptors.request.use(config => {
    // 处理参数
    return config;
},err => {
    console.log("请求拦截报错内容："+err)
})

// 响应拦截
service.interceptors.response.use(response => {
    // 处理参数
    return response;
},err => {
    console.log("响应拦截报错内容："+err)
})

// 导出请求函数
export function get(url, params, Authorization){
    // console.log(params, Authorization)
    let key = '';
    if(Authorization){
        key = Authorization;
    }
    return service.get(
        url, 
        {
            params,
            headers: {
                'Authorization': key
            }
        }
    )
}

export function post(url, data, Authorization){
    let key = '';
    if(Authorization){
        key = Authorization;
    }
    return service.post(
        url, 
        data,
        {
            headers: {
                'Authorization': key
            }
        }
    )
}

