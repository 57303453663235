<template>
  <div class="wrap">
  <div class="elplatform">
    <nav-ber title="EL数字生态平台"></nav-ber>
    <main>
      <sub-ban :ban-info="banInfo"></sub-ban>
    <section class="owne-npm1">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>现状与挑战</h5>
          <h6>Current situation and challenge</h6>
        </div>
        <div class="box clear">
          <div class="item" v-for="(item, i) in apiData" :key="item.newsId" :style="'animation-delay:  '+ (i * 150) + 'ms'">
            <div class="img">
              <img :src="item.image" alt="">
            </div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <div class="p" v-html="item.newsContent"> 
              </div>
            </div>
          </div>
     
        </div>
      </div>
    </section>
    <section class="elpl-npm2">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData1[0].newsTitle"></h5>
          <h6 v-html="apiData1[0].email"></h6>
          <div class="p" v-html="apiData1[0].newsContent">
           
          </div>
        </div>
        <div class="above wow fadeInUp50" v-html="apiData1[0].newsTags">
         
        </div>
        </div>
    </section>
    <section class="elpl-npm3">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
          <h5>产品特性</h5>
          <h6>Product characteristics</h6>
        </div>
        <swiper class="soluslid wow fadeInUp50" :modules="[Navigation, EffectFade, Pagination, Autoplay]"
            :loop="true" :speed="700" :autoplay="{
              delay: 4000,
              disableOnInteraction: true,
            }" effect="fade" :fadeEffect="{ crossFade: true }" :pagination="{ clickable: true }">

            <swiper-slide v-for="(item, i) in apiData2" :key="item.newsId">
              <div class="above">
                  <img :src="    item.image" alt="">
              </div>
              <div class="below">
                  <h5 v-html="item.newsTitle"></h5>
                  <p v-html="item.newsContent"></p>
              </div>
            </swiper-slide>
          
    
        </swiper>
      </div>
    </section>
    <section class="elpl-npm4">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>核心价值</h5>
          <h6>Core value</h6>
        </div>
        <ul>
          <li class="wow fadeInUp50" v-for="(item, i) in apiData3" :key="item.newsId" :style="'animation-delay:  '+ (i * 150) + 'ms'">
            <div class="ico">
              <img :src="item.newsAccess" alt="">
            </div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <div class="p" v-html="item.newsContent">
              </div>
            </div>
          </li>
         
        </ul>
      </div>
    </section>
    <section class="owne-npm8">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>产品优势</h5>
          <h6>Core value</h6>
        </div>
        <div class="ul">
          <div class="li wow fadeInUp50" v-for="(item, i) in apiData4" :key="item.newsId" :style="'animation-delay:  '+ (i * 150) + 'ms'">
            <div class="txt">
              <div class="i"><img :src="item.newsAccess" alt=""></div>
              <div class="p" v-html="item.newsContent"> 
              </div>
            </div>
              <em>{{item.newsTitle}}</em>
          </div>
        
        </div>
      </div>
    </section>
  </main>
  </div>
</div>
</template>
  
<script setup >
import { Navigation, EffectFade, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const banInfo = reactive({
  banUrl: '',
  tilCn: '',
  tilTxt: '',
  link: '',
  coId: "",
  enlink: true,
  txton: "免费试用",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: "903",
  });
  const { data: data2 } = await getInfo({
    coId: "907",
  });
  const { data: data3 } = await getInfo({
    coId: "909",
  });
  const { data: data4 } = await getInfo({
    coId: "911",
  });
  const { data: data5 } = await getInfo({
    coId: "913",
  });
  const { data: data6 } = await getInfo({
    coId: "423",  
    pageSize:999,
    typeId:"4171",
  });
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
  apiData3.value = data4.data;
  apiData4.value = data5.data;
  apiData5.value = data6.data;
  banInfo.banUrl = apiData5.value[0].image;
  banInfo.tilCn = apiData5.value[0].newsTitle;
  banInfo.tilTxt = apiData5.value[0].newsContent;
};
onMounted(async () => {
   getList();
});
</script>
<style lang="scss">
.elplatform {
  main{padding: 0;}

  .pagetit h5,
  .pagetit h6 {
    text-align: center;

  }

  .pagetit {
    .p {
      margin-top: 25px;

      p {
        text-align: center;
      }
    }
  }

  .owne-npm1 {
    padding: 85px 0 95px;

    .box {
      margin-top: 40px;
      display: flex;

      .item {
        width: 32.36%;
        float: left;
        margin-right: 1.25%;
        background: #f3f4f7;
        transition: all .4s;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img {
          overflow: hidden;

          img {
            transition: all .4s;
          }
        }

        &:hover {
          background: #d7000f;

          img {
            transform: scale(1.1);
          }

          .txt {

            h5,
            p {
              color: #fff;
            }
          }
        }
      }

      .txt {
        padding: 35px 30px;

        h5 {
          font-size: 18px;
          margin-bottom: 10px;
          color: #333333;
        }

        p {
          line-height: 22px;
        }
      }
    }
  }
  .elpl-npm2{
    background: url(https://www.huajiantong.com/images/digital/bg9.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 90px;
    .pagetit {
    .p {
      p {
        color: #637d9a;
      }
    }
  }
  .above{
    background: #fff;
    padding: 40px 40px 0;
    margin-top: 35px;
    .box1{
      border-radius: 10px;
      background: #d7000f;
      height: 70px;
      padding: 0 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      color: #fff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .box2{
      border-radius: 10px;
      background: #d7000f;
      height: 70px;
      padding: 0 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      color: #fff;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .box3{
      border-radius: 10px;
      background: #f3f4f7;
      padding: 40px 20px 25px;
      margin-bottom: 20px;
      h5{
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
        color: #333333;
      }
      .dd{
        border-radius: 36px;
        background: #fff;
        height: 60px;
        font-size: 14px;
        color: #808080;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .dl{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .dl1{
        .dd{
          width: 24.393%;
        }
      }
      .dl2{
        .dd{
          width: 32.8%;
        }
      }
      .dl3{
        .dd{
          width:15.985%;
          &:nth-child(1){
            width: 49.6%;
          }
        }
      }
    }
    .box4{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .dd{
        border-radius: 10px;
        font-size: 14px;
        color: #808080;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        background: #f3f4f7;
        width: 19.4%;
      }
    }
  }
  }
  .elpl-npm4{
    padding: 90px 0;
    background: url(https://www.huajiantong.com/images/digital/bg3.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    ul{
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      li{
        width:18.889%;
        padding: 40px 10px;
        background: #fff;
        min-height: 351px;
        transition: all .4s;
        position: relative;
        top: 0;
        &:hover{
          top: -10px;
        }
        .ico{
          border-radius: 50%;
          background: #f3f4f7;
          width: 100px;
          height: 100px;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .txt{
          margin-top: 30px;
          h5{
            text-align: center;
            font-size: 18px;
            margin-bottom: 10px;
            color: #333333;
          }
          p{
            text-align: center;
          }
        }
      }
    }
  }
  .elpl-npm3{
    background: url(https://www.huajiantong.com/images/digital/bg11.png) no-repeat;
    background-position: center; 
    padding: 90px 0;
    .soluslid{
      margin-top: 40px;
      .above{
        text-align: center;
      }
      .below{
        margin-top: -45px;
      }
      h5{
        text-align: center;
        font-size: 32px;
        margin-bottom: 10px;
        color: #333333;
        font-weight: 400;
      }
      p{
        font-size: 18px;
        text-align: center;
      }
    }
    .swiper {
          padding-bottom: 45px;

          .swiper-pagination {
            display: flex;
            justify-content: center;

            span {
              width: 15px;
              height: 4px;
              border-radius: 0;
              opacity: 1;
              margin: 0 5px;
              background: #afafaf;

              &.swiper-pagination-bullet-active {
                background: #d7000f;
                width: 50px;
              }
            }
          }
        }
  }
  .owne-npm8{
    padding: 90px 0;
    .ul{
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .li{
        background: #f3f4f7;
        width: 23.958%;
        min-height: 254px;
        padding: 40px 30px;
        display: flex;
    flex-direction: column;
    justify-content: space-between;
        .p{
          margin-top: 15px;
        }
        em{
          color: #d7000f;
             
          font-size: 30px;
          margin-top: 20px;
        }
      }
    }
  }

}



@media (max-width:600px) {
  .elplatform{
    .owne-npm1{
      padding: .6rem 0;
      .box{
        flex-wrap: wrap;
        margin-top: .5rem;
        .item{
          width: 100%;
          margin: 0 0 .3rem;
        }
        .txt{
          padding: .3rem;
          h5{
            font-size: .28rem;
            line-height: 1.5;
          }
        }
      }
    }
    .elpl-npm2{
      padding-top: .6rem;
      .above{
        margin-top: .5rem;
        height: 3.5rem;
        background: url(https://www.huajiantong.com/images/digital/elplatformChart1.jpg) no-repeat center;
        background-size: 100% 100%;
        & > *{
          display: none !important;
        }
      }
    }
    .elpl-npm3{
      padding: .6rem 0;
      .soluslid{
        margin-top: -.1rem;
        h5{
          font-size: .4rem;
          line-height: .6rem;
        }
        p{
          font-size: .26rem;
        }
      }
    }
    .elpl-npm4{
      padding: .6rem 0;
      ul{
        margin-top: .5rem;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
          width: 3.25rem;
          height: auto;
          padding: .5rem .3rem;
          margin-bottom: .2rem;
          min-height: auto;
          .ico{
            width: 1.6rem;
            height: 1.6rem;
          }
          .txt{
            margin-top: .4rem;
            h5{
              font-size: .28rem;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .owne-npm8{
      padding: .6rem 0;
      .ul{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: .5rem;
        .li{
          width: 3.25rem;
          height: auto;
          min-height: auto;
          padding: .5rem .3rem;
          margin-bottom: .2rem;
        }
      }
    }
  }
}

</style>
  
  