<template>
  <div class="wrap">
    <nav-ber :title="insuranceType" homeUrl="/financial"></nav-ber>
    <main>
      <div class="topBox">
        <h2>{{insuranceType}}</h2>
        <!-- <h4>涵盖施工全过程风险，保障工程顺利完成及投入使用</h4> -->
      </div>
      <div class="content">
        <section>
          <h3 class="cTil"><em>产品介绍</em></h3>
          <div class="pBox" v-html="apiData0.newsContent"></div>
        </section>
        <section>
          <h3 class="cTil"><em>保险责任 / 保障范围</em></h3>
          <div class="pBox" v-html="apiData0.newsAuthor"></div>
        </section>
        <div @click="$router.push({'path': '/embed', query: {'link': apiData0.appUrl}})" class="cApplyBtn">申请办理</div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { useRoute } from 'vue-router';
import { getFinancialInfo } from "@/api/http";
import { ref } from "vue";
const route = useRoute();
const insuranceType = route.query.type;
const newsId = route.query.newsId;
const apiData0 = ref({});
const getData = async () => {
  let { data: data } = await getFinancialInfo({ newsId })
  apiData0.value = data.data;
  console.log('测试11', data)
}
getData();
</script>

<style scoped lang="scss">
main{
  padding: 0;
  background: url(~@/assets/images/financial/insuranceDataTopBg.jpg) no-repeat top;
  background-size: 100%;
}
.topBox{
  height: 3.36rem;
  padding: 1.3rem .4rem 0;
  padding-right: 3.6rem;
  h2{
    font-size: .38rem;
    line-height: .58rem;
    margin-bottom: .1rem;
    font-weight: 600;
    background: linear-gradient(to top right, #fcbc52, #efd0a4);  
    -webkit-background-clip: text;  
    -webkit-text-fill-color: transparent; 
  }
  h4{
    width: 2.9rem;
    font-size: .24rem;
    color: rgba(255,255,255,.6);
    line-height: .3rem;
  }
}
.content{
  padding: .6rem .4rem;
  border-radius: .28rem .28rem 0 0;
  background: #fff;
  section{
    margin-bottom: .6rem;
  }
  .cTil{
    margin-bottom: .33rem;
  }
  .pic{
    overflow: hidden;
    border-radius: var(--border-radius-s);
    border: .03rem solid #c7c7c7;
    margin-top: .3rem;
  }
  .cTil{
    text-align: center;
    em:after{
      right: 0;
      margin: 0 auto;
    }
  }
  :deep(.pBox){
    h5{
      font-size: .22rem;
      color: #333;
      line-height: .36rem;
    }
    p{
      text-indent: 2em;
      margin-bottom: .36rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

</style>

