<template>
  <div class="wrap">
    <nav-ber :title="'建筑政务大厅'"></nav-ber>
    <main class="affairshall">
      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000">
        <van-swipe-item v-for="item in apiData" :key="item.newsId">
          <div
            class="img"
            :style="`background-image: url(${
                  item.image
            });`"
          >
            <div class="w1440">
              <div class="cont ">
                <div v-html="item.newsContent"></div> 
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
        <section class="affa-npm1" v-if="apiData1[0]">
      <div class="w1440">
          <div class="box" :style="`background-image: url(${    apiData1[0].newsAccess});`">
            <div class="i">
                <img src="/images/digital/badge.png" alt="">
            </div>
            <div class="txt">
              <h6 v-html="apiData1[0].newsContent"></h6>
              <h5>{{apiData1[0].newsTitle}}</h5>
              <div class="a" @click="skipOutsideChain(apiData1[0].address)">进入平台</div>
            </div>
          </div>
      </div>
   </section>
   <section class="affa-npm2" v-for="(item, index) in apiData3" :key="item.newsTitle">
      <div class="w1440">
        <div class="h5">{{apiData2[index].newsTitle}}</div>
       <van-swipe :autoplay="3000" indicator-color="#f70000">
        <van-swipe-item v-for="it in item.items" :key="it.newsId">
         <div class="a" @click="skipOutsideChain(it.address)">
              <div class="img">
              <em>{{it.name}}</em>
              <img :src="   it.newsAccess" alt="">
            </div>
            <div class="txte">
              {{it.newsTitle}}
            </div>
            </div>
        </van-swipe-item>
      </van-swipe>
      </div>
    </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getInfo,getBuilding } from "@/api/http";
import { skipOutsideChain } from '@/api/public';
import { ref } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const getList = async () => {
  const { data:data1 } = await getInfo({
    coId: 867,
  });
  const { data: data2 } = await getInfo({
    coId: 869,
  });
  const { data: data3 } = await getInfo({
    coId: 799,
  });
  const { data: data4 } = await getBuilding();
  apiData.value =data1.data;
  apiData1.value = data2.data;
apiData2.value = data3.data;
apiData3.value = data4.data;
};

getList();
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.affairshall {
  .w1440{
    padding: 0 .3rem;
  }
  .soluslid {
    height: 4.5rem;
    border-radius: 0;
    .swiper-pagination {
      width: 100%;
    }
    .w1440 {
      padding: 1rem .3rem 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; 
    }
    :deep(.cont) {
      h5 {
        font-size: 0.3rem;
        color: #fff;
      }
      h6 {
        font-size: 0.3rem;
        color: #fff;
        font-family: DIN-Bold;
        letter-spacing: -0.05px;
        opacity: 0.5;
        text-transform: uppercase;
      }
      .p {
        max-width: 100%;
        margin-top: 0.2rem;
        p {
          color: #fff;
          font-size: 0.24rem;
          line-height: 0.32rem;
        }
      }
    }
    .img {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    :deep(.van-swipe__indicators) {
      bottom: 0.3rem;
      display: flex;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      justify-content: center;
      transform: none;
      i {
        margin: 0 0.05rem;
        width: 0.52rem;
        height: 0.04rem;
        background: #fff;
        opacity: 0.5;
        border-radius: 0;
        transition: all 0.4s;
      }
    }
  }
.affa-npm1{
      margin: .9rem 0 .5rem;
      .box{
            height: auto;
            background-position: center;
      background-size: cover;
      display: flex;
      background-size: cover;
    margin-bottom: 0.2rem;
    min-height: 4rem;
    padding: 0.9rem 0.3rem 0.5rem;
    .i{
      width: .54rem;
    }
    .txt{
      margin: .1rem 0 0 .15rem;
      h6{
        p{
font-size: .26rem;
color: #7e7470;
margin-bottom: 5px;
        }
        
        margin-bottom: .05rem;
      }
      h5{
        font-size: .28rem;
        color: #d7000f;
      }
      a, .a{
        border-radius: 1rem;
        background: #d7000f;
        color: #fff;
        text-align: center;
    font-size: .24rem;
    height: 0.8rem;
    line-height: .8rem;
    margin-top: 0.5rem;
    width: 1.8rem;
      }
    }
      }
    }
    .affa-npm2{
          margin-bottom: .5rem; 
          .van-swipe-item{
            padding: .1rem;
          }
          .van-swipe{
            padding-bottom: .45rem;
            border-radius: 0;
          }
          :deep(.van-swipe__indicators){
            i{
              width: .5rem;
              height: .04rem;
              margin: 0 .06rem;
                background-color: #ccc;
                    border-radius: 0;
                    height: .04rem;
                    width: .5rem;
                    opacity: 1;
                    &.van-swipe__indicator--active{
                      background: #d7000f;
                    }
            }
          }
          .h5{
            font-size: .28rem;
            margin-bottom: .25rem;
             color: #333333;
          }
          .img{
            height: 3rem;
            position: relative;
            img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
            em{
              font-size: .22rem;
              left: .3rem;
              top: .25rem;
               position: absolute;
               line-height: 1;
        color: #fff;
            }
          }
          .txte{
            padding: .25rem .3rem;
            font-size: .24rem;
             overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
      color: #808080;
      background: #f5f5f5;
          }
    }
}
</style>
  
  