<template>
  <div class="wrap">
    <nav-ber :title="'东海建筑一体机'"></nav-ber>
    <main class="allinonemachine">
      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000">
        <van-swipe-item v-for="item in apiData" :key="item.newsId">
          <div
            class="img"
            :style="`background-image: url(${
              $store.state.localHttp + item.image
            });`"
          >
            <div class="w1440">
              <div class="cont">
                <div v-html="item.newsContent"></div>
                <router-link
                  class="a"
                  :to="{
                    path: '/consultform',
                    query: { plateArr: '一体机应用专区' },
                  }"
                  >立即咨询</router-link
                >
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <section class="all-npm5" v-if="apiData6[0]">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5 v-html="apiData6[0].newsTitle"></h5>
            <div class="p" v-html="apiData6[0].newsContent"></div>
          </div>
          <div class="below wow fadeInUp50" v-if="apiData7.length">
            <div class="item" v-for="item in apiData7" :key="item.newsId">
              <div class="i">
                <img :src="$store.state.localHttp + item.newsAccess" alt="" />
              </div>
              <div class="txt">
                <h5 v-html="item.newsTitle"></h5>
                <div v-html="item.newsContent"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="all-npm0" v-if="apiData1[0]">
        <div class="w1440">
          <div class="fl wow fadeInUp50">
            <img
              :src="$store.state.localHttp + apiData1[0].newsAccess"
              alt=""
            />
          </div>
          <div class="fr wow fadeInUp50">
            <h5 v-html="apiData1[0].newsTitle"></h5>
            <div class="p" v-html="apiData1[0].newsContent"></div>
          </div>
        </div>
      </section>
      <section class="all-npm1" v-if="apiData2.length">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5>产品亮点</h5>
          </div>
          <div class="ul clear wow fadeInUp50">
            <!--[-->
            <div class="li" v-for="item in apiData2" :key="item.newsId">
              <div class="txt">
                <div class="i">
                  <img :src="$store.state.localHttp + item.image" alt="" />
                </div>
                <div class="p">
                  <em v-html="item.newsTitle"></em>
                  <p v-html="item.newsContent"></p>
                </div>
              </div>
            </div>
            <!--]-->
            <div class="one">
              <img src="~@/assets/images/digital/one.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="all-npm2" v-if="apiData4[0]">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5>产品价值</h5>
          </div>
        </div>
        <div class="box">
          <div class="w1440">
            <div class="fl">
              <img
                :src="$store.state.localHttp + apiData4[0].newsAccess"
                alt=""
              />
            </div>
            <div class="fr">
              <div class="ul">
                <div
                  class="li wow fadeInUp50"
                  v-for="item in apiData3"
                  :key="item.newsId"
                >
                  <div class="i">
                    <img :src="$store.state.localHttp + item.image" alt="" />
                  </div>
                  <div class="txt">
                    <h5>{{ item.newsTitle }}</h5>
                    <div class="p" v-html="item.newsContent"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="all-npm3" v-if="apiData4[0]">
        <div class="w1440" v-html="apiData4[0].newsContent"></div>
      </section>
      <section class="all-npm6 all-npmarr" v-if="apiData8.length">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5>产品技术架构</h5>
          </div>
          <div class="cTabWr">
            <div class="w1440">
              <van-tabs
                v-model:active="cTabActive"
                class="ball"
                :ellipsis="false"
                swipe-threshold="1"
              >
                <van-tab
                  :title="item.newsTitle"
                  v-for="item in apiData8"
                  :key="item.newsId"
                ></van-tab>
              </van-tabs>
            </div>
            <div class="cTabBd">
              <div class="ul">
                <div
                  class="item"
                  v-for="(item, i) in apiData8"
                  :key="item.newsId"
                  v-show="cTabActive == i"
                >
                  <div
                    class="p"
                    v-if="item.newsContent"
                    v-html="item.newsContent"
                  ></div>
                  <div class="img">
                    <img
                      :src="$store.state.localHttp + item.newsAccess"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="all-npm7 all-npmarr">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5>产品业务组件</h5>
          </div>
          <div class="cTabWr">
            <div class="w1440">
              <van-tabs
                v-model:active="cTabActive2"
                class="ball"
                :ellipsis="false"
                swipe-threshold="1"
              >
                <van-tab
                  :title="item.newsTitle"
                  v-for="item in apiData9"
                  :key="item.newsId"
                ></van-tab>
              </van-tabs>
            </div>
            <div class="cTabBd">
              <div class="ul">
                <div
                  class="item"
                  v-for="(item, i) in apiData9"
                  :key="item.newsId"
                  v-show="cTabActive2 == i"
                >
                  <div
                    class="p"
                    v-if="item.newsContent"
                    v-html="item.newsContent"
                  ></div>
                  <div class="img">
                    <img
                      :src="$store.state.localHttp + item.newsAccess"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="all-npm4" v-if="apiData4[0]">
        <div class="w1440" v-html="apiData4[0].newsTags"></div>
      </section>
      <section class="know-npm3">
        <div class="w1440">
          <div class="hometit wow fadeInUp50">
            <h5>服务与承诺</h5>
          </div>
          <div class="box clear">
            <div class="fl wow fadeInUp50">
              <img src="~@/assets/images/digital/img4.png" alt="" />
            </div>
            <div class="ul wow fadeInUp50">
              <div class="li" v-for="item in apiData10" :key="item.newsId">
                <div class="i">
                  <img :src="$store.state.localHttp + item.newsAccess" alt="" />
                </div>
                <div class="txt">
                  <h5 v-html="item.newsTitle"></h5>
                  <p v-html="item.newsContent"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getInfo } from "@/api/http";
import { ref } from "vue";
const cTabActive = ref(0);
const cTabActive2 = ref(0);
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const apiData6 = ref([]);
const apiData7 = ref([]);
const apiData8 = ref([]);
const apiData9 = ref([]);
const apiData10 = ref([]);
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 789,
  });
  const { data: data2 } = await getInfo({
    coId: 375,
  });
  const { data: data3 } = await getInfo({
    coId: 377,
  });
  const { data: data4 } = await getInfo({
    coId: 379,
  });
  const { data: data5 } = await getInfo({
    coId: 381,
  });
  const { data: data6 } = await getInfo({
    coId: 407,
  });
  const { data: data7 } = await getInfo({
    coId: 409,
  });
  const { data: data8 } = await getInfo({
    coId: 1063,
  });
  const { data: data9 } = await getInfo({
    coId: 1065,
  });
  const { data: data10 } = await getInfo({
    coId: 1067,
  });
  const { data: data11 } = await getInfo({
    coId: 1069,
  });
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
  apiData3.value = data4.data;
  apiData4.value = data5.data;
  apiData5.value = data6.data;
  apiData6.value = data7.data;
  apiData7.value = data8.data;
  apiData8.value = data9.data;
  apiData9.value = data10.data;
  apiData10.value = data11.data;
};

getList();
</script>
  
<style scoped lang="scss">
main {
  padding: 0;
}
.allinonemachine {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .soluslid {
    height: 6rem;
    border-radius: 0;
    .swiper-pagination {
      width: 100%;
    }
    .w1440 {
      padding: 1rem 0.3rem 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    :deep(.cont) {
      h5 {
        font-size: 0.3rem;
        color: #fff;
      }
      h6 {
        font-size: 0.3rem;
        color: #fff;
        font-family: DIN-Bold;
        letter-spacing: -0.05px;
        opacity: 0.5;
        text-transform: uppercase;
      }
      .p {
        max-width: 100%;
        margin-top: 0.2rem;
        p {
          color: #fff;
          font-size: 0.24rem;
          line-height: 0.32rem;
        }
      }
      a {
        border-radius: 1rem;
        color: #fff;
        background: #d7000f;
        text-align: center;
        font-size: 0.24rem;
        height: 0.8rem;
        line-height: 0.8rem;
        margin-top: 0.5rem;
        width: 1.8rem;
      }
    }
    .img {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    :deep(.van-swipe__indicators) {
      bottom: 0.3rem;
      display: flex;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      justify-content: center;
      transform: none;
      i {
        margin: 0 0.05rem;
        width: 0.52rem;
        height: 0.04rem;
        background: #fff;
        opacity: 0.5;
        border-radius: 0;
        transition: all 0.4s;
      }
    }
  }
  .all-npm0 {
    padding: 0.9rem 0;
    background: #afb9c2;
    .w1440 {
      display: block;
      overflow: hidden;
    }
    .fl {
      width: 100%;
    }
    :deep(.fr) {
      width: 100%;
      padding: 0.45rem 0.3rem;
      background: #fff;
      border-bottom: 1px solid #e6e7eb;
      h5 {
        font-size: 0.28rem;
      }
      .p {
        margin-top: 0.25rem;
        p {
          margin-bottom: 0.15rem;
        }
      }
    }
  }
  .all-npm2 {
    .box {
      margin-top: 0.35rem;
      &::after {
        display: none;
      }
      .w1440 {
        display: block;
        padding: 0;
        overflow: hidden;
      }
    }
    .fl {
      width: 100%;
      padding: 0.45rem 0.3rem;
      background: url(~@/assets/images/labor/alljpg1.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }
    .fr {
      width: 100%;
      padding: 0.45rem 0.3rem;
      background: #f3f4f7;
      .li {
        padding: 0.35rem 0;
        overflow: hidden;
        border-bottom: 1px solid #e8eaed;
        &:last-child {
          border-bottom: none;
        }
        .i {
          width: 0.8rem;
          height: 0.8rem;
          border-radius: 5px;
          overflow: hidden;
          float: left;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 0.35rem;
          }
        }
        :deep(.txt) {
          width: calc(100% - 1rem);
          float: right;
          h5 {
            font-size: 0.26rem;
            margin-bottom: 0.1rem;
          }
          p {
            margin-bottom: 0.05rem;
            line-height: 0.32rem;
            position: relative;
            &::after {
              content: "";
              width: 0.06rem;
              height: 0.06rem;
              left: -0.1rem;
              top: 0.13rem;
              border-radius: 50%;
              background: #a5adbd;
              position: absolute;
            }
          }
        }
      }
    }
  }
  .all-npm3 {
    padding: 0.9rem 0;
    :deep(.box) {
      margin-top: 0.35rem;
      overflow-x: auto;
      .tit {
        font-size: 0.28rem;
        line-height: 0.9rem;
        width: 12rem;
        color: #fff;
        background: #d7000f;
        text-align: center;
      }
      .dl {
        margin-top: 0.1rem;
        display: flex;
        &:nth-child(1) {
          .dt {
            .p {
              width: 100%;
            }
          }
        }
        .dd {
          font-size: 0.28rem;
          width: 17.71%;
          background: #d7000f;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }
        .dt {
          padding: 0.25rem 0.6rem;
          background: #f3f4f7;
          width: 82.29%;
          .p {
            width: 100%;
            margin-right: 0.3rem;
            float: left;
            display: flex;
            flex-wrap: wrap;
            &:nth-child(2) {
              width: 100% !important;
            }
            &:nth-child(3) {
              width: 100%;
            }
            &:nth-child(5) {
              width: 100% !important;
            }
            p {
              margin-right: 0.4rem;
              font-size: 0.24rem;
            }
          }
        }
        &:nth-child(5) {
          .dt {
            .p {
              width: 100% !important;
            }
          }
        }
      }
    }
    :deep(.ul) {
      width: 12rem;
    }
  }
  :deep(.all-npm4) {
    padding: 0.9rem 0;
    background: #f3f4f7;
    overflow: hidden;
    .boxti {
      overflow-x: auto;
    }
    .box {
      margin-top: 0.35rem;
      width: 18rem;
    }
    dl {
      display: flex;
      background: #fff;
      &:nth-child(1) {
        background: #d7000f !important;
        dd {
          font-size: 0.28rem;
          color: #fff !important;
        }
      }
      &:nth-child(2n + 1) {
        background: #e1e2e6;
      }
      &:nth-child(n + 2) {
        border-right: 1px solid #cacbcf;
        border-left: 1px solid #cacbcf;
      }
      dd {
        padding: 0.2rem 0.27rem;
        font-size: 0.24rem;
        line-height: 0.32rem;
        color: #808080;
        border-right: 1px solid #cacbcf;
        &:nth-child(1) {
          width: 13.88%;
          color: #333;
          display: flex;
          align-items: center;
        }
        &:nth-child(2) {
          width: 20.8%;
        }
        &:nth-child(3) {
          width: 22.92%;
        }
        &:nth-child(4) {
          width: 21.53%;
        }
        &:nth-child(5) {
          width: 20.8%;
          border-right: none;
        }
      }
    }
  }
}

.all-npm5 {
  padding: 0.9rem 0;
}
.all-npm5 p,
.all-npm5 :deep(p) {
  margin-top: 0.15rem;
  text-align: center;
}
.all-npm5 .below {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
}
.all-npm5 .below .item {
  border: 1px solid #ebebeb;
  margin-bottom: 2%;
  min-height: auto;
  padding: 0.4rem 0.3rem;
  width: 49%;
}
.all-npm5 .below .item .i {
  height: 1rem;
  width: 1rem;
  align-items: center;
  background: #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
}
.all-npm5 .below .item .i img {
  width: 0.4rem;
}
.all-npm5 .below .item h5 {
  font-size: 0.26rem;
  margin: 0.3rem 0 0.1rem;
  color: #333;
  text-align: center;
}

.all-npm1 {
  padding: 0.9rem 0;
}
.all-npm1 .one {
  margin: 0.45rem auto 0;
  width: 3.6rem;
}
.all-npm1 .ul {
  flex-wrap: wrap;
  margin-top: 0.35rem;
}
.all-npm1 .ul .li {
  border: 0.02rem solid #ebecf0;
  margin-top: 0.25rem;
  padding: 0.2rem;
  width: 100%;
  border-radius: 2rem;
}
.all-npm1 .ul .li:nth-child(even) .p {
  order: 3;
  text-align: left;
}
.all-npm1 .ul .li:nth-child(even) .p p {
  text-align: left;
}
.all-npm1 .ul .li:after {
  display: none;
}
.all-npm1 .ul .txt {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.all-npm1 .ul .p {
  order: 1;
  text-align: right;
  width: calc(100% - 1.5rem);
}
.all-npm1 .ul .p p {
  line-height: 1.5;
  text-align: right;
}
.all-npm1 .ul .i {
  order: 2;
  height: 1.3rem;
  width: 1.3rem;
  align-items: center;
  background-image: linear-gradient(180deg, #f6221d, #e20c14, #e00a13);
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
.all-npm1 .ul .i img {
  width: 0.4rem;
}
.all-npm1 .ul em {
  font-size: 0.28rem;
  text-align: right;
}

.cTabWr {
  margin-top: 0.35rem;
  :deep(.van-tabs__wrap) {
    height: 0.6rem;
    .van-tabs__nav {
      padding-left: 0;
      padding-right: 0;
      background: transparent;
    }
    .van-tab {
      border-radius: 0.6rem;
      transition: all 0.4s;
      color: #2d322d;
      margin-left: 0.2rem;
      font-size: 0.24rem;
      &:first-child {
        margin-left: 0;
      }
    }
    .van-tab--active {
      color: #fff;
      background: #d7000f;
      font-weight: 500;
    }
    .van-tabs__line {
      display: none;
    }
  }
}
.cTabBd {
  margin-top: 0.4rem;
  .item {
    border-radius: 0.1rem;
    padding: 0.5rem 0.3rem;
    background: #fff;
    :deep(.p) {
      margin-bottom: 0.3rem;
      p {
        text-align: center;
      }
    }
  }
}
.all-npmarr {
  padding: 0.9rem 0;
  background: #e3ecfb;
}
.all-npm7 {
  background: #fff;
  .item {
    border: 1px solid #e0e0e0;
  }
}

.know-npm3 {
  padding: 0.9rem 0;
  background: #fff;
}
.know-npm3 .hometit h5 {
  font-size: 0.4rem;
  font-weight: 600;
  text-align: center;
  color: #333333;
}
.know-npm3 .fl {
  float: none;
  margin: 0 auto;
  width: 5rem;
}
.know-npm3 .box {
  margin-top: 0.35rem;
}
.know-npm3 .ul {
  margin-top: 0.35rem;
  width: 100%;
}
.know-npm3 .ul .li {
  padding: 0.3rem 0;
  border-top: 1px solid #e1e2e6;
  display: flex;
}
.know-npm3 .ul .li:last-child {
  border-bottom: 1px solid #e1e2e6;
}
.know-npm3 .ul .li .txt {
  width: calc(100% - 0.5rem);
}
.know-npm3 .ul .li .txt h5 {
  font-size: 0.26rem;
  margin: 0.05rem 0 0.1rem;
}
.know-npm3 .ul .li .i {
  margin-right: 0.1rem;
  width: 0.4rem;
}
</style>
  
  