<template>
  <div class="wrap">
  <div class="capitaldig">
    <nav-ber title="资金管理"></nav-ber>
    <main>
      <sub-ban :ban-info="banInfo"></sub-ban>
    <section class="tend-npm1">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData3[0].newsTitle"></h5>
          <h6 v-html="apiData3[0].newsContent"></h6>
        </div>
        <ul class="wow fadeInUp50">
          <li
            class="wow fadeInUp50"
            v-for="(item, i) in apiData4"
            :key="item.newsId"
            :data-id="item.newsId"
            :style="'animation-delay:' + i * 150 + 'ms'"
          >
            <div class="ico">
              <img :src="item.newsAccess" alt="" />
            </div>
            <h5 v-html="item.newsTitle"></h5>
            <em>{{ item.newsAuthor }}</em>
          </li>
        </ul>
      </div>
    </section>

    <section class="bim-npm1">
            <div class="pagetit wow fadeInUp50">
              <h5>业务场景</h5>
            </div>
            <div class="cTabWr">
                 <div class="w1440">
              <van-tabs v-model:active="cTabActive" class="ball" :ellipsis="false" swipe-threshold="1">
                <van-tab :title="item.newsTitle"  v-for="item in apiData5"></van-tab>
              
              </van-tabs>
                 </div>
              <div class="cTabBd">
                <div class="ul">
                  <div class="li"  v-for="(item, i) in apiData5" style="" v-show="cTabActive == i">
                    <div class="liflex clear">
                      <div class="fr animated fadeInUp50">
                  <img :src="    item.image" alt="" />
                </div>
                <div class="fl animated fadeInUp50">
                  <div class="ico"><img :src="    item.newsAccess" alt="" /></div>
                  <h5 v-html="item.newsTitle"></h5>
                  <div class="p" v-html="item.newsContent"></div>
                  <router-link class="homemore" :to="{path:'/consultform', query:{'title': banInfo.txton, 'plateArr': banInfo.tilCn}}">立即咨询</router-link>
                </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>

    <pcDigital-pop :banenter="banenter" />
    </main>
  </div>
  </div>
</template>
<script setup >
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const cTabActive = ref(0);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const banInfo = reactive({
  banUrl: '',
  tilCn: '',
  tilTxt: '',
  link: '',
  coId: "",
  enlink: true,
  txton: "免费试用",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: "423",  
        typeId:"1369",
  });
  const { data: data2 } = await getInfo({
    coId: "755",  
  });
  const { data: data3 } = await getInfo({
    coId: "801", 
        typeId:"1369",
  });
  const { data: data4 } = await getInfo({
    coId: "757", 
        typeId:"1369",
  });
  const { data: data5 } = await getInfo({
    coId: "759", 
        typeId:"1369",
  });
  apiData1.value = data1.data;
  apiData2.value = data2.data;
  apiData3.value = data3.data;
  apiData4.value = data4.data;
  apiData5.value = data5.data;
  banInfo.banUrl = apiData1.value[0].image;
  banInfo.tilCn = apiData1.value[0].newsTitle;
  banInfo.tilTxt = apiData1.value[0].newsContent;
};
onMounted(async () => {
   getList();
});
</script>
<style lang="scss">
.capitaldig {
  main{padding: 0;}
  .pagetit h5,
  .pagetit h6 {
    text-align: center;
  }
  #tend-npm4 {
    position: absolute;
    height: 80px;
    left: 0;
    bottom: 0;
  }
  .tend-npm1 {
    padding: 90px 0;
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      li {
        width: 23.9583%;
        background: #f3f4f7;
        position: relative;
        top: 0;
        transition: all 0.4s;
        height: 235px;
        overflow: hidden;
        &:after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          -webkit-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          height: 4px;
          background: #d7000f;
        }
        &:hover {
          &:after {
            width: 100%;
            left: 0;
          }
          em {
            color: #d7000f;
          }
        }
        .ico {
          border-radius: 50%;
          width: 96px;
          height: 96px;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 45px auto 30px;
        }
        h5 {
          text-align: center;
          font-size: 18px;
          color: #333333;
        }
        em {
          position: absolute;
          top: 30px;
          left: 35px;
          color: #c5c5c5;
          font-size: 20px;
          letter-spacing: -0.05px;
          font-family: "DIN-Bold";
          transition: all 0.4s;
        }
      }
    }
  }

 .bim-npm1 {
    padding-top: .6rem;

    .cTabWr { 
      margin-top: .35rem;
        .van-tabs__wrap {
        height: 0.6rem;
        .van-tabs__nav {
          padding-left: 0;
          padding-right: 0;
          background: transparent;
        }
        .van-tab {
          border-radius: 0.6rem;
          transition: all 0.4s;
          color: #2d322d;
          margin-left: 0.2rem;
          font-size: .24rem;
          &:first-child {
            margin-left: 0;
          }
        }
        .van-tab--active {
          color: #fff;
          background: #d7000f;
          font-weight: 500;
        }
        .van-tabs__line {
          display: none;
        }
      }
    }
    .mobileTabs {
      margin-top: 0.5rem;
      display: none;
    }

    .cTabBd {
      background: url(https://www.huajiantong.com/images/digital/bg6.jpg)
        no-repeat;
      background-position: center;
      background-size: cover; 

      .fl {
        width: 36.81%;
        position: relative;
        .homemore {
          color: #fff;
          background: #d7000f;
          border: 2px solid #d7000f;
          width: 1.8rem;
    line-height: .8rem;
    font-size: .24rem;
    border-radius: 1rem;
        }
        h5 {
          font-size: .26rem;
          color: #333333;
          position: relative;
          z-index: 3;
        }

        .p {
          margin: .2rem 0 .45rem;
          position: relative;
          z-index: 3;
        }

        .ico {
          margin-bottom: .2rem;
          width: .42rem;
          position: relative;
          z-index: 3;
        }
      }

      .fr {
        width: 47.569%;
        img {
          max-width: none;
          float: right;
        }
      }
    }
  }
}
@media (max-width: 1500px) {
  .capitaldig .bim-npm1 .below .fr img {
    transform: scale(0.9);
    transform-origin: right;
  }
}

.capitaldig .bim-npm1 .mobileTabs {
  margin-top: 0.5rem;
  display: none;
}
@media (max-width: 600px) {
  .capitaldig {
    .tend-npm1 {
      padding: 0.6rem 0;
      ul {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 3.25rem;
padding: 0 .2rem;
          height: 2.8rem;
          margin-bottom: 0.2rem;
          em {
            left: 0.3rem;
            top: 0.3rem;
          }
          .ico {
            width: 1.2rem;
            height: 1.2rem;
            margin: 0.6rem auto 0.3rem;
          }
          h5 {
            font-size: 0.3rem;
            line-height: 1;
          }
        }
      }
    }
    .bim-npm1 {
      padding-top: 0.6rem;
      overflow: hidden;
      .tabbox {
        display: none;
        margin-top: 0.5rem;
        .item {
          font-size: 0.24rem;
          line-height: 0.6rem;
        }
      }
      .mobileTabs {
        display: block;
      }
      .cTabBd {
        height: auto;
        padding: 0.6rem .4rem;
        margin: 0.6rem 0;
        .liflex {
          flex-wrap: wrap;
        }
        .fl {
          width: 100%;
          order: 1;
        }
        .fr {
          order: 2;
          width: 100%;
          margin-top: 0.5rem;
          img {
            max-width: 100%;
          }
        }
      }
    }
    .tend-npm2 {
      .ul {
        margin-top: 0.5rem;
        .li {
          width: 0.7rem;
          .ico {
            width: 0.7rem;
            height: 0.7rem;
            font-size: 0.36rem;
            margin: 0 auto 0.1rem;
          }
          h5 {
            font-size: 0.3rem;
            line-height: 1.2;
          }
        }
        .i {
          width: 0.2rem;
          margin-top: 0.35rem;
        }
      }
    }
  
    .tend-npm3 {
      ul {
        margin-top: 0.5rem;
        flex-wrap: wrap;
        li {
          width: 100%;
        }
      }
    }
    #tend-npm4 {
      height: 0.6rem;
    }
    .tend-npm4 {
      padding: 0.6rem 0;
      .fl {
        margin-top: 0;
        width: 100%;
        h5 {
          font-size: 0.4rem;
          line-height: 0.6rem;
        }
        ul {
          margin-top: 0.2rem;
          overflow: hidden;
        }
      }
      .fr {
        margin-top: 0.5rem;
        width: 100%;
        padding: 0.5rem 0.3rem;
        h5 {
          font-size: 0.4rem;
          line-height: 0.6rem;
        }
        .formcon {
          margin-top: 0.3rem;
        }
        .inp {
          height: 1rem;
          padding: 0 0.3rem;
          margin-bottom: 0.2rem;
        }
        .submit {
          height: 1rem;
          line-height: 1rem;
        }
      }
    }
  }
}
</style>
  
  