<template>
  <div class="wrap">
    <nav-ber :title="'生活污水垃圾处理设施运营管理系统'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="sewage">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item " v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit ">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
         
            </div>
        </section>
         <section class="constru-npm2">
      <div class="w1440">
        <div class="pagetit ">
          <h5 v-html="apiData2[0].newsTitle"></h5> 
        </div>
        <div class="clear">
          <div class="fl ">
            <div class="img">
                  <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000" effect="fade">
        <van-swipe-item v-for="image in imageUrlStrings" :key="image">
           <img :src="  image" alt="">
        </van-swipe-item>
      </van-swipe>
            </div>
          </div>
          <div class="fr ">
            <div class="txtbox">
              <ul>
                <li v-for="item in apiData3" :key="item.newsId">
                  <div class="ico">
                    <img
                      :src="  item.newsAccess"
                      alt=""
                    />
                  </div>
                  <div class="txt">
                    <h5 v-html="item.newsTitle"></h5>
                    <div v-html="item.newsContent"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
      <section class="constru-npm3" v-if="apiData4[0]">
      <div class="w1440">
        <div class="pagetit">
          <h5 v-html="apiData4[0].newsTitle"></h5> 
        </div>
        <div
          class="itembox"
          v-html="apiData4[0].newsContent"
        ></div>
      </div>
    </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]); 
const apiData4 = ref([]); 
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
});
const imageUrlStrings = ref([]);
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 539,
    typeId: 99,
  });
  const { data: data2 } = await getInfo({
    coId: 585,
  });
  const { data: data3 } = await getInfo({
    coId: 587,
  });
    const { data: data4 } = await getInfo({
    coId: 589,
  }); 
  const { data: data5 } = await getInfo({
    coId: 591,
  }); 
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data; 
    apiData4.value = data5.data; 
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent;
   imageUrlStrings.value = apiData2.value[0].image.split(',')
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.sewage {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0; 
               border-bottom: 1px solid #e3e5e8;
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                                margin-bottom: 0.1rem;
                        }
                    }
                }
            }
        
        }
       
.constru-npm2 {
      padding:.9rem 0 0.7rem;
      .clear {
        margin-top: 0.4rem;
      }
      .fl {
        width: 100%;
           :deep(.van-swipe__indicators){
                    bottom: .3rem;
                    i{
                        width: .52rem;
                        height: .04rem;
                        border-radius: 0;
                        background: #a6a6a6;
          opacity: 1;
                         &.van-swipe__indicator--active{
            background: #d7000f;
          }
                    }
                }
        .img {
          margin-left: 0;
        }
      }
      .fr {
        width: 100%;
        li {
          padding: 0.4rem 0;
          position: relative;
           display: flex;
          &:nth-child(1) {
            padding: 0 0 0.4rem 0;
          }
          &:last-child {
          &::after {
            opacity: 0;
          }
        }
           &::after {
          content: "";
          width: calc(100% - 0.75rem);
          height: 1px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: #e3e5e8;
        }
      
        }
        .ico {
          width: 0.45rem;
        }
        .txt {
          margin-left: 0.2rem;
          width: calc(100% - 0.75rem);
          h5 {
            font-size: 0.26rem;
            margin-bottom: 0.1rem;
             color: #333333;
          }
        }
      }
    }
    .constru-npm3 {
      padding: 0.9rem 0;
      background: linear-gradient(to bottom, #f3f4f7, #f8f8fa, #fefefe);
    
       :deep(.itembox) {
        margin-top: 0.4rem;
        display: block;
        overflow: hidden;
       .fl {
          width: 100%;
          .above {
            display: block;
            .item {
              width: 100%;
              margin-bottom: 0.2rem;
              background: #ebecf0;
              padding: 0.4rem 0.3rem;
               position: relative;
              &:nth-child(2){
                ul{
                    li{
                        width: 48%;
                    }
                }
              }
              &::before{
                    width: .41rem;
                    height: .57rem;
                    bottom: -.37rem;
                    background-size: 100%;
              }
              h5 {
                font-size: 0.28rem;
                color: #333333;
                text-align: center;
              }
              ul {
                margin-top: 0.3rem;
                li {
                  background: #fff;
                   text-align: center;
                   color: #808080;
                  height: 0.8rem;
                  font-size: 0.24rem;
                  margin-bottom: 0.17rem;
                  border-radius: 1rem;
                  max-width: 100%;
                  width: 3rem;
                  margin: 0 auto .1rem;
                      line-height: initial;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
          .contxt{
            position: relative;
            &::before,&::after{
                opacity: 0;
            }
            .item{
                margin-top: .2rem;
                font-size: .24rem;
                 height: 0.8rem;
                     background: #ebecf0;
                     line-height: initial;
                display: flex;
                  align-items: center;
                  justify-content: center;
                   color: #808080;
                  &::after{
                    opacity: 0;
                  }
            }
          }
          .below{
            margin-top: .2rem;
            display: flex;
        justify-content: space-between;
            .item{
                height: 0.8rem;
                font-size: .24rem;
                    line-height: initial;
                     background: #ebecf0;
                    color: #808080;
                display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                      width: 41.6%;
                  &::before{
                    content: "";
                        width: .41rem;
                     height: .57rem;    
                    top: -.37rem;
                    background: url(~@/assets/images/labor/line6.png) no-repeat;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 3;
                    background-size: 100%;

                  }
                   p{
                     position: absolute;
            width: 100%;
                    bottom: -.45rem;
                    text-align: center;
                  }
                   &:nth-child(2) {
            width: 55.55%;
          }
            }
          }
        }
        .fr{
            width: 100%;
            display: block;
           margin-top: .8rem;
            .item{
                width: 100% !important;
                margin-bottom: .2rem;
                padding: 0.4rem 0.3rem;
                    background: #ebecf0;
                h5{
                    font-size: .28rem;
                    text-align: center;
                }
                ul{
                    margin-top: .3rem;
                    li{
                         height: 0.8rem;
                         background: #fff;
                  font-size: 0.24rem;
                  margin-bottom: 0.17rem;
                  border-radius: 1rem;
                  max-width: 100%;
                  width: 3rem;
                  margin: 0 auto .1rem;
                      line-height: initial;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                    }
                }
            }
        }
      }
    }
}
</style>
  
  