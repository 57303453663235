<template>
  <div class="wrap">
    <nav-ber :title="'产业分析'"></nav-ber>
    <main>
      <Cbanner :list="apiData0"></Cbanner>
      <Clist :list="apiData2"></Clist>
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import Clist from "@/components/Clist.vue";
import NavBer from "@/components/NavBer.vue";
import { getInfo } from "@/api/http";
import { ref } from "vue";
const escape = (url) => {
  return encodeURIComponent(url);
}

const apiData0 = ref([]);
const apiData2 = ref([]);
const getList = async () => {
  const promises = [
    getInfo({ coId: 359, typeId: 9 }).then(res => res.data),
    getInfo({ coId: 365, typeId: 9 }).then(res => res.data)
  ];
  const results = await Promise.all(promises);
  apiData0.value = results[0].data;
  results[1].data.forEach(obj => {
    let item = {
      image: obj.image,
      newsTitle: obj.newsTitle,
      newsContent: obj.newsContent,
      address: obj.address,
      isTop: obj.isTop,
      btnTxt: obj.address ? '前往平台' : '敬请期待'
    }
    apiData2.value.push(item);
  })
};
getList();
</script>
