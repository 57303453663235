<template>
  <div class="wrap">
    <div class="topBox">
      <div class="retBtn" @click="onClickLeft"></div>
      <div class="ipt">
        <input ref="ipt" type="text" placeholder="请输入你想要的内容" v-model="value" />
        <div class="icon" to="/construction"></div>
        <div class="clear" @click="clearIpt" v-show="value.length > 0"></div>
      </div>
      <div class="btn" @click="seekFn">搜索</div>
    </div>
    <main>
      <!-- 关键词 -->
      <div class="reminder" v-show="showKey">
        <div class="box">
          <h4 class="til">热门搜索</h4>
          <div class="ul">
            <van-button type="primary" color="#f4f9fc" @click="recordSeek(item)" v-for="(item, index) in HotButtonWord" :key="index">{{ textAstrict(item) }}</van-button>
          </div>
        </div>
        <div class="box" v-if="recordArr.length">
          <h4 class="til">历史记录</h4>
          <div class="deleteBtn" @click="deleteHistory"></div>
          <div class="ul">
            <van-button type="primary" color="#f4f9fc" v-for="text in recordArr" :key="text" @click="recordSeek(text)">{{ textAstrict(text) }}</van-button>
          </div>
        </div>
      </div>
      <!-- 未找到 -->
      <div class="notFound" v-if="notFound">
        <img src="~@/assets/images/seek/seekNone.png" alt="" />
        <div class="p">没有找到相关结果，换个关键字试试吧~</div>
      </div>
      <!-- 内容列表 -->
      
      <van-list class="newsList" v-model="listLoad" :finished="finished" finished-text="我是有底线的" @load="onLoad">
        <div class="li" v-for="item in list" :key="item.newsId">
          <div class="a" @click="skipOutsideChain(item.address)" target="_blank" v-if="item.addres && item.addres.trim() !== ''">
            <div class="pic" v-if="item.image"><img :src="item.image" alt=""></div>
            <div class="txt">
              <h4>{{ item.newsTitle }}</h4>
              <div class="p">
                发布时间：<em>{{item.newsAddTime}}</em><br/>
                <em>{{item.newsNum}}</em>人观看
              </div>
            </div>
          </div>
          <router-link v-else :to="`/newsdetail/${item.newsId}`">
            <div class="pic" v-if="item.image"><img :src="item.image" alt=""></div>
            <div class="txt">
              <h4>{{ item.newsTitle }}</h4>
              <div class="p">
                发布时间：<em>{{item.newsAddTime}}</em><br/>
                <em>{{item.newsNum}}</em>人观看
              </div>
            </div>
          </router-link>
        </div>
      </van-list>
    </main>
  </div>
</template>
<script setup>
import { showConfirmDialog } from 'vant';
import { getNews, getPopularKeys } from '@/api/http';
import { skipOutsideChain } from '@/api/public';
import { ref, onMounted } from 'vue';
const value = ref("");
const ipt = ref("ipt");
const notFound = ref(false);
const showKey = ref(true);
const pageIndex = ref(1);
const finished = ref(false);
const listLoad = ref(true);
const list = ref([]);
const total = ref(0);
const recordArr = ref([]);
const HotButtonWord = ref([]);
// if(agms){
//   seekFn(agms)
// }

onMounted(() => {
  const labelArr = localStorage.getItem('recordArr');
  recordArr.value = labelArr ? JSON.parse(labelArr) : [];
  ipt.value.focus();
  ipt.value.addEventListener("input", () => {
    showKey.value = true;
  })
})

async function getHotButtonWord(){
  const { data } = await getPopularKeys();
  HotButtonWord.value = data.data;
  console.log('测试44', data.data)
}
getHotButtonWord();


// 删除历史记录提示
function deleteHistory(){
    showConfirmDialog({
        title: "删除",
        message: "确定删除全部历史记录？",
    }).then(() => {
      recordArr.value = [];
      localStorage.setItem('recordArr','');
    }).catch(() => {
        console.log('取消')
    });
}

// 清空搜索框
function clearIpt(){
    value.value = ""
    showKey.value = true;
    pageIndex.value = 1;
    list.value = [];
    notFound.value = false;
    finished.value = false;
}

// 搜索
async function seekFn(){
  let val = value.value.trim();
  if(val === '') return
  let idx = recordArr.value.indexOf(val);
  if(idx > -1){
    recordArr.value.splice(idx, 1);
  }
  recordArr.value.unshift(val);
  let str = JSON.stringify(recordArr.value);
  localStorage.setItem('recordArr', str);
  showKey.value = false;
  listLoad.value = true;
  const { data } = await getNews({
    key: val,
    pageIndex: pageIndex.value,
    pageSize: 10,
    typeId: 0
  });
  let arr = data.data;
  if(!data.total) return notFound.value = true
  if(!arr.length) return finished.value = true
  total.value = data.total;
  listLoad.value = false;
  list.value.push(...arr);
  pageIndex.value++;
}

const onClickLeft = () => history.back();

function textAstrict(text){
  if(text.length > 7){
    return text.slice(0, 7) + '...';
  }else{
    return text;
  }
}

function recordSeek(str){
  value.value = str;
  seekFn()
}


// 加载更多
const onLoad = () => {
  if(listLoad.value) return
  seekFn()
}
</script>
<style scoped lang="scss">
.wrap {
  padding-top: 0;
}
.topBox {
  display: flex;
  align-items: center;
  padding: 0.6rem 0.4rem 0.4rem;
  .retBtn {
    margin-left: -0.34rem;
    width: 0.9rem;
    height: 0.9rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAeCAYAAAAl+Z4RAAAAmklEQVQ4jaXVOxKAIAxF0eB+oND9L8GGDeFowcgn5OVBf48gMIRSijAjXekLj52YAv6xG+jjfOcAA7MYnoEWQ8AqNgErXgJIrAJoPAU88QB44wZg4gqw8TtCPCMdC3sbG6D/Yr8caAY7SF0CizT/gEGGn+hFprvgQdRtRJHlOUAQ8yBZCHQSVwh8lDXE/bRtPSyzmVC3sSIi8gA78G1NX/ltRAAAAABJRU5ErkJggg==)
      no-repeat center;
    background-size: 0.16rem;
  }
  .ipt {
    flex: 1;
    position: relative;
    input {
      border-radius: 0.28rem;
      border: 0 none;
      width: 100%;
      height: 0.8rem;
      padding: 0.1rem 0.8rem 0.1rem 0.92rem;
      line-height: 0.6rem;
      font-size: 0.24rem;
      color: #666;
      background: #f4f4f4;
    }
    .clear{
        position: absolute;
        right: .1rem;
        top: .1rem;
        width: 0.6rem;
        height: 0.6rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAACHklEQVQ4jZ3Uy4vNcRjH8dfMKJdyL8NmNggxLkORu43LipVSFnJbCGWFYuQWImHhMvJHWLgnGwsiuYVxT64bE+MaY/TkOXU6nTOGp06d+v6+7+/n8/k+z7eqvqG+3Z86jF146d+qB+ZgC8bU1A6qrcIQzEA3XMOXTiKrMR2NGI+LAXyIDxiNqeiFO/jYCdg8bMVEnMamALbiEb4ldGYqjYNaKsBifT42YBLOYSeuBjA++Iq7+IRxab8Gt9FaAovM5mJ9KrsQynAlFgvAqB9oTqsNRZleL8o08p6FzQk7nypvor0UGPUdzxMwFlPQN08PF7OxLWFnUmU4aysASoES1lyU6bRUWoc1mIyz2JHq24s3d6kQ+ns0peJ1WFsQkLcZlm+U21hOYXGmD9LqCPTDZazG/VJlhaquRMsNHxM0MC8k/nctzuxfFPbHUixP+JvMMeBv8aLcpkoZ9sFibM9vmrL5l2AhuuNzTtTPzihckSPVG4fydzmza8gRrct2aukIWJ2hb0zL+3ECj1NJ2H6CkfkoDM0We10OGIEvy5boiSPYh2dFB7YlPDIclj06AK8KmRYyDGuLcgriFo9hN95VyPhk9ugBLEhOzPy9gsKV2fkBPoij+dCW7bWsWL+FCTmig+MtDeAerMrM4sTj+XR1BIuKTAMaGUbjR6bDAxjPT8xq2Nxbktnf6heeZqaj4m0M73HtlxIY4f5PnUItGn8DqkWLjjH+3jQAAAAASUVORK5CYII=) no-repeat center;
        background-size: .2rem;
    }
    .icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 0.8rem;
      height: 0.8rem;
      background: url(~@/assets/images/iconSeek.png) no-repeat center;
      background-size: 0.36rem;
    }
  }
  .btn {
    font-size: 0.28rem;
    color: #2d322d;
    line-height: 0.8rem;
    white-space: nowrap;
    padding: 0 0.2rem;
    margin: 0 -0.2rem 0 0.2rem;
  }
}
main {
  position: relative;
  padding: 0.6rem 0.4rem;
  background: url(~@/assets/images/seekBg.jpg) repeat-x top;
  background-size: 0.4rem;
}
.reminder {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.6rem 0.4rem;
  z-index: 5;
  background: url(~@/assets/images/seekBg.jpg) repeat-x top;
  background-size: 0.4rem;
  .box {
    position: relative;
    margin-top: 0.6rem;
    &:first-child {
      margin-top: 0;
    }
  }
  h4 {
    font-size: 0.28rem;
    color: #2d322d;
    line-height: 0.4rem;
    margin-bottom: 0.24rem;
  }
  .ul {
    max-height: 3.1rem;
    overflow: hidden;
    margin-right: -0.26rem;
  }
  .van-button {
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.24rem;
    color: #4f8dcd !important;
    margin: 0 0.26rem 0.26rem 0;
    border-radius: 0.2rem;
    padding: 0 0.3rem;
  }
  .deleteBtn {
    position: absolute;
    right: -0.15rem;
    top: -0.11rem;
    width: 0.6rem;
    height: 0.6rem;
    background: url(~@/assets/images/seekIconDelete.png) no-repeat center;
    background-size: 0.31rem;
  }
}
.notFound {
  padding-top: 2rem;
  img {
    width: 2.36rem;
    margin: 0 auto 0.55rem;
  }
  .p {
    font-size: 0.28rem;
    line-height: 0.38rem;
    color: #000;
    text-align: center;
  }
}
.newsList {
  a, .a {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.3rem 0;
    border-top: 1px dashed #dfdfdf;
    &:first-child {
      border-top: 0 none;
    }
  }
  .pic {
    width: 2.9rem;
    overflow: hidden;
    margin-right: .36rem;
    border-radius: 0.28rem;
    min-height: 1.7rem;
    background: #dfdfdf;
  }
  .txt {
    flex: 1;
    padding: 0.12rem 0 0;
    h4 {
      font-size: 0.28rem;
      line-height: 0.4rem;
      margin-bottom: 0.2rem;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 设置显示的行数 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .p {
      font-size: 0.22rem;
      line-height: 0.3rem;
      color: rgba(45, 50, 45, 0.4);
      em {
        font-family: "Gilroy-Bold";
      }
    }
  }
}
</style>
  
  