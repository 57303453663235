<template>
  <div class="wrap">
  <div class="ownerinvestor">
    <nav-ber title="业主（投资方）"></nav-ber>
    <sub-ban :ban-info="banInfo"></sub-ban>
    <main>
    <section class="owne-npm1">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>实现项目全周期成本管理</h5>
          <h6>Achieve project full cycle cost management</h6>
        </div>
        <div class="box clear wow fadeInUp50">
          <div class="item wow fadeInUp50" v-for="(item, i) in apiData1" :key="item.newsId" :style="'animation-delay: '+(i * 150) + 'ms'">
            <div class="img">
              <img :src="item.image" alt="">
            </div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <p v-html="item.newsContent"></p>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <section class="owne-npm2">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData2[0].newsTitle"></h5>
          <h6 v-html="apiData2[0].email"></h6>
          <div class="p" v-html="apiData2[0].newsContent">
          </div>
        </div>
        <div class="processes clear wow fadeInUp50" v-html="apiData2[0].newsTags">
        
        </div>
      </div>
    </section>
    <section class="owne-npm3">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData3[0].newsTitle"></h5>
          <h6 v-html="apiData3[0].email"></h6>
          <div class="p" v-html="apiData3[0].newsContent">
          </div>
        </div>
        <div class="box clear">
          <div class="fr wow fadeInUp50">
            <img :src="apiData3[0].newsAccess" alt="">
          </div>
          <div class="fl wow fadeInUp50">
            <div class="dl" v-for="(item, i) in apiData4" :key="item.newsId">
              <img :src="item.newsAccess" alt="">
              <div class="h5" v-html="item.newsTitle">
              </div>
            </div>
           
          </div>

        </div>
      </div>
    </section>
    <section class="owne-npm4">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData5[0].newsTitle"></h5>
          <h6 v-html="apiData5[0].email"></h6>
        </div>
        <div class="img wow fadeInUp50">
          <img :src="apiData5[0].newsAccess" alt="">
        </div>
      </div>
    </section>
    <section class="owne-npm1">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>应用范围</h5>
          <h6>Scope of application</h6>
        </div>
        <div class="box clear">
          <div class="item wow fadeInUp50" v-for="(item, i) in apiData6" :key="item.newsId" :style="'animation-delay: '+(i * 150) + 'ms'">
            <div class="img">
              <img :src="item.newsAccess" alt="">
            </div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <p v-html="item.newsContent"></p>
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <section class="buil-npm2">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>业主及项目管理单位主要面临的问题及解决方案</h5>
          <h6>The main problems faced by the owner and the project <br>management unit and the solutions</h6>
        </div>
        <div class="itembox">
          <div class="item wow fadeInUp50" v-for="(item, i) in apiData7" :key="item.newsId" :class="{ 'on': i === 0 }">
            <div class="ico"><img :src="item.newsAccess" alt=""></div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <div class="p" v-html="item.newsContent">
              </div>
            </div>
            <em>{{ item.newsAuthor }}</em>
          </div>

        </div>
      </div>
    </section>
    <section class="owne-npm5">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>其他参建方单位主要面临的问题及解决方案</h5>
          <h6>The main problems faced by other participating <br>units and their solutions</h6>
        </div>
        <div class="ul">
          <div class="li wow fadeInUp50" v-for="(item, i) in apiData8" :key="item.newsId" :style="'animation-delay: '+(i * 150) + 'ms'">
            <div class="h5">
              <h5 v-html="item.newsTitle"></h5>
              <div class="i">
                <img class="i1" :src="item.newsAccess" alt="">
                <img class="i2" :src="item.image" alt="">
              </div>
            </div>
            <div class="dl" v-html="item.newsContent">
              
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <section class="owne-npm6">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData9[0].newsTitle"></h5>
          <h6 v-html="apiData9[0].email"></h6>
        </div>
        <div class="ul clear" v-html="apiData9[0].newsContent">
        
        </div>
      </div>
    </section>
    <section class="owne-npm7">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>核心价值</h5>
          <h6>Core value</h6>
        </div>
        <div class="ul">
          <div class="li wow fadeInUp50" v-for="(item, i) in apiData10" :key="item.newsId" :style="'animation-delay: '+(i * 150) + 'ms'">
            <div class="i">
              <img :src="item.newsAccess" alt="">
            </div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <div class="p" v-html="item.newsContent">
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </section>
    <section class="owne-npm8">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>产品优势</h5>
          <h6>Core value</h6>
        </div>
        <div class="ul">
          <div class="li wow fadeInUp50" v-for="(item, i) in apiData11" :key="item.newsId" :style="'animation-delay: '+(i * 150) + 'ms'">
            <div class="txt">
              <div class="i"><img :src="item.newsAccess" alt=""></div>
              <div class="p" v-html="item.newsContent"> 
              </div>
            </div>
              <em>{{ item.newsTitle }}</em>
          </div>
          
        </div>
      </div>
    </section>
      
    </main>
  </div>
</div>
</template>
<script setup >
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const apiData6 = ref([]);
const apiData7 = ref([]);
const apiData8 = ref([]);
const apiData9 = ref([]);
const apiData10 = ref([]);
const apiData11 = ref([]);
const banInfo = reactive({
  banUrl: '',
  tilCn: '',
  tilTxt: '',
  link: '',
  coId: "",
  enlink: true,
  txton: "免费试用",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: "423",  
    pageSize:999,
    typeId:"4269",
  });
  const { data: data2 } = await getInfo({
    coId: "919",  
  });
  const { data: data3 } = await getInfo({
    coId: "921", 
  });
  const { data: data4 } = await getInfo({
    coId: "923",  
  });
  const { data: data5 } = await getInfo({
    coId: "925",   
  });
  const { data: data6 } = await getInfo({
    coId: "927",  
  });
  const { data: data7 } = await getInfo({
    coId: "929",   
  });
  const { data: data8 } = await getInfo({
    coId: "931",  
  });
  const { data: data9 } = await getInfo({
    coId: "933",   
  });
  const { data: data10 } = await getInfo({
    coId: "935", 
  });
  const { data: data11 } = await getInfo({
    coId: "937",   
  });
  const { data: data12 } = await getInfo({
    coId: "939",   
  });
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
  apiData3.value = data4.data;
  apiData4.value = data5.data;
  apiData5.value = data6.data;
  apiData6.value = data7.data;
  apiData7.value = data8.data;
  apiData8.value = data9.data;
  apiData9.value = data10.data;
  apiData10.value = data11.data;
  apiData11.value = data12.data;
  banInfo.banUrl = apiData5.value[0].image;
  banInfo.tilCn = apiData5.value[0].newsTitle;
  banInfo.tilTxt = apiData5.value[0].newsContent;
};
onMounted(async () => {
   getList();
});
</script>
<style lang="scss">
.ownerinvestor {
  main{padding: 0;}

  .pagetit h5,
  .pagetit h6 {
    text-align: center;

  }

  .pagetit {
    .p {
      margin-top: 25px;

      p {
        text-align: center;
      }
    }
  }

  .owne-npm1 {
    padding: 85px 0 95px;

    .box {
      margin-top: 40px;
      display: flex;

      .item {
        width: 32.36%;
        float: left;
        margin-right: 1.25%;
        background: #f3f4f7;
        transition: all .4s;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img {
          overflow: hidden;

          img {
            transition: all .4s;
          }
        }

        &:hover {
          background: #d7000f;

          img {
            transform: scale(1.1);
          }

          .txt {

            h5,
            p {
              color: #fff;
            }
          }
        }
      }

      .txt {
        padding: 35px 30px;

        h5 {
          font-size: 18px;
          margin-bottom: 10px;
          color: #333333;
        }

        p {
          line-height: 22px;
        }
      }
    }
  }

  .owne-npm2 {
    background: #f3f4f7;
    padding-top: 100px;

    .processes {
      background: #fff;
      padding: 2.8% 2.8% 0;
      margin-top: 45px;

      .fl {
        width: 88.2353%;

        .box1 {
          display: flex;
          justify-content: space-between;

          .li1 {
            width: 49.16%;
            display: flex;
            justify-content: space-between;

            .t1 {
              color: #fff;
              background: #d7000f;
              width: 16.95%;
              line-height: 60px;
              border-radius: 30px;
              text-align: center;
            }

            .dl {
              display: flex;
              width: 80.3%;
              background: #fff;
              justify-content: space-between;
              align-items: center;

              .dd {
                color: #808080;
                width: 23.4%;
              }
            }

            .dd {
              line-height: 60px;
              border-radius: 30px;
              text-align: center;
              background: #f0f1f5;
              width: 18.64%;
            }
          }

          .li2 {
            background: #f0f1f5;
            border-radius: 36px;
            width: 49.16%;
            padding: 0 63px 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .li {
              display: flex;

              p {
                margin-right: 10px;
              }
            }
          }
        }

        .box2 {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;

          .li1 {
            background: #d7000f;
            border-radius: 10px;
            padding: 20px 20px 15px 30px;
            width: 49.16%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
              font-size: 16px;
              width: 22%;
              font-weight: bold;
              color: #fff;
            }

            .dl {
              width: 71.1864%;
              display: flex;

              .dd {
                line-height: 60px;
                width: 31.66%;
                margin-right: 2.381%;
                text-align: center;
                border-radius: 36px;
                color: #808080;
                background: #fff;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

          .li2 {
            padding: 20px 20px 15px 20px;

            .dl {
              width: 83.6%;

              .dd {
                margin-right: 2.13%;
                width: 23.3%;
              }
            }
          }
        }

        .box3 {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;

          h5 {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            text-align: center;
          }

          .li1 {
            width: 21.67%;
            border-radius: 10px;
            background: #f3f4f7;
            padding: 30px 20px;

            ul {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-top: 20px;

              li {
                line-height: 60px;
                width: 47.7679%;
                background: #fff;
                border-radius: 30px;
                text-align: center;
                margin-bottom: 10px;
                color: #808080;
              }
            }
          }

          .li2 {
            width: 63.08%;
            background: #f3f4f7;
            border-radius: 10px;
            padding: 30px 20px 20px;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              width: 45px;
              height: 13px;
              top: 0;
              bottom: 0;
              margin: auto;
              right: -33px;
              background: url(https://www.huajiantong.com/images/digital/line17.png);
              z-index: 5;
            }

            &::before {
              content: '';
              position: absolute;
              width: 45px;
              height: 13px;
              top: 0;
              bottom: 0;
              margin: auto;
              left: -33px;
              background: url(https://www.huajiantong.com/images/digital/line17.png);
              z-index: 5;
            }

            .dl {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;

              .dd {
                border-radius: 7px;
                background: #fff;
                width: 15.416%;
                padding: 20px 0;
                position: relative;

                &::after {
                  content: '';
                  position: absolute;
                  width: 19px;
                  height: 13px;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  right: -15px;
                  background: url(https://www.huajiantong.com/images/digital/line16.png);
                  z-index: 5;
                }

                &:last-child {
                  &::after {
                    opacity: 0;
                  }
                }

                h6 {
                  font-size: 16px;
                  margin-bottom: 10px;
                  font-weight: bold;
                  color: #333333;
                  text-align: center;
                }

                .dt {
                  text-align: center;
                  color: #808080;
                  line-height: 24px;

                }
              }
            }

            .dl2 {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;

              .dt {
                border-radius: 7px;
                background: #fff;
                padding: 20px 20px 15px;

                &:nth-child(1) {
                  width: 66.25%;
                }

                &:nth-child(2) {
                  width: 32.36%;

                  ul {
                    li {
                      max-width: 59px;
                      writing-mode: lr;
                      width: 31.383%;
                      padding: 15px 8px;
                      align-items: flex-start;
                      line-height: 18px;
                    }
                  }
                }

                h6 {
                  font-size: 16px;
                  margin-bottom: 10px;
                  font-weight: bold;
                  color: #333333;
                  text-align: center;
                }

                ul {
                  display: flex;
                  justify-content: space-between;

                  li {
                    border: 3px solid #e8eaed;
                    border-radius: 5px;
                    writing-mode: tb-rl;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 15px 5px;
                    max-width: 40px;
                    color: #808080;
                    width: 9.15%;
                  }
                }
              }
            }
          }

          .li3 {
            width: 11.92%;

            ul {
              display: block;

              li {
                width: 100%;
              }
            }
          }
        }

        .box4 {
          background: #d7000f;
          border-radius: 10px;
          margin-top: 20px;
          padding: 20px;
          display: flex;
          justify-content: space-between;

          .dl {
            border-radius: 36px;
            line-height: 60px;
            text-align: center;
            color: #808080;
            background: #fff;
            width: 18.56%;
          }
        }

        .box5 {
          overflow: hidden;
          margin-top: 30px;

          h5 {
            float: left;
            font-size: 18px;
            padding-top: 10px;
            font-weight: bold;
            color: #333333;
            text-align: center;
          }

          .dl {
            display: flex;
            justify-content: space-between;
            width: 90.7%;
            float: right;

            .dd {
              margin-bottom: 8px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .box6 {
          border-radius: 10px;
          margin: 32px 0;
          background: #f3f4f7;
          display: flex;
          justify-content: space-between;
          padding: 30px 75px 30px 30px;

          .dl {
            display: flex;
            justify-content: space-between;
            width: 83.56%;

            .dd {
              color: #808080;
              position: relative;

              &::after {
                content: '';
                position: absolute;
                width: 8px;
                height: 19px;
                top: -53px;
                margin: auto;
                left: -25px;
                background: url(https://www.huajiantong.com/images/digital/line18.png);
                z-index: 5;
              }

              &::before {
                content: '';
                position: absolute;
                width: 8px;
                height: 19px;
                bottom: -53px;
                margin: auto;
                left: -25px;
                background: url(https://www.huajiantong.com/images/digital/line18.png);
                z-index: 5;
              }
            }
          }

          h6 {
            color: #808080;
          }
        }

        .box7 {
          background: #d7000f;
          border-radius: 10px;
          line-height: 80px;
          text-align: center;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .fr {
        width: 10.294%;

        li {
          text-align: center;
          line-height: 60px;
          margin-bottom: 25px;
          border-radius: 30px;
          color: #fff;
          background: #cbd3d9;
        }
      }
    }
  }

  .owne-npm3 {
    padding: 90px 0 15px;

    .box {
      margin-top: 40px;

      .fl {
        width: 47.2%;
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .dl {
          border: 1px solid #e3e5e8;
          width: 48.5294%;
          min-height: 184px;
          padding: 30px 40px;
          margin-bottom: 3.448%;
        }

        .h5 {
          font-size: 16px;
          color: #808080;
          line-height: 24px;
          margin-top: 15px;
        }
      }

      .fr {
        width: 52.8%;
        position: relative;
        z-index: 3;

        img {
          float: right;
          max-width: none;
          margin-right: -67px;
        }
      }
    }
  }

  .owne-npm4 {
    background: #f3f4f7;
    padding: 90px 0;

    .img {
      margin-top: 40px;
      text-align: center;
      background: #fff;
      padding: 85px 0 60px;
    }
  }

  .buil-npm2 {
    background: #f3f4f7;
    background-position: right center;
    background-size: cover;
    padding: 90px 0;

    .itembox {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      .item {
        width: 15.416%;
        height: 270px;
        padding: 60px 18px;
        background: #fff;
        position: relative;
        transition: all 0.6s;
        overflow: hidden;

        &.on {
          width: 49.31%;
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;

          &:nth-child(1) {
            em {
              right: -30px;
            }
          }

          h5 {
            text-align: left;
            margin: 0 0 5px;
          }

          .ico {
            text-align: left;
          }

          .p {
            opacity: 1;
            transition-delay: .3s;
          }

          em {
            font-size: 280px;
            right: 0;
            letter-spacing: -15px;
            bottom: 0;
            width: auto;
            left: auto;
            line-height: 1;
          }
        }

        .ico {
          text-align: center;
          position: relative;
          z-index: 3;
        }

        .txt {
          position: relative;
          z-index: 3;
        }

        h5 {
          margin: 25px 0 5px;
          text-align: center;
          font-size: 18px;
          line-height: 26px;
          color: #333333;
        }

        .p {
          opacity: 0;
          transition: all 0.3s;
          position: absolute;

        }

        em {
          font-size: 120px;
          line-height: 160px;
          bottom: -35px;
          width: 100%;
          left: 0;
          text-align: center;
          position: absolute;
          font-family: 'DIN-Bold';
          background: linear-gradient(to bottom, #f4f4f4, #fbfbfb, #ffffff);
          -webkit-background-clip: text;
          color: transparent;
        }
      }
    }
  }
  .owne-npm5{
    padding: 90px 0;
    .ul{
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
      .li{
        width: 23.958%;
        min-height: 400px;
        border: 4px solid #f2f3f7;
        transition: all .4s;
        &:hover{
          border: 4px solid #d7000f;
          .h5{
            background: #d7000f;
            h5{
              color: #fff;
            }
            .i{
              .i1{
                display: none;
              }
              .i2{
                display: block;
              }
            }
          }
        }
        .h5{
          background: #f2f3f7;
          transition: all .4s;
          padding:22px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h5{
               
            font-size: 24px;
            color: #333333;
          }
        }
        .i{
          .i2{
            display: none;
          }
        }
        .dl{
          padding: 40px 20px 35px;
          .dd{
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #edeef2;
            h6{
              font-size: 18px;
              margin-bottom: 8px;
              color: #333333;
              font-weight: bold;
            }
            p{
              line-height: 22px;
            }
            &:last-child{
              padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .owne-npm6{
    padding: 90px 0;
    background: url(https://www.huajiantong.com/images/digital/bg8.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    .ul{  
      margin-top: 20px;
      position: relative;
      .img{
        width: 36.042%;
        margin: auto;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }
      .li{
        border-radius: 46px;
        background: #fff;
        height: 70px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding-right: 30px;
        width: 100%;
        position: relative;
        .em{
          background: #edeff2;
          border-radius: 50%;
          width: 52px;
          height: 52px; 
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          margin:0 10px;
          font-family: "DIN-Bold";
          color: #808080;
        }
        p{
          width: calc(100% - 72px);
          line-height: 22px;
        }
      }
      .fl{
        width: 34.72%;
        position: relative;
        z-index: 3;
        margin-top: 38px;
        .li:nth-child(1),.li:nth-child(5){
          right: -24%;
        }
        .li:nth-child(2),.li:nth-child(4){
          right: -8%;
        }
      }
      .fr{
        width: 34.72%;
        position: relative;
        z-index: 3;
        margin-top: 38px;
        .li{
          flex-direction: row-reverse;
          padding:0 0 0 30px;
          p{
            text-align: right;
          }
        }
        .li:nth-child(1),.li:nth-child(5){
          left: -24%;
        }
        .li:nth-child(2),.li:nth-child(4){
          left: -8%;
        }
      }
    }
  }
  .owne-npm7{
    padding: 90px 0;
    .ul{
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .li{
        border: 1px solid #e8eaed;
        width: 18.889%;
        min-height: 346px;
        padding-top: 45px;
        transition: all .4s;
        &:hover{
          box-shadow:0 0 20px 0 rgba(65, 94, 133, 0.12);
          border: 1px solid #fff;
        }
        .i{
          border-radius: 50%;
          width: 90px;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f3f4f7;
          margin: auto;
        }
        h5{
          text-align: center;
          font-size: 18px;
          margin-top: 35px;
          color: #333333;
        }
        .p{
          margin-top: 10px;
          p{
            text-align: center;
          }
        }
      }
    }
  }
  .owne-npm8{
    padding:0 0 90px 0;
    .ul{
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .li{
        background: #f3f4f7;
        width: 23.958%;
        min-height: 254px;
        padding: 40px 30px;
        display: flex;
    flex-direction: column;
    justify-content: space-between;
        .p{
          margin-top: 15px;
        }
        em{
          color: #d7000f;
             
          font-size: 30px;
          margin-top: 20px;
        }
      }
    }
  }
}
@media screen and (max-width:1550px){
  .ownerinvestor .owne-npm6 .ul .img{
    top: 60px;
  }
  .ownerinvestor .owne-npm3 .box .fr img{
    margin-right: -167px;
  }
}
@media (max-width:600px){
  .ownerinvestor{
    .owne-npm1{
      padding: .6rem 0;
      
      .box{
        flex-wrap: wrap;
        margin-top: .5rem;
        .item{
          width: 100%;
          margin-bottom: .2rem;
          .txt{
            padding: .3rem;
            h5{
              font-size: .28rem;
              line-height: .4rem;
              font-weight: 600;
            }
          }
        }
      }
    }
    .owne-npm2{
      padding-top: .6rem;
      .processes{
        margin-top: .5rem;
        *{
          display: none;
        }
        height: 5.42rem;
        background: url(https://www.huajiantong.com/images/digital/ownerinvestorChart1.jpg) no-repeat;
        background-size: 100% 100%;
      }
    }
    .owne-npm3{
      padding-top: .6rem;
      .box{
        margin-top: .2rem;
        display: flex;
        flex-wrap: wrap;
        .fl{
          order: 1;
          width: 100%;
          .dl{
            padding: .4rem .3rem;
            min-height: auto;
            img{
              width: .6rem;
            }
          }
          .h5{
            font-size: .26rem;
            line-height: .46rem;
          }
        }
        .fr{
          width: 100%;
          order: 2;
        }
      }
    }
    .owne-npm4{
      padding: .6rem 0;
      .img{
        padding: .5rem 0;
      }
    }
    .buil-npm2{
      padding: .6rem 0 .2rem;
      .itembox{
        margin-top: .5rem;
        flex-wrap: wrap;
        justify-content: space-between;
        .item,.item.on{
          width: 3.25rem;
          height: 4.6rem;
          padding: .5rem .3rem;
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          margin-bottom: .4rem;
          h5{
            font-size: .26rem;
            line-height: .4rem;
            font-weight: 600;
            text-align: left;
            margin: 0 0 .1rem;
          }
          .p{
            opacity: 1;
          }
          em{
            font-size: 120px;
            line-height: 160px;
            bottom: -35px;
            width: 100%;
            left: 0;
          }
        }
      }
    }
    .owne-npm5{
      padding: .6rem 0;
      .ul{
        margin-top: .5rem;
        flex-wrap: wrap;
        .li{
          width: 100%;
          margin-bottom: .2rem;
          min-height: auto;
          .h5{
            position: relative;
            padding: .3rem;
            h5{
              font-size: .3rem;
            }
            .i{
              position: absolute;
              right: .3rem;
              top: 8%;
              height: 80%;
              img{
                height: 100%;
                width: auto;
              }
            }
          }
          .dl{
            padding: .5rem .3rem;
            .dd{
              padding-bottom: .4rem;
              margin-bottom: .4rem;
              h6{
                font-size: .28rem;
              }
              strong{
                font-size: .24rem;
              }
            }
          }
        }
      }
    }
    .owne-npm6{
      padding: .6rem 0;
      .ul{
        display: flex;
        flex-wrap: wrap;
        .img{
          order: 1;
          width: 100%;
          position: static;
          margin-bottom: 0;
        }
        .fl,.fr{
          margin-top: 0;
          order: 2;
          width: 100%;
          .li:nth-child(odd),.li:nth-child(even){
            display: flex;
            left: 0;
            right: 0;
            margin: 0 auto .3rem;
            padding: 0 10px 0 0;
            flex-direction: row;
            p{
              text-align: left;
            }
          }
        }
      }
    }
    .owne-npm7{
      padding: .6rem 0;
      .ul{
        display: flex;
        margin-top: .5rem;
        justify-content: space-between;
        flex-wrap: wrap;
        .li{
          width: 3.25rem;
          min-height: auto;
          height: auto;
          margin: 0 0 .2rem;
          padding: .5rem .3rem;
          .i{
            width: 1.2rem;
            height: 1.2rem;
            margin: 0 auto ;
          }
          h5{
            margin-top: .3rem;
            font-size: .28rem;
            line-height: 1.5;
          }
        }
      }
    }
    .owne-npm8{
      padding-bottom: .6rem;
      .ul{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: .5rem;
        .li{
          width: 3.25rem;
          height: auto;
          min-height: auto;
          padding: .5rem .3rem;
          margin-bottom: .2rem;
        }
      }
    }
  }
}
</style>
  
  