<template>
  <div class="wrap">
    <nav-ber title="办理意向填写" homeUrl="/financial"></nav-ber>
    <iframe class="view" :src="$route.query.link"></iframe>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref, useProps  } from 'vue';
import { useRoute } from "vue-router";
const route = useRoute();
console.log('测试', route.query)
const title = route.query.title;
</script>
    
<style scoped lang="scss">
.wrap{
    height:100vh;
    overflow: hidden;
}
.view{
    height: 100%;
    overflow-y: auto;
}
iframe{
  display: block;
  width: 100%;
}
</style>
    
    