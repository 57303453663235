<template>
  <div class="wrap">
    <nav-ber :title="'工程资料服务'"></nav-ber>
    <main class="engineeringdata">
      <Cbanner :list="apiData"></Cbanner>
      <section class="buil-npm2">
        <div class="pagetit wow fadeInUp50">
          <h5>服务内容</h5>
        </div>
        <div class="itembox">
          <div
            class="item"
            :class="{ on: i === 0 }"
            v-for="(item, i) in apiData1"
            :key="item.newsId"
          >
            <div class="ico">
              <img :src="      item.image" alt="" />
            </div>
            <div class="txt">
              <h5>{{ item.newsTitle }}</h5>
              <div class="p">
                <p v-html="item.newsContent"></p>
              </div>
            </div>
            <em>{{ item.newsAuthor }}</em>
          </div>
        </div>
      </section>

      <section class="serv-npm2">
        <div class="pagetit wow fadeInUp50">
          <h5>服务优势</h5>
        </div>
        <div class="ul wow fadeInUp50">
          <div class="li" v-for="item in apiData2" :key="item.newsId">
            <div class="img">
              <img :src="      item.image" alt="" />
            </div>
            <div class="txt">
              <h5>{{ item.newsTitle }}</h5>
              <div v-html="item.newsContent"></div>
            </div>
          </div>
        </div>
      </section>

      <van-button
        class="cApplyBtn"
        @click="
          $router.push({
            path: '/consultform',
            query: { title: '提交意向', plateArr: '工程资料服务' },
          })
        "
        >在线咨询</van-button
      >
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import NavBer from "@/components/NavBer.vue";
import { getInfo } from "@/api/http";
import { ref } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 325,
    typeId: 2,
  });
  const { data: data2 } = await getInfo({
    coId: 361,
  });
  const { data: data3 } = await getInfo({
    coId: 363,
  });
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
};

getList();
</script>
  
<style scoped lang="scss">
.engineeringdata {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .soluslid {
    height: 4.5rem;
    border-radius: 0;
    .swiper-pagination {
      width: 100%;
    }
    .w1440 {
      padding: 1rem 0.3rem 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    :deep(.cont) {
      h5 {
        font-size: 0.3rem;
        color: #fff;
      }
      h6 {
        font-size: 0.3rem;
        color: #fff;
        font-family: DIN-Bold;
        letter-spacing: -0.05px;
        opacity: 0.5;
        line-height: 1.1;
        text-transform: uppercase;
      }
      .p {
        max-width: 100%;
        margin-top: 0.2rem;
        p {
          color: #fff;
          font-size: 0.24rem;
          line-height: 0.32rem;
        }
      }
    }
    .img {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    :deep(.van-swipe__indicators) {
      bottom: 0.3rem;
      display: flex;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      justify-content: center;
      transform: none;
      i {
        margin: 0 0.05rem;
        width: 0.52rem;
        height: 0.04rem;
        background: #fff;
        opacity: 0.5;
        border-radius: 0;
        transition: all 0.4s;
      }
    }
  }
  .buil-npm2 {
    margin-bottom: .6rem;
    background-position: right center;
    background-size: cover;
    .itembox {
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
      .item {
        width: 100%;
        height: auto;
        padding: 0.8rem 0.3rem;
        margin-bottom: 0.2rem;
        background: #fff;
        box-shadow: 0 0 0.3rem #415e851f;
        position: relative;
        overflow: hidden;
        em {
          font-size: 3rem;
          letter-spacing: -0.15rem;
          line-height: 1;
          right: -0.2rem;
          bottom: 0.3rem;
          text-align: right;
          text-align: center;
          position: absolute;
          font-family: "DIN-Bold";
          background: linear-gradient(to bottom, #f4f4f4, #fbfbfb, #ffffff);
          -webkit-background-clip: text;
          color: transparent;
        }
        .ico {
          text-align: center;
          position: relative;
          z-index: 3;
        }

        .txt {
          position: relative;
          z-index: 3;
        }
        .p {
          opacity: 1;
          position: relative;
          p {
            text-align: center;
          }
        }
        h5 {
          margin: 0.25rem 0 0.1rem;
          font-size: 0.28rem;
          color: #333333;
          text-align: center;
        }
        img {
          width: 0.5rem;
          margin: auto;
        }
      }
    }
  }
  .serv-npm2 {
    padding-bottom: 0.9rem;
    .ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 0.35rem;
      .li {
        width: 100%;
        margin-bottom: 0.2rem;
        background: #f3f4f7;
        overflow: hidden;
        border-radius: .28rem;
        img {
          width: 100%;
        }
        .txt {
          padding: 0.35rem 0.3rem;
          h5 {
            font-size: 0.28rem;
            margin-bottom: 0.1rem;
          }
        }
      }
    }
  }
}
</style>
  
  