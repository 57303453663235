<template>
  <div class="wrap">
    <nav-ber :title="' 项目进度解决方案'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>项目进度解决方案</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/schedulePic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>进度计划</h4>
                <div class="line"></div>
                <p>制定合理的进度计划，通过双代号网络计划保证计划逻辑关系和关键线路完整性和正确性，指导项目时刻抓住主要矛盾，自动计算最短工期、推演最优项目方案，规避现场冲突，辅助项目计算关键线路变化，及时准确预警风险，指导纠偏，提供索赔依据，最终达到有效缩短工期，节约成本，增强企业和项目竞争力、降低履约风险的目的。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/schedulePic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>进度管理</h4>
                <div class="line"></div>
                <p>以进度为主线贯穿生产过程的各个部门，保证生产高效进行。公司管理者可实时查看进度报表，各项目的总工期和关键节点工期提前滞后情况一目了然，便于进行实时工期预警，提前掌握风险，合理优化、调配公司资源，发挥集团、公司优势。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/schedulePic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>BIM5D</h4>
                <div class="line"></div>
                <p>BIM5D以BIM平台为核心的一款基于BIM的施工过程精细化管理工具。它可以集成全专业模型，并以集成模型为载体，关联施工过程中的进度、合同、成本、质量、安全、图纸、物料等信息，为项目提供数据支撑，实现有效决策和精细管理，从而达到减少施工变更，缩短工期、控制成本、提升质量的目的，同时也能够为项目的进度、成本、物料管控等提供精确模型与准确数据</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/schedulePic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>形象进度管理</h4>
                <div class="line"></div>
                <p>建立分部分项标准库以及可视化形象进度，实现进度的实施管控。<br>
                1、以分部分项标准库为基础进行形象进度统计的管理，并且根据任务完成情况，自动抓取每日进展，实时更新;<br>
                /2、形象进度以可视化的形式进行展示，直观查看工期差异，并支持对应施工节点的影像查看。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/schedulePic5.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4>进度分析</h4>
                <div class="line"></div>
                <p>将数据结果以直观的图表、报表或仪表盘形式展示，便于管理人员快速了解工地进展情况，识别潜在风险和瓶颈，并及时做出调整和决策。</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .82rem 0 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}

</style>

