<template>
  <div class="wrap">
    <nav-ber title="热点活动"></nav-ber>
    <van-loading vertical  type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="news" v-if="!typeLoad">
      <!-- <van-tabs
        :class="['border', {'four': newsTypeArr.length == 4}]"
        v-model:active="active"
        color="#f70000"
        line-width="56"
        title-inactive-color="#2d322d"
        @change="newsChange"
      >
        <van-tab :title="til.typeName" v-for="til in newsTypeArr" :key="til.typeId"></van-tab>
      </van-tabs> -->
      <van-list  class="newsList" v-model="listLoad" :finished="finished" finished-text="我是有底线的" @load="onLoad">
        <div class="li" v-for="item in list" :key="item.newsId">
          <div class="a" @click="skipOutsideChain(item.address)" target="_blank" v-if="item.address && item.address.trim !== ''">
            <div class="pic" v-if="item.newsAccess"><img :src="item.newsAccess" alt=""></div>
            <div class="txt">
              <h4>{{ item.newsTitle }}</h4>
              <div class="p">
                发布时间：<em>{{item.newsAddTime}}</em><br/>
                <em>{{item.newsNum}}</em>人观看
              </div>
            </div>
          </div>
          <router-link v-else :to="`/newsdetail/${item.newsId}`">
            <div class="pic" v-if="item.newsAccess"><img :src="item.newsAccess" alt=""></div>
            <div class="txt">
              <h4>{{ item.newsTitle }}</h4>
              <div class="p">
                发布时间：<em>{{item.newsAddTime}}</em><br/>
                <em>{{item.newsNum}}</em>人观看
              </div>
            </div>
          </router-link>
        </div>
        <!-- <router-link to="/news/vdoDetail">
          <div class="pic">
            <img src="~@/assets/images/home/homeNewsPic3.jpg" alt="" />
          </div>
          <div class="txt">
            <h4>[视频]推动高质量发展广西为重项目建设提供资源要素保障..</h4>
            <div class="p">
              发布时间：<em>2024.03.01</em><br />
              <em>6677</em>人观看
            </div>
          </div>
        </router-link>
        <router-link to="/newsdetail">
          <div class="pic">
            <img src="~@/assets/images/home/homeNewsPic4.jpg" alt="" />
          </div>
          <div class="txt">
            <h4>科技创新 AI赋能AI大模型主题沙龙活动成功举办</h4>
            <div class="p">
              发布时间：<em>2024.03.01</em><br />
              <em>6677</em>人观看
            </div>
          </div>
        </router-link> -->
      </van-list>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getNewsTypes, getNews } from '@/api/http';
import { skipOutsideChain } from '@/api/public';
import { ref } from "vue";

const active = ref(0);
const newsTypeArr = ref([]);
const list = ref([]);
const pageIndex = ref(1);
const finished = ref(false);
const typeLoad = ref(false);
const listLoad = ref(false);
// 获取新闻类型
const newsType = async () => {
  try {
    typeLoad.value = true;
    const { data: res } = await getNewsTypes()
    res.data.forEach(n => {
      console.log(n)
      if(n.typeName == '新闻资讯' || n.typeName == '热点活动'){
        newsTypeArr.value.push(n)
      }
    })
    const num =  newsTypeArr.value[0].typeId;
    typeLoad.value = false;
    newsList(num);
  } catch(err) {
    typeLoad.value = false;
    console.log('错误捕获，获取新闻类型',err)
  }
}
// newsType();
// 根据类型获取新闻列表
const newsList = async (typeId) => {
  try{
    if(listLoad.value) return
    listLoad.value = true;
    const { data } = await getNews({
      pageIndex: pageIndex.value,
      pageSize: 10,
      typeId
    })
    listLoad.value = false;
    if(!data.data.length) return finished.value = true
    pageIndex.value++;
    let arr = data.data;
    console.log('测试',arr)
    list.value.push(...arr)
  }catch(err){
    listLoad.value = false;
    console.log('错误捕获，获取新闻类型',err)
  }
}
// 新闻类型切换
const newsChange = (i) => {
  const num =  newsTypeArr.value[i].typeId;
  list.value = [];
  pageIndex.value = 1;
  finished.value = false;
  newsList(num);
}
// 加载更多
const onLoad = () => {
  // const num =  newsTypeArr.value[active.value].typeId;
  const num =  4551;
  newsList(num)
}
</script>
  
<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';

.news {
  position: relative;
  padding: 0.4rem;
  min-height: 60vh;
  background: url(~@/assets/images/news/newsBg.jpg) no-repeat top center;
  background-size: 100%;
}
.newsList{
  .four{
    :deep(.van-tabs__nav){
      justify-content: space-between;
    }
  }
  a, .a{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: .3rem 0;
    border-top: 1px dashed #dfdfdf;
    &:first-child{
      border-top: 0 none;
    }
  }
  .pic{
    width: 2.9rem;
    overflow: hidden;
    margin-right: .36rem;
    border-radius: .28rem;
  }
  .txt{
    flex: 1;
    padding: .12rem 0 0;
    h4{
      font-size: .28rem;
      line-height: .4rem;
      margin-bottom: .2rem;
      display: -webkit-box;  
      -webkit-line-clamp: 2; /* 设置显示的行数 */  
      -webkit-box-orient: vertical;  
      overflow: hidden;  
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .p{
      font-size: .22rem;
      line-height: .3rem;
      color: rgba(45,50,45,.4);
      em{
        font-family: 'Gilroy-Bold';
      }
    }
  }
}
</style>
  
  