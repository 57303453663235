<template>
  <div class="wrap">
    <nav-ber :title="'视频详情'"></nav-ber>
    <main>
      <div class="content">
        <div class="vdo">
          <img src="~@/assets/images/news/vdoPic1.jpg" alt="">
          <div class="btn"></div>
        </div>
        <div class="btnRow">
          <div class="btn share">分享</div>
          <div class="btn collect">收藏</div>
          <div class="btn cache">缓存</div>
        </div>
        <div class="tilBox">
          <h2>要素联动编制住房发展规划 精准施策满足百姓安居需求</h2>
          <div class="row">
            <div class="time">发布时间：2024.03.01</div>
            <div class="num">6677人观看</div>
          </div>
        </div>
        <div class="txt">
          <p>住房城乡建设部在近日发布的《关于做好住房发展规划和年度计划编制工作的通知》中，重点提出了两大任务：一是科学编制2024年、2025年住房发展年度计划，并要求各城市以适当方式向社会公布；二是提前谋划2026～2030年5年住房发展规划，要求各省份提前谋划本行政区域的规划，同时指导各城市做好规划编制工作并适时向社会公布。规划实施期间，各城市要在每年3月31日前向社会公布当年住房发展年度计划有关情况。</p>
          <p>清华大学建设管理系教授、房地产研究所所长刘洪玉在接受本报记者采访时表示，为实现全体居民住有所居，许多国家和地区都建立了长期住房发展规划（或长期策略）和短期年度住房供应计划（或行动计划）相结合的住房供应管理机制。长期规划和年度计划形成的过程，也是政府部门之间、政府与市场和社会之间就规划目标、资源需求等达成共识的过程，进而为后续实施奠定基础。虽然目前我国一些城市编制了当地的“十四五”住房发展规划，但实际的情况是，更多的城市没有编制或没有公开发</p>
        </div>
      </div>
      <div class="correlation">
        <h3 class="cTil"><em>相关阅读</em></h3>
        <div class="newsList">
        <router-link to="">
          <div class="pic">
            <img src="~@/assets/images/home/homeNewsPic1.jpg" alt="" />
          </div>
          <div class="txt">
            <h4>推动高质量发展广西为重项目建设提供资源要素保障..</h4>
            <div class="p">
              发布时间：<em>2024.03.01</em><br />
              <em>6677</em>人观看
            </div>
          </div>
        </router-link>
        <router-link to="">
          <div class="pic">
            <img src="~@/assets/images/home/homeNewsPic2.jpg" alt="" />
          </div>
          <div class="txt">
            <h4>科技创新 AI赋能AI大模型主题沙龙活动成功举办</h4>
            <div class="p">
              发布时间：<em>2024.03.01</em><br />
              <em>6677</em>人观看
            </div>
          </div>
        </router-link>
        <router-link to="">
          <div class="pic">
            <img src="~@/assets/images/home/homeNewsPic3.jpg" alt="" />
          </div>
          <div class="txt">
            <h4>推动高质量发展广西为重项目建设提供资源要素保障..</h4>
            <div class="p">
              发布时间：<em>2024.03.01</em><br />
              <em>6677</em>人观看
            </div>
          </div>
        </router-link>
        <router-link to="">
          <div class="pic">
            <img src="~@/assets/images/home/homeNewsPic4.jpg" alt="" />
          </div>
          <div class="txt">
            <h4>科技创新 AI赋能AI大模型主题沙龙活动成功举办</h4>
            <div class="p">
              发布时间：<em>2024.03.01</em><br />
              <em>6677</em>人观看
            </div>
          </div>
        </router-link>
      </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>
  
<style scoped lang="scss">

main {
  position: relative;
  padding: .6rem .4rem;
  background: url(~@/assets/images/news/newsBg.jpg) no-repeat top center;
  background-size: 100%;
}
.content{
  padding-bottom: .3rem;
  h2.cTil{
    height: auto;
    line-height: .5rem;
    text-align: left;
    em{
      height: auto;
      padding-bottom: .23rem;
      margin-bottom: -.06rem;
      &:after{
        right: auto;
      }
    }
  }
  .txt{
    p{
      margin-bottom: .36rem;
    }
    img{
      margin: 0 auto;
      border-radius: var(--border-radius-s);
    }
  }
}

.vdo{
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  margin-bottom: .3rem;
  .btn{
    position: absolute;
    left: 50%;
    top: 50%;
    width: .9rem;
    height: .9rem;
    margin: -.45rem 0 0 -.45rem;
    z-index: 5;
    background: url(~@/assets/images/news/vdoIconPlay.png) no-repeat center;
    background-size: contain;
  }
}
.btnRow{
  display: flex;
  justify-content: flex-end;
  margin-bottom: .4rem;
  .btn{
    font-size: .22rem;
    height: .5rem;
    line-height: .5rem;
    color: rgba(45,50,45,.4);
    padding-left: .45rem;
    background: no-repeat left center;
    background-size: .34rem;
    margin-left: .6rem;
    &.share{
      background-image: url(~@/assets/images/news/vdoIconShare.png);
    }
    &.collect{
      background-image: url(~@/assets/images/news/vdoIconCollect.png);
    }
    &.cache{
      background-image: url(~@/assets/images/news/vdoIconCache.png);
    }
  }
}
.tilBox{
  position: relative;
  padding-bottom: .2rem;
  border-bottom: .06rem solid #e5e5e5;
  margin-bottom: .35rem;
  &:after{
    position: absolute;
    left: 0;
    bottom: -.06rem;
    content: "";
    display: block;
    height: .06rem;
    width: 1.1rem;
    background: #f70000;
  }
  h2{
    line-height: .5rem;
    font-size: .36rem;
    color: #2d322d;
    margin-bottom: .2rem;
  }
  .row{
    display: flex;
    .time,.num{
      font-size: .22rem;
      line-height: .36rem;
      color: rgba(45,50,45,.4);
    }
    .num{
      margin-left: auto;
    }
  }
}
.newsList{
  a{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: .3rem 0;
    border-top: 1px dashed #dfdfdf;
    &:first-child{
      border-top: 0 none;
    }
  }
  .pic{
    width: 2.9rem;
    overflow: hidden;
    border-radius: .28rem;
  }
  .txt{
    width: 3.44rem;
    padding: .12rem 0 0;
    h4{
      font-size: .28rem;
      line-height: .4rem;
      height: .8rem;
      margin-bottom: .32rem;
      display: -webkit-box;  
      -webkit-line-clamp: 2; /* 设置显示的行数 */  
      -webkit-box-orient: vertical;  
      overflow: hidden;  
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .p{
      font-size: .22rem;
      line-height: .3rem;
      color: rgba(45,50,45,.4);
      em{
        font-family: 'Gilroy-Bold';
      }
    }
  }
}
</style>
  
  