import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/index.vue'
import Financial from '../views/financial/financial.vue'
import FinancialLwgzd from '../views/financial/lwgzd.vue'
import FinancialUniversal from '../views/financial/universal.vue'
import FinancialInsuranceList from '../views/financial/insuranceList.vue'
import FinancialCreditList from '../views/financial/creditList.vue'
import FinancialCreditData from '../views/financial/creditData.vue'
import FinancialInsuranceData from '../views/financial/insuranceData.vue'
import FinancialGreenfinance from '../views/financial/greenfinance.vue'
import GZD from '../views/gzd.vue'



// import FinancialSafety from '../views/financial/safety.vue'
// import FinancialEnsure from '../views/financial/ensure.vue'
// import FinancialPrevention from '../views/financial/prevention.vue'
// import FinancialIntentionToInsure from '../views/financial/intentionToInsure.vue'
// import FinancialIntentionFinancing from '../views/financial/intentionFinancing.vue'
// import FinancialSafetyResponsibility from '../views/financial/safetyResponsibility.vue'
// import FinancialSafetyResponsibilityForm from '../views/financial/safetyResponsibilityForm.vue'
import FinancialPayment from '../views/financial/payment.vue'

import Engineering from '../views/engineering/engineering.vue'
import Bim from '../views/engineering/bim.vue'
import Intelligent from '../views/engineering/intelligent.vue'
import Safety from '../views/engineering/safety.vue'
import Quality from '../views/engineering/quality.vue'
import Schedule from '../views/engineering/schedule.vue'
import Cost from '../views/engineering/cost.vue'
import Build from '../views/engineering/build.vue'
import Machinery from '../views/engineering/machinery.vue'
import Supplies from '../views/engineering/supplies.vue'
import Exhibition from '../views/engineering/exhibition.vue'
import Experience from '../views/engineering/experience.vue'
import Adhibition from '../views/engineering/adhibition.vue'
import Seal from '../views/engineering/seal.vue'
import Consult from '../views/engineering/consult.vue'

import Engineeringsev from '../views/engineeringsev/engineeringsev.vue'
import EngineeringsevBim from '../views/engineeringsev/bim.vue'
import EngineeringsevPartner from '../views/engineeringsev/partner.vue'
import EngineeringsevProvider from '../views/engineeringsev/provider.vue'
import EngineeringsevDistributor from '../views/engineeringsev/distributor.vue'


import Digitization from '../views/digitization/digitization.vue'
import DigitizationArrows from '../views/digitization/arrows.vue'
import DigitizationScene from '../views/digitization/scene.vue'
import DigitizationIndustrialization from '../views/digitization/industrialization.vue'
import DigitizationWisdom from '../views/digitization/wisdom.vue'


import Facility from '../views/facility/facility.vue'
import Classify from '../views/facility/classify.vue'
import FacilityDetails from '../views/facility/id.vue'

import News from '../views/news/news.vue'
import HotNews from '../views/news/hotNews.vue'
import NewsDetail from '../views/news/detail.vue'
import NewsVdoDetail from '../views/news/vdoDetail.vue'

import Enterprise from '../views/enterprise/enterprise.vue'
import EnterpriseHunt from '../views/enterprise/hunt.vue'
import EnterpriseConsult from '../views/enterprise/consult.vue'

import Worker from '../views/worker/worker.vue'
import Construction from '../views/construction/construction.vue'

import Seek from '../views/seek/seek.vue'

import Sest from '../views/sest.vue'

import Embed from '../views/public/embed.vue'
import TransactResult from '../views/public/transactResult.vue'
import Consultform from '../views/public/consultform.vue'
import SmartConsultform from '../views/public/smartConsultform.vue'
import SuccessfulDelivery from '../views/public/successfulDelivery.vue'

import MarketUsed from '../views/market/used'

import Shoppingmall from '../views/shoppingmall/shoppingmall'

import Knowledge from '../views/knowledge/knowledge'
import KnowledgeArchitecture from '../views/knowledge/architecture'
import KnowledgeSafety from '../views/knowledge/safety'

import Industrialbrain from '../views/industrialbrain/industrialbrain'
import IndustrialbrainIntegrity from '../views/industrialbrain/integrity'

import Digital from '../views/digital/digital'
import DigitalEnterpriseend from '../views/digital/enterpriseend'
import DigitalConsgeneral from '../views/digital/consgeneral'
import DigitalProfsubcontra from '../views/digital/profsubcontra'
import DigitalOwnerinvestor from '../views/digital/ownerinvestor'
import DigitalElplatform from '../views/digital/elplatform'
import Digitaltendering from '../views/digital/tendering'
import digitalcapitaldig from '../views/digital/capitaldig'
import digitalriskcontrol from '../views/digital/riskcontrol'
import digitalmanagement from '../views/digital/management'
import digitalscene from '../views/digital/scene'
import digitalcontract from '../views/digital/contract'
import digitalcostcontrol from '../views/digital/costcontrol'
import digitalmaterialdig from '../views/digital/materialdig'
import digitalsubcontracting from '../views/digital/subcontracting'


// ===
import labor from '../views/labor/labor'
import optimal from '../views/labor/optimal'
import buildingMaterials from '../views/buildingMaterials/buildingMaterials'
import equipment from '../views/equipment/equipment'
import lease from '../views/equipment/lease'
import robot from '../views/equipment/robot'
import service from '../views/service/service'
import serviceCost from '../views/service/cost'
import serviceEngineeringdata from '../views/service/engineeringdata'
import serviceSignet from '../views/service/signet'
import governmentdigital from '../views/digital/governmentdigital'
import buildings from '../views/digital/buildings'
import digitalconstruction from '../views/digital/construction'
import industrialization from '../views/digital/industrialization'
import intelligence from '../views/digital/intelligence'
import supportability from '../views/digital/supportability'
import housing from '../views/digital/housing'
import capital from '../views/digital/capital'
import selfhouse from '../views/digital/selfhouse'
import estate from '../views/digital/estate'
import smartgas from '../views/digital/smartgas'
import drainage from '../views/digital/drainage'
import residential from '../views/digital/residential'
import examination from '../views/digital/examination'
import community from '../views/digital/community'
import sewage from '../views/digital/sewage'
import environment from '../views/digital/environment'
import urbanization from '../views/digital/urbanization'
import file from '../views/digital/file'
import government from '../views/digital/government'
import city from '../views/digital/city'
import cim from '../views/digital/cim'
import affairshall from '../views/digital/affairshall'
import allinonemachine from '../views/digital/allinonemachine'
// ===





const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/gzd',
    component: GZD
  },
  {
    path: '/financial',
    name: 'financial',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/financial/financial.vue')
    component: Financial
  },
  {
    path: '/financial/lwgzd',
    name: 'financialLwgzd',
    meta: {
      keepAlive: true
    },
    component: FinancialLwgzd
  },
  {
    path: '/financial/universal',
    name: 'financialUniversal',
    meta: {
      keepAlive: true
    },
    component: FinancialUniversal
  },
  {
    path: '/financial/insuranceList',
    name: 'financialInsuranceList',
    component: FinancialInsuranceList
  },
  {
    path: '/financial/creditList',
    name: 'financialCreditList',
    component: FinancialCreditList
  },
  {
    path: '/financial/creditData',
    name: 'financialCreditData',
    meta: {
      keepAlive: true
    },
    component: FinancialCreditData
  },
  {
    path: '/financial/insuranceData',
    name: 'financialInsuranceData',
    meta: {
      keepAlive: true
    },
    component: FinancialInsuranceData
  },
  {
    path: '/financial/greenfinance',
    name: 'financialGreenfinance',
    meta: {
      keepAlive: true
    },
    component: FinancialGreenfinance
  },
  // {
  //   path: '/financial/safety',
  //   name: 'financialSafety',
  //   component: FinancialSafety
  // },
  // {
  //   path: '/financial/ensure',
  //   name: 'financialEnsure',
  //   component: FinancialEnsure
  // },
  // {
  //   path: '/financial/prevention',
  //   name: 'financialPrevention',
  //   component: FinancialPrevention
  // },
  // {
  //   path: '/financial/intentionToInsure',
  //   name: 'financialIntentionToInsure',
  //   meta: {
  //     keepAlive: true
  //   },
  //   component: FinancialIntentionToInsure
  // },
  // {
  //   path: '/financial/intentionFinancing',
  //   name: 'financialIntentionFinancing',
  //   meta: {
  //     keepAlive: true
  //   },
  //   component: FinancialIntentionFinancing
  // },
  // {
  //   path: '/transactResult',
  //   name: 'transactResult',
  //   meta: {
  //     keepAlive: true
  //   },
  //   component: TransactResult
  // },
  // {
  //   path: '/financial/safetyResponsibility',
  //   name: 'financialSafetyResponsibility',
  //   component: FinancialSafetyResponsibility
  // },
  // {
  //   path: '/financial/safetyResponsibilityForm',
  //   name: 'financialSafetyResponsibilityForm',
  //   component: FinancialSafetyResponsibilityForm
  // },
  {
    path: '/financial/payment',
    name: 'financialPayment',
    component: FinancialPayment
  },
  {
    path: '/engineeringsev',
    name: 'engineeringsev',
    component: Engineeringsev
  },
  {
    path: '/engineeringsevBim',
    name: 'engineeringsevBim',
    component: EngineeringsevBim
  },
  {
    path: '/engineeringsev/partner',
    name: 'engineeringsevPartner',
    component: EngineeringsevPartner
  },
  {
    path: '/engineeringsev/provider',
    name: 'engineeringsevProvider',
    component: EngineeringsevProvider
  },
  {
    path: '/engineeringsev/distributor',
    name: 'engineeringsevDistributor',
    component: EngineeringsevDistributor
  },

   
  {
    path: '/engineering',
    name: 'engineering',
    component: Engineering
  },
  {
    path: '/engineering/bim',
    name: 'bim',
    component: Bim
  },
  {
    path: '/engineering/intelligent',
    name: 'intelligent',
    component: Intelligent
  },
  {
    path: '/engineering/safety',
    name: 'safety',
    component: Safety
  },
  {
    path: '/engineering/quality',
    name: 'quality',
    component: Quality
  },
  {
    path: '/engineering/schedule',
    name: 'schedule',
    component: Schedule
  },
  {
    path: '/engineering/cost',
    name: 'cost',
    component: Cost
  },
  {
    path: '/engineering/build',
    name: 'build',
    component: Build
  },
  {
    path: '/engineering/machinery',
    name: 'machinery',
    component: Machinery
  },
  {
    path: '/engineering/supplies',
    name: 'supplies',
    component: Supplies
  },
  {
    path: '/engineering/exhibition',
    name: 'exhibition',
    component: Exhibition
  },
  {
    path: '/engineering/experience',
    name: 'experience',
    component: Experience
  },
  {
    path: '/engineering/adhibition',
    name: 'adhibition',
    component: Adhibition
  },
  {
    path: '/engineering/seal',
    name: 'seal',
    component: Seal
  },
  {
    path: '/engineering/consult',
    name: 'consult',
    meta: {
      keepAlive: true
    },
    component: Consult
  },

  {
    path: '/digitization',
    name: 'digitization',
    component: Digitization
  },
  {
    path: '/digitization/arrows',
    name: 'arrows',
    component: DigitizationArrows
  },
  {
    path: '/digitization/scene',
    name: 'scene',
    component: DigitizationScene
  },
  {
    path: '/digitization/industrialization',
    name: 'industrialization',
    component: DigitizationIndustrialization
  },
  {
    path: '/digitization/wisdom',
    name: 'wisdom',
    component: DigitizationWisdom
  },
  {
    path: '/facility',
    name: 'facility',
    component: Facility
  },
  {
    path: '/facility/classify',
    name: 'classify',
    meta: {
      keepAlive: true
    },
    component: Classify
  },
  {
    path: '/facility/:id',
    name: 'facilityDetails',
    meta: {
      keepAlive: true
    },
    component: FacilityDetails
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      keepAlive: true
    },
    component: News
  },
  {
    path: '/hotNews',
    name: 'hotNews',
    meta: {
      keepAlive: true
    },
    component: HotNews
  },
  {
    path: '/newsdetail/:id',
    name: 'newsDetail',
    meta: {
      keepAlive: true
    },
    component: NewsDetail
  },
  {
    path: '/news/vdoDetail',
    name: 'newsVdoDetail',
    component: NewsVdoDetail
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: Enterprise
  },
  {
    path: '/enterprise/hunt',
    name: 'hunt',
    component: EnterpriseHunt
  },
  {
    path: '/enterprise/consult',
    name: 'enterpriseConsult',
    meta: {
      keepAlive: true
    },
    component: EnterpriseConsult
  },
  {
    path: '/worker',
    name: 'worker',
    component: Worker
  },
  {
    path: '/construction',
    name: 'construction',
    component: Construction
  },
  {
    path: '/seek',
    name: 'seek',
    meta: {
      keepAlive: true
    },
    component: Seek
  },
  {
    path: '/sest',
    name: 'sest',
    component: Sest
  },
  {
    path: '/embed',
    name: 'embed',
    meta: {
      keepAlive: true
    },
    component: Embed
  },
  {
    path: '/consultform',
    name: 'consultform',
    meta: {
      keepAlive: true
    },
    component: Consultform
  },
  {
    path: '/smartConsultform',
    name: 'smartConsultform',
    meta: {
      keepAlive: true
    },
    component: SmartConsultform
  },
  {
    path: '/successfulDelivery',
    name: 'successfulDelivery',
    meta: {
      keepAlive: true
    },
    component: SuccessfulDelivery
  },
  {
    path: '/marketUsed',
    name: 'marketUsed',
    component: MarketUsed
  },
  {
    path: '/shoppingmall',
    name: 'shoppingmall',
    component: Shoppingmall
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    component: Knowledge
  },
  {
    path: '/knowledge/architecture',
    name: 'knowledgeArchitecture',
    component: KnowledgeArchitecture
  },
  {
    path: '/knowledge/safety',
    name: 'knowledgeSafety',
    component: KnowledgeSafety
  },
  {
    path: '/industrialbrain',
    name: 'industrialbrain',
    component: Industrialbrain
  },
  {
    path: '/industrialbrain/integrity',
    name: 'industrialbrainIntegrity',
    component: IndustrialbrainIntegrity
  },
  {
    path: '/digital',
    name: 'digital',
    component: Digital
  },
  {
    path: '/digital/enterpriseend',
    name: 'digitalEnterpriseend',
    component: DigitalEnterpriseend
  },
  {
    path: '/digital/consgeneral',
    name: 'digitalConsgeneral',
    component: DigitalConsgeneral
  },
  {
    path: '/digital/profsubcontra',
    name: 'digitalProfsubcontra',
    component: DigitalProfsubcontra
  },
  {
    path: '/digital/ownerinvestor',
    name: 'digitalOwnerinvestor',
    component: DigitalOwnerinvestor
  },
  {
    path: '/digital/elplatform',
    name: 'digitalElplatform',
    component: DigitalElplatform
  },
  {
    path: '/digital/tendering',
    name: 'digitaltendering',
    component: Digitaltendering
  },
  {
    path: '/digital/capitaldig',
    name: 'digitalcapitaldig',
    component: digitalcapitaldig
  },
  {
    path: '/digital/riskcontrol',
    name: 'digitalriskcontrol',
    component: digitalriskcontrol
  },
  {
    path: '/digital/management',
    name: 'digitalmanagement',
    component: digitalmanagement
  },
  {
    path: '/digital/scene',
    name: 'digitalscene',
    component: digitalscene
  },
  {
    path: '/digital/contract',
    name: 'digitalcontract',
    component: digitalcontract
  },
  {
    path: '/digital/costcontrol',
    name: 'digitalcostcontrol',
    component: digitalcostcontrol
  },
  {
    path: '/digital/materialdig',
    name: 'digitalmaterialdig',
    component: digitalmaterialdig
  },
  {
    path: '/digital/subcontracting',
    name: 'digitalsubcontracting',
    component: digitalsubcontracting
  },
  
  // ======
  {
    path: '/labor',
    name: 'labor',
    component: labor
  },
  {
    path: '/optimal',
    name: 'optimal',
    component: optimal
  },
  {
    path: '/buildingMaterials',
    name: 'buildingMaterials',
    component: buildingMaterials
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: equipment
  },
  {
    path: '/equipment/lease',
    name: 'lease',
    component: lease
  },
  {
    path: '/robot',
    name: 'robot',
    component: robot
  },
  {
    path: '/service',
    name: 'service',
    component: service
  },
  {
    path: '/service/cost',
    name: 'serviceCost',
    component: serviceCost
  },
  {
    path: '/service/engineeringdata',
    name: 'serviceEngineeringdata',
    component: serviceEngineeringdata
  },
  {
    path: '/service/signet',
    name: 'serviceSignet',
    component: serviceSignet
  },
  {
    path: '/digital/governmentdigital',
    name: 'governmentdigital',
    component: governmentdigital
  },
  {
    path: '/digital/buildings',
    name: 'buildings',
    component: buildings
  },
  {
    path: '/digital/construction',
    name: 'digitalconstruction',
    component: digitalconstruction
  },
  {
    path: '/digital/industrialization',
    name: 'industrialization12',
    component: industrialization
  },
  {
    path: '/digital/intelligence',
    name: 'intelligence',
    component: intelligence
  },
  {
    path: '/digital/supportability',
    name: 'supportability',
    component: supportability
  },
  {
    path: '/digital/housing',
    name: 'housing',
    component: housing
  },
  {
    path: '/digital/capital',
    name: 'capital',
    component: capital
  },
  {
    path: '/digital/selfhouse',
    name: 'selfhouse',
    component: selfhouse
  },
  {
    path: '/digital/estate',
    name: 'estate',
    component: estate
  },
  {
    path: '/digital/smartgas',
    name: 'smartgas',
    component: smartgas
  },
  {
    path: '/digital/drainage',
    name: 'drainage',
    component: drainage
  },
  {
    path: '/digital/residential',
    name: 'residential',
    component: residential
  },
  {
    path: '/digital/examination',
    name: 'examination',
    component: examination
  },
  {
    path: '/digital/community',
    name: 'community',
    component: community
  },
  {
    path: '/digital/sewage',
    name: 'sewage',
    component: sewage
  },
  {
    path: '/digital/environment',
    name: 'environment',
    component: environment
  },
  {
    path: '/digital/urbanization',
    name: 'urbanization',
    component: urbanization
  },
  {
    path: '/digital/file',
    name: 'file',
    component: file
  },
  {
    path: '/digital/government',
    name: 'government',
    component: government
  },
  {
    path: '/digital/city',
    name: 'city',
    component: city
  },
  {
    path: '/digital/cim',
    name: 'cim',
    component: cim
  },
  {
    path: '/digital/allinonemachine',
    name: 'allinonemachine',
    component: allinonemachine
  },
  {
    path: '/digital/affairshall',
    name: 'affairshall',
    component: affairshall
  },
  
  // ======
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), //历史模式
  // history: createWebHashHistory(process.env.BASE_URL), //hash模式
  
  routes,
  scrollBehavior(to, from, savedPosition){
    return { top: 0 };
  }
})

export default router
