<template>
  <div class="wrap">
    <nav-ber title="BIM解决方案"></nav-ber>
    <swiper
      :pagination="pagination"
      :modules="modules"
      :loop="true"
      class="banner"
    >
      <swiper-slide>
        <img src="~@/assets/images/engineeringsev/engineeringsevBan1.jpg" alt="">
        <div class="txt">
          <h3>汇聚智能建造全场景解决方案</h3>
          <h4>Gathering intelligent construction<br>full scenario solutions</h4>
          <div class="p">让智能建造成就未来城市、智能科技助力高效施工。打造更美好的明天!</div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <img src="~@/assets/images/engineeringsev/engineeringsevBan1.jpg" alt="">
        <div class="txt">
          <h3>汇聚智能建造全场景解决方案</h3>
          <h4>Gathering intelligent construction<br>full scenario solutions</h4>
          <div class="p">让智能建造成就未来城市、智能科技助力高效施工。打造更美好的明天!</div>
        </div>
      </swiper-slide>
    </swiper>

    <main>
      <van-tabs v-model:active="active" class="ball" @click-tab="scrollToSection">
        <van-tab title="BIM建模"></van-tab>
        <van-tab title="BIM碰撞检查"></van-tab>
        <van-tab title="BIM深化设计"></van-tab>
        <van-tab title="BIM施工模拟"></van-tab>
      </van-tabs>
      <div class="bd">
        <div class="item">
          <ul class="infoUl">
            <li>
              <div class="dt">
                <img src="      /hjt-door/202404/gw/hjt/20240415175752646c80a6-1fec-43cf-b1d2-787419270c62.png" alt="">
                <div class="til">BIM解决方案</div>
              </div>
              <div class="dd">
                <p>提供全面的BIM解决方案优化建筑生命周期管理</p>
              </div>
            </li>
            <li>
              <div class="dt">
                <img src="      /hjt-door/202404/gw/hjt/20240415175752646c80a6-1fec-43cf-b1d2-787419270c62.png" alt="">
                <div class="til">BIM解决方案</div>
              </div>
              <div class="dd">
                <p>提供全面的BIM解决方案优化建筑生命周期管理</p>
              </div>
            </li>
            <li>
              <div class="dt">
                <img src="      /hjt-door/202404/gw/hjt/20240415175752646c80a6-1fec-43cf-b1d2-787419270c62.png" alt="">
                <div class="til">BIM解决方案</div>
              </div>
              <div class="dd">
                <p>提供全面的BIM解决方案优化建筑生命周期管理</p>
              </div>
            </li>
            <li>
              <div class="dt">
                <img src="      /hjt-door/202404/gw/hjt/20240415175752646c80a6-1fec-43cf-b1d2-787419270c62.png" alt="">
                <div class="til">BIM解决方案</div>
              </div>
              <div class="dd">
                <p>提供全面的BIM解决方案优化建筑生命周期管理</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);

const modules = [Pagination];
const pagination = {
  clickable: true,
};

</script>

<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
.wrap {
  padding-bottom: 0;
}
main {
  padding: .4rem .4rem .6rem;
}

:deep(.banner) {
  position: relative;
  overflow: hidden;
  .swiper-slide{
    height: 4rem;
    img{
      height: 100%;
      width: 100%;
      object-fit:cover;
    }
  }
  .swiper-pagination {
    height: .1rem;
    line-height: .1rem;
    bottom: 0.3rem;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    border-radius: .1rem;
    font-size: 0;
    .swiper-pagination-bullet {
      vertical-align: top;
      margin: 0 .04rem;
      width: .1rem;
      height: 100%;
      border-radius: 50%;
      opacity: 1;
      transition: all 0.3s;
      background: #fff;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: #f70000;
    }
  }
  .txt{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding: .3rem;
    transform: translateY(-50%);
    h3{
      font-size: .3rem;
      line-height: .4rem;
      font-weight: 600;
      letter-spacing: .04rem;
      margin-bottom: .1rem;
      color: #fff;
    }
    h4{
      font-size: .22rem;
      line-height: .24rem;
      color: rgba(255,255,255,.4);
      font-family: "Gilroy-Bold";
      text-transform: uppercase;
    }
    .p{
      margin-top: .2rem;
      font-size: .26rem;
      color: #fff;
      line-height: .34rem;
    }
  }
}
.van-tabs{
  margin-bottom: .6rem;
}

.bd{
  .infoUl{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      width: 3.25rem;
      margin-bottom: .2rem;
      border-radius: .2rem;
      overflow: hidden;
      background: #f3f4f7;
      .dt{
        position: relative;
        &:after{
          position: absolute;
          content: "";
          display: block;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.2);
        }

        .til{
          position: absolute;
          left: .3rem;
          right: .3rem;
          top: .6rem;
          color: #fff;
          font-size: .3rem;
          line-height: .4rem;
          padding-bottom: .1rem;
          z-index: 5;
          &:after{
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: .6rem;
            height: .04rem;
            background: #f70000;
          }
        }
      }
      .dd{
        padding: .2rem .3rem;
        min-height: 1.1rem;
        display: flex;
        align-items: center;
        p{
          font-size: .22rem;
          color: rgba(0,0,0,.5);
          line-height: .36rem;
        }
      }
    }
  }
}
</style>

