<template>
  <div class="wrap">
    <nav-ber :title="'智慧排水监管系统'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="drainage">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item " v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit ">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
            <div class="below ">
                    <ul>
                        <template v-for="(item, index) in apiData2">
                            <li >
                                <p v-html="item.newsTitle"></p>
                                <div class="em">{{ item.newsAuthor }}</div>
                            </li>
                            <div class="line" v-if="index !== apiData2.length - 1">
                                <img src="~@/assets/images/labor/line11.png" alt="" />
                            </div>
                        </template>
                    </ul>
                </div>
            </div>
        </section>
       
      <section class="constru-npm2" >
            <div class="w1440 ">
                <div class="pagetit ">
                    <h5>案例成效</h5> 
                </div>
                <div class="clear"> 
                       <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000" effect="fade">
        <van-swipe-item v-for="item in apiData3" :key="item.newsId">
            <div class="img">
                    <img :src="  item.image" alt="">
                  </div>
                  <div class="txte">
                            <h5 v-html="item.newsTitle"></h5>
                            <div v-html="item.newsContent"></div>
                        </div>
        </van-swipe-item>
      </van-swipe>
                   
                </div>
                
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
}); 
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 539,
    typeId: 101,
  });
  const { data: data2 } = await getInfo({
    coId: 593,
  });
  const { data: data3 } = await getInfo({
    coId: 595,
  });
    const { data: data4 } = await getInfo({
    coId: 597,
  });
   

  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data;
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent; 
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.drainage {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0;
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
             .below{
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                        align-items: center;
                    .line{ 
                        width: .5rem;
                        margin: 0 .1rem 0 .2rem;
                        &:nth-of-type(3){
                            opacity: 0;
                        }
                    }
                    li{
                        width: 1.6rem;
                        height: 1.6rem; 
                        margin-bottom: .2rem;
                        background: #f3f4f7;
                        border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
                        p{
                            text-align: center;
                        }
                        .em{
                        font-size: .24rem;
                        width: .3rem;
                        height: .3rem;
                        right: -.15rem;
                         display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            color: #fff;
            border-radius: 50%;
                background: #d7000f;
                 top: 0;
            bottom: 0;
            margin: auto;
                    }
                    }
                    
                }
            }
        }
        .constru-npm3{
            :deep(.above){
                h5{
                    font-size: .4rem;
                     color: #333333;
                text-align: center;
                font-weight: bold;
                }
                .ul{
                    width: 100%;
                        margin-top: .1rem;
                        padding-top: .5rem;
                        margin: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                    &::after{
                      content: '';
                        width: 90%;
                        height: .5rem;
                        background-repeat: no-repeat;
                        top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    position: absolute;
                    background: url(~@/assets/images/labor/line8.png);
                    background-size: 100%;
                    }
                    .li{
                        width: 1.8rem;
                        height: 1.8rem;
                         display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #d7000f;
                    text-align: center;
                    position: relative;
                    border-radius: 50%;
                        &:nth-child(1),&:nth-child(5){
                            &::after{
                              content: '';
                               background: url(~@/assets/images/labor/line9.png);
                                width: .23rem;
                                height: .37rem;
                                background-size: 100%;
                                bottom: -.55rem;
                                position: absolute;
                            left: 0;
                            right: 0;
                            margin: auto;
                            }
                        }
                        &:nth-child(5){
                           &::after{
                             transform: rotate(-180deg);
                           }
                        }
                        img{
                            width: .4rem;
                            margin: auto;
                        }
                        p{
                            font-size: .24rem;
                            margin-top: .1rem;
                            text-align: center;
                        color: #fff;
                        }
                    }
                }
            }
            :deep(.below) {
                margin-top: .7rem;
                .fr{
                   display: flex;
                justify-content: space-between;
                position: relative;
                        .item{
                          width: 48.25%;
                            h5{
                                min-height: .25rem;
                            }
                            ul{
                               p{
                        text-align: center;
                    }
                            }
                        }
                    }
                .dt{
                    width: 100%;
                    padding: .5rem .3rem;
                    margin-bottom: .2rem;
                    background: linear-gradient(to bottom, #f3f4f7,#fbfbfc, #ffffff); 
                    h4{
                        font-size: .28rem;
                         text-align: center;
                    color: #333333;
                    }
                    
                    .itembox{
                            margin-top: .2rem; 
                            background: #fff;
    padding: .3rem 0 0;
    h5{
                        font-size: .26rem;
                         margin-bottom: .08rem;
                         text-align: center;
                        color: #4c4c4c;
                    }
                    .ul{
                        margin-top: .3rem;
                        display: flex;
                        justify-content: center;
                        .li{
                            padding: 0 .4rem;
                            border-right: 1px solid #edeef0;
                            &:last-child{
                                border: none;
                            }
                            h6{
                                font-size: .26rem;
                                margin-bottom: .08rem;
                                color: #333333;
                            }
                        }
                    }
                    }
                }
            }
        }
   .constru-npm2{
            padding: .9rem 0 .7rem;
            background: url(~@/assets/images/labor/bg3.jpg);
            background-position: center;
            background-size: cover;
            overflow: hidden;
            .soluslid{
              border-radius: 0;
              position: relative;
            }
              :deep(.van-swipe__indicators){
                    bottom: auto;
                    top: 3.5rem;
                    i{
                        width: .52rem;
                        height: .04rem;
                        border-radius: 0;
                        background: #a6a6a6;
          opacity: 1;
                         &.van-swipe__indicator--active{
            background: #d7000f;
          }
                    }
                }
            .clear{
                margin-top: .45rem;
            }
            .img{
                   width: 100%;
                }
          .txte{
                    width: 100%;
                    margin-top: .6rem;
                    h5{
                        font-size: .26rem;
                        margin-bottom: .15rem;
                    }
                }
        }
}
</style>
  
  