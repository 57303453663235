<template>
  <div class="wrap">
    <nav-ber :title="'企业服务'"></nav-ber>
    <main>
      <div class="topBox">
        <h2>企业找帮手 华建通企业服务</h2>
        <h4>一站式企业服务品牌<br>
        提供有价值高标准服务</h4>
      </div>
      <div class="content">
        <section class="numberData">
          <ul>
            <li>
              <div class="icon"><img src="~@/assets/images/enterprise/enterpriseIcon1.jpg" alt=""></div>
              <div class="txt">
                <div class="i"><em>30</em> 年</div>
                <div class="p">服务团队</div>
              </div>
            </li>
            <li>
              <div class="icon"><img src="~@/assets/images/enterprise/enterpriseIcon2.jpg" alt=""></div>
              <div class="txt">
                <div class="i"><em>600+</em></div>
                <div class="p">服务城市</div>
              </div>
            </li>
            <li>
              <div class="icon"><img src="~@/assets/images/enterprise/enterpriseIcon3.jpg" alt=""></div>
              <div class="txt">
                <div class="i"><em>300</em> 万</div>
                <div class="p">服务人数</div>
              </div>
            </li>
            <li>
              <div class="icon"><img src="~@/assets/images/enterprise/enterpriseIcon4.jpg" alt=""></div>
              <div class="txt">
                <div class="i"><em>3000+</em></div>
                <div class="p">服务企业</div>
              </div>
            </li>
          </ul>
        </section>
        <section class="itemSwiper">
          <div class="swiper-container">  
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <router-link to="/enterprise/hunt">
                  <div class="pic"><img src="~@/assets/images/enterprise/enterprisePic1.jpg" alt=""></div>
                  <div class="txt">
                    <div class="bulge">
                      <h4 class="til">筑猎服务</h4>
                      <div class="line"></div>
                    </div>
                    <p>依托1800+猎头顾问和200万精英，借助“大数据+人工智能”核心技术通过线上与线下的深度融合</p>
                  </div>
                </router-link>
              </div>
              <div class="swiper-slide">
                <div @click="skipOutsideChain('http://www.gggcn.com/huajiantongzt99.html')">
                  <div class="pic"><img src="~@/assets/images/enterprise/enterprisePic12.jpg" alt=""></div>
                  <div class="txt">
                    <div class="bulge">
                      <h4 class="til">工商服务</h4>
                      <div class="line"></div>
                    </div>
                    <p>主要提供公司注册、许可证办理、公司变更、公司注销等服务专业顾问全程代办</p>
                  </div>
                </div>
              </div>
            </div>  
            <!-- Swiper Pagination -->  
            <div class="user-pagination">
              <div :class="['dot', {'on': swiperAct === 0}]"></div>
              <div :class="['dot', {'on': swiperAct === 1}]"></div>
              <div :class="['dot', {'on': swiperAct === 2}]"></div>
            </div>  
          </div>
        </section>
        <section class="else">
          <h3 class="cTil"><em>其他业务</em></h3>
          <div class="ul">
            <div class="li">
              <div class="pic"><img src="~@/assets/images/enterprise/enterprisePic2.jpg" alt=""></div>
              <div class="txt">
                <h3>财税服务</h3>
                <p>平台制定统一标准接口，各类出函机构《银行、保险公司、担保公司》均可在线按照要求想交材料申请接入平台</p>
                <div class="btn" @click="skipOutsideChain('http://www.gggcn.com/huajiantongzt100.html')">查看详情</div>
              </div>
            </div>
            <div class="li">
              <div class="pic"><img src="~@/assets/images/enterprise/enterprisePic3.jpg" alt=""></div>
              <div class="txt">
                <h3>建筑资质</h3>
                <p>释放企业资金压力，保障工程建设的顺利完成,包括投标保证担保、履约担保、预付款担保、工程款支付担保、农民工工资支付担保</p>
                <div class="btn" @click="skipOutsideChain('http://www.gggcn.com/huajiantongzt101.html')">查看详情</div>
              </div>
            </div>
            <div class="li">
              <div class="pic"><img src="~@/assets/images/enterprise/enterprisePic4.jpg" alt=""></div>
              <div class="txt">
                <h3>知识产权</h3>
                <p>荣膺中华商标协会颁发的“优秀商标代理机构”荣誉称号拥有国家知识产权局核发的专利代理资质</p>
                <div class="btn" @click="skipOutsideChain('http://www.gggcn.com/huajiantongzt102.html')">查看详情</div>
              </div>
            </div>
            <div class="li">
              <div class="pic"><img src="~@/assets/images/enterprise/enterprisePic5.jpg" alt=""></div>
              <div class="txt">
                <h3>教育培训</h3>
                <p>7年教育培训服务经验，与国内多所985/211、本科、专科和中专院校建立战略合作关系</p>
                <div class="btn" @click="skipOutsideChain('http://www.gggcn.com/huajiantongzt140.html')">查看详情</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import Swiper, { Pagination } from 'swiper';  
import 'swiper/swiper-bundle.css';
import { skipOutsideChain } from '@/api/public';
import NavBer from '@/components/NavBer.vue';
import { ref, onMounted } from 'vue';
const activeNames = ref(['0']);

const swiperAct = ref(0);
onMounted(function(){
  const swiper = new Swiper('.swiper-container',{
    spaceBetween : 10,
    pagination: {  
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      slideChangeTransitionStart: function(){
        console.log(this.activeIndex)
        swiperAct.value = this.activeIndex;
      }
    }
  });
})
</script>

<style scoped lang="scss">
main{
  background: url(~@/assets/images/enterprise/enterpriseTopBg.jpg) no-repeat top;
  background-size: 100%;
  overflow: hidden;
}
.topBox{
  height: 3.55rem;
  padding: 1.04rem .4rem 0;
  h2{
    font-size: .38rem;
    line-height: .58rem;
    margin-bottom: .1rem;
    font-weight: 600;
    background: linear-gradient(to bottom, #ffbb00, #ffa200);  
    -webkit-background-clip: text;  
    -webkit-text-fill-color: transparent; 
  }
  h4{
    font-size: .24rem;
    color: #ede6d1;
    line-height: .36rem;
    font-weight: 500;
  }
}
.content{
  padding: .6rem .4rem;
  border-radius: .28rem .28rem 0 0;
  background: #fff url(~@/assets/images/enterprise/enterpriseBg.jpg) repeat-x;
  background-size: .6rem;
  .cTil{
    height: .66rem;
    font-size: .28rem;
    color: #2d322d;
    line-height: .48rem;
    border-bottom: .06rem solid #e1e1e1;
    em{
      display: block;
      position: relative;
      height: .66rem;
      &:after{
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        display: block;
        height: .06rem;
        width: 1.4rem;
        background: #f70000;
      }
    }
  }
}
.explain{
  padding-bottom: .6rem;
  .cTil{
    height: .8rem;
    line-height: .56rem;
    font-size: .36rem;
    text-align: center;
    margin-bottom: .5rem;
    em{
      height: .8rem;
      &:after{
        right: 0;
        margin: 0 auto;
      }
    }
  }
}
.advantage{
  padding-bottom: .4rem;
  .cTil{
    margin-bottom: .54rem;
  }
  ul{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: url(~@/assets/images/financial/financialSafetyAdvantageListBg.png) no-repeat center;
    background-size: 2.86rem;
  }
  li{
    position: relative;
    width: 3.22rem;
    height: 2.5rem;
    padding: .45rem .28rem 0;
    background: #fff;
    border-radius: .28rem;
    box-shadow: 0 .02rem .5rem rgba(230,230,230,.6);
    margin-bottom: .3rem;
    .num{
      position: absolute;
      right: .26rem;
      top: .2rem;
      font-size: .24rem;
      color: #ec3d22;
      line-height: 1;
      font-family: 'Gilroy-Bold';
    }
    h4{
      font-size: .26rem;
      color: #2d322d;
      line-height: .36rem;
      margin-bottom: .16rem;
    }
  }
}
.case{
  padding-bottom: .6rem;
  .cTil{
    margin-bottom: .4rem;
  }
  .box{
    margin: 0 -.4rem;
    background: #f3f4f7;
  }
  .txt{
    padding: .7rem .4rem .6rem;
  }
}

.numberData{
  padding-bottom: .3rem;
  ul{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      display: flex;
      width: 3.2rem;
      padding: 0 .3rem;
      align-items: center;
      height: 1.26rem;
      background: #fff;
      margin-bottom: .3rem;
      border-radius: var(--border-radius);
      .icon{
        width: .8rem;
        height: .8rem;
        overflow: hidden;
        border: .01rem solid #e2e2e2;
        border-radius: 50%;
        margin-right: .3rem;
      }
      .txt{
        flex: 1;
        .i{
          font-size: .22rem;
          color: #e60010;
          line-height: .3rem;
          em{
            font-size: .48rem;
            font-family: 'Gilroy-Bold';
          }
        }
        .p{
          font-size: .22rem;
          color: #2d322d;
          line-height: .3rem;
        }
      }
    }
  }
}
.itemSwiper{
  padding: 0 .4rem;
  margin: 0 -.4rem .6rem;
  .swiper-container{
    overflow: visible;
  }
  .swiper-slide{
    overflow: hidden;
    border-radius: .28rem;
    opacity: .5;
    transition: opacity .4s;
    &.swiper-slide-active{
      opacity: 1;
    }
    .pic{
      height: 3.75rem;
    }
    .txt{
      position: relative;
      z-index: 5;
      min-height: 2.6rem;
      padding: .64rem .4rem .5rem;
      margin-top: -.2rem;
      border-radius: 0 .16rem .28rem .28rem;
      background: linear-gradient(to bottom, #fefefe, #e2e2e2);
      .bulge{
          position: absolute;
          left: 0;
          top: -.64rem;
        .til{
          position: relative;
          padding: .2rem 0 0 .4rem;
          height: .66rem;
          border-radius: .28rem 0 0 0;
          line-height: .4rem;
          font-size: .3rem;
          color: #000;
          z-index: 5;
          font-weight: 500;
          background: #fefefe;
          margin-bottom: .26rem;
          &:after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            z-index: -1;
            right: -1rem;
            width: 1.2rem;
            height: 100%;
            background: url(~@/assets/images/financial/financialEnsureRiskControlItemAngle.png) no-repeat center;
            background-size: 100% 100%;
          }
        }
        .line{
          position: absolute;
          left: .4rem;
          right: 0;
          top: .9rem;
          height: .06rem;
          background: #c8c8c8;
          &:after{
            content: "";
            display: block;
            height: 100%;
            width: .14rem;
            background: #f70000;
          }
        }
      }
      h5{
        font-size: .28rem;
        color: #000;
        line-height: .4rem;
        font-weight: 500;
        margin-bottom: .1rem;
      }
    }
  }
}
.else{
  .cTil{
    margin-bottom: .6rem;
  }
  .li{
    overflow: hidden;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    margin-bottom: .3rem;
    .txt{
      padding: .4rem;
      background: #e2e2e2;
      &:after{
        content: "";
        display: block;
        height: 0;
        clear: both;
      }
      h3{
        font-size: .3rem;
        line-height: .4rem;
        color: #000;
        margin-bottom: .14rem;
      }
      .btn{
        float: right;
        margin-top: .4rem;
        width: 1.5rem;
        height: .54rem;
        line-height: .52rem;
        text-align: center;
        border-radius: .54rem;
        color: #5a5a5a;
        border: .01rem solid #c5c5c5;
      }
    }
  }
  
}
</style>

