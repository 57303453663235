<template>
  <div class="wrap">
    <nav-ber title="企业服务"></nav-ber>
    <main class="laborhome">
      <Cbanner :list="apiData0"></Cbanner>
      <CinfoUl :list="apiData1"></CinfoUl>
      <section class="serv-search">
        <div class="box">
          <input
            class="inp"
            type="text"
            v-model="searchVal"
            @focus="seekFocusState = true"
            @blur="seekFocusState = false"
            placeholder="请输入服务名称"
          />
          <div class="search" @click="submitclick">查询</div>
        </div>
      </section>
      <section class="about-npm1">
        <div class="ul">
          <div
            class="li"
            @click="toFn(item.email)"
            v-for="item in apiData2"
            :key="item.newsId"
          >
            <div class="ico">
              <img :src="      item.image" alt="" />
              <h5>{{ item.newsTitle }}</h5>
            </div>
            <div class="txt" v-html="item.newsContent"></div>
          </div>
        </div>
        <div class="more">虚位待发</div>
      </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import Cbanner from "@/components/Cbanner.vue";
import CinfoUl from "@/components/CinfoUl.vue";
import { skipOutsideChain } from "@/api/public";
import { getInfo } from "@/api/http";
import { useRouter } from 'vue-router';
import { ref } from "vue";
const router = useRouter();
const apiData0 = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const searchVal = ref("");
const getList2 = async () => {
  let { data } = await getInfo({
    coId: "329",
    key: searchVal.value,
  });
  console.log("测试2", data);
  apiData2.value = data.data;
};

const getList = async () => {
  const promises = [
    getInfo({ coId: 359, typeId: 5 }).then((res) => res.data),
    getInfo({ coId: 333 }).then((res) => res.data),
  ];
  const results = await Promise.all(promises);
  apiData0.value = results[0].data;
  results[1].data.forEach((obj) => {
    let item = {
      newsId: obj.newsId,
      newsAccess: obj.newsAccess,
      newsTitle: obj.name,
      name: obj.address,
      email: obj.email,
      newsContent: obj.newsTitle,
    };
    apiData1.value.push(item);
  });
};
getList();
getList2();
const submitclick = () => {
  getList2();
}

// 跳转方法
const toFn = (url) => {
  let one = url.trim().charAt(0);
  if (url.indexOf('appId') > -1) {
    toWeChat(url)
  }else if(one == "/"){
    router.push(url);
  }else {
    skipOutsideChain(url);
  }
};

function toWeChat(url){
  const appIdMatch = url.match(/appId=(.*?)(?=&|$)/);
  const pubAppIdMatch = url.match(/pubAppId=(.*?)(?=&|$)/);
  const cleanedUrl = url.replace(/&?(appId|pubAppId)=[^&]*/g, '');
  let link = encodeURIComponent(cleanedUrl)
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${link}&${appIdMatch[0]}&${pubAppIdMatch[0]}`
}
</script>
  
<style scoped lang="scss">
.serv-search {
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
  .box {
    display: flex;
  }
  input {
    line-height: 0.9rem;
    padding: 0 0.2rem;
    width: calc(100% - 1.8rem);
    font-size: 0.24rem;
    border: 1px solid #e3e3e3;
    background: #f7f7f7;
    &::placeholder {
      font-size: 0.24rem;
      color: #b2b2b2;
    }
  }
  .search {
    background: #d7000f;
    color: #fff;
    width: 1.8rem;
    line-height: 0.9rem;
    font-size: 0.24rem;
    cursor: pointer;
    text-align: center;
  }
}
.about-npm1 {
  padding: 0 0 0.6rem;
  overflow: hidden;
  .p {
    margin-top: 0.5rem;
    text-align: center;
    p {
      text-align: center;
    }
  }
  .more {
    border-radius: 1rem;
    height: 0.8rem;
    margin: 0.5rem auto 0;
    border: 0.02rem solid #e6e6e6;
    font-size: 0.24rem;
    line-height: 0.8rem;
    width: 1.8rem;
    text-align: center;
    color: #808080;
  }
  .ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .li {
      width: 3.25rem;
      margin: 0 0 2% 0;
      min-height: auto;
      overflow: hidden;
      border-radius: 0.2rem;
      background: #f3f4f7;
      &:nth-child(2n) {
        margin: 0 0 2% 0;
      }
      &:nth-child(3n) {
        margin: 0 0 2% 0;
      }
      .ico {
        height: 2.5rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.4s;
        }
        &::after {
          content: "";
          width: 100%;
          height: 50%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(
            180deg,
            #005ccc,
            rgba(0, 92, 204, 0.5),
            rgba(0, 92, 204, 0)
          );
        }
        h5 {
          left: 0.3rem;
          top: 0.25rem;
          font-size: 0.24rem;
          line-height: 1;
          color: #fff;
          position: absolute;
          z-index: 3;
        }
      }
      .txt {
        height: auto;
        padding: 0.25rem 0.3rem;
        position: relative;
        z-index: 3;
        background: #f5f5f5;
        p {
          font-size: 0.24rem;
          height: auto;
        }
      }
    }
  }
}
</style>