<template>
  <div class="wrap">
    <nav-ber :title="'BIM解决方案'"></nav-ber>
    <main>
      <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div>
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>BIM服务</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/bimPic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>BIM建模</h4>
                <div class="line"></div>
                <p>
                  提供BIM建模服务，包括BIM存储、BIM建模、BIM编码、BIM交付标准等内容，建立施工三维BIM模型，自动出具管线综合平面图、各专业平面图、剖面图、精准的预留洞图及关键节点大样等施工图，精准指导施工，提高施工的效率。
                </p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/bimPic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>BIM碰撞检查</h4>
                <div class="line"></div>
                <p>
                  利用BIM协同平台集成各专业的BIM模型于—体，进行碰撞检查，提前发现不同专业之间存在的不协调的问题，通过三维可视化解决各专业设计的问题，提高现场施工的效率。
                </p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/bimPic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>BIM深化设计</h4>
                <div class="line"></div>
                <p>
                  结合各专业图纸及施工现场实际情况，对图纸进行细化、补充和完善，符合相关的设计规范和施工规范，评估并选择最佳的施工方案，指导现场施工；
                </p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/bimPic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>BIM施工模拟</h4>
                <div class="line"></div>
                <p>
                  利用BIM施工模拟技术，使得复杂的施工过程，变得简单、可视、易懂。BIM4D虚拟建造形象直观、动态模拟施工阶段过程和重要环节施工工艺，将多种施工及工艺方案的可实施性进行比较，为最终方案优选决策提供支持。
                </p>
              </div>
            </router-link>
          </div>
        </section>
        <section class="delivery">
          <h2 class="cTil"><em>BIM数字化交付</em></h2>
          <div class="ul">
            <div class="li">
              <van-swipe
                class="pic"
                :autoplay="3000"
                indicator-color="#f70000"
              >
                <van-swipe-item>
                  <img src="~@/assets/images/engineering/bimPic5.jpg" alt="" />
                </van-swipe-item>
                <van-swipe-item>
                  <img src="~@/assets/images/engineering/bimPic5.jpg" alt="" />
                </van-swipe-item>
              </van-swipe>
              <div class="txt">
                <h4>数字孪生平台</h4>
                <div class="line"></div>
                <p>数字孪生平台将真实世界的物理实体和数字系统相结合，利用传感器、数据采集、模型分析等技术手段，实时监测和分析物理实体的运行状态和数据，生成数字孪生实体，并通过数字孪生系统模拟和预测物理实体的运行和风险情况；将多源异构数据进行结构化存储，进行建筑数据的组织及关联管理，结合物联网（IoT）技术实现对静态、动态数据的全掌控，可作为建筑数字化交付的承载平台，帮助业主便捷化接收和精细化管理服务方移交的数据资产。</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: 0;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}
.delivery{
  border-radius: .28rem .28rem 0 0;
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimDeliveryBg.jpg) no-repeat top;
  background-size: 100%;
  .txt{
    background: linear-gradient(to bottom, #edf4fd, #d5e5fb);
  }
}


</style>

