<template>
  <div class="wrap">
    <nav-ber :title="'融资信贷服务'"></nav-ber>
    <main>
      <div class="topBox">
        <div class="ipt">
          <input ref="ipt" type="text" placeholder="输入产品名称或银行名称进行查询" v-model="value" @input="seekFn"/>
          <div class="icon" to="/construction"></div>
          <div class="clear" @click="clearIpt" v-show="value.length > 0"></div>
        </div>
        <!-- <div class="btn" @click="seekFn">搜索</div> -->
      </div>
      <div class="bd">
        <div v-for="item in list" :key="item.newsId" @click="$router.push({'path': '/financial/creditData', query: {'newsId': item.newsId}})" class="item">
          <div class="logo">
            <img :src="$store.state.localHttp + item.image" alt="" />
          </div>
          <div class="name">{{item.newsTitle}}<em>{{item.phone}}</em></div>
          <div class="ul" v-html="item.newsTags"></div>
          <div class="labels">
            <div class="it" v-for="lab in item.newsAuthor.split('|')" :key="lab">{{lab}}</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getInfo } from '@/api/http';
import { ref, onMounted } from 'vue';
const value = ref("");
const bxbhList4 = ref([]);
const list = ref([]);
const seek = ref(false);
const getList = async () => {
  const promises = [
    getInfo({ coId: 1073, pageSize: 100 }).then(res => res.data)
  ];
  const results = await Promise.all(promises);
  bxbhList4.value = results[0].data;
  list.value = results[0].data;
};
getList();

// 清空搜索框
function clearIpt(){
    value.value = ""
    list.value = bxbhList4.value;
    seek.value = false;
}

// 搜索
async function seekFn(){
  let val = value.value.trim();
  if(val === ''){
    return list.value = bxbhList4.value;
  }
  list.value = [];
  seek.value = true;
  let arr = bxbhList4.value.filter(item => {
    if(item.name.indexOf(val) > -1 || item.newsTitle.indexOf(val) > -1){
      return item
    }
  })
  list.value = arr;
}

</script>

<style scoped lang="scss">
.topBox {
  display: flex;
  align-items: center;
  margin-bottom: .4rem;
  .retBtn {
    margin-left: -0.34rem;
    width: 0.9rem;
    height: 0.9rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAeCAYAAAAl+Z4RAAAAmklEQVQ4jaXVOxKAIAxF0eB+oND9L8GGDeFowcgn5OVBf48gMIRSijAjXekLj52YAv6xG+jjfOcAA7MYnoEWQ8AqNgErXgJIrAJoPAU88QB44wZg4gqw8TtCPCMdC3sbG6D/Yr8caAY7SF0CizT/gEGGn+hFprvgQdRtRJHlOUAQ8yBZCHQSVwh8lDXE/bRtPSyzmVC3sSIi8gA78G1NX/ltRAAAAABJRU5ErkJggg==)
      no-repeat center;
    background-size: 0.16rem;
  }
  .ipt {
    flex: 1;
    position: relative;
    input {
      border-radius: 0.8rem;
      border: 0 none;
      width: 100%;
      height: 0.8rem;
      padding: 0.1rem 0.8rem 0.1rem 0.92rem;
      line-height: 0.6rem;
      font-size: 0.24rem;
      color: #666;
      background: #fff;
    }
    .clear{
        position: absolute;
        right: .1rem;
        top: .1rem;
        width: 0.6rem;
        height: 0.6rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAACHklEQVQ4jZ3Uy4vNcRjH8dfMKJdyL8NmNggxLkORu43LipVSFnJbCGWFYuQWImHhMvJHWLgnGwsiuYVxT64bE+MaY/TkOXU6nTOGp06d+v6+7+/n8/k+z7eqvqG+3Z86jF146d+qB+ZgC8bU1A6qrcIQzEA3XMOXTiKrMR2NGI+LAXyIDxiNqeiFO/jYCdg8bMVEnMamALbiEb4ldGYqjYNaKsBifT42YBLOYSeuBjA++Iq7+IRxab8Gt9FaAovM5mJ9KrsQynAlFgvAqB9oTqsNRZleL8o08p6FzQk7nypvor0UGPUdzxMwFlPQN08PF7OxLWFnUmU4aysASoES1lyU6bRUWoc1mIyz2JHq24s3d6kQ+ns0peJ1WFsQkLcZlm+U21hOYXGmD9LqCPTDZazG/VJlhaquRMsNHxM0MC8k/nctzuxfFPbHUixP+JvMMeBv8aLcpkoZ9sFibM9vmrL5l2AhuuNzTtTPzihckSPVG4fydzmza8gRrct2aukIWJ2hb0zL+3ECj1NJ2H6CkfkoDM0We10OGIEvy5boiSPYh2dFB7YlPDIclj06AK8KmRYyDGuLcgriFo9hN95VyPhk9ugBLEhOzPy9gsKV2fkBPoij+dCW7bWsWL+FCTmig+MtDeAerMrM4sTj+XR1BIuKTAMaGUbjR6bDAxjPT8xq2Nxbktnf6heeZqaj4m0M73HtlxIY4f5PnUItGn8DqkWLjjH+3jQAAAAASUVORK5CYII=) no-repeat center;
        background-size: .2rem;
    }
    .icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 0.8rem;
      height: 0.8rem;
      background: url(~@/assets/images/iconSeek.png) no-repeat center;
      background-size: 0.36rem;
    }
  }
  .btn {
    font-size: 0.28rem;
    color: #2d322d;
    line-height: 0.8rem;
    white-space: nowrap;
    padding: 0 0.2rem;
    margin: 0 -0.2rem 0 0.2rem;
  }
}

main {
  min-height: 70vh;
  padding: 0.4rem 0.4rem .6rem;
  background: url(~@/assets/images/cBg.jpg) no-repeat top;
  background-size: 100%;
  .bd {
    .item {
      position: relative;
      height: 3.12rem;
      padding: 0.26rem 0.3rem;
      border: 0.01rem solid #ddd;
      border-radius: 0.28rem;
      margin-bottom: 0.3rem;
      background: url(~@/assets/images/financial/banBg.jpg) no-repeat;
      background-size: 100% 100%;
      &.last{
        height: auto;
        .name{
          height: .64rem;
        }
        p{
          position: relative;
          padding-left: .2rem;
          &:after{
            position: absolute;
            left: 0;
            top: 0;
            content: "-";
            display: block;
            font-size: .24rem;
            color: #646464;
            line-height: .36rem;

          }
        }
      }
    }
    .logo {
      height: 1rem;
      img {
        max-height: 100%;
      }
    }
    .name {
      height: 0.94rem;
      font-size: 0.3rem;
      line-height: 0.5rem;
      color: #181918;
      em {
        color: #959695;
        margin-left: 0.15rem;
      }
    }
    :deep(.ul) {
      display: flex;
      .li {
        font-size: 0.2rem;
        line-height: 0.32rem;
        color: #646464;
        &:nth-child(1) {
          width: 2.54rem;
        }
        &:nth-child(3) {
          margin-left: auto;
        }
        em {
          font-family: "Gilroy-Bold";
          font-size: 0.44rem;
          padding-right: 0.05rem;
          color: #f70000;
        }
      }
    }
    .labels {
      position: absolute;
      right: 0.3rem;
      top: 0.4rem;
      .it {
        width: 1.2rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        font-size: 0.2rem;
        color: #fff;
        background: #f78600;
        border-radius: 0.4rem;
        margin-bottom: 0.1rem;
      }
    }
  }
}

</style>

