<template>
  <div class="appMain" :style="{ paddingTop: $store.state.safetyTop + 'px'}">
    <router-view v-slot="{ Component }">
      <keep-alive v-if="!$route.meta.keepAlive">  
        <component :is="Component"></component>  
      </keep-alive>  
      <component v-else :is="Component" :key="$route.fullPath"></component>
    </router-view>
    <div :class="['cPageBtns', {'show': isScrolled, 'up': close}]">
      <div class="wr">
        <!-- <div class="ai" @click="close = false"><div class="i"></div></div> -->
        <div class="hid" style="height: 2rem;">
          <!-- <div class="a robot" @click="skipOutsideChain('http://114.242.210.44:5200/#/aichat/app')">
            <div class="icon"></div>
            <div class="p">筑小<br/>智</div>
          </div> -->
          <div class="a wx" @click="skipOutsideChain('https://aicc.caih.com/chat_online/index?channelId=3398bc6cd46245f6963410a9c6a09155')">
            <div class="icon"></div>
            <div class="p">在线<br/>咨询</div>
          </div>
          <!-- <div class="close" @click="close = true"></div> -->
        </div>
        <div class="a top" @click="goTop"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getInfo } from '@/api/http';
import { skipOutsideChain } from '@/api/public';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const isScrolled = ref(false);
const close = ref(false);

// 移动设备的安全区
function callBackSafeArea(top,right,bottom,left,density){
  let num = top || 0;
  let ratio = density;
  let resNum = Math.ceil(num / ratio);
  store.commit('setSafetyArea', resNum);
}
const getMobileInfo = () => {
  let top = store.state.safetyTop;
  if(top === null){
    window.location.href = "bridge:/base/getSafeArea/pageInit"
  }
}
// 回到顶部
const goTop = () => {
  window.scroll({
    top: 0, 
    behavior: 'smooth'
  })
}

// ios审核隐藏金融支付
async function getIosState(){
  const { data: data } = await getInfo({
    coId: 649
  });
  if(data.data[0].isTop){
    const userAgent = navigator.userAgent;
    let isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    store.commit('setIosAuditState', isIOS)
  }
}
getIosState();

onMounted(function(){
  window.addEventListener('scroll',function(){
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    isScrolled.value = scrollTop > 300;
  })

  // 获取手机设备安全区域
  window.callBackSafeArea = callBackSafeArea;
  getMobileInfo();
})
</script>
<style lang="scss">
.cPageBtns{
  position: fixed;
  right: .2rem;
  top: 50%;
  margin-top: -2rem;
  width: .7rem;
  z-index: 50;
  transition: all .4s .6s;
  .top{
    transition: all .4s;
    opacity: 0;
    pointer-events: none;
  }
  &.show{
    .top{
      opacity: 1;
      pointer-events: all;
    }
  }
  .a{
    position: relative;
    padding-top: .7rem;
    border-radius: 1.5rem;
    margin-bottom: .19rem;
    height: 1.46rem;
    background: url(~@/assets/images/iconPopBtnBg.png) no-repeat center;
    background-size: 100% 100%;
    box-shadow: 0 .1rem .16rem rgba(0,0,0,.03);
    &:last-child{
      margin-bottom: 0;
    }
    .icon{
      position: absolute;
      top: .04rem;
      left: 50%;
      transform: translateX(-50%);
      background: no-repeat top center;
      background-size: contain;
    }
    .p{
      position: relative;
      z-index: 5;
      font-size: .22rem;
      color: #fff;
      text-align: center;
      line-height: .3rem;
    }
  }
  .robot{
    .icon{
      width: .8rem;
      height: .8rem;
      background-image: url(~@/assets/images/iconRobot.png);
    }
  }
  .wx{
    .icon{
      top: .19rem;
      width: .42rem;
      height: .42rem;
      background-image: url(~@/assets/images/iconWx.png);
    }
  }
  .top{
    height: .7rem;
    background-image: url(~@/assets/images/iconGoTop.png);
  }
  .ai{
    position: absolute;
    top: .04rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
    margin-right: 0;
    opacity: 1;
    transition: all .4s;
    pointer-events: none;
    .i{
      width: .8rem;
      height: .8rem;
      opacity: 0;
      transition: all .4s .6s;
      background-image: url(~@/assets/images/iconRobot.png);
      background-size: 100% 100%;
    }
  }
  .hid{
    overflow: hidden;
    width: .8rem;
    height: 4.2rem;
    padding: 0 .05rem;
    margin-left: -.05rem;
    transition: all .4s .6s;
    &>*{
      transition: all .4s .6s;
    }
  }
}
.close{
  width: .4rem;
  height: .4rem;
  margin: .2rem auto 0;
  border-radius: 50%;
  background: url(~@/assets/images/iconPopClose.png) no-repeat center;
  background-size: contain;
}
.cPageBtns.up{
  margin-top: -1rem;
  transition-delay: 0s;
  .ai{
    opacity: .5;
    margin-left: .4rem;
    transition-delay: .6s;
    pointer-events: all;
    .i{
      opacity: 1;
      transition-delay: 0s;
    }
  }
  .hid{
    pointer-events: none;
    height: 1.1rem;
    transition-delay: 0s;
    &>*{
      opacity: 0;
      transition-delay: 0s;
    }
  }
}
</style>