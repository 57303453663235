<template>
  <div class="wrap">
    <nav-ber title="中国—东盟二手工程机械交易平台"></nav-ber>
    <main>
      <div class="topBox">
        <swiper
          :pagination="pagination"
          :modules="modules"
          :loop="true"
          class="banSwiper"
        >
          <swiper-slide class="item2">
            <img src="~@/assets/images/market/usedBan2.jpg" alt="" />
            <div class="txt">
              <h2>科技赋能建造</h2>
            </div>
          </swiper-slide>
          <swiper-slide class="item3">
            <img src="~@/assets/images/market/usedBan3.jpg" alt="" />
            <div class="txt">
              <h2>
                全球视野，公平交易<br />
                持续创新，合作共赢
              </h2>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="content">
        <section class="introduce">
          <h2 class="cTil"><em>平台简介</em></h2>
          <div class="txt">
            <p>
              二手工程机械交易平台充分利用中国东信在“一带一路”倡议下的渠道资源，致力于推动国内二手工程机械交易的繁荣发展。平台背靠中国东信-华建通建筑产业互联网平台，采用制造场景、沉淀数据、专业运营的思路方针，依托中国东信的资源整合能力，汇聚二手工程机械细分领域的各类资源、服务及数字化能力，形成纳入产业链多方参与者的服务生态体系，构建面向二手工程机械领域的一站式互联网服务平台。
            </p>
          </div>
        </section>
        <section class="distribution">
          <h2 class="cTil"><em>市场分布</em></h2>
          <img src="~@/assets/images/market/map.jpg" alt="">
        </section>
        <section class="service">
          <h2 class="cTil"><em>我们的服务</em></h2>
          <div class="hd">
            <div @click="serviceAct = 0" :class="['li', {'on': serviceAct === 0}]">
              <img src="~@/assets/images/market/po1.png" alt="" />
              <div class="p">金融服务</div>
            </div>
            <div @click="serviceAct = 1" :class="['li', {'on': serviceAct === 1}]">
              <img src="~@/assets/images/market/po2.png" alt="" />
              <div class="p">质量监管</div>
            </div>
            <div @click="serviceAct = 2" :class="['li', {'on': serviceAct === 2}]">
              <img src="~@/assets/images/market/po3.png" alt="" />
              <div class="p">配件维修</div>
            </div>
            <div @click="serviceAct = 3" :class="['li', {'on': serviceAct === 3}]">
              <img src="~@/assets/images/market/po4.png" alt="" />
              <div class="p">法律与监管</div>
            </div>
            <div @click="serviceAct = 4" :class="['li', {'on': serviceAct === 4}]">
              <img src="~@/assets/images/market/po5.png" alt="" />
              <div class="p">交易撮合</div>
            </div>
            <div @click="serviceAct = 5" :class="['li', {'on': serviceAct === 5}]">
              <img src="~@/assets/images/market/po6.png" alt="" />
              <div class="p">商机资讯</div>
            </div>
            <div @click="serviceAct = 6" :class="['li', {'on': serviceAct === 6}]">
              <img src="~@/assets/images/market/po7.png" alt="" />
              <div class="p">物流服务</div>
            </div>
          </div>
          <div class="bd">
            <div class="item" v-show="serviceAct === 0">
              <h4>金融服务</h4>
              <p>处理不同货币的支付交易，并管理金融风险</p>
            </div>
            <div class="item" v-show="serviceAct === 1">
              <h4>质量监管</h4>
              <p>严选并确保商品符合目的地国家或地区的合规性标准</p>
            </div>
            <div class="item" v-show="serviceAct === 2">
              <h4>配件维修</h4>
              <p>
                提供包括配件维修、更换或退换等服务，以确保消费者在购买产品后能够享受到完善的售后支持
              </p>
            </div>
            <div class="item" v-show="serviceAct === 3">
              <h4>法律与监管</h4>
              <p>提供法律合规指导、政策更新提醒和监管合规审核等服务</p>
            </div>
            <div class="item" v-show="serviceAct === 4">
              <h4>交易撮合</h4>
              <p>
                快速检索海量商品，提供多样选择，并优化支付流程，促成交易双方迅速完成订单
              </p>
            </div>
            <div class="item" v-show="serviceAct === 5">
              <h4>商机资讯</h4>
              <p>
                提供最新的政策法规、国际市场趋势等前沿信息，助您抓住商机并取得成功
              </p>
            </div>
            <div class="item" v-show="serviceAct === 6">
              <h4>物流服务</h4>
              <p>协助进行报关和清关手续，并实时跟踪物流信息</p>
            </div>
          </div>
        </section>
        <section class="worth">
          <h2 class="cTil"><em>平台的价值</em></h2>
          <ul>
            <li>
              <div class="i">
                <img src="~@/assets/images/market/li1.png" alt="" />
              </div>
              <div class="txt">
                <h4>全球化的市场</h4>
                <p>打破传统贸易的地域限制，提供全球化市场</p>
              </div>
            </li>
            <li>
              <div class="i">
                <img src="~@/assets/images/market/li2.png" alt="" />
              </div>
              <div class="txt">
                <h4>降低交易成本</h4>
                <p>直接连接买卖双方，去除中间环节，降本增效</p>
              </div>
            </li>
            <li>
              <div class="i">
                <img src="~@/assets/images/market/li3.png" alt="" />
              </div>
              <div class="txt">
                <h4>个性化服务</h4>
                <p>通过大数据和人工智能技术提供个性化服务</p>
              </div>
            </li>
            <li>
              <div class="i">
                <img src="~@/assets/images/market/li4.png" alt="" />
              </div>
              <div class="txt">
                <h4>全产业供应链</h4>
                <p>整合产业链上下游资源，实现资源的最优配置，提高整体效率</p>
              </div>
            </li>
            <li>
              <div class="i">
                <img src="~@/assets/images/market/li5.png" alt="" />
              </div>
              <div class="txt">
                <h4>促进行业升级和创新</h4>
                <p>
                  获取市场信息和竞争情报，了解行业趋势和技术发展，推动产品和服务的创新
                </p>
              </div>
            </li>
          </ul>
        </section>
        <section class="deal">
          <h5>全球视野，公平交易<br />持续创新，合作共赢</h5>
          <h6>
            Global perspective, fair trade<br />Continuous innovation, win-win
            cooperation
          </h6>
          <div class="btns">
            <div class="btn buy" @click="$router.push({path: '/consultform', query: { title: '我要买', plateArr: '中国—东盟二手工程机械交易平台 - 我要买'}})">我要买</div>
            <div class="btn" @click="$router.push({path: '/consultform', query: { title: '我要卖', plateArr: '中国—东盟二手工程机械交易平台 - 我要卖'}})">我要卖</div>
          </div>
        </section>
        <section class="demand">
          <h2 class="cTil"><em>中东2024某大客户需求</em></h2>
          <swiper
            :pagination="pagination"
            :modules="modules"
            :space-between="50"
            :auto-height="true"
            :loop="true"
            class="banSwiper"
          >
            <!-- 升降平台 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="2">Concrete Works<br/>
                    混泥土设备</td>
                    <td>Concrete vibrator混凝土振动器</td>
                    <td>——</td>
                    <td>524</td>
                  </tr>
                  <tr>
                    <td>Concrete polisher混凝土抛光机</td>
                    <td>——</td>
                    <td>376</td>
                  </tr>
                  <tr>
                    <td rowspan="5">Masonry and Finishing<br/>
                    砌筑设备</td>
                    <td>Concrete vibrating leveler混凝土振动整平机</td>
                    <td>6m</td>
                    <td>207</td>
                  </tr>
                  <tr>
                    <td>Concrete Placing Boom混凝土布料机</td>
                    <td>12m</td>
                    <td>98</td>
                  </tr>
                  <tr>
                    <td>Drum mixer滚筒搅拌机</td>
                    <td>——</td>
                    <td>245</td>
                  </tr>
                  <tr>
                    <td>Tile cutting machine瓷砖切割机</td>
                    <td>Tile Cutter</td>
                    <td>205</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
            <!-- 起重机 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="9">Lifting<br/>
                    起重机</td>
                    <td>Tower crane塔式起重机</td>
                    <td>Boom 80m,Tip load 4.0T</td>
                    <td>62</td>
                  </tr>
                  <tr>
                    <td>Tower crane塔式起重机</td>
                    <td>BOOM 75m,Tip load 2.7T</td>
                    <td>43</td>
                  </tr>
                  <tr>
                    <td>Rebar and formwork<br/>
                    钢筋机械设备</td>
                    <td>400T</td>
                    <td>34</td>
                  </tr>
                  <tr>
                    <td>Rebar and formwork<br/>
                    钢筋机械设备</td>
                    <td>200T</td>
                    <td>16</td>
                  </tr>
                  <tr>
                    <td>Crawler crane履带式起重机</td>
                    <td>50T</td>
                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Wheeled crane起重机</td>
                    <td>50T</td>
                    <td>62</td>
                  </tr>
                  <tr>
                    <td>Wheeled crane起重机</td>
                    <td>100T</td>
                    <td>17</td>
                  </tr>
                  <tr>
                    <td>Construction elevator室外电梯</td>
                    <td>1000Kg</td>
                    <td>82</td>
                  </tr>
                  <tr>
                    <td>Material hoist物料提升机</td>
                    <td>1000Kg</td>
                    <td>155</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
            <!-- 砌筑设备 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="8">Masonry and Finishing<br/>
                    砌筑设备</td>
                    <td>Rotary drilling rig<br/>
                      旋挖钻机</td>
                    <td>D1000</td>
                    <td>90</td>
                  </tr>
                  <tr>
                    <td>Steel bar straightening machine 钢筋调直机</td>
                    <td>——</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>Rebar cutting machine钢筋切断机</td>
                    <td>——</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>Bar bending machine钢筋弯曲机</td>
                    <td>——</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>AC welding machine交流焊机</td>
                    <td>——</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>Mud pump泥浆泵</td>
                    <td>——</td>
                    <td>180</td>
                  </tr>
                  <tr>
                    <td>Crawler crane履带式起重机</td>
                    <td>150-200T</td>
                    <td>95</td>
                  </tr>
                  <tr>
                    <td>Excavator挖掘机</td>
                    <td>——</td>
                    <td>20</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
            <!-- 升降平台+钢筋机械设备 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="2">Working Platform<br/>
                    升降平台</td>
                    <td>Scissor car剪刀式升降车</td>
                    <td>Scissor Lift</td>
                    <td>108</td>
                  </tr>
                  <tr>
                    <td>Manlift登高车</td>
                    <td>25-40m</td>
                    <td>105</td>
                  </tr>
                  <tr>
                    <td rowspan="5">Rebar and formwork<br/>
                    钢筋机械设备</td>
                    <td>Bar bending machine钢筋弯曲机</td>
                    <td>——</td>
                    <td>17</td>
                  </tr>
                  <tr>
                    <td>Rebar cutting machine钢筋切断机</td>
                    <td>——</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <td>Steel bar straightening machine钢筋调直机</td>
                    <td>——</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>Rebar threading machine钢筋套丝机</td>
                    <td>——</td>
                    <td>27</td>
                  </tr>
                  <tr>
                    <td>Woodworking table saw木工台锯</td>
                    <td>——</td>
                    <td>103</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
          <!-- 通用设备1 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="9">General Equipment<br/>
                    通用设备</td>
                    <td>Generator set发电机组</td>
                    <td>350KW</td>
                    <td>121</td>
                  </tr>
                  <tr>
                    <td>Welding machine电焊机</td>
                    <td>BX1-500</td>
                    <td>53</td>
                  </tr>
                  <tr>
                    <td>Boom Truck随车起重机</td>
                    <td>10-15T</td>
                    <td>42</td>
                  </tr>
                  <tr>
                    <td>Generator set发电机组</td>
                    <td>630KW</td>
                    <td>103</td>
                  </tr>
                  <tr>
                    <td>Generator set发电机组</td>
                    <td>100KW</td>
                    <td>65</td>
                  </tr>
                  <tr>
                    <td>Generator set发电机组</td>
                    <td>250kw</td>
                    <td>362</td>
                  </tr>
                  <tr>
                    <td>TRILLER 板车</td>
                    <td>14MTR/50T</td>
                    <td>24</td>
                  </tr>
                  <tr>
                    <td>Tower Light 灯车</td>
                    <td>——</td>
                    <td>209</td>
                  </tr>
                  <tr>
                    <td>Roller 压路机</td>
                    <td>20T</td>
                    <td>19</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
          <!-- 通用设备2 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="9">General Equipment<br/>
                    通用设备</td>
                    <td>Telehandler 伸缩臂叉车</td>
                    <td>3-5T/12m</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <td>Backhoe Loader 装载挖掘机</td>
                    <td>——</td>
                    <td>23</td>
                  </tr>
                  <tr>
                    <td>Bobcat Skip Loader 滑移装载机</td>
                    <td>——</td>
                    <td>46</td>
                  </tr>
                  <tr>
                    <td>Wheel Loader装载机</td>
                    <td>3m3</td>
                    <td>17</td>
                  </tr>
                  <tr>
                    <td>Fuel Tanker 油罐车</td>
                    <td>20000L</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>Concrere Hopper混凝土料斗</td>
                    <td>1m3</td>
                    <td>27</td>
                  </tr>
                  <tr>
                    <td>Compressor 空压机</td>
                    <td>20m3</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td>Weigh Bridge 地磅</td>
                    <td>150T</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>Block Cutter 砌块切割机</td>
                    <td>——</td>
                    <td>28</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
          <!-- 土方工程 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="9">Earthworks<br/>
                    土方工程</td>
                    <td>Excavator挖掘机</td>
                    <td>2m3</td>
                    <td>183</td>
                  </tr>
                  <tr>
                    <td>Loader装载机</td>
                    <td>3m3</td>
                    <td>42</td>
                  </tr>
                  <tr>
                    <td>Grader平地机</td>
                    <td>——</td>
                    <td>21</td>
                  </tr>
                  <tr>
                    <td>Dump truck自卸车</td>
                    <td>20m3</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>Single drum roller单钢轮压路机</td>
                    <td>20T</td>
                    <td>41</td>
                  </tr>
                  <tr>
                    <td>Vibratory impact compactor振动冲击压实机</td>
                    <td>Tamping Rammer</td>
                    <td>54</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
          <!-- 运输设备 -->
            <swiper-slide>
              <table>
                <thead>
                  <tr>
                    <th width="20%">category<br/>
                      类别</th>
                    <th width="40%">Name<br/>
                      中文名称</th>
                    <th width="26%">Equipment Type<br/>
                      设备类型</th>
                    <th width="14%">Total quantityNo<br/>
                      总数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan="5">Transportation<br/>
                    运输设备</td>
                    <td>Light truck轻型卡车</td>
                    <td>3-5T</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <td>High-board trailer高板拖车</td>
                    <td>40-60T</td>
                    <td>66</td>
                  </tr>
                  <tr>
                    <td>Forklift叉车</td>
                    <td>5T</td>
                    <td>90</td>
                  </tr>
                  <tr>
                    <td>Forklift叉车</td>
                    <td>10T</td>
                    <td>33</td>
                  </tr>
                  <tr>
                    <td>Water tank洒水车</td>
                    <td>20-32T</td>
                    <td>42</td>
                  </tr>
                </tbody>
              </table>
            </swiper-slide>
          </swiper>
        </section>
        <section class="business">
          <h2 class="cTil"><em>需求&商机</em></h2>
          <div class="box">
            <h3 class="hTil">欧洲市场</h3>
            <van-collapse class="bd" v-model="activeNames1" accordion>
              <van-collapse-item name="1" :class="['li', {'on': activeNames1.includes('1')}]">
                <template #title>
                  <div class="above">
                    <h6>欧洲</h6>
                    <p>以俄罗斯为代表的东欧地区工程机械呈现贸易逆差，缺乏本土品牌、可观的市场需求规模、且相对良好的竞争环境，使得东欧地区成为我国最具潜力的出口地区之一</p>
                  </div>
                </template>
                <div class="below">
                  <p>
                    根据Freedonia数据，2022年东欧地区工程机械产量约为68亿美元，相对于其114亿美元需求规模仍然较少，因此，东欧地区贸易逆差约为46亿美元，为工程机械净进口地区，进口占总需求近4成。东欧地区市场需求增长潜力大，但本土厂商较少。俄罗斯是该地区工程机械最大生产国，2022年出货量为20亿美元，波兰是第二大生产国，其次为白俄罗斯、捷克共和国、匈牙利、乌克兰等。由于东欧地区市场规模较为客观，且本土品牌缺乏高端和特殊工程机械产品，因此东欧地区工程机械需求很大一部需要依靠进口。俄乌冲突前，奥地利、德国、意大利以及其他西欧国家品牌是东欧地区工程机械的重要来源，而中国、日本、韩国、美国也是其海外供应商。冲突发生后，俄罗斯对外贸易伙伴关系发生变化，这也为我国工程机械品牌的市场拓展带来一定机遇。总体上看，欧洲工程机械成净出口状态，贸易顺差主要来自西欧地区，但近年该地区能源成本、工程机械设备价格攀升以及地缘冲突影响，致贸易顺差减小，亚洲、美国品牌占据一定市场份额。而以俄罗斯为代表的东欧地区工程机械呈现贸易逆差，缺乏本土品牌、可观的市场需求规模、且相对良好的竞争环境，使得东欧地区成为我国最具潜力的出口地区之一。
                  </p>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div class="box">
            <h3 class="hTil">东南亚市场</h3>
            <van-collapse class="bd" v-model="activeNames2" accordion>
              <van-collapse-item name="1" :class="['li', {'on': activeNames2.includes('1')}]">
                <template #title>
                  <div class="above">
                    <h6>越南</h6>
                    <p>城镇化及制造业投资迅速增长，带来基建及生产类建筑需求</p>
                  </div>
                </template>
                <div class="below">
                  <p>
                    从需求端看，近年来，对公共基础设施的投资一直是越南经济发展的关键驱动力之一，根据OECD数据，2010-2017年期间，基础设施占官方发展援助总额的53%。此外，越南的快速城市化推动了基建的发展。据估计，目前50%的人口居住在大城市，不断增长的人口已经超过了现有连接网络和公用事业系统的容量。2021年越南政府批准了2021-2030年的公路网发展计划，在此期间投资430-650亿美元建设和升级公路、铁路、内河航道、海运和空运基础设施。2030年越南高速公路里程将从2021年的3841km增长到5000km，国道从5474km增长到29795km，海岸公路增长3034km。另外，越南制造业投资迅速增加，厂房等建筑需求也将成为当地工程机械需求的驱动力。2021年制造业占越南投资总额的24.3%，比重为各门类之首，越南正在大量承接制造业产能，对厂房等基础设施的需求不断增加。2021年运输仓储业占越南投资总额的比重为10.7%，仅次于制造业和其他服务业。从供给端看，越南缺乏有竞争力的本土工程机械品牌，但这也为中资品牌带来了一定的契机。我国对越南工程机械出口额在经历了2020年疫情导致短暂下跌以后，在2021年达到12年来的最快增速。
                  </p>
                </div>
              </van-collapse-item>
              <van-collapse-item name="2" :class="['li', {'on': activeNames2.includes('2')}]">
                <template #title>
                  <div class="above">
                    <h6>马来西亚</h6>
                    <p>基建投资充裕，建筑市场较为活跃</p>
                  </div>
                </template>
                <div class="below">
                  <p>
                    在大型交通和能源项目投资的推动下，马来西亚建筑市场在疫情后开始恢复。GlobalData预计2024-2027年马来西亚建筑市场AAGR将达到6%以上。马来西亚政府通过建立公私伙伴关系（PPP）3.0机制，与企业合作募集资金，投入2021年至2025年第12个马来西亚计划（12MP）下的基础设施项目。马来西亚建筑市场的主要部门包含工业建筑、能源和公用事业建设、机构建设和住宅建设。随着政府放宽税收吸引跨国企业和制造业的持续投资，工业建筑业不断扩张；由于大型石油和天然气项目的执行和一级可再生能源产能增加，能源和公用设施建设行业不断增长；而在医疗保健和教育部门投资的支持下，机构建设将不断增长,以上部门2024年至2027年间平均增长将超过6%。此外，政府的目标是到2025年建造50万套经济适用房，也将有助于对住宅行业的产出拉动。此外，马来西亚政府于2022年10月公布了204.3亿美元的基础设施发展预算，2023年计划开发7615个各类项目，比2022年增长25.7%。该计划将优先用于道路建设，以及农村地区道路维护和升级等基础设施工程。此前，马来西亚政府计划改善与邻国印度尼西亚和泰国的交通连接，将与邻国联合开发新的国际公路，以促进整个地区的交通运输，因此对工程机械的需求将进一步增长。
                  </p>
                </div>
              </van-collapse-item>
              <van-collapse-item name="3" :class="['li', {'on': activeNames2.includes('3')}]">
                <template #title>
                  <div class="above">
                    <h6>印度尼西亚</h6>
                    <p>
                      疫情后基建投资预算增长，项目完成时限对未来两年工程机械需求形成支撑
                    </p>
                  </div>
                </template>
                <div class="below">
                  <p>
                    疫情后基础设施预算的增加支持了工程机械市场未来两年的增长。2023年印尼国家收支预算草案显示，基础设施预算为392万亿印尼盾，同比增长7.8%。2022-2024年预计将有55个额外的基础设施项目，其中2023年的基础设施建设目标包含长297公里的收费公路、24座大坝以及灌溉网络和公寓建设。政府规定使用国家预算（APBN）的国家战略项目（PSN）必须在2024年前完成，除非项目属于具有多年合同的长期投资项目，有利于保障未来2年内的工程机械需求。2021年我国对印尼工程机械出口额为16.3亿美元，2022年1-11月出口印尼工程机械24.9亿美元，同比增长76.9%，占出口比重6.18%，印尼也是我国工程机械出口金额第三大国家。
                  </p>
                </div>
              </van-collapse-item>
              <van-collapse-item name="4" :class="['li', {'on': activeNames2.includes('4')}]">
                <template #title>
                  <div class="above">
                    <h6>印度</h6>
                    <p>
                      国道修建需求迫切，道路建设等基建项目是工程机械主要驱动力
                    </p>
                  </div>
                </template>
                <div class="below">
                  <p>
                    印度的工程机械需求主要来自于道路建设等基建项目。从公路总长度来看，印度拥有全球第二大公路网，其公路长度从1998年的270万公里，增加至2008年的330万公里，到2019年达到690万公里，近十年发展迅速。其国道长度也从2001年的5万公里翻了一番，到2020年达到13.3万公里。虽然从公路网总长度来看，国道只占了整个印度公路网的2%，但却承载了全国40%的交通量，而印度大约只有12%的国道为四车道，只有22%的车道为两车道，交通问题十分严峻。因此印度道路运输和公路部(MoRTH)正在对国内公路网进行全面审查，批准了印度第二大高速公路建设项目巴拉特马拉项目（Bharatmala），该项目将分两个阶段建设约65000公里的国道，第一阶段正在进行中，第二阶段即将在2024年开始。此外，印度的农村基建发展潜力也较大，目前，印度政府也在通过PradhanMantri
                    GramSadakYojana(PMGSY)等举措关注农村发展，而在城市中，承包商也往往更青睐于大型设备。例如，具有多功能的新型装载机，挖掘装载机在印度就很受欢迎，当然履带式挖掘机也是用途最广泛的土方机械之一。
                  </p>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div class="box">
            <h3 class="hTil">中东市场</h3>
            <van-collapse class="bd" v-model="activeNames3" accordion>
              <van-collapse-item name="1" :class="['li', {'on': activeNames3.includes('1')}]">
                <template #title>
                  <div class="above">
                    <h6>中东</h6>
                    <p>较高的能源价格为基建投资带来良好的财政支持</p>
                  </div>
                </template>
                <div class="below">
                  <p>
                    2020年以来，中东经济持续复苏，2022年沙特和阿联酋2022年GDP增速分别为8.7%和7.6%，国际货币基金组织预计2023年中东地区经济增速为3.2%，2024年将升至3.5%。受益于能源价格上涨和新项目开工等因素影响，惠誉预测，2023年中东北非地区的基建行业将同比增长5.0%，高于全球2.3%的同比增长率，领先于其他地区。据前瞻经济学人统计，2020年中东基建工程市场为555亿美元，其中沙特作为最大的承包工程市场，正在推进“2030愿景”中的NEOM新城（5000亿美元）、红海旅游（100亿美元）等大型项目，其中“TheLine”未来新城市计划更是耗资一万亿美元，将成为沙特最大的交通运输和公用基础设施项目。
                  </p>
                  <p>
                    推动中东北非地区基建的主要原因除了疫情后该地区的经济恢复、较稳定的投资环境外，还有处于高位的全球能源价格，因为较高的能源价格使得该地区的石油输出国有更多资金投入非石油经济，尤其是有利于让国家经济更加多元化的基建项目等。根据惠誉预测，2023年原油均价为95美元/桶，虽然低于2022年的99美元/桶，但按照IMF计算，阿曼、沙特、阿联酋的财政盈亏平衡油价分别为75.1美元、66.8美元、65.8美元/桶，因此，该地区石油输出国仍然有充足的财政空间来支持基建项目建设。当然，地区内也会存在一定发展不均衡的情况，例如，利比亚、埃及和伊朗有望成为该地区行业实际增长率较快的市场，而卡塔尔由于在2022年为筹备世界杯已经在交通、商业、公共基础设施等领域建设相对饱和，新投资有限，且人口增长相对疲软，因此其主要投资项目也与LNG扩建等能源项目相关。总体上看，根据惠誉基建关键项目数据库，未来5年中东北非地区在基建领域有大量的在建和准备中的项目，有望保持较为乐观的增长速度。
                  </p>
                  <p>
                    2021年，我国对中东地区出口工程机械金额约为15.3亿美元，增速达15.3%。沙特、阿联酋是我国工程机械出口到中东地区的主要国家，出口占比显著高于该地区其他国家。据前瞻经济学人统计，2022年1-11月，我国对沙特工程机械出口额7.66亿美元，同比增长109.5%。
                  </p>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div class="box">
            <h3 class="hTil">非洲市场</h3>
            <van-collapse class="bd" v-model="activeNames4" accordion>
              <van-collapse-item name="1" :class="['li', {'on': activeNames4.includes('1')}]">
                <template #title>
                  <div class="above">
                    <h6>非洲</h6>
                    <p>
                      基建开发颇具潜力的地区，“一带一路”为工程机械出海带来契机
                    </p>
                  </div>
                </template>
                <div class="below">
                  <p>
                    近年来非洲基础设施建设市场不断增长，“一带一路”政策推动了非洲国家基础设施投资。据丝路国际产能促进中心统计，中国是除非州政府外最大的投资方，2020年非洲大约五分之一的基础设施项目由中国提供融资，三分之一的基础设施项目由中国建设。非盟规划的非洲基础设施发展计划预计于2040年完成，总投资达3600亿美元，其中2012—2020年第一期优先行动计划包含51个项目，如价值156亿美元、全长1081公里的阿比让—拉各斯高速公路项目，2021—2030年第二期优先行动计划将推进71个优先项目。2021年，我国对非洲工程机械出口额同比增长70.7%。据前瞻经济学人统计，2022年1-11月，我国对非洲工程机械出口额35.43亿美元，同比增长24.2%。南非、刚果金、尼日利亚、坦桑尼亚、埃及是我国出口非洲地区的主要国家。整体上看，除2020年疫情影响外，我国对非洲工程机械出口额整体成稳定增长趋势，该地区基础设施和建筑均有较大开发潜力，且相对缺乏本土品牌，未来仍然是我国工程机械出口的重要市场。
                  </p>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const modules = [Pagination];
const pagination = {
  clickable: true,
};
const serviceAct = ref(0);
const activeNames1 = ref(["0"]);
const activeNames2 = ref(["0"]);
const activeNames3 = ref(["0"]);
const activeNames4 = ref(["0"]);
onMounted(function () {
});
</script>

<style scoped lang="scss">
main{
  padding: 0;
}
.topBox {
  height: 4rem;
  .banSwiper {
    .txt {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 5;
      h2 {
        font-size: 0.38rem;
        line-height: 0.48rem;
        font-weight: 600;
        background: linear-gradient(to right, #ffa200, #ffbb00);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .item1 {
      .txt {
        padding: 2.05rem 0.3rem 0;
      }
    }
    .item2 {
      .txt {
        padding: 2.15rem 0.3rem 0;
      }
    }
    .item3 {
      .txt {
        padding: 1.2rem 0.3rem 0;
        h2 {
          color: #fff;
          background-color: transparent;
          -webkit-text-fill-color: #fff;
        }
      }
    }
    :deep(.swiper-pagination) {
      position: absolute;
      left: auto;
      width: auto;
      right: 0.3rem;
      bottom: 1rem;
      text-align: right;
      .swiper-pagination-bullet {
        margin: 0 0 0 0.1rem;
        background: #fff;
      }
      .swiper-pagination-bullet-active {
        background: #f70000;
      }
    }
  }
}
.swiper{
  :deep(.swiper-pagination) {
    position: static;
    margin-top: .3rem;
    height: 0.1rem;
    line-height: 0.1rem;
    z-index: 5;
    .swiper-pagination-bullet {
      vertical-align: top;
      width: 0.1rem;
      height: 0.1rem;
      opacity: 1;
      background: #2d322d;
      margin: 0 .05rem;
    }
    .swiper-pagination-bullet-active {
      background: #f70000;
    }
  }
}
.content {
  position: relative;
  z-index: 10;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
  margin-top: -0.45rem;
  background-size: 0.6rem;
}
section{
  padding: .6rem .3rem;
}
.tab{
  .hd{
    display: flex;
    justify-content: center;
    margin-bottom: .6rem;
    .li{
      margin: 0 .1rem;
      padding: 0 .25rem;
      height: .56rem;
      line-height: .56rem;
      background: #efefef;
      border-radius: .56rem;
      font-size: .24rem;
      color: #2d322d;
      transition: all .4s;
      &.on{
        color: #f70000;
        background: #fbeddc;
      }
    }
    margin: 0 .1rem;
  }
}
.distribution{
  img{
    max-width: 7.5rem;
    margin: 0 -.3rem;
  }
}
.service{
  background: #f4f8fb;
  .hd{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: .4rem;
    .li{
      position: relative;
      width: 24%;
      margin: 0 .5%;
      transition: all .4s;
      &:before,&:after{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: url(~@/assets/images/market/zc1.png) no-repeat bottom center;
        background-size: 100%;
        transition: all .4s;
      }
      &:after{
        width: 1.84rem;
        height: 2.05rem;
        background: url(~@/assets/images/market/poon.png) no-repeat center;
        background-size: 100% 100%;
        left: 50%;
        right: auto;
        bottom: auto;
        top: 0;
        margin-left: -.92rem;
        opacity: 0;
      }
      &:nth-child(n + 5){
        margin-top: -.42rem;
      }
      &.on:before{
        background-image: url(~@/assets/images/market/zc2.png);
      }
      &.on:after{
        opacity: 1;
      }
      .p{
        position: absolute;
        left: 0;
        right: 0;
        bottom: .3rem;
        color: #fff;
        font-size: .24rem;
        line-height: .34rem;
        text-align: center;
        z-index: 2;
      }
    }
  }
  .bd{
    padding: 0 .6rem;
    h4{
      font-size: .26rem;
      line-height: .4rem;
      font-weight: 600;
      color: #294654;
      text-align: center;
    }
    p{
      color: #3f5d6b;
      text-align: center;
    }
  }
}
.worth{
  padding-bottom: .3rem;
  ul{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  li{
    width: 3.3rem;
    height: 3.2rem;
    padding: .5rem .3rem;
    margin: 0 0 .3rem;
    border: .02rem solid #edeff2;
    .i img{
      height: .5rem;
      margin-bottom: .3rem;
    }
    h4{
      font-size: .26rem;
      line-height: .4rem;
      font-weight: 600;
      color: #294654;
      margin-bottom: .1rem;
    }
  }
}
.deal{
  padding: 1.2rem .3rem;
  background: url(~@/assets/images/market/bg2.jpg) no-repeat center;
  background-size: auto 100%;
  h5{
    font-size: .38rem;
    line-height: .48rem;
    color: #fff;
    margin-bottom: .2rem;
    font-weight: 600;
  }
  h6{
    font-size: .24rem;
    color: rgba(255,255,255,.3);
    line-height: 1;
    text-transform: uppercase;
    font-family: 'Gilroy-Bold';
  }
  .btns{
    margin-top: .5rem;
    display: flex;
    .btn{
      font-size: 0.24rem;
      color: #fff;
      height: 0.56rem;
      line-height: 0.52rem;
      text-align: center;
      width: 1.4rem;
      border: 0.02rem solid rgba(255,255,255,.5);
      border-radius: 0.25rem;
      margin-right: .2rem;
      &.buy{
        color: #fff;
        background: #f70000;
        border-color: #f70000;
      }
    }
  }
}
.demand{
  .swiper{
    margin: 0 -.3rem;
  }
  .swiper-slide{
    overflow: hidden;
  }
  table,th,td{
    border: 1px solid #e1e2e6;
  }
  table{
    width: 100%;
  }
  th,td{
    padding: .2rem;
    font-size: .24rem;
    color: #2d322d;
    line-height: .3rem;
    font-family: 'dincond-medium-webfont', "Microsoft YaHei", "微软雅黑", \5FAE\8F6F\96C5\9ED1, arial, sans-serif, \5b8b\4f53;
    text-transform: uppercase;
  }
  th{
    border-color: #eff0f4;
    background: #d9dce9;
  }
}
.business{
  background: url(~@/assets/images/market/bg3.jpg) no-repeat center;
  background-size: cover;
  h2.cTil{
    border-color: #999;
  }
  :deep(.van-cell) {
    padding: 0 0.3rem;
    margin: 0 -0.3rem;
    width: auto;
    background: transparent;
    &:after {
      display: none;
    }
    .van-icon {
      display: none;
    }
  }
  :deep(.van-collapse){
    &.van-hairline--top-bottom:before, &.van-hairline-unset--top-bottom:before,&.van-hairline--top-bottom:after, &.van-hairline-unset--top-bottom:after{
      display: none;
    }
  }
  :deep(.van-collapse-item__wrapper) {
    padding: 0 .4rem .3rem;
    .van-collapse-item__content{
      padding: .3rem 0 0;
      border-top: .02rem solid #d7e2ec;
      background: transparent;
    }
  }
  .van-collapse-item{
    margin-bottom: .2rem;
    background: #f2f9ff;
    &.on{
      .above{
        h6{
          &:after{
            transform: rotate(180deg);
            background-image: url(~@/assets/images/market/usedDownOn.png);
          }
        }
      }
    }
  }
  .van-collapse-item--border:after{
    display: none;
  }
  
  .box{
    background: #fff;
    margin-bottom: .2rem;
    padding: .3rem;
    .hTil{
      font-size: .28rem;
      line-height: .38rem;
      color: #2d322d;
      margin-bottom: .3rem;
    }
    .above{
      padding: .3rem .4rem;
      h6{
        position: relative;
        font-size: .24rem;
        line-height: .4rem;
        color: #2a4f6e;
        font-weight: 600;
        &:before{
          content: "";
          display: block;
          width: .1rem;
          height: .1rem;
          background: #99b8d2;
          border-radius: 50%;
          position: absolute;
          left: -.2rem;
          top: .15rem;
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          top: .08rem;
          right: 0;
          height: .24rem;
          width: .24rem;
          background: url(~@/assets/images/market/usedDown.png) no-repeat center;
          background-size: contain;
          transition: all .4s;
        }
      }
      p{
        color: #2a4f6e;
      }
    }
    .below{
      p{
        color: #7593ad;
      }
    }

  }
}
</style>

