<template>
  <div class="wrap">
    <nav-ber :title="'提示'"></nav-ber>
    <main class="financialTransactResult">
      <h2 class="til">提交成功！</h2>
      <div class="icon"><img src="~@/assets/images/financial/iconSucceed.png" alt=""></div>
      <div class="p">感谢您的投递<br/>
        我们将会在1-3个工作日内与您取得联系
      </div>
      <div class="hint">系统将在 <em>{{num}}</em> 秒后跳转至首页</div>
      <van-button class="submit" type="primary" size="large" color="#f70000" @click="goTo()">返回首页</van-button>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { usePublic } from '@/api/public'
import { ref, onMounted, onDeactivated } from 'vue';
const num = ref(5);
const { skipLink } = usePublic();
const IntTime =  setInterval(function(){
  if(num.value > 0){
    console.log(num.value);
    num.value = num.value - 1;
  }else{
    goTo();
  }
},1000);
function goTo(){
    clearInterval(IntTime);
    skipLink('/');
}
onMounted(() => {
  window.addEventListener('popstate', () => clearInterval(IntTime))
})
onDeactivated(() => {
  clearInterval(IntTime);
})
</script>

<style scoped lang="scss">
.financialTransactResult{
  padding: 1.25rem .4rem;
  background: #f3f3f3 url(~@/assets/images/financial/transactResultBg.jpg) no-repeat top;
  background-size: 100%;
  .til{
    font-size: .36rem;
    color: #2d322d;
    line-height: .46rem;
    margin-bottom: .3rem;
    text-align: center;
  }
  .icon{
    width: 1.26rem;
    margin: 0 auto .34rem;
  }
  .p{
    font-size: .3rem;
    color: #47677b;
    text-align: center;
    margin-bottom: 1.4rem;
  }
  .hint{
    font-size: .22rem;
    color: #646464;
    text-align: center;
    line-height: .36rem;
    margin-bottom: .35rem;
    em{
      color: #2a6ed3;
    }
  }
}
</style>

