<template>
  <div class="wrap">
    <nav-ber :title="'预售资金监管平台'"></nav-ber>
    <main class="capital">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item " v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit ">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
            
            </div>
        </section>
         <section class="constru-npm2" v-if="apiData2[0]">
            <div class="w1440">
                <div class="pagetit ">
                    <h5 v-html="apiData2[0].newsTitle"></h5> 
                </div>
                <div class="img ">
                    <h5 v-html="apiData2[0].newsContent"></h5>
                    <img :src=" apiData2[0].image" alt="">
                </div>
            </div>
        </section>
      <section class="constru-npm3">
            <div class="w1440">
                <div class="pagetit wow fadeInUp50">
                    <h5>建设亮点</h5> 
                </div>
                <ul>
                    <li class="wow fadeInUp50" v-for="item in apiData3" :key="item.newsId"
                       >
                        <div class="ico"> <img :src="item.image" alt=""></div>
                        <div class="txt">
                            <h5 v-html="item.newsTitle"></h5>
                            <div v-html="item.newsContent"></div>
                        </div>
                    </li>

                </ul>
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
}); 
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 537,
    typeId: 91,
  });
  const { data: data2 } = await getInfo({
    coId: 563,
  });
  const { data: data3 } = await getInfo({
    coId: 565,
  });
    const { data: data4 } = await getInfo({
    coId: 567,
  });

  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data;
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent; 
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{
    padding: 0;
}
.capital {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0;
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
        }
        
    .constru-npm2{
                padding-bottom: .8rem;
                .img{
                    margin-top: .4rem;
                    text-align: center;
                    h5{
                        font-size: .26rem;
                        color: #333333;
                        margin-bottom: .2rem;
                    }
                }
             }
             .constru-npm3{
                padding: .9rem 0;
                background: linear-gradient(to bottom, #f3f4f7, #edf3fc, #d6ecff);
                ul{
                    margin-top: .4rem;
                    flex-wrap: wrap;
                     display: flex;
            justify-content: space-between;
                    li{
                        width: 100%;
                        margin-bottom: .2rem;
                        padding: .5rem .3rem;
                         background: #fff;
                         display: flex;
                        .ico{
                            width: 1.3rem;
                            height: 1.3rem;
                            display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f3f4f7;
                    border-radius: 50%;
                            img{
                                width: .4rem;
                            }
                            
                        }
                        .txt{
                                margin-left: .4rem;
                                width: calc(100% - 1.7rem);
                                h5{
                                    font-size: .26rem;
                                    margin-bottom: .08rem;
                                     color: #333333;
                        font-weight: bold;
                                }
                            }
                    }
                }
             }
}
</style>
  
  