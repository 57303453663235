<template>
  <div class="wrap">
  <div class="riskcontrol">
    <nav-ber title="风控管理"></nav-ber>
    <main>
      <sub-ban :ban-info="banInfo"></sub-ban>
      <section class="tend-npm1">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData3[0].newsTitle"></h5>
          <h6 v-html="apiData3[0].newsContent"></h6>
        </div>
        <ul class="wow fadeInUp50">
          <li class="wow fadeInUp50" v-for="(item, i) in apiData4" :key="item.newsId" :data-id="item.newsId" :style="'animation-delay:' +(i *150)  + 'ms'">
            <div class="ico">
              <img :src="item.newsAccess" alt="">
            </div>
            <h5 v-html="item.newsTitle"></h5>
            <em>{{ item.newsAuthor }}</em>
          </li>
        </ul>
      </div>
    </section>
    <section class="tend-npm2">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData5[0].newsTitle"></h5>
          <h6 v-html="apiData5[0].email"></h6>
        </div>
        <div class="box wow fadeInUp50" v-html="apiData5[0].newsContent">
          
        </div>
      </div>
    </section>
    <section class="bim-npm1">
            <div class="pagetit wow fadeInUp50">
              <h5>业务场景</h5>
            </div>
            <div class="cTabWr">
                 <div class="w1440">
              <van-tabs v-model:active="cTabActive" class="ball" :ellipsis="false" swipe-threshold="1">
                <van-tab :title="item.newsTitle"  v-for="item in apiData6"></van-tab>
              
              </van-tabs>
                 </div>
              <div class="cTabBd">
                <div class="ul">
                  <div class="li"  v-for="(item, i) in apiData6" style="" v-show="cTabActive == i">
                    <div class="liflex clear">
                      <div class="fr animated fadeInUp50">
                  <img :src="    item.image" alt="" />
                </div>
                <div class="fl animated fadeInUp50">
                  <div class="ico"><img :src="    item.newsAccess" alt="" /></div>
                  <h5 v-html="item.newsTitle"></h5>
                  <div class="p" v-html="item.newsContent"></div>
                  <router-link class="homemore" :to="{path:'/consultform', query:{'title': banInfo.txton, 'plateArr': banInfo.tilCn}}">立即咨询</router-link>
                </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
    <section class="tend-npm3">
      <div class="w1440">
        <div class="pagetit wow fadeInUp50">
          <h5>应用价值</h5>
          <h6>Application value</h6>
        </div>
        <ul class="wow fadeInUp50">
          <li v-for="(item, i) in apiData7" :key="item.newsId">
            <div class="li">
              <div class="ico">
             <img :src="item.newsAccess" alt="">
            </div>
            <h5 v-html="item.newsTitle"></h5> 
            <p v-html="item.newsContent"></p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <div id="tend-npm4"></div>
    <pcDigital-pop :banenter="banenter" />
    </main>
  </div>
  </div>
</template>
<script setup >
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const cTabActive = ref(0);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const apiData6 = ref([]);
const apiData7 = ref([]);
const banInfo = reactive({
  banUrl: '',
  tilCn: '',
  tilTxt: '',
  link: '',
  coId: "",
  enlink: true,
  txton: "免费试用",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: "423",  
        typeId:"1371",
  });
  const { data: data2 } = await getInfo({
    coId: "755",  
  });
  const { data: data3 } = await getInfo({
    coId: "801", 
        typeId:"1371",
  });
  const { data: data4 } = await getInfo({
    coId: "757", 
        typeId:"1371",
  });
  const { data: data5 } = await getInfo({
    coId: "769", 
        typeId:"1371",
  });
  const { data: data6 } = await getInfo({
    coId: "759", 
        typeId:"1371",
  });
  const { data: data7 } = await getInfo({
    coId: "771", 
        typeId:"1371",
  });
  apiData1.value = data1.data;
  apiData2.value = data2.data;
  apiData3.value = data3.data;
  apiData4.value = data4.data;
  apiData5.value = data5.data;
  apiData6.value = data6.data;
  apiData7.value = data7.data;
  banInfo.banUrl = apiData1.value[0].image;
  banInfo.tilCn = apiData1.value[0].newsTitle;
  banInfo.tilTxt = apiData1.value[0].newsContent;
};
onMounted(async () => {
   getList();
});
</script>
<style lang="scss">
.riskcontrol {
  main{padding: 0;}
  #tend-npm4 {
    height: 90px;
  }

  .pagetit h5,
  .pagetit h6 {
    text-align: center;
  }

  .tend-npm1 {
    padding: 90px 0;

    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      li {
        width: 23.9583%;
        background: #f3f4f7;
        position: relative;
        top: 0;
        transition: all .4s;
        height: 235px;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 0%;
          -webkit-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
          height: 4px;
          background: #d7000f;
        }

        &:hover {
          &:after {
            width: 100%;
            left: 0;
          }

          em {
            color: #d7000f;
          }
        }

        .ico {
          border-radius: 50%;
          width: 96px;
          height: 96px;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 45px auto 30px;
        }

        h5 {
          text-align: center;
          font-size: 18px;
          color: #333333;
        }

        em {
          position: absolute;
          top: 30px;
          left: 35px;
          color: #c5c5c5;
          font-size: 20px;
          letter-spacing: -.05px;
          font-family: 'DIN-Bold';
          transition: all .4s;
        }
      }
    }
  }

  .tend-npm2 {
    .box {
      padding: 0 35px;
      margin-top: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      ul {
        width: 10.22%;
        position: relative;

        &::after {
          content: '';
          width: 57px;
          height: 393px;
          right: -65px;
          top: 20px;
          position: absolute;
          background: url(https://www.huajiantong.com/images/digital/line13.png);
        }

        li {
          width: 100%;
          line-height: 40px;
          font-size: 14px;
          margin-bottom: 25px;
          background: #d7000f;
          text-align: center;
          color: #fff;
          position: relative;
          z-index: 3;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .middle {
        display: flex;
        align-items: center;
        width: 55.8%;
        overflow: hidden;

        .ico {
          border-radius: 50%;
          background: #d7000f;
          width: 120px;
          height: 120px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 3;

          p {
            color: #fff;
            font-size: 14px;
            margin-top: 5px;
          }
        }

        .img {
          background: url(https://www.huajiantong.com/images/digital/line14.png);
          background-size: 100% 100%;
          margin-left: -120px;
          width: 100%;
          height: 304px;
          font-size: 30px;
             
          color: #fff;
          line-height: 304px;
          text-align: center;
          padding-left: 120px;
        }
      }

      .below {
        border: 1px dashed #f8ced1;
        padding: 35px 20px;
        width: 25.183%;
        height: 392px;
        background: linear-gradient(to right, #fef8f8, #fdf0ed);

        .tit {
          line-height: 54px;
          text-align: center;
          color: #fff;
          font-size: 20px;
          background: #d7000f;
          box-shadow: 0 0 20px 0 rgba(215, 0, 15, .4);
          margin-bottom: 25px;
        }

        dl {
          dt {
            font-size: 16px;
            line-height: 48px;
            color: #808080;
            text-align: center;
            font-family: 'DIN-Bold';
          }
        }
      }
    }
  }

    .bim-npm1 {
    padding-top: .6rem;

    .cTabWr { 
      margin-top: .35rem;
        .van-tabs__wrap {
        height: 0.6rem;
        .van-tabs__nav {
          padding-left: 0;
          padding-right: 0;
          background: transparent;
        }
        .van-tab {
          border-radius: 0.6rem;
          transition: all 0.4s;
          color: #2d322d;
          margin-left: 0.2rem;
          font-size: .24rem;
          &:first-child {
            margin-left: 0;
          }
        }
        .van-tab--active {
          color: #fff;
          background: #d7000f;
          font-weight: 500;
        }
        .van-tabs__line {
          display: none;
        }
      }
    }
    .mobileTabs {
      margin-top: 0.5rem;
      display: none;
    }

    .cTabBd {
      background: url(https://www.huajiantong.com/images/digital/bg6.jpg)
        no-repeat;
      background-position: center;
      background-size: cover; 

      .fl {
        width: 36.81%;
        position: relative;
        .homemore {
          color: #fff;
          background: #d7000f;
          border: 2px solid #d7000f;
          width: 1.8rem;
    line-height: .8rem;
    font-size: .24rem;
    border-radius: 1rem;
        }
        h5 {
          font-size: .26rem;
          color: #333333;
          position: relative;
          z-index: 3;
        }

        .p {
          margin: .2rem 0 .45rem;
          position: relative;
          z-index: 3;
        }

        .ico {
          margin-bottom: .2rem;
          width: .42rem;
          position: relative;
          z-index: 3;
        }
      }

      .fr {
        width: 47.569%;
        img {
          max-width: none;
          float: right;
        }
      }
    }
  }

  .tend-npm3 {
    overflow: hidden;

    ul {
      margin-top: 50px;
      display: flex;

      li {
        float: left;
        width: 33.333%;
        padding: 40px 0 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ico {
          text-align: center;
          margin-bottom: 15px;
        }

        h5 {
          text-align: center;
          font-size: 20px;
          margin-bottom: 7px;
          color: #4c4c4c;
        }

        &:nth-child(1) {
          background: #ebedf1;
        }

        &:nth-child(2) {
          background: #e6e9ed;
        }

        &:nth-child(3) {
          background: #e2e4e8;
        }
      }
    }
  }
}

@media (max-width: 1500px) {

  .riskcontrol .tend-npm2 .box ul::after {
    right: -50px;
  }
}

.riskcontrol .bim-npm1 .mobileTabs {
  margin-top: 0.5rem;
  display: none;
}
@media (max-width: 600px) {
  .riskcontrol {
    .tend-npm1 {
      padding: 0.6rem 0;
      ul {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 3.25rem;
padding: 0 .2rem;
          height: 2.8rem;
          margin-bottom: 0.2rem;
          em {
            left: 0.3rem;
            top: 0.3rem;
          }
          .ico {
            width: 1.2rem;
            height: 1.2rem;
            margin: 0.6rem auto 0.3rem;
          }
          h5 {
            font-size: 0.3rem;
            line-height: 1;
          }
        }
      }
    }
    .tend-npm2 {
      .box{
        margin-top: .5rem;
        padding: 0;
        flex-wrap: wrap;
        ul{
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: .84rem;
          &:after{
            height: 6rem;
            width: .6rem;
            background-size: 100% 100%;
            left: 0;
            right: 0;
            margin: -.5rem auto;
            top: 0;
            transform: rotate(90deg);
          }
          li{
            width: 12%;
            height: 2rem;
            padding: 0 .2rem;
            margin: 0;
            font-size: .26rem;
            line-height: .4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
        .middle{
          display: block;
          width: 100%;
          overflow: visible;
          .ico{
            width: 2rem;
            height: 2rem;
            margin: 0 auto;
          }
          .img{
            position: relative;
            z-index: 5;
            height: 3rem;
            width: 6.9rem;
            padding: 1.8rem 0 0;
            margin: -.8rem 0 0;
            background: no-repeat;
            font-size: .4rem;
            line-height: .6rem;
            &:after{
              position: absolute;
              left: 1.95rem;
              top: -1.5rem;
              content: "";
              display: block;
              width: 3rem;
              height: 6.9rem;
              background: url(https://www.huajiantong.com/images/digital/line14.png) no-repeat center;
              background-size: 100% 100%;
              transform: rotate(90deg);
              transform-origin: 50%;
              z-index: -1;
            }
          }
        }
        .below{
          margin-top: .5rem;
          width: 100%;
          height: auto;
          padding: .5rem .3rem .3rem;
          .tit{
            height: .6rem;
            line-height: .6rem;
            font-size: .3rem;
            margin-bottom: .3rem;
          }
          dl{
            dt{
              height: .6rem;
              line-height: .6rem;
              font-size: .24rem;
            }
          }
        }
      }
      .ul {
        margin-top: 0.5rem;
        .li {
          width: 0.7rem;
          .ico {
            width: 0.7rem;
            height: 0.7rem;
            font-size: 0.36rem;
            margin: 0 auto 0.1rem;
          }
          h5 {
            font-size: 0.3rem;
            line-height: 1.2;
          }
        }
        .i {
          width: 0.2rem;
          margin-top: 0.35rem;
        }
      }
    }
   .bim-npm1 {
      padding-top: 0.6rem;
      overflow: hidden;
      .tabbox {
        display: none;
        margin-top: 0.5rem;
        .item {
          font-size: 0.24rem;
          line-height: 0.6rem;
        }
      }
      .mobileTabs {
        display: block;
      }
      .cTabBd {
        height: auto;
        padding: 0.6rem .4rem;
        margin: 0.6rem 0;
        .liflex {
          flex-wrap: wrap;
        }
        .fl {
          width: 100%;
          order: 1;
        }
        .fr {
          order: 2;
          width: 100%;
          margin-top: 0.5rem;
          img {
            max-width: 100%;
          }
        }
      }
    }
    .tend-npm3 {
      ul {
        margin-top: 0.5rem;
        flex-wrap: wrap;
        li {
          width: 100%;
        }
      }
    }
    #tend-npm4 {
      height: 0.6rem;
    }
    .tend-npm4 {
      padding: 0.6rem 0;
      .fl {
        margin-top: 0;
        width: 100%;
        h5 {
          font-size: 0.4rem;
          line-height: 0.6rem;
        }
        ul {
          margin-top: 0.2rem;
          overflow: hidden;
        }
      }
      .fr {
        margin-top: 0.5rem;
        width: 100%;
        padding: 0.5rem 0.3rem;
        h5 {
          font-size: 0.4rem;
          line-height: 0.6rem;
        }
        .formcon {
          margin-top: 0.3rem;
        }
        .inp {
          height: 1rem;
          padding: 0 0.3rem;
          margin-bottom: 0.2rem;
        }
        .submit {
          height: 1rem;
          line-height: 1rem;
        }
      }
    }
  }
}
</style>
  
  