<template>
  <div class="wrap">
    <nav-ber :title="'老旧小区改造项目管理系统'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="residential">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item wow fadeInUp50" v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit wow fadeInUp50">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
            
            </div>
        </section>
           <section class="constru-npm3">
            <div class="w1440 ">
                <div class="pagetit wow fadeInUp50">
                    <h5>主要功能</h5> 
                </div>
                <ul class="wow fadeInUp50">
                    <li class="wow fadeInUp50"  v-for="item in apiData2" :key="item.newsId" >
                        <div class="txt">
                            <h5 v-html="item.newsTitle"></h5>
                            <div v-html="item.newsContent"></div>
                        </div>
                        <div class="ico">
                            <img class="p1" :src="item.newsAccess" alt="">
                            <img class="p2" :src="item.image" alt="">
                        </div>
                        <em>{{item.newsAuthor}}</em>
                    </li>
                  
                </ul>
            </div>
        </section>
      <section class="constru-npm2" v-if="apiData3[0]">
            <div class="w1440 ">
                <div class="pagetit ">
                    <h5 v-html="apiData3[0].newsTitle"></h5>
                </div>
                <div class="clear">
                    <div class="fl ">
                    
                    <div class="img">
                      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000" effect="fade">
        <van-swipe-item v-for="image in imageUrlStrings" :key="image">
           <img :src="  image" alt="">
        </van-swipe-item>
      </van-swipe>
          
                    </div>
                    </div>
                    <div class="fr ">
                        <div class="txt"> 
                            <div v-html="apiData3[0].newsContent"></div>
                        </div> 
                    </div>
                </div>
                
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]); 
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
});
const imageUrlStrings = ref([]);
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 539,
    typeId: 105,
  });
  const { data: data2 } = await getInfo({
    coId: 605,
  });
  const { data: data3 } = await getInfo({
    coId: 607,
  });
    const { data: data4 } = await getInfo({
    coId: 609,
  }); 

  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data; 
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent;
   imageUrlStrings.value = apiData3.value[0].image.split(',')
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.residential {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0;
             border-bottom: 1px solid #e3e5e8;
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
        }
        .constru-npm3{
     
                padding: .9rem 0;
                ul{
                    display: flex;
            justify-content: space-between;
                    flex-wrap: wrap;
                    margin-top: .4rem;
                    li{
                        width: 49%;
                        margin-bottom: 2%;
                        height: auto;
                        padding: .4rem .3rem;
                         background: #f3f4f7;
                         position: relative;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                overflow: hidden;
                      
                        .txt{
                            position: relative;
                    z-index: 3;
                            h5{
                                font-size: .26rem;
                                margin-bottom: .1rem;
                                 color: #333333;
                            }
                        }
                        em{
                            font-size: 2.3rem;
                            line-height: 2rem;
                            bottom:.3rem;
                            right: 0;
                            letter-spacing: -.1rem;
                             font-family: 'DIN-Bold';
                    background: linear-gradient(to right, #f1f3f6, #eaedf3);
                    -webkit-background-clip: text;
                    color: transparent;
                    position: absolute;
                        }
                    }
                }
        
        }
   .constru-npm2{
            padding: .9rem 0 .7rem;
            background: url(~@/assets/images/labor/bg3.jpg);
            background-position: center;
            background-size: cover;
            .soluslid{
              border-radius: 0;
            }
            .clear{
                margin-top: .45rem;
            }
            .fl{
                width: 100%;
                 position: relative;
                  :deep(.van-swipe__indicators){
                    bottom: .3rem;
                    i{
                        width: .52rem;
                        height: .04rem;
                        border-radius: 0;
                        background: #a6a6a6;
          opacity: 1;
                         &.van-swipe__indicator--active{
            background: #d7000f;
          }
                    }
                }
                .img{
                    margin: 0;
                }
            }
            :deep(.fr){
                width: 100%;
                .txt{
                    h5{
                        font-size: .26rem;
                        margin-bottom: .15rem;
                        color: #333333;
                    }
                }
                ul{
                    margin-top: .5rem;
                    justify-content: space-between;
                    li{ 
                       width: 100%;
                       margin-right: 0; 
                       padding: .25rem 0;
                       font-size: .24rem;
                       color: #477a89;
                        border-bottom: 1px solid #c2d5dd;
                        &:last-child{
                        border-bottom: none;
                    }
                    }
                }
            
            }
        }
}
</style>
  
  