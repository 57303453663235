<template>
  <div class="wrap">
    <nav-ber :title="'项目成本管理'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>项目成本管理</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/costPic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>合同管理</h4>
                <div class="line"></div>
                <p>详细记录企业项目的各方合同信息，以便管理人员进行快速查询与统计。包括合同签订、合同执行、合同变更、合同结算等纳入过程化、规范化管理，实现合同的建档、审批会签、归档全过程履约资料集中化管理。通过内置合同风险库、合同范本核心助力合同签约过程中风险识别，降低签约合同风险。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/costPic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>合同收付款</h4>
                <div class="line"></div>
                <p>帮助企业实现收付款计划、收付款记录的管理，提高财务管理和整体运营效率，实现所有场景内的支付结算。可以对企业的收付款进行自动化管理，包括账户管理、清结算、风控管理和金融服务等功能。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/costPic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>签证变更</h4>
                <div class="line"></div>
                <p>对企业的工程签证变更进行管理，形成签证变更流程，包括每个项目的签证数量、工程签证扫描件、文件电子原件管理、支持签证日期查找、签证摘要关键字查找功能等多项功能。让签证变更数据及时准确，无需反复核算，核量更精准。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/costPic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>结算管理</h4>
                <div class="line"></div>
                <p>根据实际施工情况，通过人工或计算机辅助工具对工程项目的各个部分进行工程量计算，将各项费用汇总后，进行最终的费用结算，生成结算报告、结算凭证及结算报表，为项目的审计提供依据提高工程项目的管理效率和精度，避免因为费用核算不准确而导致的纠纷和损失。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/costPic5.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4>成本测算</h4>
                <div class="line"></div>
                <p>输入相关的项目成本信息，包括人工费用、材料费用、设备租赁费用等。系统会根据输入的数据自动计算并生成详细的成本预算报告。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/costPic6.jpg" alt="" />
                <div class="num">06</div>
              </div>
              <div class="txt">
                <h4>供应商管理</h4>
                <div class="line"></div>
                <p>集中管理供应商信息，对供应商的表现进行评估和打分，识别和管理潜在的供应商风险，对供应商数据进行统计和分析，生成各类供应商相关的报告和图表，提高供应链的协同效率、减少风险。</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .82rem 0 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}

</style>

