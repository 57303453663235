<template>
  <div class="wrap">
    <nav-ber :title="'城市运行管理中心'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="cityit">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item " v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit ">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
            
            </div>
        </section>
         <section class="constru-npm2">
            <div class="w1440">
              
                <div class="img wow fadeInUp50">
                    <img :src=" apiData1[0].image" alt="">
                </div>
            </div>
        </section>
    
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
}); 
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 477,
    typeId: 117,
  });
  const { data: data2 } = await getInfo({
    coId: 495,
  });


  apiData.value = data1.data;
  apiData1.value = data2.data;
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent; 
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.cityit {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0 0; 
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
        }
        
   .constru-npm2{
                padding-bottom: .9rem;
                .img{
                    margin-top: .4rem;
                }
             }
          
}
</style>
  
  