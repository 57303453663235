import { useRouter } from 'vue-router';
export function usePublic() {
    const router = useRouter();
    // 跳转
    function skipLink(str){
        router.push(str)
    }
    return {
        skipLink
    }
}

// app跳转链接
export function skipOutsideChain(url){
    let aa = url.trim();
    window.location.href = `bridge:/webview/open/pageInit?url=${encodeURIComponent(aa)}`
}