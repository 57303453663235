<template>
  <div class="wrap">
    <nav-ber :title="'智慧展厅解决方案'"></nav-ber>
    <main>
      <!-- <div class="topBox">
        <h2>建筑施工阶段全场景解决方案</h2>
        <h4>打造智能、安全、高效的工地管理体系</h4>
        <h5>
          Full-scenario solution <br />
          for the construction stage
        </h5>
      </div> -->
      <div class="content">
        <section class="serve">
          <h2 class="cTil"><em>智慧展厅</em></h2>
          <div class="ul">
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/exhibitionPic1.jpg" alt="" />
                <div class="num">01</div>
              </div>
              <div class="txt">
                <h4>调度指挥中心</h4>
                <div class="line"></div>
                <p>调取系统所有的运行与统计数据，管理者能够实现对整个工地的全面监控和管理，为工地管理者提供实时的数据支持和决策依据。还可以与外部系统集成，实现与其他相关系统的信息共享和交互，进一步提高整个工程项目的管理水平和效率</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/exhibitionPic2.jpg" alt="" />
                <div class="num">02</div>
              </div>
              <div class="txt">
                <h4>企业文化展厅</h4>
                <div class="line"></div>
                <p>展示企业核心价值观和企业文化，包括企业的发展历程、经营理念、产品特色以及服务承诺等信息，深入了解企业的文化内涵和品牌形象。企业文化展厅是企业与客户、合作伙伴等建立联系的重要场所，通过参观展厅，参观者可以更加直观地感受到企业的实力和信誉，从而建立起更加紧密的关系。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/exhibitionPic3.jpg" alt="" />
                <div class="num">03</div>
              </div>
              <div class="txt">
                <h4>安全防范展厅</h4>
                <div class="line"></div>
                <p>通过现场设置的体验互动项目让参观者学习到各种自救知识和技能，对参观者进行安全知识教育，提高安全防患意识，更加深入地了解危险因素和应对方法，提高安全意识和应急能力</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/exhibitionPic4.jpg" alt="" />
                <div class="num">04</div>
              </div>
              <div class="txt">
                <h4>党建展厅</h4>
                <div class="line"></div>
                <p>通过结合先进的信息技术手段和党建教育资源，为用户提供了一个全新的、互动性强的党建学习和交流平台，推动党员干部的思想理论学习、党史学习和党建工作的深入开展，促进党的事业不断发展壮大。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/exhibitionPic5.jpg" alt="" />
                <div class="num">05</div>
              </div>
              <div class="txt">
                <h4>3D打印</h4>
                <div class="line"></div>
                <p>展示3D打印技术，具有环保、成本低、速度快、制作精准等特点施工过程中的重点及复杂节点，建筑整体模型、特殊结构使用高端树脂材料通过3D打印手段的新方式将其实体打印成型，对探索施工工艺起到先知先觉的功效。</p>
              </div>
            </router-link>
            <router-link to="" class="li">
              <div class="pic">
                <img src="~@/assets/images/engineering/exhibitionPic6.jpg" alt="" />
                <div class="num">06</div>
              </div>
              <div class="txt">
                <h4>BIM应用展厅</h4>
                <div class="line"></div>
                <p>BIM技术应用展厅可进行实时演示和模拟展示，将建筑项目的设计、施工、装修等不同阶段的过程呈现；了解建筑项目从概念到竣工的整个生命周期，深入了解设计决策、协调与碰撞检测、进度管理等关键环节。</p>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);
</script>

<style scoped lang="scss">
.wrap {
  padding-bottom: 0;
}
main {
  padding: .82rem 0 0;
  background: #f8f8f8;
}
.topBox {
  height: 3.77rem;
  padding: 1.2rem 0.4rem 0;
  background: url(~@/assets/images/engineering/bimTopBg.jpg) no-repeat top
    center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    background: linear-gradient(to right, #d9d9d9, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.22rem;
    line-height: 0.36rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0.04rem;
  }
  h5 {
    font-size: 0.24rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  background: #fff;
}
.serve{
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/engineering/bimServeBg.jpg) repeat-x;
  background-size: .4rem;
}

.li{
  padding: .2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: .3rem;
  .pic{
    position: relative;
    overflow: hidden;
    border-radius: .24rem .24rem 0 0;
    .num{
      position: absolute;
      right: .2rem;
      bottom: .16rem;
      font-size: .76rem;
      color: #fff;
      font-family: 'Gilroy-Bold';
      line-height: 1;
    }
  }
  .txt{
    min-height: 3.2rem;
    padding: .5rem .3rem;
    border-radius: 0 0 .24rem .24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4{
      font-size: .28rem;
      color: #000;
      line-break: .38rem;
    }
    .line{
      width: 1.06rem;
      height: .06rem;
      margin: .13rem 0 .15rem;
      background: #e1e1e1;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: .14rem;
        background: #f70000;
      }
    }
  }
}

</style>

