<template>
  <div class="wrap">
    <nav-ber :title="'模块标题'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="optimal">
      <section class="opti-npm1" >
      <div class="w1440" v-for="item in apiData" :style="`background-image:url(${      item.image}) ;`">
        <div class="txt ">
          <h5 v-html="item.newsTitle"></h5>
          <div class="p" v-html="item.newsContent"> 
          </div>
          <a :href="item.address" class="more" target="_blank">立即体验</a>
        </div>
      </div>
    </section>
    <section class="opti-npm2">
      <div class="w1440">
        <div class="above" v-for="item in apiData1">
          <div class="fl" >
              <img :src="      item.newsAccess" alt="">
          </div>
          <div class="fr">
            <div class="txt" >
              <h5 v-html="item.newsTitle"></h5>
              <div class="p" v-html="item.newsContent">
                
              </div>
            </div>
            <a href="#jump" class="more " >了解详情</a>
          </div>
        </div>
        <div class="below">
          <div class="ul">
            <div class="li " v-for="item in apiData2" :key="item.newsId" >
              <div class="i"><img :src="      item.newsAccess" alt=""></div>
              <div class="txt">
                <h5 v-html="item.newsTitle"></h5>
                <p v-html="item.newsContent"></p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </section>
    <section class="opti-npm3">
      <div class="w1440">
        <div class="pagetit ">
          <h5>华建劳务优选平台</h5> 
        </div>
        <div class="box clear">
          <div class="fl" ref="scrollContainer">
            <div class="item "  @click="handleClick(item, index)"
            :class="{ on: activeIndex === index }" v-for="(item,index) in apiData3" :key="item.newsId">
              <div class="i"><img :src="      item.newsAccess" alt=""></div>
              <h5 v-html="item.newsTitle"></h5>
            </div>
        
          </div>
          <div class="fr" :style="`background-image:url(${      apiData3[1].image}) ;`">
            <div class="item fadeInUp50 animated" v-for="(item,index) in apiData3" :key="item.newsId" v-show="selectedItemIndex === index - 1">
              <div class="txt">
                <div class="p" v-html="item.newsContent">
                </div>
              </div>
            </div>
      
          </div>
        </div>
      </div>
    </section>
       <section class="opti-npm4">
      <div id="jump"></div>
      <div class="w1440">
        <div class="pagetit ">
          <h5>服务介绍</h5> 
        </div>
        <div class="ul">
          <div class="li clear " v-for="item in apiData4" :key="item.newsId">
            <div class="fl">
              <img :src="      item.image" alt="">
            </div>
            <div class="fr">
              <h5 v-html="item.newsTitle"></h5>
              <div class="p" v-html="item.newsContent">
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
    <section class="opti-npm5">
      <div class="pagetit wow fadeInUp50">
          <h5>采购标讯</h5> 
        </div>
      <div class="w1440">
        <van-swipe
  class="soluslid"
  :autoplay="4000"
  vertical="true"
  :loop="true"
  duration="700" 
>
  <van-swipe-item v-for="item in formattedApiData" :key="item.newsId">
    <a
      :href="item.address"
      target="_blank"
    >
       <div class="above clear">
          <div class="fl">
            <span>{{ formatDate(item.newsAddTime) }}</span>
            <h5 v-html="item.newsTitle"></h5>
            <div class="em">
              <em v-for="tagDetail in item.formattedTags" :key="formattedTags">{{ tagDetail }}</em>
            </div>
          </div>
          <div class="fr">{{item.email}}</div>
        </div>
        <div class="below clear">
          <div class="fl" v-html="item.newsContent">
          </div>
          <div class="fr" v-html="item.name">
          </div>
        </div>
    </a>
  </van-swipe-item>
</van-swipe>
      </div>
    </section>
      <section class="opti-npm6">
      <div class="pagetit wow fadeInUp50">
          <h5>劳务金融借支讯息</h5> 
        </div>
      <div class="w1440">
       <van-swipe
  class="soluslid"
  :autoplay="4000"
  vertical="true"
  :loop="true"
  duration="700" 
>
  <van-swipe-item  v-for="item in apiData6" >
   <div class="above clear">
          <div class="fl"> 
            <h5  v-html="item.newsTitle"></h5> 
          </div>
          <div class="fr">
            <div class="it1" >手机号：<em v-html="item.phone"></em></div>
            <div class="it1">身份证号：<em v-html="item.email"></em></div>
          </div>
        </div>
  </van-swipe-item>
</van-swipe>
      </div>
    </section>
    </main>
    
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getInfo } from "@/api/http";
import { ref, computed, onUpdated,onMounted } from 'vue';
const activeIndex = ref(0);
const scrollContainer = ref(null);
const selectedItemIndex = ref(-1);
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const apiData6 = ref([]);
const getList = async () => {
  const { data:data1 } = await getInfo({
    coId: 889,
  });
const { data:data2 } = await getInfo({
    coId: 893,
  });
  const { data:data3 } = await getInfo({
    coId: 895,
  });
  const { data:data4 } = await getInfo({
    coId: 897,
  });
   const { data:data5 } = await getInfo({
    coId: 899,
  });
  const { data:data6 } = await getInfo({
    coId: 981,
  });
 const { data:data7 } = await getInfo({
    coId: 983,
  });
  apiData.value =data1.data;
  apiData1.value =data2.data;
  apiData2.value =data3.data;
   apiData3.value =data4.data;
   apiData4.value =data5.data;
   apiData5.value =data6.data;
apiData6.value =data7.data;

  // console.log(apiData5)
};
 
// 计算滚动位置并滚动
const scrollToActiveItem = () => {
  if (activeIndex.value !== null && scrollContainer.value) {
    const activeItem = scrollContainer.value.querySelector('.item.on');
    if (activeItem) {
      const actWinValue = activeItem.offsetWidth;
      scrollContainer.value.scrollTo({
        left: activeIndex.value * actWinValue - 50,
        behavior: 'smooth',
      });
    }
  }
};

// 处理点击事件
const handleClick = (item, index) => {
  activeIndex.value = index;
  selectedItemIndex.value = index - 1;
  console.log(index,"fff")
  scrollToActiveItem();
};
const formatNewsTags = (newsTags) => {
  if (newsTags) {
    // 分割字符串，去除前后空白，并返回处理后的新数组
    return newsTags.split('|').map(tag => tag.trim());
  }
  return [];
};
 const formattedApiData = computed(() => {
  return apiData5.value.map(item => { 
    // 直接调用formatNewsTags方法来处理item的newsTags
    item.formattedTags = formatNewsTags(item.newsTags);
    return item;
  });
});
function formatDate(dateStr) {
  return dateStr.split(' ')[0].replace(/-/g, '.');
}
// 在数据更新后重新计算滚动位置
onUpdated(scrollToActiveItem);
onUpdated(() => {
  
console.log( formattedApiData.value,"formattedApiData")
});
getList();
</script>
  
<style scoped lang="scss">
.optimal{ 
  h5,h6{
    line-height: 1.1;
  }
  .w1440{
    padding: 0 .3rem;
  }
    .more{
      border-radius: 1rem;
    color: #fff;
    line-height: 0.8rem;
    width: 1.8rem;
    font-size: 0.24rem; 
    background: #d7000f;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    }
     .pagetit{
    h5,h6{
      text-align: center;
    }
  }
    #jump{
      height: 1.45rem;
    }
    .opti-npm1{
height: 4.5rem;
a{
      border-radius: 1rem;
    height: 0.8rem;
    margin-top: 0.5rem;
    font-size: 0.24rem;
    line-height: 0.8rem;
    width: 1.8rem;
}
  .w1440{
     position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      
 background-position: center;
    background-size: cover;
     :deep(.txt){ 
        h5{
           color: #fff;
              font-size: 0.3rem;
        }
        .p{
          margin: .01rem 0 0;
          h6{
                font-size: .26rem;
    margin:.1rem 0 .2rem;
     color:#fff;
          }
          p{
            font-size: .24rem;
            line-height: .32rem;
            color: #fff;
          }
        }
      }
    }
    }
   .opti-npm2{
    padding: .9rem 0;
    .above{
      display: block;
      overflow: hidden;
      .fl{
        width: 100%;
         img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      }
      .fr{
        width: 100%;
        padding: .45rem 0 ;
         border-bottom: 1px solid #e6e7eb;
        h5{
          font-size: 0.26rem;
          margin-bottom: .15rem;
        }
        p{
          margin-bottom: .1rem;
        }
        .txt{
          margin-bottom: .6rem;
        }
      }
    }
    .below{
      .ul{
        margin-top: .6rem;
        display: block;
        .li{
          width: 100%;
          margin-bottom: .2rem;
          padding: 0 .3rem;
          min-height: 2rem;
           display: flex;
          align-items: center;
          background: #fff;
          box-shadow: 0 0 20px 0 rgba(65, 94, 133, 0.12);
          p{
            line-height: .32rem;
          }
          .i{
            margin-right: .2rem;
            width: .4rem;
          }
          .txt{
            width: calc(100% - .6rem);
            h5{
              font-size: .26rem;
              margin-bottom: .05rem;
            }
          }
        }
      }
    }
   }
   .opti-npm3{
    padding-top: .9rem;
    height: auto;
    background: #f3f4f7;
    .box{
      height: auto;
      margin-top: .35rem;
      margin-bottom: 0;
      overflow: hidden;
      .fl{
        width: 100%;
            display: -webkit-box; 
             background: #fff;
    padding:0 .2rem ;
    overflow-x: scroll;
    overflow-y: hidden;
  
    .item{
      padding: .2rem; 
      border-bottom: 1px solid  #e6e7eb;
      display: flex;
          align-items: center;
          cursor: pointer;
           &.on{
            h5{
              color: #d7000f;
            }
          }
      .i{
        width: .72rem;
        margin-right: .2rem;
      }
      h5{
        font-size: .24rem;
        width: 100%;
         color: #808080;
      }
    }
      }
      .fr{
        width: 100%;
        padding: .2rem .3rem; 
        height: 4.5rem;
          background-position: center;
        background-size: cover;
        .item{ 
          height: 100%;
        }
        .txt{
          display: flex;
          align-items: center;
          height: 100%;
          :deep(.p){
            p{
              font-size: .24rem;
              line-height: .32rem;
              padding-left: .13rem;
              margin-bottom: .1rem;
              position: relative;
            color: #fff;
              &::after{
                width: .06rem;
                height: .06rem;
                top: .15rem;
              }
            }
          }
        }
      }
    }
   }
   .opti-npm4{
        padding: 0 0 .9rem;
    .ul{
      margin-top: .4rem; 
display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .li{
        display: block;
            border: 1px solid #e1e2e6;
            margin-bottom: .2rem;
            min-height: 2%;
            width: 49%;
        .fl{
          width: 100%;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .4s;
          }
        }
        .fr{
          width: 100%;
          padding: .3rem .1rem;
          border: none;
          h5{
            font-size: .26rem;
            margin-bottom: .15rem;
          }
        }
      }
    }
   }
   .opti-npm5{
        padding: .9rem 0 .85rem;
        background: url(~@/assets/images/labor/bg2.jpg);
    background-position: center;
    background-size: cover;
        .w1440{
          width: calc(100% - .6rem);
           background: #fff;
          margin:.35rem auto 0;
          padding: .4rem .2rem;
          height: 5rem;
        }
        .van-swipe-item{
          border: 1px solid #e8eaed;
        }
         :deep(.van-swipe__indicators){
          display: none;
        }
        .soluslid {
          height: 100%;
          border-radius: 0;
          a{
            padding: .2rem;
          }
          :deep(.above){
            padding-bottom: .25rem;
            position: relative;
            border-bottom: 1px solid #e8eaed;
            overflow: hidden;
            .fl{
             width: 100%;
             
            }
            .fr{
             position: absolute;
             background: #d7000f;
              text-align: center;
             right: 0;
             top: .2rem;
                  border-radius: 1rem;
    color: #fff;
    line-height: 0.8rem;
    width: 1.8rem;
    font-size: 0.24rem;
            }
            span{
              font-size: .26rem;
              line-height: 1;
              color: #999999;
            }
            h5{
               font-size: .26rem;
                   margin: .05rem 0 .15rem;
                    width:calc(100% - 2rem);
                     font-weight: bold;
        white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
            }
            em{
              font-size: .24rem;
                  padding: .04rem .08rem;
                  margin-right: .1rem;
                  display: inline-block;
        background: #d7effc;
        color: #74b2d4;
        line-height: 1;
            }
          }
          .below {
            padding-top: .2rem;
            .fl{
              font-size: .24rem;
              line-height: .32rem;
              width: 100%;
              color: #808080;
            }
            .fr{
              width: 100%;
              font-size: .24rem;
               color: #028df0;
            }
          }
        }
   }
   .opti-npm6{
    padding: .9rem 0;
      :deep(.van-swipe__indicators){
          display: none;
        }
    .w1440{
      margin-top: .3rem;
    }
    .soluslid{
      height: 2rem;
       border-bottom: 1px solid #e8eaed;
       border-radius: 0;
    }
 
    .van-swipe-item{
      padding: .25rem;
      .fl{
        width: 100%;
        h5{
          font-size: .26rem;
          padding-left: .1rem;
          margin-bottom: .1rem;
           font-weight: bold;
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
          &::after{
             content: '';
          position: absolute;
          border-radius: 50%;
          background: #d7000f; 
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
            width: .05rem;
            height: .05rem;
          }
        }
      }
      .fr{
        width: 100%;
        .it1{
          font-size: .24rem;
          line-height: 1.5;
        }
      }
    }
   }
  }
</style>
  
  