<template>
  <div class="cList">
    <slot></slot>
    <div class="li" v-for="(item, index) in list" :key="index">
      <div class="pic" :style="`background-image: url(${$store.state.localHttp + item.image})`"></div>
      <div class="txt">
        <h4>{{item.newsTitle}}</h4>
        <div class="p" v-html="item.newsContent"></div>
        <div class="btn" v-if="!item.address" @click="comingSoon">
          <em>{{ item.btnTxt || '敬请期待' }}</em>
        </div>
        <div class="btn" v-else-if="item.isTop" @click="toWeChat(item.address)">
          <em>{{ item.btnTxt || '前往专区' }}</em>
        </div>
        <div class="btn" v-else @click="toFn(item.address)">
          <em>{{ item.btnTxt || '前往专区' }}</em>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { skipOutsideChain } from '@/api/public';
import { showDialog } from 'vant';
import { defineProps } from "vue";
import { useRouter } from 'vue-router';
const router = useRouter();
const props = defineProps({
  list: Array,
});
function toWeChat(url){
  const appIdMatch = url.match(/appId=(.*?)(?=&|$)/);
  const pubAppIdMatch = url.match(/pubAppId=(.*?)(?=&|$)/);
  const cleanedUrl = url.replace(/&?(appId|pubAppId)=[^&]*/g, '');
  let link = encodeURIComponent(cleanedUrl)
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${link}&${appIdMatch[0]}&${pubAppIdMatch[0]}`
}
// 敬请期待
function comingSoon(){
  showDialog({
    title: '即将开放，敬请期待'
  }).then(() => {
    // on close
  });
}
// 跳转方法
const toFn = (url) => {
  let one = url.trim().charAt(0);
  if(one == '/'){
    router.push(url)
  }else{
    skipOutsideChain(url)
  }
}
</script>
<style scoped lang="scss">
.li {
  padding: 0.2rem;
  background: #fff;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 0.3rem;
  .pic {
    position: relative;
    overflow: hidden;
    height: 3.93rem;
    background: no-repeat center;
    background-size: cover;
    border-radius: 0.24rem 0.24rem 0 0;
    .num {
      position: absolute;
      right: 0.2rem;
      bottom: 0.16rem;
      font-size: 0.76rem;
      color: #fff;
      font-family: "Gilroy-Bold";
      line-height: 1;
    }
  }
  .txt {
    overflow: hidden;
    min-height: 3.2rem;
    padding: 0.5rem 0.3rem;
    border-radius: 0 0 0.24rem 0.24rem;
    background: linear-gradient(to bottom, #f3f3f3, #eaebeb);
    h4 {
      font-size: 0.28rem;
      color: #000;
      line-height: 0.38rem;
      margin-bottom: .1rem;
    }
    .p{
      font-size: .22rem;
      line-height: .36rem;
      color: #646464;
      p{
        font-size: .22rem;
        line-height: .6rem;
        color: inherit;
      }
    }
    .btn {
      float: left;
      margin-top: 0.3rem;
      height: 0.54rem;
      line-height: 0.54rem;
      padding: 0 0.24rem;
      background: #f70000;
      border-radius: 0.1rem;
      font-size: 0;
      em {
        font-size: 0.22rem;
        color: #fff;
        padding-right: 0.22rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC)
          no-repeat right center;
        background-size: 0.08rem;
      }
    }
  }
}
</style>
  
  