<template>
  <div class="wrap">
    <nav-ber :title="'完整社区信息系统'"></nav-ber>
    <main class="community">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item " v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit ">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
         
            </div>
        </section>
         
      <section class="constru-npm2" v-if="apiData2[0]">
            <div class="w1440">
                <div class="pagetit ">
                    <h5 v-html="apiData2[0].newsTitle"></h5> 
                   
                </div>
                <div class="img ">
                    <img :src=" apiData2[0].image" alt="">
                </div>
            </div>
        </section>
          <section class="constru-npm3" v-if="apiData3[0]">
            <div class="w1440">
                <div class="clear">
                    <div class="fl">
                        <div class="pagetit ">
                            <h5 v-html="apiData3[0].newsTitle"></h5> 
                   
                </div>
                    </div>
                    <div class="fl">
                        <img :src=" apiData3[0].image" alt="">
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]); 
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 539,
    typeId: 113,
  });
  const { data: data2 } = await getInfo({
    coId: 627,
  });
  const { data: data3 } = await getInfo({
    coId: 629,
  });
    const { data: data4 } = await getInfo({
    coId: 631,
  }); 

  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data; 
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent;
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.community {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0; 
               border-bottom: 1px solid #e3e5e8;
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
        
        }
       
 .constru-npm2{
                padding: .9rem 0;
              
                .img{
                    margin-top: .3rem;
                }
             }
                .constru-npm3{
                    background: url(~@/assets/images/labor/bg3.jpg);
            background-position: center;
            background-size: cover;
                    padding: .9rem 0 0;
                    .fl{
                     &:nth-child(1){
                        width: 100%;
                        margin-bottom: .35rem;
                     }   
                    }
             }
}
</style>
  
  