<template>
  <div class="wrap">
    <nav-ber :title="'全部分类'"></nav-ber>
    <main class="classify">
      <div class="homeSeek">
        <input type="text" placeholder="请输入你想要的内容" v-model="value" />
        <div class="clear" @click="clearIpt" v-show="value.length > 0"></div>
        <div class="btn" @click="seekFn"></div>
      </div>
      <div class="typeSelect">
        <div class="hd">
          <div :class="['li', {'on': acitve == 0}]" @click="cutFn(0)">全部类型</div>
          <div :class="['li', {'on': acitve == item.typeId}]" @click="cutFn(item.typeId)" v-for="item in typeArr" :key="item.typeId">{{ item.typeName.slice(0,4) }}</div>
        </div>
        <div class="bd">
          <h3 class="til">{{ typeName }}</h3>
          <van-loading vertical  type="spinner" color="#1989fa" v-show="listLoad">
            加载中...
          </van-loading>
          <div class="ul">
            <router-link :to="{path: `/facility/${item.newsId}`}" class="li" v-for="item in list" :key="item.newsId">
              <div class="pic"><img :src="item.image" alt=""></div>
              <div class="p">{{item.newsTitle}}</div>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref, watch } from 'vue';
import { useRoute } from "vue-router"; 
import { getEquipmentTypes, getEquipment } from '@/api/http';
const route = useRoute();
const list = ref([]);
const typeArr = ref([]);
let acitve = ref(0);
const value = ref('');
const listLoad = ref(false);
const typeName = ref('全部类型');
let IntTime = null;
async function cutFn (id){
  if(!typeArr.value.length && !IntTime){
    return IntTime = setInterval(function(){
      cutFn(id);
    },100)
  }
  clearInterval(IntTime)
  let obj = typeArr.value.filter(n => n.typeId == id);
  if(obj.length){
    typeName.value = obj[0].typeName;
  }else{
    typeName.value = '全部类型';
  }
  acitve.value = id;
  list.value = [];
  listLoad.value = true;
  const { data } = await getEquipment({
    key: value.value,
    pageIndex: 1,
    pageSize: 200,
    typeId: id
  })
  list.value = data.data;
  listLoad.value = false;
}
const getTypes = async () => {
    const { data } = await getEquipmentTypes();
    typeArr.value = data.data;
    console.log('arr', typeArr.value)
}

getTypes();
// 首次进来加载
let oneId = route.query.typeId - 0;
cutFn(oneId)

// 清空搜索框
function clearIpt(){
    value.value = ""
    seekFn();
}

const seekFn = async () => {
  list.value = [];
  listLoad.value = true;
  const { data } = await getEquipment({
    key: value.value,
    pageIndex: 1,
    pageSize: 200,
    typeId: acitve.value
  })
  list.value = data.data;
  listLoad.value = false;
  console.log(data)
}
</script>
<style scoped lang="scss">
.wrap{
  height: 100vh;
}
.classify {
  position: relative;
  padding: 1.2rem 0 0;
  height: 100%;
}

.homeSeek {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0.2rem .4rem;
  border-radius: .8rem;
  overflow: hidden;
  border: 0.03rem solid #fff;
  box-shadow: 0 0 0.4rem rgba(206, 190, 189, 0.3);
  input {
    border-radius: 0.27rem;
    border: 0 none;
    width: 100%;
    height: .8rem;
    padding: 0.2rem 1.8rem 0.2rem 0.5rem;
    line-height: 0.6rem;
    font-size: 0.24rem;
    color: #666;
    background: #f4f4f4;
    box-shadow: inset 0 0 0.2rem rgba(185, 171, 171, 0.2);
  }
  .clear{
    position: absolute;
    right: 1.1rem;
    top: .1rem;
    width: 0.6rem;
    height: 0.6rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAACHklEQVQ4jZ3Uy4vNcRjH8dfMKJdyL8NmNggxLkORu43LipVSFnJbCGWFYuQWImHhMvJHWLgnGwsiuYVxT64bE+MaY/TkOXU6nTOGp06d+v6+7+/n8/k+z7eqvqG+3Z86jF146d+qB+ZgC8bU1A6qrcIQzEA3XMOXTiKrMR2NGI+LAXyIDxiNqeiFO/jYCdg8bMVEnMamALbiEb4ldGYqjYNaKsBifT42YBLOYSeuBjA++Iq7+IRxab8Gt9FaAovM5mJ9KrsQynAlFgvAqB9oTqsNRZleL8o08p6FzQk7nypvor0UGPUdzxMwFlPQN08PF7OxLWFnUmU4aysASoES1lyU6bRUWoc1mIyz2JHq24s3d6kQ+ns0peJ1WFsQkLcZlm+U21hOYXGmD9LqCPTDZazG/VJlhaquRMsNHxM0MC8k/nctzuxfFPbHUixP+JvMMeBv8aLcpkoZ9sFibM9vmrL5l2AhuuNzTtTPzihckSPVG4fydzmza8gRrct2aukIWJ2hb0zL+3ECj1NJ2H6CkfkoDM0We10OGIEvy5boiSPYh2dFB7YlPDIclj06AK8KmRYyDGuLcgriFo9hN95VyPhk9ugBLEhOzPy9gsKV2fkBPoij+dCW7bWsWL+FCTmig+MtDeAerMrM4sTj+XR1BIuKTAMaGUbjR6bDAxjPT8xq2Nxbktnf6heeZqaj4m0M73HtlxIY4f5PnUItGn8DqkWLjjH+3jQAAAAASUVORK5CYII=) no-repeat center;
    background-size: .2rem;
  }
  .btn {
    position: absolute;
    right: .2rem;
    top: 0;
    width: .8rem;
    height: .8rem;
    background: url(~@/assets/images/iconSeek.png) no-repeat center;
    background-size: 0.36rem;
  }
}

.typeSelect{
  position: relative;
  height: 100%;
  .hd{
    position: absolute;
    left: 0;
    top: .2rem;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    width: 2rem;
    background: #f9f9f9;
    border-radius: 0 .5rem 0 0;
    padding: .3rem .2rem;
    .li{
      height: .6rem;
      line-height: .6rem;
      border-radius: .6rem;
      color: #999;
      font-size: .28rem;
      padding-left: .24rem;
      margin-bottom: .4rem;
      &.on{
        color: #fff;
        background: linear-gradient(to bottom, #ca2d00, #ec3d22);
      }
    }
  }
  .bd{
    padding: 0 .4rem 0 2.3rem;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .til{
      padding: .1rem 0 .2rem;
      font-size: .24rem;
      color: #333;
      line-break: .44rem;
    }
    .ul{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .li{
      width: 2.3rem;
      height: 2.3rem;
      padding: .18rem;
      margin-bottom: .18rem;
      border-radius: var(--border-radius);
      background: #f9f9f9;
      .pic{
        height: 1.54rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      .p{
        padding-top: .05rem;
        font-size: .24rem;
        color: #333;
        line-height: .34rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
}
</style>
  
  