<template>
  <div class="wrap">
    <nav-ber :title="'建筑机器人'"></nav-ber>
    <main class="facility">
      <div class="bd">
        <div class="cntWrap">
          <van-swipe class="banner" :autoplay="3000" indicator-color="#f70000">
            <van-swipe-item>
              <router-link to="">
                <img src="~@/assets/images/facility/facilityBan2.jpg" alt="" />
                <div class="txt t1">
                  <h2>匠心铸就,工程利器</h2>
                  <h2>助力建设新时代！</h2>
                  <h3>Craftsmanship, engineering<br>
                  toolsHelp build a new era!</h3>
                </div>
              </router-link>
            </van-swipe-item>
            <!-- <van-swipe-item>
              <router-link to="">
                <img src="~@/assets/images/facility/facilityBan1.jpg" alt="" />
                <div class="txt">
                  <h2>匠心铸就,工程利器</h2>
                  <h2>助力建设新时代！</h2>
                </div>
              </router-link>
            </van-swipe-item>
            <van-swipe-item>
              <router-link to="">
                <img src="~@/assets/images/facility/facilityBan1.jpg" alt="" />
                <div class="txt">
                  <h2>匠心铸就,工程利器</h2>
                  <h2>助力建设新时代！</h2>
                </div>
              </router-link>
            </van-swipe-item> -->
          </van-swipe>
          <div class="btnList">
            <router-link :to="toClassify('基础处理类')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem1.png" alt=""></div>
              <div class="p">基础处理类</div>
            </router-link>
            <router-link :to="toClassify('墙面打磨类')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem2.png" alt=""></div>
              <div class="p">墙面打磨类</div>
            </router-link>
            <router-link :to="toClassify('地库施工类')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem3.png" alt=""></div>
              <div class="p">地库施工类</div>
            </router-link>
            <router-link :to="toClassify('搬运辅助类')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem4.png" alt=""></div>
              <div class="p">搬运辅助类</div>
            </router-link>
            <router-link :to="toClassify('装饰装修类')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem5.png" alt=""></div>
              <div class="p">装饰装修类</div>
            </router-link>
            <router-link :to="toClassify('通用功能类')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem6.png" alt=""></div>
              <div class="p">通用功能类</div>
            </router-link>
            <router-link :to="toClassify('其他类')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem7.png" alt=""></div>
              <div class="p">其他类</div>
            </router-link>
            <router-link :to="toClassify('全部类型')" class="li">
              <div class="icon"><img src="~@/assets/images/facility/facilityTypeItem8.png" alt=""></div>
              <div class="p">全部类型</div>
            </router-link>
          </div>
          <div class="typeColumn">
            <div class="item" v-for="item in typeArr" :key="item.typeId">
              <h3 class="cTil"><em>{{item.typeName}}</em></h3>
              <div class="bd">
                <router-link :to="{path: `/facility/${sub.newsId}`}" class="li" v-for="sub in item.children" :key="sub.newsId">
                  <div class="pic"><img :src="sub.image" alt=""></div>
                  <div class="p">{{ sub.newsTitle }}</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { ref, computed, onMounted } from 'vue';
import { getEquipmentTypes, getEquipment } from '@/api/http';
import { skipOutsideChain } from '@/api/public';
const active = ref(0);
const active2 = ref(0);
const actWid = ref(0);
const actLeft = ref(0);
const typeArr = ref([]);
const list = ref([]);
const tabLen = ref(0);
const line = ref('line');

const getTypes = async () => {
  try{
    const { data } = await getEquipmentTypes();
    typeArr.value = data.data;
    const requestArr = typeArr.value.map(item => {
      return getEquipment({
        pageIndex: 1,
        pageSize: 4,
        typeId: item.typeId
      })
    })
    const projectResponses = await Promise.all(requestArr);
    typeArr.value.forEach((item, idx) => {
      if(projectResponses[idx]){
        item.children = projectResponses[idx].data.data
      }
    })
    console.log('arr', typeArr.value)
  }catch(err){
    console.log('捕获错误',err)
  }
}
getTypes();

const toClassify = (name) => {
  let item = typeArr.value.filter(it => name == it.typeName)
  if(item.length) return {path: '/facility/classify', query: {'typeId': item[0].typeId}}
  return {path: '/facility/classify', query: {'typeId': 0}}
}

onMounted(() => {
  setLine();
})

function setLine(){
  let tabList = document.querySelectorAll(".tab");
  actWid.value = tabList[active.value].clientWidth;
  actLeft.value = tabList[active.value].offsetLeft;
}

function cutFn(num){
  active.value = num;
  setLine();
}

// const getList = async () => {
//   try{
//     const { data } = await getEquipment({
//       pageIndex: 1,
//       pageSize: 50,
//       typeId: 0
//     });
//     list.value = data.data;
//     console.log(list.value)
//   }catch(err){
//     console.log('捕获错误',err)
//   }
// }
// getList();
</script>
  
<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
.facility {
  position: relative;
  padding: .6rem .4rem;
  background: url(~@/assets/images/seekBg.jpg) repeat-x top center;
  background-size: .4rem;
}
.banner{
  margin-bottom: .6rem;
  .txt{
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 .4rem;
    top: 50%; 
    transform: translateY(-50%);
  }
  .t1{
    top: 45%;
    h2{
      background: linear-gradient(to bottom, #fff, #d9d9d9); 
      -webkit-background-clip: text;
    }
  }
  h2{
    font-size: .36rem;
    line-height: .48rem;
    font-weight: 600;
    letter-spacing: .04rem;
    background: linear-gradient(to bottom, #ffbb00, #ffa200); 
    -webkit-background-clip: text;  
    -webkit-text-fill-color: transparent; 
  }
  h3{
    font-size: 0.2rem;
    color: rgba(255,255,255,.4);
    line-height: .24rem;
    font-family: "Gilroy-Bold";
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .02rem;
    margin-top: .2rem;
  }
  h4{
    font-size: .22rem;
    color: rgba(176, 193, 202, .4);
    line-height: .4rem;
    margin-top: .1rem;
  }
  .t3{
    font-size: 0;
    h2{
      background: linear-gradient(to bottom, #fff, #94c7ff); 
      -webkit-background-clip: text;
      font-size: .32rem;
      line-height: .42rem;
    }
    .btn{
      width: 1.42rem;
      padding-left: .2rem;
      font-size: .22rem;
      color: #fff;
      height: .54rem;
      line-height: .54rem;
      border-radius: .54rem;
      background: #fcc40f url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC) no-repeat 1.14rem center;
      background-size: .08rem;
      margin-top: .26rem;
    }
  }
}
.infoUl{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: .36rem;
  .li{
    border-radius: .2rem;
    width: 2.1rem;
    height: 1.58rem;
    padding: .2rem 0 0;
    background: #fff;
    box-shadow: 0 0 .5rem rgba(230,230,230,.6);
    margin-bottom: .22rem;
    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      height: .4rem;
      margin-bottom: .16rem;
      img{
        max-height: 100%;
      }
    }
    .p{
      font-size: .2rem;
      line-height: .3rem;
      color: #666;
      text-align: center;
      em{
        font-size: .4rem;
        font-family: 'Gilroy-Bold';
        padding-right: .1rem;
        color: #333;
      }
    }
  }
}
.list{
  .cTil{
    margin-bottom: .4rem;
  }
  li{
    position: relative;
    padding: .6rem .3rem .3rem;
    overflow: hidden;
    height: 1.6rem;
    background: #fff;
    margin-bottom: .22rem;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    .state{
      position: absolute;
      left: 0;
      top: 0;
      font-size: .22rem;
      color: #fff;
      width: 1rem;
      height: .4rem;
      line-height: .4rem;
      text-align: center;
      background: #f70000;
      border-radius: 0 0 .2rem 0;
    }
  }
}
.btnList{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -.2rem;
  padding-bottom: .34rem;
  .li{
    position: relative;
    width: 1.5rem;
    height: 1.7rem;
    margin-bottom: .38rem;
    .icon{
      width: 1.4rem;
      margin: 0 auto;
    }
    .p{
      font-size: .24rem;
      line-height: .28rem;
      color: #666;
      text-align: center;
      white-space: nowrap;
    }
  }
}
.typeColumn{
  .item{
    margin-top: .4rem;
    &:first-child{
      margin-top: 0;
    }
  }
  .bd{
    padding: .3rem 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .li{
      height: 2.3rem;
      width: 3.26rem;
      padding: .2rem .3rem;
      margin-bottom: .2rem;
      background: #f9f9f9;
      border-radius: var(--border-radius);
      .pic{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.56rem;
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
      .p{
        font-size: .24rem;
        color: #333;
        line-height: .34rem;
        padding-top: .05rem;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
      }
    }
  }
}

.border{
  :deep(.van-tabs__wrap){
    margin-bottom: .5rem;
  }
}

.user-tabs{
  position: relative;
  display: flex;
  height: 1rem;
  border-bottom: .06rem solid #e1e1e1;
  margin-bottom: .6rem;
  .tab{
    position: relative;
    margin-right: .4rem;
    height: .94rem;
    line-height: .94rem;
    font-size: .28rem;
    color: #2d322d;
  }
  .line{
    position: absolute;
    height: .06rem;
    width: 1rem;
    bottom: -.06rem;
    background: #f70000;
    transition: all .4s;
  }
}

.lease{
  .coreService {
    padding-bottom: .3rem;
    h3.cTil{
      margin-bottom: .6rem;
    }
    .li {
      position: relative;
      padding: 0.27rem 0.3rem;
      display: flex;
      min-height: 2.1rem;
      align-items: center;
      margin-bottom: 0.3rem;
      border-radius: 0.28rem;
      background: #fff;
      box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
    }
    .icon {
      position: absolute;
      left:.3rem;
      top: .5rem;
      width: 0.88rem;
      height: 0.88rem;
      border: 0.01rem solid #e1e1e1;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 0.3rem;
    }
    .txt {
      flex: 1;
      min-height: 1.2rem;
      margin-left: 1.2rem;
      h4 {
        font-size: 0.28rem;
        color: #2d322d;
        line-height: 0.42rem;
      }
      p {
        font-size: 0.22rem;
        color: #646464;
        line-height: 0.36rem;
      }
    }
    .num {
      position: absolute;
      top: .3rem;
      right: 0.3rem;
      font-size: 0.26rem;
      color: #f70000;
      line-height: 0.4rem;
      font-family: "Gilroy-Bold";
    }
  }
  .internetPlatform{
    h3.cTil{
      margin-bottom: .2rem;
    }
    .van-tabs{
      margin-bottom: .4rem;
    }
    .bd{
      background: url(~@/assets/images/facility/facilityZzcInternetBg1.jpg) no-repeat center;
      background-size: cover;
      display: flex;
      align-items: center;
      padding: .3rem;
      min-height: 3.9rem;
      border-radius: .28rem;
      .li{
        position: relative;
        font-size: .22rem;
        color: #333;
        line-height: .3rem;
        padding-left: .3rem;
        margin-bottom: .3rem;
        &:last-child{
          margin-bottom: 0;
        }
        &:before{
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: .04rem;
          width: .22rem;
          height: .22rem;
          background: url(~@/assets/images/facility/facilityZzcInternetIconOk.png) no-repeat center;
          background-size: contain;
        }
      }
    }
  }
  .promise{
    position: relative;
    margin-top: .6rem;
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li{
      width: 3.24rem;
      height: 2.5rem;
      border-radius: .28rem;
      box-shadow: 0 0 .5rem rgba(230,230,230,.6);
      padding: .24rem .3rem;
      background: #fff;
      margin-bottom: .24rem;
      &:nth-child(2){
        .til{
          background-image: url(~@/assets/images/facility/facilityZzcInternetIcon2.png);
        }
      }
      &:nth-child(3){
        padding-top: .6rem;
        .til{
          background-image: url(~@/assets/images/facility/facilityZzcInternetIcon3.png);
        }
      }
      &:nth-child(4){
        padding-top: .6rem;
        .til{
          background-image: url(~@/assets/images/facility/facilityZzcInternetIcon4.png);
        }
      }
      .til{
        font-size: .26rem;
        color: #2d322d;
        line-height: .4rem;
        padding-left: .44rem;
        background: url(~@/assets/images/facility/facilityZzcInternetIcon1.png) no-repeat left center;
        margin-bottom: .14rem;
        background-size: .32rem;
      }
      p{
        font-size: .22rem;
        color: rgba(100,100,100,.8);
        line-height: .36rem;
      }
    }
    .cnt{
      position: absolute;
      left: 50%;
      top: 50%;
      width: 1.36rem;
      height: 1.36rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      font-size: .26rem;
      color: #fff;
      line-height: .3rem;
      text-align: center;
      background: url(~@/assets/images/facility/facilityZzcInternetCon.png) no-repeat center;
      background-size: contain;
    }
  }
}
</style>
  
  