<template>
  <div class="wrap">
    <nav-ber :title="'文章详情'"></nav-ber>
    <main>
      <div class="content">
        <van-loading vertical  type="spinner" color="#2d322d" v-show="cntLoad">
          加载中...
        </van-loading>
        <h2 class="cTil"><em>{{ content.newsTitle }}</em></h2>
        <div class="txt" v-html="content.newsContent"></div>
      </div>
      <div class="correlation">
        <h3 class="cTil"><em>相关阅读</em></h3>
        <van-loading vertical  type="spinner" color="#2d322d" v-show="listLoad">
          加载中...
        </van-loading>
        <div class="newsList">
          <div class="li" v-for="item in list" :key="item.newsId">
            <div class="a" @click="skipOutsideChain(item.address)" target="_blank" v-if="item.address && item.address.trim !== ''">
              <div class="pic" v-if="item.newsAccess"><img :src="item.newsAccess" alt=""></div>
              <div class="txt">
                <h4>{{ item.newsTitle }}</h4>
                <div class="p">
                  发布时间：<em>{{item.newsAddTime}}</em><br/>
                  <em>{{item.newsNum}}</em>人观看
                </div>
              </div>
            </div>
            <router-link v-else :to="`/newsdetail/${item.newsId}`">
              <div class="pic" v-if="item.newsAccess"><img :src="item.newsAccess" alt=""></div>
              <div class="txt">
                <h4>{{ item.newsTitle }}</h4>
                <div class="p">
                  发布时间：<em>{{item.newsAddTime}}</em><br/>
                  <em>{{item.newsNum}}</em>人观看
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import { getNewsInfo, getRelatedNewsList, updateNum } from '@/api/http'
import NavBer from "@/components/NavBer.vue";
import { skipOutsideChain } from '@/api/public';
import { ref, onUpdated } from "vue";
import { useRoute } from 'vue-router';
const router = useRoute();
const newsId = parseInt(router.params.id);
const content = ref({});
const list = ref([]);
const cntLoad = ref(false);
const listLoad = ref(false);
// 浏览量 +1
const flow = async () => {
  const res = await updateNum({newsId});
};

// 获取相关阅读列表
const getList = async (num) => {
  listLoad.value = true;
  try{
    const { data } = await getRelatedNewsList({
      newsId: num
    })
    list.value = data.data
    listLoad.value = false;
  } catch(err){
    listLoad.value = false;
    console.log('错误捕获，获取相关阅读列表', err)
  }
}

// 获取新闻详情
const info = async (num) => {
  cntLoad.value = true;
  try{
    const { data } = await getNewsInfo({
      newsId: num
    })
    getList(num)
    content.value = data.data
    cntLoad.value = false;
  } catch(err){
    cntLoad.value = false;
    console.log('错误捕获，获取新闻详情', err)
  }
}

info(newsId)
flow()
</script>
  
<style scoped lang="scss">

main {
  position: relative;
  padding: .6rem .4rem;
  background: url(~@/assets/images/news/newsBg.jpg) no-repeat top center;
  background-size: 100%;
}
.content{
  padding-bottom: .3rem;
  h2.cTil{
    height: auto;
    line-height: .5rem;
    text-align: left;
    em{
      height: auto;
      padding-bottom: .23rem;
      margin-bottom: -.06rem;
      &:after{
        right: auto;
      }
    }
  }
  :deep(.txt){
    overflow: auto;
    p{
      margin-bottom: .36rem;
    }
    img{
      margin: 0 auto;
      border-radius: var(--border-radius-s);
    }
  }
}
.newsList{
  a, .a{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: .3rem 0;
    border-top: 1px dashed #dfdfdf;
    &:first-child{
      border-top: 0 none;
    }
  }
  .pic{
    width: 2.9rem;
    overflow: hidden;
    border-radius: .28rem;
    margin-right: .36rem;
  }
  .txt{
    flex: 1;
    padding: .12rem 0 0;
    h4{
      font-size: .28rem;
      line-height: .4rem;
      margin-bottom: .32rem;
      display: -webkit-box;  
      -webkit-line-clamp: 2; /* 设置显示的行数 */  
      -webkit-box-orient: vertical;  
      overflow: hidden;  
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .p{
      font-size: .22rem;
      line-height: .3rem;
      color: rgba(45,50,45,.4);
      em{
        font-family: 'Gilroy-Bold';
      }
    }
  }
}
</style>
  
  