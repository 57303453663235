<template>
  <div class="wrap">
    <nav-ber title="产品详情" homeUrl="/financial"></nav-ber>
    <main>
      <div class="topBox">
        <div class="row">
          <h4 v-html="pageCnt.newsTitle"></h4>
          <div class="num" v-html="pageCnt.email"></div>
        </div>
        <div class="label" v-html="pageCnt.newsAccess"></div>
      </div>
      <div class="content">
        <div v-html="pageCnt.newsContent"></div>
        <div @click="$router.push({'path': '/embed', query: {'link': pageCnt.address + parameter}})" class="cApplyBtn">申请办理</div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { skipOutsideChain } from '@/api/public'
import { getNewsInfoPc } from '@/api/http';
import { ref, onMounted, inject } from 'vue'
import { useRoute } from 'vue-router';

const route = useRoute();
const parameter = ref('');
const isFromApp = ref(1);
const newsId = route.query.newsId;
const pageCnt = ref({});
const getData = async () => {
  let { data: res } = await getNewsInfoPc({ newsId })
  console.log('测试44', res.data)
  pageCnt.value = res.data
};
getData();



// 获取URL中的查询字符串
const checkSource = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get('source');

  if (!source) {
    isFromApp.value = 1;
    parameter.value = ''
  }else if(source === 'link'){
    // 短信入口
    isFromApp.value = 2;
    parameter.value = '&source=link'
  }else if(source === 'gjt'){
    // 桂建通入口
    isFromApp.value = 3;
    parameter.value = '&source=gjt'
  }
}

// 使用inject来注入_hmt变量
const _hmt = inject('_hmt');
const setCustomVariables = () => {
  // 设置自定义变量
  if (isFromApp.value == 1) {
    _hmt.push(['_setCustomVar', 1, 'Source', 'App', 1]);
  } else if(isFromApp.value == 2) {
    _hmt.push(['_setCustomVar', 1, 'Source', 'Link', 1]);
  } else{
    _hmt.push(['_setCustomVar', 1, 'Source', 'Gjt', 1]);
  }
  // 发送页面视图
  _hmt.push(['_trackPageview']);
}

onMounted(() => {
  checkSource();
  setCustomVariables();
})

</script>

<style scoped lang="scss">
main{
  padding: 0;
  background: url(~@/assets/images/financial/lwgzdTopBg.jpg) no-repeat top;
  background-size: 100%;
}
.topBox{
  height: 3.36rem;
  padding: .55rem .4rem 0;
  .row{
    position: relative;
    min-height: .98rem;
    h4{
      font-size: .42rem;
      color: #fff;
      line-height: .52rem;
      font-weight: 600;
    }
    .num{
      font-size: .22rem;
      color: #fff;
      line-height: .32rem;
    }
  }
  .p{
    font-size: .24rem;
    color: #fff;
    line-height: .34rem;
  }
  :deep(.label){
    margin-top: .3rem;
    display: flex;
    margin-right: -.12rem;
    .i{
      padding: .12rem .5rem;
      height: .84rem;
      line-height: .3rem;
      font-size: .24rem;
      margin-right: .12rem;
      color: #f70202;
      border-radius: .04rem;
      background: linear-gradient(to bottom, #fff8f3, #ffe6d1);
      box-shadow: 0 .06rem .2rem rgba(51,51,51,.2);
      font-weight: 600;
      .p{
        color: #d19f68;
        text-align: center;
        line-height: .3rem;
        font-size: .2rem;
        font-weight: 500;
      }
    }
  }
}
:deep(.content){
  padding: .6rem .4rem;
  border-radius: .28rem .28rem 0 0;
  background: #fff;
  section{
    margin-bottom: .6rem;
  }
  .cTil{
    margin-bottom: .33rem;
  }
  .pic{
    overflow: hidden;
    border-radius: var(--border-radius-s);
    border: .03rem solid #c7c7c7;
    margin-top: .3rem;
  }
  .cTil{
    text-align: center;
    em:after{
      right: 0;
      margin: 0 auto;
    }
  }
  .pBox{
    h4{
      font-size: .22rem;
      color: #333;
      line-height: .36rem;
    }
    p{
      margin-bottom: .36rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  ul{
    display: flex;
    justify-content: space-between;
  }
  li{
    position: relative;
    &:after{
      content: "";
      display: block;
      position: absolute;
      left: calc(50% + .82rem);
      top: .25rem;
      width: .2rem;
      height: .2rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAATCAYAAACp65zuAAABFklEQVQokX2RvUpDMRiGn55C70Jw65bJyXsQpAq1+ENGM4pYhw6Ool5AKFTN6OimQmcXXcyqi+BSqCgqVIdy5JMU4zmN3/zk/Uvl7nTzGTgCDpS2OYnLgA9gH9hOQQRwGXgQVe/MVhJU2t4ALeAeOPTOtFOKKG1vgTXgSbJ6Z3aKYCXPf/N7Z+aBM2AG2JU4k4JZ/Eppew0sAY+iHBfMKFzIXCr4xzo+78wccALUgb2SYhRDCp4DNaDzn6KscAyMZL6pit6Z9WD7BjSVthcl0DuzGqBX2VZpezVtxw2gC3yKndL2sjR4gHrAGFhQ2vZjpx/rkMkBX8BiEZKrNmYH0k4yvQArk0zFE0WBhnHwFPgePj8JAXwDwDJsTp/NsVkAAAAASUVORK5CYII=) no-repeat center;
      background-size: .1rem;
    }
    &:last-child:after{
      display: none;
    }
    .icon{
      width: .7rem;
      height: .7rem;
      margin: 0 auto .05rem;
    }
    p{
      font-size: .22rem;
      color: #666;
      text-align: center;
      white-space: nowrap;
      line-height: .42rem;
    }
  }
}
</style>

