<template>
  <div class="wrap">
    <nav-ber :title="'房屋安全综合管理平台'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="housing">
      <sub-ban :ban-info="banInfo"></sub-ban>
     <section class="constru-npm1">
            <div class="w1440"> 
                <div class="above ">
                    <div class=" item" v-for="item in apiData1" :key="item.newsId">
                        <div class="pagetit">
                            <h5 v-html="item.newsTitle"></h5> 
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </div> 
                    </div> 
                </div>
            </div>
        </section>
        <section class="constru-npm2">
            <div class="w1440">
                <div class="pagetit">
                    <h5>数据管理</h5> 
                </div>
                <div class="above">
                    <ul>
                        <template v-for="(item, index) in apiData2">
                            <li >
                                <p v-html="item.newsTitle"></p>
                                <div class="em">{{ item.newsAuthor }}</div>
                            </li>
                            <div class="line" v-if="index !== apiData2.length - 1">
                                <img src="/images/digital/line11.png" alt="" />
                            </div>
                        </template>
                    </ul>
                </div>
                <div class="below">
                    <h5>数据建库</h5>
                    <ul>
                        <li v-for="(item) in apiData3" :key="item.newsId" >
                            <h6 v-html="item.newsTitle"></h6>
                            <div class="p" v-html="item.newsContent"> 
                            </div>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </section>
   <section class="constru-npm3" v-if="apiData4[4]">
            <div class="w1440">
                <div class="pagetit">
                    <h5>主要功能</h5> 
                </div>
                <div class="itembox ">
                    <div class="fl">
                        <div class="item" v-for="(item) in apiData4" :key="item.newsId">
                            <h5 v-html="item.newsTitle"></h5>
                            <div v-html="item.newsContent"></div>
                        </div>
                      
                    </div>
                    <div class="fr">
                        <div class="item">
                            <h5 v-html="apiData4[4].newsTitle"></h5>
                            <div v-html="apiData4[4].newsContent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]); 
const apiData4 = ref([]); 
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
}); 
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 537,
    typeId: 89,
  });
  const { data: data2 } = await getInfo({
    coId: 555,
  });
  const { data: data3 } = await getInfo({
    coId: 557,
  });
    const { data: data4 } = await getInfo({
    coId: 559,
  }); 
const { data: data5 } = await getInfo({
    coId: 561,
  }); 
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data; 
   apiData4.value = data5.data; 
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent; 
    // console.log(apiData3.value[0], imageUrlStrings.value , "555");
};


  
  

onMounted(async () => {
   getList();
});
</script>
  
<style scoped lang="scss">
main{
    padding: 0;
}
.housing {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1{
             padding: .9rem 0;
             border-bottom: 1px solid #e3e5e8;
               .above{ 
                display: block;
                .item{
                    width: 100%;
                    margin-bottom: .35rem;  
                    :deep(.p){
                        max-width: 100%;
                        margin: .25rem auto 0;
                         p{
                            text-align: center;
                        }
                    }
                }
            }
        }
       
    .constru-npm2{
        padding: .9rem 0;
            :deep(.above){
                margin-top: .4rem;
                ul{
                  display: flex;
                justify-content: space-between;
                align-items: center;
                    .line{
                        display: none;
                    }
                    li{
                        width: 1.2rem;
                        height: 1.2rem;
                          border-radius: 50%;
                    position: relative;
                    background: #f3f4f7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                        .em{
                            width: .3rem;
                            height: .3rem;
                            right: -.15rem;
                            font-size: .24rem;
                            border-radius: 50%;
                        background: #d7000f;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        margin: auto;
                        }
                    }
                }
            }
            :deep(.below) {
                margin-top: .45rem;
                h5{
                    font-size: .26rem;
                    text-align: center;
                position: relative;
                color: #333333;
                    &::after{
                        content: "";
                        width: 90%;
                        left: 0;
                    right: 0;
                    margin: auto;
                    position: absolute;
                    background: url(~@/assets/images/labor/line10.png);
                        background-size:100% 100%;
                        height: .5rem;
                        bottom: -.53rem;
                    }
                    
                }
                ul{
                        margin-top: .55rem;
                        flex-wrap: wrap;
                        display: flex;
                justify-content: space-between;
                        li{
                            width: 49%;
                            margin-bottom: 2%;
                            min-height: 2rem;
                            padding: .4rem .3rem;
                            background: #f3f4f7;
                            h6{
                                font-size: .22rem;
                                color: #333333;
                            }
                            .p{
                                margin-top: .1rem;
                                p{
                                    margin-bottom: .1rem;
                                }
                            }
                        }
                    }
            }
        }
        .constru-npm3{
            padding:0 0 .8rem 0;
            :deep(.itembox){
                margin-top: .45rem;
                display: block;
                overflow: hidden;
                .item{
                    background: #f3f4f7;
                     padding: .4rem .3rem;
                      h5{
                            font-size: .26rem;
                             color: #333333;
                             text-align: center;
                        }
                        ul{
                            margin-top: .3rem;
                            li{
                                width:100%;
                                background: #fff;
                                height: .8rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                line-height: 1;
                                font-size: .24rem;
                                    border-radius: .6rem;
                                 margin: 0 auto .1rem;
                                   color: #808080;
                    text-align: center;
                            }
                        }
                         &:nth-child(4) {
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
                }
                .fl{
                    width: 100%;
                    display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                    .item{
                        width: 49%;
                        padding: .3rem;
                        margin: 0 0 2% 0;
                     &:nth-child(5){
                        display: none;
                    }
                    &:nth-child(2n) {
                        margin: 0 0 2.1% 0;
                    }

                    &:nth-child(n+3) {
                        margin: 0;
                    }
                    }
                }
                .fr{
                    width: 100%;
                    margin-top: .1rem;
                    .item{
                        
                        ul{
                            li{
                                width: 3.5rem;
                            }
                        }
                    }
                }
            }
        }
}
</style>
  
  