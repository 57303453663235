<template>
  <div class="wrap">
    <nav-ber :title="'政务数字化专区'"></nav-ber>
    <main class="govedigital">
      <sub-ban :ban-info="banInfo"></sub-ban>
      <section class="constru-npm1">
        <div class="w1440">
          <div class="above">
            <div class="txt">
              <h5 class="wow fadeInUp50" v-html="apiData1[0].newsTitle"></h5>
              <div
                class="wow fadeInUp50"
                v-html="apiData1[0].newsContent"
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section class="constru-npm2">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5>工程建设项目全生命周期</h5>
          </div>
          <div class="above">
            <div
              class="item wow fadeInUp50"
              v-for="item in apiData2"
              :key="item.newsId"
            >
              <div class="img">
                <div class="ico"><img :src=" item.newsAccess" alt="" /></div>
                <h5 v-html="item.newsTitle"></h5>
              </div>
              <div v-html="item.newsContent"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="constru-npm3">
            <div class="w1440">
                <div class="item" v-for="(item, index) in apiData3" :key="index">
                    <h5>{{ item.newsTitle }}</h5>
                    <div class="dl">
                        <a :href="tm.address" class="dd" v-for="tm in item.items" :key="tm.newsId">
                            <div class="img">
                                <img :src=" tm.newsAccess" alt="" />
                            </div>
                            <div class="txt">
                                {{ tm.newsTitle }}
                            </div>
                        </a>
                    </div>
                </div>
              
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo,getPolymerizationList } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 477,
    typeId: 197,
  });
  const { data: data2 } = await getInfo({
    coId: 805,
  });
  const { data: data3 } = await getInfo({
    coId: 807,
  });
  const { data: data4 } = await getPolymerizationList();
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
  apiData3.value = data4.data;
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent;
};

// console.log(apiData3.value, "555");
getList();
onMounted(async () => {});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.govedigital {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1 {
    padding: 0.9rem 0;
    .above {
      height: auto;
      margin-bottom: 0.2rem;
      min-height: 4rem;
      padding: 0.9rem 0.3rem;
      background: url(~@/assets/images/labor/bg5.jpg) no-repeat;
      background-position: center;
      background-size: cover;
      :deep(.txt) {
        width: 100%;
        padding: 0;
        h5 {
          font-size: 0.28rem;
          color: #fff;
          margin-bottom: 0.2rem;
        }
        p {
          color: #fff;
        }
      }
    }
  }
  .constru-npm2 {
    padding: 0.9rem 0;
    background: #f3f4f7;
    :deep(.above) {
      flex-wrap: wrap;
      margin-top: 0.45rem;
      display: flex;
            justify-content: space-between;
      .ico {
        margin-bottom: 0.1rem;
        text-align: center;
        display: flex;
        justify-content: center;
        img {
          width: 0.35rem;
        }
      }
      h5 {
        font-size: 0.26rem;
        text-align: center;
        color: #333333;
      }
      ul {
        margin: 0.3rem 0;
        li {
          padding: 0 0.15rem;
          font-size: 0.24rem;
          margin-bottom: 0.1rem;
          border-radius: 1rem;
          line-height: 0.9rem;
          background: #fff;
          color: #808080;
          text-align: center;
           &:last-child {
                        margin-bottom: 0;
                    }
                   
        }
      }
      .item {
        width: 49%;
        border-right: 1px solid #e1e2e6;
        padding: 0 .2rem;
        &:last-child {
          border-right: 1px solid #e1e2e6; 
        }
         &:nth-child(2n){
                      border-right: none;
                      padding: 0 .1rem;
                    }
      }
    }
  }
   .constru-npm3{
     padding-bottom: .9rem;
        .item{
            margin-top: .3rem;
            h5{
                font-size: .28rem;
            }
            .dl{
                margin-top: .25rem;
                .dd{
                    width: 49%;
                        margin: 0 2% 2% 0;
                        display: inline-block;
                        .img{
                            height: 2.5rem;
                             img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        }
                        &:nth-child(4n){
                            margin: 0 0 2% 0; 
                        }
                         &:nth-child(2n){
                            margin: 0 0 2% 0; 
                        }
                        .txt{
                            font-size: .24rem;
                            padding: .25rem .3rem;
                            line-height: 1;
                        color: #808080;
                        background: #f5f5f5;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        }
                }
            }
        }
      }
}
</style>
  
  