<template>
  <div class="wrap">
    <nav-ber :title="'模块标题'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="leasehome">
      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000">
        <van-swipe-item v-for="item in apiData" :key="item.newsId">
          <div
            class="img"
            :style="`background-image: url(${
                  item.image
            });`"
          >
            <div class="w1440">
              <div class="cont ">
                <div v-html="item.newsContent"></div> 
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <section class="lease-npm1">
      <div class="w1440 ">
          <div class="above" v-for="item in apiData1">
          <div class="fl" >
              <img :src="      item.newsAccess" alt="">
          </div>
          <div class="fr">
            <div class="txt" >
              <h5 v-html="item.newsTitle"></h5>
              <div class="p" v-html="item.newsContent">
                
              </div>
            </div>
            <a :href="item.email" class="more " >了解详情</a>
          </div>
        </div>
      </div>
    </section>
     <section class="lease-npm2">
      <div class="w1440 ">
        <div class="pagetit ">
                <h5>我们的服务</h5> 
        </div>
        <div class="box ">
            <div class="item" v-for="item in apiData2" :key="item.newsId">
                <div class="above">
                  <div class="i"><img :src="      item.newsAccess" alt=""></div>
                  <h5 v-html="item.newsTitle"></h5>
                </div>
                <div class="p" v-html="item.newsContent"> 
                </div>
            </div>
          
        </div>
      </div>
    </section>
     <section class="lease-npm3">
      <div class="w1440 ">
        <div class="pagetit ">
                <h5>使用华建周材租赁产业互联网平台</h5> 
        </div>
        <div class="sildbox ">
           <div class="fr" ref="scrollContainer">
            <div class="li "  @click="handleClick(item, index)"
            :class="{ on: activeIndex === index }" v-for="(item, index) in apiData3" :key="item.newsId">{{item.newsTitle}}</div> 
          </div>
          <div class="fl">
            <div class="item" :style="`background-image: url(${      item.image});`" v-for="(item, index) in apiData3" :key="item.newsId" v-show="selectedItemIndex === index - 1">
              <div class="txt" v-html="item.newsContent">
              </div>
            </div>
           
          </div>
         
        </div>
      </div>
    </section>
     <section class="sehp-npm7">
            <div class="w1440">
                <div class="pagetit ">
                        <h5>我们的承诺</h5> 
                  </div>
               <div class="ul ">
                    <div class="li" v-for="item in apiData4" :key="item.newsId">
                        <div class="txt">
                            <h5>{{item.newsTitle}}</h5>
                            <p v-html="item.newsContent"></p>
                        </div>
                        <em>{{item.newsAuthor}}</em>
                    </div>
               </div>
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getInfo } from "@/api/http";
import { ref } from "vue";
const activeIndex = ref(0);
const scrollContainer = ref(null);
const selectedItemIndex = ref(-1);
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const getList = async () => {
  const { data:data1 } = await getInfo({
    coId: 1033,
  });
  const { data: data2 } = await getInfo({
    coId: 229,
  });
  const { data: data3 } = await getInfo({
    coId: 231,
  });
  const { data: data4 } = await getInfo({
    coId: 239,
  });
   const { data: data5 } = await getInfo({
    coId: 243,
  });
  apiData.value =data1.data;
  apiData1.value = data2.data;
apiData2.value = data3.data;
apiData3.value = data4.data;
apiData4.value = data5.data;
console.log(apiData3.value)
};
// 计算滚动位置并滚动
const scrollToActiveItem = () => {
  if (activeIndex.value !== null && scrollContainer.value) {
    const activeItem = scrollContainer.value.querySelector('.lease-npm3 .li.on');
    if (activeItem) {
      const containerWidth = scrollContainer.value.clientWidth;
      const totalWidth = scrollContainer.value.scrollWidth;
      const actWinValue = activeItem.offsetWidth;
      const itemCount = apiData3.value.length;
      
      // 新增: 直接计算最后一个元素的起始位置，避免复杂条件判断
      const lastItemStartPosition = (itemCount - 1) * actWinValue - 20;
      // 确保最后一个元素至少部分可见
      const ensureLastVisible = Math.max(lastItemStartPosition, totalWidth - containerWidth);
      
      let targetScrollLeft;
      
      // 对于倒数第二及之后的元素，直接计算保证末尾元素可见的scrollLeft
      if (activeIndex.value >= itemCount - 2) {
        targetScrollLeft = ensureLastVisible;
      } else {
        targetScrollLeft = activeIndex.value * actWinValue - 20;
      }

      // 防止滚动超出左侧边界
      targetScrollLeft = Math.max(targetScrollLeft, 0);

      scrollContainer.value.scrollTo({
        left: targetScrollLeft,
        behavior: 'smooth',
      });
    }
  }
};

// 处理点击事件
const handleClick = (item, index) => {
  activeIndex.value = index;
  selectedItemIndex.value = index - 1; 
  scrollToActiveItem();
};
getList();
</script>
  
<style scoped lang="scss">
.leasehome {
  h5,h6{
    line-height: 1.1;
  }
  .w1440{
    padding: 0 .3rem;
  }
  .soluslid {
    height: 4.5rem;
    border-radius: 0;
    .swiper-pagination {
      width: 100%;
    }
    .w1440 {
      padding: 1rem .3rem 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; 
    }
    :deep(.cont) {
      h5 {
        font-size: 0.3rem;
        color: #fff;
      }
      h6 {
        font-size: 0.3rem;
        color: #fff;
        font-family: DIN-Bold;
        letter-spacing: -0.05px;
        opacity: 0.5;
        line-height: 1.1;
        text-transform: uppercase;
      }
      .p {
        max-width: 100%;
        margin-top: 0.2rem;
        p {
          color: #fff;
          font-size: 0.24rem;
          line-height: 0.32rem;
        }
      }
    }
    .img {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    :deep(.van-swipe__indicators) {
      bottom: 0.3rem;
      display: flex;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      justify-content: center;
      transform: none;
      i {
        margin: 0 0.05rem;
        width: 0.52rem;
        height: 0.04rem;
        background: #fff;
        opacity: 0.5;
        border-radius: 0;
        transition: all 0.4s;
      }
    }
  }
 .lease-npm1{
  padding: .9rem 0;
    .more{
      border-radius: 1rem;
    color: #fff;
    line-height: 0.8rem;
    width: 1.8rem;
    font-size: 0.24rem; 
    background: #d7000f;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    }
  .above{
      display: block;
      overflow: hidden;
      .fl{
        width: 100%;
         img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      }
      .fr{
        width: 100%;
        padding: .45rem 0 ;
         border-bottom: 1px solid #e6e7eb;
        h5{
          font-size: 0.26rem;
          margin-bottom: .15rem;
        }
        p{
          margin-bottom: .1rem;
        }
        .txt{
          margin-bottom: .6rem;
        }
      }
    }
 }
  .lease-npm2{
      padding: .9rem 0;
      .box{
        margin-top: .35rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 1.8rem;
        position: relative;
        .item{
          min-height: auto;
          padding: .3rem .2rem;
          width: 49%;
          margin-bottom: 2%;
          border: 1px solid #f0f0f0;
          &:nth-child(5){
            width: 100%;
            position: absolute;
            top: 0;
                border:none;
                .p{
                  margin-top: .08rem;
                }
            h5{
              font-size: .28rem;
    margin: 0 0 0 .15rem;
     text-align: left;
            }
             .above{
            display: flex;
            justify-content: center;
            align-items: center;
          }
         
          } 
          .i{
            min-height: .4rem;
             display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
            img{
              width: .4rem;
            }
          }
          h5{
            font-size: .26rem;
            margin: .18rem 0 .08rem;
            text-align: center;
          }
          :deep(p){
          text-align: center;
        }
        }
      }
    }
     .lease-npm3{
      height: auto;
      padding: .9rem 0;
     background: #f3f4f7;
      .sildbox {
        height: auto;
        margin-top: .35rem; 
        overflow: hidden;
         background: #fff;
        .fr{
              display: -webkit-box; 
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    .li{
      padding:.3rem .2rem;
      margin-right: .3rem;
      font-size: .24rem;
       color: #808080;
       border-bottom: 1px solid #edeff2;
       position: relative;
       &::after{
          content: '';
          background: url(~@/assets/images/labor/ri1.png) no-repeat;
           width: .06rem;
        height: .1rem;
        background-size: 100%;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &.on{
          color: #d7000f;
          &::after{
            background: url(~@/assets/images/labor/ri2.png) no-repeat;
            background-size: 100%;
          }
        }
    }
        }
        .fl{
          width: 100%;
           min-height: 4rem;
           
          .item{
            padding: 1rem .25rem;
            min-height: 4rem;
             height: 100%;
        background-position: center;
        background-size: cover;
            :deep(h5){
              font-size: .24rem;
              line-height: .32rem;
              margin-bottom: .15rem;
              position: relative;
               color: #fff;
              &::after{
                content: '';
                width: .06rem;
                height: .06rem;
                top: .13rem;
                border-radius: 50%;
            background: #fff;
            position: absolute;
              }
            }
          }
        }
      }
    }
    .sehp-npm7{
      padding: .9rem 0;
      .ul{
        flex-wrap: wrap;
        display: flex;
            justify-content: space-between;
        margin-top: .35rem;
        .li{
          width: 49%;
          margin-bottom: 2%;
          padding: .3rem .3rem 1rem;
          min-height: auto;
           border: 1px solid #e3e5e8;
           position: relative;
          h5{
            font-size: .26rem;
            margin-bottom: .1rem;
          }
          p{
            line-height: .32rem;
          }
          em{
            font-size: .6rem;
            left: .3rem;
            bottom: .25rem;
             line-height: 1;
                    position: absolute;
            background: linear-gradient(180deg,#f5bec2,#fbe7e8,#fefbfb);
    -webkit-background-clip: text; 
    color: transparent;
    font-family: DIN-Bold;
          }
        }
      }
    }
}
</style>
  
  