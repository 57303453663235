<template>
  <div class="wrap">
    <nav-ber title="租赁商城"></nav-ber>
    <main class="laborhome">
      <Cbanner :list="apiData0"></Cbanner>
      <section class="cloud-npm3">
        <CinfoUl :list="apiData1"></CinfoUl>
      </section>
      <section class="equ-npm1">
        <Clist :list="apiData2"></Clist>
      </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import Cbanner from "@/components/Cbanner.vue";
import CinfoUl from "@/components/CinfoUl.vue";
import Clist from "@/components/Clist.vue";
import { getInfo } from "@/api/http";
import { ref } from "vue";
const apiData0 = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const getList = async () => {
  const promises = [
    getInfo({ coId: 359, typeId: 4 }).then(res => res.data),
    getInfo({ coId: 1013 }).then(res => res.data),
    getInfo({ coId: 365, typeId: 4 }).then(res => res.data),
  ];
  const results = await Promise.all(promises);
  apiData0.value = results[0].data;
  apiData1.value = results[1].data;
  apiData2.value = results[2].data;
};
getList();
</script>
  
  