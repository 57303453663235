<template>
  <div class="wrap">
    <nav-ber :title="'详情'"></nav-ber>
    <main class="details">
      <van-loading vertical  type="spinner" color="#2d322d" v-show="cntLoad">
        加载中...
      </van-loading>
      <div class="cnt" v-show="!cntLoad">
        <div class="vdo">
          <video :src="content.newsAccess" controls preload controlsList="nodownload"></video>
          <!-- <img src="~@/assets/images/facility/facilityDetailsPic.jpg" alt="">
          <div class="btn"></div> -->
        </div>
        <div class="txt">
          <h3>{{content.newsTitle}}</h3>
          <div v-html="content.newsContent"></div>
          <h3>产品优势</h3>
          <div v-html="content.address"></div>
          <!-- <p>1. 高质量：精准控制标高，人机配合下施工作业面平整度可达到（±5mm）的标准*。<br>
          2. 高效率：施工工效≥100㎡/h*，且大部分工作面一次成型，减少工人的工作量。<br>
          3. 智能化：自主开发的GNSS导航系统，集成智能运动控制算法操作更平稳、控制更精准。<br>
          4. 降低工人劳动强度：自动化程度高、工人的劳动强度降低。</p> -->
        </div>
      </div>
      <van-button class="cApplyBtn" @click="$router.push({path: '/consultform', query: { title: '租赁咨询', plateArr: content.newsTitle}})">在线咨询</van-button>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { getNewsInfo } from '@/api/http';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
const router = useRoute();
const newsId = parseInt(router.params.id);
const cntLoad = ref(false);
const content = ref({});
// 获取详情
const info = async (num) => {
  cntLoad.value = true;
  const { data } = await getNewsInfo({
    newsId: num
  })
  content.value = data.data;
  console.log(content.value)
  cntLoad.value = false;
}
info(newsId)

</script>
<style scoped lang="scss">
.details{
  padding: .6rem .4rem;
  background: url(~@/assets/images/facility/facilityDetailsBg.jpg) no-repeat top;
  background-size: 100%;
}
.vdo{
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  margin-bottom: .6rem;
  video{
    display: block;
    width: 100%;
  }
  .btn{
    position: absolute;
    left: 50%;
    top: 50%;
    width: .6rem;
    height: .6rem;
    margin: -.3rem 0 0 -.3rem;
    z-index: 5;
    background: url(~@/assets/images/facility/facilityIconPlay.png) no-repeat center;
    background-size: contain;
  }
}
.txt{
  h3{
    position: relative;
    font-size: .28rem;
    color: #2d322d;
    line-height: .38rem;
    padding: 0 0 .22rem;
    margin: .4rem 0 .2rem;
    &:after{
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      display: block;
      width: .5rem;
      height: .04rem;
      background: #d4310a;
    }
  }
  
  :deep(.item){
    display: flex;
    border-bottom: .01rem solid #e5e5e5;
    padding-bottom: .2rem;
    margin-bottom: .2rem;
    .em{
      width: .7rem;
      height: .7rem;
      text-align: center;
      line-height: .7rem;
      font-size: .3rem;
      font-family: 'Gilroy-Bold';
      color: #d4310a;
      background: #e6e6e6;
      margin-right: .2rem;
      margin-top: .1rem;
    }
    h4{
      font-size: .24rem;
      color: #333;
      line-height: .3rem;
    }
    .txt{
      flex: 1;
    }
  }
}
.cApplyBtn{
  margin-top: .6rem;
}

video::-internal-media-controls-download-button {
    display:none;
}
video::-webkit-media-controls-enclosure {
    overflow:hidden;
}
video::-webkit-media-controls-panel {
    width: calc(100% + 50px); 
}
</style>
  
  