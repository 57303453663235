<template>
    <div :class="['navBer', {'on': isScrolled}]" :style="{ height: 'calc(1.1rem + ' + $store.state.safetyTop + 'px)'}">
        <div class="retBtn" @click="onClickLeft"></div>
        <h1>{{title}}</h1>
        <router-link v-if="homeUrl" :to="homeUrl" class="retHome"></router-link>
    </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
const onClickLeft = () => history.back();
import { defineProps } from 'vue';
const props = defineProps({
    title: String,
    homeUrl: String
})
const isScrolled = ref(false);

onMounted(() => {
    window.addEventListener('scroll',function(){
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        isScrolled.value = scrollTop > 60;
    })
})
</script>
<style scope lang="scss">
.navBer{
    position: fixed;
    left: 0;
    top: 0;
    height: 1.1rem;
    width: 100%;
    z-index: 998;
    background: #fff;
    display: flex;
    align-items: flex-end;
    &.on{
        box-shadow: 0 0 .2rem rgba(0,0,0,.1);
    }
    .retBtn{
        position: absolute;
        left: .06rem;
        bottom: .1rem;
        width: .9rem;
        height: .9rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAeCAYAAAAl+Z4RAAAAmklEQVQ4jaXVOxKAIAxF0eB+oND9L8GGDeFowcgn5OVBf48gMIRSijAjXekLj52YAv6xG+jjfOcAA7MYnoEWQ8AqNgErXgJIrAJoPAU88QB44wZg4gqw8TtCPCMdC3sbG6D/Yr8caAY7SF0CizT/gEGGn+hFprvgQdRtRJHlOUAQ8yBZCHQSVwh8lDXE/bRtPSyzmVC3sSIi8gA78G1NX/ltRAAAAABJRU5ErkJggg==) no-repeat center;
        background-size: .16rem;
    }
    h1{
        width: 100%;
        font-size: .3rem;
        color: #2d322d;
        line-height: 1.1rem;
        text-align: center;
        font-weight: 600;
    }
    .retHome{
        position: absolute;
        right: .1rem;
        bottom: .1rem;
        width: .9rem;
        height: .9rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAAEBUlEQVRIibWWXWgcVRTHf//Z2WhSQalaEOLXg0qxkirECiqKoqCCxkZ8EbQofbEq0pIXUSr6IGjfrFalKMUXEW0pgmgRgqIPLX5VihX76FeNImhNNdmde+SsZ+LsupvsmnrgMnfO/Of87z33fFyNj49jZhRFQa1WI6WEpJau2WwyMjLC3Nxc65vrHedPSacBd5rZdErpSJZlLcz8/HwLMzw83LJV2itt+nCcY3IGlDCyGngHOAf4E7gN2DeorWwQsK9a0pWSvgxil5MlvSvpbv/+v5EDk2b2Ycx/Au4APgtv7DKzLSecPIxPmdkbofpW0hrgTUnrgI9Dvw146YSROzGwFXg6VB9JWi1pJr43UkrjZrYngmljvV7f5YG2LHI3UBTFbkmPR8TulXRVSun3LvD1kp4PnJ//p0DtP5FLquV5vqcoits9jRqNxo5msznh8x54X+ymRqOxNTCXmtknwOig5Gd4ROd5PhGGH0kp3b+UK93tKaUngPtCNSbpK0mX9Ut+gbvMzC6MonCvmT3lu4nzX1QC8zJwU+BWAAfM7PqlyG+QdFjS2UBD0q2SXum1y+qzi3gRugKYMTM/+/eAjV3JJU2Y2b4IkuPhqrf4J9XKItPmAddVvVJi4r/9wCXAdwH3NNzSST4laU/Md7urzOxQuSs/ayfw4TW5lzhp2R/KhZjZTATdjljQNjPb7vPa6Ojo+qIodsZqG+4eM7sc+A04WiV3w+WCup2/6xzXEZjnAZslHQPGgSHA7TfyoihWVAzVgYfDzd8An/ci6iVVt4ecK+mxTniWZavylNKrWZadClwX+puBk2LnbbvqR8r4qMgflfnbgJ/bF8CTZUvdHsPle+Csbi6tyFrv5cD5gd8LfNDH2m6pvnTr5/8qiR3EU17nO3a3GXgWeIjuuy9llade+ZINUDiIgCkbzCHgOaBssQ9KmuxMxQ5ZSO1WivZDXua4mT1DJX/N7AFJV0t6LXAvppRq/XQ0BrxMeBBeE/MF9wbpptCf7tFNnwHa92VCUhG56nl8cZlSXnSKorhowWCWzfbqfAOTV0prM6W0M9SeGdeamR/b2izLXg/cdLPZ/NFvvV3yfXDyaJOtHZrZo8AxSSOSpv0eJ8nvcKNBdldZhlm86fRHzt+uJM9zL6/HAb+zHYhPK+N52ItUrVb7YWhoqK0MLyZ939srxrzlrkspjXndNrOjWZbtj5tMa/TjcpYoMrMsHrUHY3QuDtoLzWxF3ebpvNO4B1H8vJIe0tGze+4svp1SUTXayLv8cyRa3gvABs+sntb7k7FA/WJmP7eRV3Myzm1DSul94EzgxmUSt8TTNMuyyXq9btXjaXN7zD1y10i6pxLNy5FfzcxvR1+3GQH+Apwz6b6fo/bhAAAAAElFTkSuQmCC) no-repeat center;
        background-size: .31rem;
    }
}
</style>
  
  