<template>
  <div class="wrap">
    <nav-ber :title="'数字服务'"></nav-ber>
    <main>
      <van-tabs class="border" v-model:active="active" color="#f70000" line-width="70" title-inactive-color="#2d322d" @change="changeFn">
        <van-tab title="应用商城"></van-tab>
        <van-tab title="政务数字化"></van-tab>
        <van-tab title="企业数字化"></van-tab>
      </van-tabs>
      <div class="bd">
        <div class="tabItem" v-show="active === 1">
          <div class="topBox">
            <img src="~@/assets/images/digitization/digitizationPic1.jpg" alt="">
            <div class="txt">
              <h2>政务数字化</h2>
              <h4>Digitalization of <br>
                government Affairs</h4>
            </div>
          </div>
          <div class="ul">
            <router-link class="item" to="/digitization/arrows">
              <div class="pic" :style="{backgroundImage: 'url(' + require('@/assets/images/digitization/digitizationItemBg1.jpg') + ')'}">
                <div class="icon"><img src="~@/assets/images/digitization/digitizationIcon1.png" alt=""></div>
                <h4>建筑市场监管<br>
                  云平台</h4>
                <div class="i"></div>
              </div>
              <div class="txt">
                <p>以实现各系统互联互通为基础，结合数字化技术，为住建主管部门打造的一体化监管平台</p>
              </div>
            </router-link>
            <router-link class="item" to="/digitization/scene">
              <div class="pic" :style="{backgroundImage: 'url(' + require('@/assets/images/digitization/digitizationItemBg2.jpg') + ')'}">
                <div class="icon"><img src="~@/assets/images/digitization/digitizationIcon2.png" alt=""></div>
                <h4>施工现场质量<br>
                  安全监管平台</h4>
                <div class="i"></div>
              </div>
              <div class="txt">
                <p>提高建筑市场监管覆盖面，动态记录工程项目行为，加强社会监督，确保质量安全</p>
              </div>
            </router-link>
            <router-link class="item" to="/digitization/industrialization">
              <div class="pic" :style="{backgroundImage: 'url(' + require('@/assets/images/digitization/digitizationItemBg3.jpg') + ')'}">
                <div class="icon"><img src="~@/assets/images/digitization/digitizationIcon3.png" alt=""></div>
                <h4>建筑工业化<br>
                监管平台</h4>
                <div class="i"></div>
              </div>
              <div class="txt">
                <p>政府部门提供覆盖全施工生命周期的<br>
                数字化监管解决方案</p>
              </div>
            </router-link>
            <router-link class="item" to="/digitization/wisdom">
              <div class="pic" :style="{backgroundImage: 'url(' + require('@/assets/images/digitization/digitizationItemBg4.jpg') + ')'}">
                <div class="icon"><img src="~@/assets/images/digitization/digitizationIcon4.png" alt=""></div>
                <h4>建筑工程智慧<br>
                  安全监管平台</h4>
                <div class="i"></div>
              </div>
              <div class="txt">
                <p>建筑工程智慧安全监管平台保障施工安<br> 
                全的智能化解决方案</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="tabItem" v-show="active === 2">
          <div class="topBox">
            <img src="~@/assets/images/digitization/digitizationPic2.jpg" alt="">
            <div class="txt">
              <h2>企业数字化</h2>
              <h4>Digitalization of <br>
                Enterprises</h4>
            </div>
          </div>
          <div class="ul">
            <h3 class="cTil"><em>主页</em></h3>
            <div class="box">
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/enterpriseend')">主页</van-button>
            </div>
            <h3 class="cTil"><em>工程云</em></h3>
            <div class="box">
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/tendering')">招投标管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/capitaldig')">资金管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/riskcontrol')">风控管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/management')">劳务管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/scene')">现场管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/contract')">合同管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/costcontrol')">成本管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/materialdig')">物资管理</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/subcontracting')">分包管理</van-button>
            </div>
            <h3 class="cTil"><em>企业SaaS</em></h3>
            <div class="box">
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/profsubcontra')">专业分包商</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/consgeneral')">施工总承包商</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/ownerinvestor')">业主（投资方）</van-button>
              <van-button class="btn" type="primary" color="#fbeddc" round @click="skipOutsideChain('https://www.huajiantong.com/digital/elplatform')">EL数字生态平台</van-button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { skipOutsideChain } from "@/api/public";
import { ref } from "vue";
const active = ref(1);
const changeFn = (i) => {
  if(i === 0){
    active.value = 1;
    skipOutsideChain('https://market.caih.com/');
  }
  
}
</script>

<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
main {
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/seekBg.jpg) repeat-x;
  background-size: .4rem;
}
.topBox {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  margin-bottom: .6rem;
  .txt{
    position: absolute;
    left: .4rem;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    letter-spacing: .04rem;
    background: linear-gradient(to bottom, #fff, #d9d9d9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.24rem;
    color: rgba(176, 193, 202, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.bd{
  padding-top: .3rem;
}
.item{
  border-radius: var(--border-radius);
  background: #fff;
  box-shadow: var(--box-shadow);
  height: 2.75rem;
  display: flex;
  margin-bottom: .3rem;
  align-items: center;
  overflow: hidden;
  .pic{
    width: 2.04rem;
    height: 100%;
    padding: .3rem 0 0 .2rem;
    background: no-repeat center;
    background-size: cover;
    border-radius: var(--border-radius);
    .icon{
      display: flex;
      align-items: center;
      width: .54rem;
      height: .54rem;
      margin-bottom: .2rem;
    }
    h4{
      font-size: .26rem;
      color: #bdb6a7;
      line-height: .34rem;
    }
    .i{
      margin-top: .33rem;
      width: .35rem;
      height: .18rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAASCAYAAADR/2dRAAAAiklEQVRIicXVwQ2AIAwF0I+jcWMDNtQJ9KSr1WCMwUDLif5/JYGXtkAQEZRcx4aJkXfroB0RU8YyU9CJWItemLoiKsizMkOQd5tMkDcGFoiBgQZiYdADhXNfzevmGWZlmni9wFq+rsSUA7My9Xg888PCNBCQMF0ICBgVAmeMCYEjZggpYX6U/wC4AYSsHjWLNp3IAAAAAElFTkSuQmCC) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .txt{
    flex: 1;
    padding: 0 .4rem 0 .3rem;
  }
}
.border{
  :deep(.van-tabs__nav){
    .van-tabs__line{
      width: 1.4rem !important;
    }
  }
}
.iconUl{
  .li {
    position: relative;
    padding: 0.46rem 0.3rem .6rem;
    display: flex;
    min-height: 2.7rem;
    align-items: flex-start;
    margin-bottom: 0.3rem;
    border-radius: 0.28rem;
    background: #fff;
    box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
  }
  .icon {
    width: 0.88rem;
    height: 0.88rem;
    border: 0.01rem solid #e1e1e1;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 0.3rem;
  }
  
  .more{
    position: absolute;
    right: .3rem;
    bottom: .2rem;
    font-size: .22rem;
    color: #999;
    line-height: .4rem;
    padding-right: .2rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAPCAYAAADZCo4zAAABBElEQVQYlWWQIUsEURSFv/NmNNosJv+FqMVgE+yistVF1GBYsGgwLiiK4oDFIPoDDIJ/QKPFJIhJsAmCYXhz5A27sjMeeOHd+3HuvUdFUfQljQO9qqp+QgjYrp8kcmDO9iwQgC3AjCgVu8AbsAkc0lICnoEV2x+S9mzvtB3SvKeyLDuSvoE+sN4ABnoANgABF5KW20DSte1tqJe/BBbaADHG8xjjiaRJ4OgfkGXZNDA/+N4lqz/Zngoh3EqaAU6Bg1GHCUlXtlPzBthNE2tAUp5l2ZntReA+hNCVVA6vGJN0nOf5GvAoqQN8DW3TDvu2U8wvklJAn40kbS8B75JWgdfGScAvl3lVYiDwrr0AAAAASUVORK5CYII=) no-repeat right center;
    background-size: .08rem;
  }
  .txt {
    flex: 1;
    .row {
      margin-bottom: 0.19rem;
      * {
        display: inline-block;
        vertical-align: middle;
      }
    }
    h4 {
      font-size: 0.28rem;
      color: #2d322d;
      line-height: 0.42rem;
    }
    p {
      font-size: 0.22rem;
      color: #646464;
      line-height: 0.36rem;
    }
  }
}
.tabItem{
  .box{
    margin: .4rem 0 .16rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .van-button{
      width: 2rem;
      height: .56rem;
      line-height: .56rem;
      margin-bottom: .36rem;
      color: #2d322d !important;
      border: 0 none;
      &:active{
        color: #f70000 !important;
      }
    }
  }
}
</style>

