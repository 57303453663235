<template>
  <div class="wrap">
    <nav-ber :title="'人力劳务'"></nav-ber>
    <main>
        <div class="topBox">
        <h2>人力劳务</h2>
        <h4>Find labor workers</h4>
      </div>
      <div class="content">
        <section class="optimization">
          <h2 class="cTil"><em>劳务优选</em></h2>
          <ul>
            <li @click="skipOutsideChain('https://m.bidding.guangyuyun.com/')">
              <div class="dt">
                <div class="state">招标中</div>
                <h4>保利和松苑一期模板工程</h4>
                <p>特区建工集团盐田先进制造业园区启动区优质产业空间供给试点项目位于深圳市盐田区盐田港后方陆域...</p>
                <div class="info">
                  <div class="p">广东 佛山</div>
                  <div class="p">95542㎡</div>
                  <div class="p">80万</div>
                </div>
              </div>
              <div class="dd">
                <div class="p">投标条件：未认证</div>
                <div class="time">2023-07-23 13:30:33</div>
              </div>
            </li>
            <li @click="skipOutsideChain('https://m.bidding.guangyuyun.com/')">
              <div class="dt">
                <div class="state">招标中</div>
                <h4>保利和松苑一期模板工程</h4>
                <p>特区建工集团盐田先进制造业园区启动区优质产业空间供给试点项目位于深圳市盐田区盐田港后方陆域...</p>
                <div class="info">
                  <div class="p">广东 佛山</div>
                  <div class="p">95542㎡</div>
                  <div class="p">80万</div>
                </div>
              </div>
              <div class="dd">
                <div class="p">投标条件：未认证</div>
                <div class="time">2023-07-23 13:30:33</div>
              </div>
            </li>
          </ul>
          <van-button class="more" plain hairline type="primary" block color="#9f9f9f" style="height: .9rem; border-color: #e6e6e6; margin-top: .6rem;" @click="skipOutsideChain('https://m.bidding.guangyuyun.com/')">查看更多</van-button>
        </section>
        <section class="jgj">
          <h2 class="cTil"><em>吉工家</em></h2>
          <ul>
            <li>
              <div class="dt">
                <h4>板房拆塔1-2人</h4>
                <p>龙桥贝艾尼厂内招板房现金20元</p>
                <div class="labels">
                  <div class="label">板房拆塔</div>
                </div>
                <div class="location">四川成都</div>
                <div class="time">2023-07-23 13:30:33</div>
              </div>
              <div class="dd">
                <div class="user">
                  <div class="icon"><img src="~@/assets/images/worker/workerIconUser.jpg" alt=""></div>
                  <div class="p">四川成都</div>
                </div>
                <div class="btn" @click="tojgj">立即沟通</div>
              </div>
            </li>
            <li>
              <div class="dt">
                <h4>板房拆塔1-2人</h4>
                <p>龙桥贝艾尼厂内招板房现金20元</p>
                <div class="labels">
                  <div class="label">板房拆塔</div>
                </div>
                <div class="location">四川成都</div>
                <div class="time">2023-07-23 13:30:33</div>
              </div>
              <div class="dd">
                <div class="user">
                  <div class="icon"><img src="~@/assets/images/worker/workerIconUser.jpg" alt=""></div>
                  <div class="p">四川成都</div>
                </div>
                <div class="btn" @click="tojgj">立即沟通</div>
              </div>
            </li>
          </ul>
          <van-button class="more" plain hairline type="primary" block color="#9f9f9f" style="height: .9rem; border-color: #e6e6e6; margin-top: .6rem;" @click="tojgj">查看更多</van-button>
        </section>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { skipOutsideChain } from '@/api/public';

// 吉工家
function tojgj(){
  let url = "pages/tabBar/tab2/index?siyu_source=sfhl_dx_jzzgzh";
  url = encodeURIComponent(url)
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${url}&appId=gh_dc82dfffc292`
}
</script>
<style scoped lang="scss">
.topBox {
  height: 3rem;
  padding: 1rem 0.4rem 0;
  background: url(~@/assets/images/worker/workerTopBg.jpg) no-repeat top center;
  background-size: 100%;
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    margin-bottom: .1rem;
    background: linear-gradient(to bottom, #ffbb00, #ffa200);  
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.29rem;
    color: rgba(255, 255, 255, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.content {
  position: relative;
  z-index: 5;
  overflow: hidden;
  padding-bottom: 1.6rem;
  margin-top: -0.22rem;
  border-radius: 0.28rem 0.28rem 0 0;
  padding: .6rem .4rem;
  background: url(~@/assets/images/enterprise/enterpriseBg.jpg) repeat-x top;
  background-size: .6rem;
}
.jgj{
  padding-top: .5rem;
  li {
    padding: .3rem;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    margin-bottom: .3rem;
    .dt{
      position: relative;
      padding-bottom: .2rem;
      border-bottom: .02rem solid #f3f3f3;
    }
    h4{
      font-size: .28rem;
      color: #2d322d;
      line-height: .38rem;
      margin-bottom: .15rem;
    }
    .labels{
      display: flex;
      flex-wrap: wrap;
      margin: .13rem 0 0;
      .label{
        height: .4rem;
        line-height: .4rem;
        font-size: .2rem;
        color: #3a8dea;
        background: #e8eff7;
        border-radius: .4rem;
        padding: 0 .22rem;
        margin: 0 .2rem .2rem 0;
      }
    }
    .location{
      font-size: .2rem;
      color: #646464;
      line-height: .3rem;
      padding-left: .24rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAATCAYAAABLN4eXAAABTElEQVQokYWTzytEURTHP4aFlQ0rNRslC0sbjUnfja0srGYnhaRITf4LKaVGs8GOhfxYjMQcG0srQpGdrZUyodHhvenOm3n5ru79fs/n3Xtu53XU63Vimdk0MAPkgE7gG7gGypIO47oGZGZlYJZ0lSQteJqJgNI/gGvezDZ/T6pWq+PAVaJgG7gDhoG5RJbvAoqB8QVMSLKgz33gLOrRterXGw2g9RBwSboANgIr51BPYByl9HMarHsd+gyMvhQo9GsOvQRGsQ3gWgnWzw6dB0bezHbMLMvfI2TNbA8YC2oq/uRDwEPiyx/AK9APdCeywYykR+AyEXjhQBvgRNJTJtosp/SS1BrxGEm6BXb/AXxo7xtQpEXgPQV4A5biTQOS5EAhBSpIqrVAEXjs10gAW5IqodH0E8YyM+/RJ/xG0kgybzop0CRwAEy1JMAPlRZgWGfHW3wAAAAASUVORK5CYII=) no-repeat left center;
      background-size: .13rem;
    }
    .time{
      position: absolute;
      right: 0;
      bottom: .2rem;
      font-size: .2rem;
      color: #bdbdbd;
      line-height: .3rem;
    }
    .dd{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: .3rem;
      .user{
        display: flex;
        align-items: center;
        .icon{
          border-radius: 50%;
          width: .52rem;
          height: .52rem;
          background: #fff;
          margin-right: .16rem;
          overflow: hidden;
        }
      }
      .btn{
        width: 1.66rem;
        height: .64rem;
        font-size: .22rem;
        color: #fff;
        line-height: .64rem;
        padding-left: .57rem;
        background: #f70000 url(~@/assets/images/worker/workerIconWx.png) no-repeat .24rem center;
        background-size: .24rem;
        border-radius: .64rem;
      }
    }
  }
}
.optimization{
  li{
    padding: .3rem;
    background: #f9f9f9;
    border: .01rem solid #ddd;
    margin-bottom: .3rem;
    border-radius: var(--border-radius);
    .dt{
      position: relative;
      padding-bottom: .2rem;
      border-bottom: .01rem solid #e6e6e6;
    }
    .state{
      position: absolute;
      right: 0;
      top: -.01rem;
      width: 1.2rem;
      height: .4rem;
      line-height: .4rem;
      text-align: center;
      font-size: .2rem;
      color: #fff;
      background: #f78600;
      border-radius: .4rem;
    }
    h4{
      font-size: .28rem;
      color: #2d322d;
      line-height: .38rem;
      margin-bottom: .38rem;
      padding-right: 1.4rem;
    }
    .info{
      margin-top: .54rem;
      display: flex;
      margin-right: -.5rem;
      .p{
        font-size: .2rem;
        color: #3a8dea;
        line-height: .3rem;
        margin-right: .5rem;
      }
    }
    .dd{
      padding-top: .2rem;
      display: flex;
      justify-content: space-between;
      .p{
        font-size: .2rem;
        color: #646464;
        line-height: .3rem;
      }
      .time{
        font-size: .2rem;
        color: #bdbdbd;
        line-height: .3rem;
      }
    }
  }
}
</style>

  
  