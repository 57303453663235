<template>
     <div class="inside-top">
    <div class="sub-ban" >
      <div
        class="bg"
        :style="'background-image:url(' +       props.banInfo.banUrl + ');'"
      ></div>

      <div class="w1440" >
        <div class="con">
          <h5 class="wow fadeInUp50" v-html="props.banInfo.tilCn"></h5>
        <div  class="h6 wow fadeInUp50" v-html="props.banInfo.tilTxt"></div>
        <div class="linka wow fadeInUp50" >
          <router-link class="a" :to="{path:'/consultform', query:{'title': props.banInfo.txton, 'plateArr': props.banInfo.tilCn}}">{{props.banInfo.txton}}</router-link>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  banInfo: {
    type: Object,
    default() {
      return {
        banUrl: "",
        tilCn: "",
      };
    },
  },
});
console.log(props.banInfo,"值") 
</script>
<style scoped lang="scss">
.inside-top{
    .sub-ban{
    height: 4rem; 
    position: relative;
      .bg {
    height: 100%;
    background: no-repeat 64%; 
    background-size: cover; 
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 0;
    height: 100%; 
  }
   .w1440 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 5;
    .con{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
    h5{
      font-size: .36rem;
      margin-bottom: .15rem;
      color: #333333;
    }
    .h6{
      font-size: .24rem;
      line-height: .32rem;
        color: #666666;
    }
    .w1440 {
      .con{
        padding: 0 .3rem;
      }
    }
    .linka {
    margin-top: 0.5rem;
      .a{
        border-radius: 1rem;
    color: #fff;
    line-height: .8rem;
    width: 1.8rem;
    font-size: .24rem;
        background: #d7000f;
        text-align: center;
      }
    }
  }
}
</style>