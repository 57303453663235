<template>
  <div class="wrap">
    <nav-ber :title="'企业数字化专区'"></nav-ber>
    <div class="enterpriseend">
      <div class="banvideo">
        <div class="sub-ban">
          <div class="con">
            <h5>企业数字化</h5>
            <p>
              华建通实现了业务的数字化和智能化，提高了企业的竞争力和创新能力。<br />这使得华建通能够更好地适应数字时代的商业环境，并为客户提供更优质、高效的解决方案。
            </p>
          </div>
        </div>
      </div>
      <section class="enter-npm1" v-if="apiData1">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5>企业Saas方案</h5>
          </div>
          <div class="below">
            <a
              :href="item.address"
              class="item wow fadeInUp50"
              v-for="(item, i) in apiData1"
              :key="item.newsId"
            >
              <div class="fl">
                <img :src="    item.newsAccess" alt="" />
              </div>
              <div class="fr">
                <div class="h5" v-html="item.newsTitle"></div>
                <div class="p" v-html="item.newsContent"></div>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section class="enter-npm2" v-if="apiData2">
        <div class="w1440">
          <div class="pagetit wow fadeInUp50">
            <h5>工程云方案</h5>
          </div>
          <div class="box">
            <div class="ul">
              <a
                :href="item.address"
                class="li wow fadeInUp50"
                v-for="(item, i) in apiData2"
                :key="item.newsId"
              >
                <div class="ico">
                  <img :src="    item.newsAccess" alt="" />
                </div>
                <div class="txt">
                  <h5 v-html="item.newsTitle"></h5>
                  <div class="p" v-html="item.newsContent"></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
    
<script setup >
import { getInfo } from "@/api/http";
import NavBer from "@/components/NavBer.vue";
import { ref, onMounted } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);

const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: "827",
  });
  const { data: data2 } = await getInfo({
    coId: "777",
  });
  const { data: data3 } = await getInfo({
    coId: "829",
  });
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
};
getList();
</script>
<style lang="scss" scoped>
main{padding: 0;}
@media (max-width: 750px) {
  .enterpriseend {
    .banvideo {
      position: relative;
      height: 5rem;
      background: url(~@/assets/images/digital/banmp4.jpg) no-repeat center;
      background-size: cover;
      .sub-ban{
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        padding: 0 .4rem;
        transform: translateY(-50%);
        h5{
            color: #333;
            text-align: center;
            font-size: .36rem;
            margin-bottom: .15rem;
        }
        p{
            font-size: .26rem;
            line-height: .4rem;
            text-align: center;
            color: #666;
        }
      }
    }
    .enter-npm1 {
      margin-top: 0;
      padding: 0.6rem 0 .3rem;
      .above {
        flex-wrap: wrap;
        padding-bottom: 0.3rem;
        .box {
          flex: 1;
          display: block;
          margin-bottom: 0.1rem;
        }
        .item {
          a {
            margin: 0 0.3rem 0 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .below {
        flex-wrap: wrap;
        margin-top: 0.4rem;
        .item {
          display: block;
          width: 100%;
          margin-bottom: 0.3rem;
          overflow: hidden;
          .fl {
            display: block;
            width: 100%;
          }
          .fr {
            display: block;
            width: 100%;
            background: #f3f4f7;
            padding: 0.5rem 0.3rem;
            .h5 {
              font-size: 0.28rem;
              color: #000;
              line-height: 0.38rem;
              padding-bottom: 0.3rem;
            }
            .p {
              p {
                line-height: 0.4rem;
              }
            }
          }
        }
      }
    }
  }
}

.enter-npm2 {
    padding: 0;
    .pagetit{
        margin-bottom: .4rem;
    }
  .li {
    position: relative;
    padding: 0.3rem;
    display: flex;
    min-height: 2.1rem;
    align-items: flex-start;
    margin-bottom: 0.3rem;
    border-radius: 0.28rem;
    background: #f3f4f7;
  }
  .ico {
    display: flex;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    overflow: hidden;
    margin-top: .14rem;
    margin-right: 0.3rem;
    background: #fff;
    justify-content: center;
    align-items: center;
    img{
        max-width: .4rem;
        max-height: .4rem;
    }
  }
  .txt {
    flex: 1;
    padding: 0.07rem 0 0;
    .row {
      margin-bottom: 0.14rem;
      * {
        display: inline-block;
        vertical-align: middle;
      }
    }
    h4 {
      font-size: 0.28rem;
      color: #2d322d;
      line-height: 0.42rem;
    }
    .label {
      height: 0.32rem;
      line-height: 0.32rem;
      padding: 0 0.13rem;
      border-radius: 0.32rem;
      color: #fff;
      background: #f70000;
      margin-left: 0.16rem;
    }
    p {
      position: relative;
      font-size: 0.22rem;
      color: #646464;
      line-height: 0.36rem;
    }
  }
}
</style>
  