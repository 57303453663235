<template>
  <div class="wrap">
    <nav-ber title="我要提高诚信分"></nav-ber>
    <main>
      <section class="lump1">
        <h3 class="cTil"><em>华建通建筑监管数据小程序</em></h3>
        <div class="txt">
          <p>
            华建通建筑监管数据小程序着重对企业诚信分进行智能预警监控，密切留意企业资质到期、诚信分降低、不良行为出现等关键要素，一旦异常即刻发出预警，利于企业及时处理潜在问题以确保合规运营，为其长远发展提供保障。该小程序还将信用评分数据、实名考勤数据与工程报监数据加以融合，给予企业全面且准确的数据支持。
          </p>
        </div>
      </section>
      <section class="lump2">
        <div class="chart">
          <ul>
            <li>
              <div class="num">01</div>
              <div class="p">加分政策及活动</div>
            </li>
            <li>
              <div class="num">02</div>
              <div class="p">诚信分预警</div>
            </li>
            <li>
              <div class="num">03</div>
              <div class="p">诚信分评估与监测</div>
            </li>
            <li>
              <div class="num">04</div>
              <div class="p">诚信分排行</div>
            </li>
          </ul>
        </div>
      </section>
      <section class="lump3">
        <h3 class="cTil"><em>账号开通流程</em></h3>
        <div class="ul">

            <div class="li">
            <div class="txt">
              <div class="i"><img src="https://www.huajiantong.com/images/industrialbrain/ico5.png" alt=""/></div>
              <p>联系客服</p>
              <em>01</em>
            </div>
          </div>
          <div class="li">
            <div class="txt">
              <div class="i"><img src="https://www.huajiantong.com/images/industrialbrain/ico6.png" alt=""/></div>
              <p>提供企业信息</p>
              <em>02</em>
            </div>
          </div>
          <div class="li">
            <div class="txt">
              <div class="i"><img src="https://www.huajiantong.com/images/industrialbrain/ico7.png" alt=""/></div>
              <p>签署合同</p>
              <em>03</em>
            </div>
          </div>
          <div class="li">
            <div class="txt">
              <div class="i"><img src="https://www.huajiantong.com/images/industrialbrain/ico8.png" alt=""/></div>
              <p>开通账号</p>
              <em>04</em>
            </div>
          </div>
          <div class="li">
            <div class="txt">
              <div class="i"><img src="https://www.huajiantong.com/images/industrialbrain/ico9.png" alt=""/></div>
              <p>登录小程序</p>
              <em>05</em>
            </div>
          </div>
        </div>
      </section>
      <van-button class="cApplyBtn" @click="show = true">免费试用</van-button>
        <van-dialog v-model:show="show" title="免费试用" show-cancel-button confirmButtonText="跳转" @confirm="confirm">
            <div class="txt">
                <div class="p">您可以使用以下账号和密码进行试用。</div>
                <div class="strong">账号:18888888888</div>
                <div class="strong">密码:18888888888</div>
            </div>
        </van-dialog>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { showDialog } from 'vant';
import { ref } from 'vue';
const escape = (url) => {
  return encodeURIComponent(url);
};
const show = ref(false);
function toWeChat(url){
  const appIdMatch = url.match(/appId=(.*?)(?=&|$)/);
  const pubAppIdMatch = url.match(/pubAppId=(.*?)(?=&|$)/);
  const cleanedUrl = url.replace(/&?(appId|pubAppId)=[^&]*/g, '');
  let link = encodeURIComponent(cleanedUrl)
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${link}&${appIdMatch[0]}&${pubAppIdMatch[0]}`
}
const confirm = () => {
    toWeChat('pages/home/index&appId=gh_02755cac7273&pubAppId=wxe7173fd553918ca9')
}
</script>
<style lang="scss" scoped>
.chart {
  height: 4.2rem;
  border-radius: 0.2rem;
  background: url(~@/assets/images/industrialbrain/integrityChart.jpg) no-repeat
    center;
  background-size: cover;
  padding: 1.1rem 0.2rem 0;
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  li {
    width: 2.08rem;
    height: 1.14rem;
    background: linear-gradient(to bottom, #fff, #fcf1f0);
    border-radius: 0.2rem;
    padding: 0.2rem;
    margin-bottom: 0.26rem;
    &:nth-child(even) {
      margin-left: 2rem;
    }
    .num {
      font-size: 0.24rem;
      color: #ec3d22;
      line-height: 0.3rem;
      font-family: "Gilroy-Bold";
      margin-bottom: 0.1rem;
    }
    .p {
      font-size: 0.22rem;
      color: #333;
      line-height: 0.36rem;
      white-space: nowrap;
    }
  }
}
section {
  margin-bottom: 0.6rem;
  .cTil {
    margin-bottom: 0.33rem;
    text-align: center;
    em:after {
      right: 0;
      margin: 0 auto;
    }
  }
}
.lump3{
  .ul{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin:.7rem -.4rem 0;
  }
  .li {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: var(--box-shadow);
    display: flex;
    height: 1.6rem;
    justify-content: center;
    width: 1.6rem;
    padding-top: .2rem;
    margin: 0 .3rem .4rem;
    position: relative;
    .i{
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: .4rem;
      max-width: .4rem;
      margin: 0 auto .1rem;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    p {
      color: gray;
    }
    em {
      align-items: center;
      background: #d7000f;
      border-radius: 50%;
      color: gray;
      color: #fff;
      display: flex;
      font-family: "Gilroy-Bold";
      font-size: .24rem;
      height: .5rem;
      justify-content: center;
      margin: auto;
      position: absolute;
      left: 50%;
      top: -.25rem;
      width: .5rem;
      margin-left: -.25rem;
    }
  }
}
:deep(.van-dialog){
    .txt{
        padding: .4rem;
    }
    .p, .strong{
        font-size: .26rem;
        line-height: .46rem;
        color: #333;
        text-align: center;
    }
    .p{
        margin-bottom: .2rem;
    }
    .strong{
        font-weight: 600;
    }
}
</style>