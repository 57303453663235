<template>
  <div class="wrap">
    <nav-ber :title="'智慧印章解决方案'"></nav-ber>
    <main class="signet">
      <Cbanner :list="apiData1"></Cbanner>
      <section class="constru-npm3">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData5[0].newsTitle"></h5>
          <h6 v-html="apiData5[0].email"></h6>
        </div>
        <div
          class="h5 wow fadeInUp50"
          v-html="apiData5[0].newsContent"
        ></div>
        <ul class="wow fadeInUp50">
          <li
            v-for="(item, i) in apiData"
            :key="item.newsId"
            class="wow fadeInUp50"
            :style="'animation-delay:' + i * 150 + 'ms'"
          >
            <div class="ico">
              <img :src="      item.image" alt="" />
            </div>
            <div class="txt">
              <h5 v-html="item.newsTitle"></h5>
              <div v-html="item.newsContent"></div>
            </div>
          </li>
        </ul>
    </section>
    <section class="bim-npm2">
        <div class="pagetit wow fadeInUp50">
          <h5 v-html="apiData2[0].newsTitle"></h5>
          <h6 v-html="apiData2[0].email"></h6>
        </div>
        <div class="box wow fadeInUp50">
          <div class="fl">
            <img
              :src="      apiData2[0].image"
              alt=""
            />
          </div>
          <div class="fr">
            <div
              class="item"
              v-for="(item, i) in apiData3"
              :key="item.newsId"
            >
              <div class="em">{{ item.newsAuthor }}</div>
              <div class="txt">
                <h5 v-html="item.newsTitle"></h5>
                <p v-html="item.newsContent"></p>
              </div>
            </div>
          </div>
      </div>
    </section>
    <section class="bim-npm1">
      <div>
        <div class="pagetit wow fadeInUp50">
          <h5>产品亮点</h5>
          <h6>Product highlights</h6>
        </div>
        <div class="tabbox wow fadeInUp50">
          <div
            class="item"
            :class="{ on: i === 0 }"
            v-for="(item, i) in apiData4"
            :key="item.newsId"
            :data-id="item.newsId"
            @click="handleClick"
          >
            {{ item.newsTitle }}
          </div>
        </div>
        <PcTabScroll
          class="mobileTabs"
          :list="apiData4"
          @itemClick="handleClick"
        ></PcTabScroll>
      </div>
      <div class="below">
          <div class="ul">
            <div
              class="li"
              v-for="(item, i) in apiData4"
              :key="item.newsId"
            >
              <div class="liflex">
                <div class="fr animated fadeInUp50">
                  <img :src="      item.newsAccess" alt="" />
                </div>
                <div class="fl animated fadeInUp50">
                  <h5 v-html="item.newsTitle"></h5>
                  <div class="p" v-html="item.newsContent"></div>
                  <div class="consulting" @click="$router.push({path: '/consultform', query: { plateArr: '智慧印章解决方案'}})">立即咨询</div>
                  <div class="emcou">
                    <div class="ico">
                      <img :src="      item.image" alt="" />
                    </div>
                  </div>
                  <em>{{ item.newsAuthor }}</em>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
    <van-button class="cApplyBtn" @click="$router.push({path: '/consultform', query: { plateArr: '智慧印章解决方案'}})">在线咨询</van-button>
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import NavBer from "@/components/NavBer.vue";
import { getInfo } from "@/api/http";
import { ref } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
const apiData5 = ref([]);
const getList = async () => {
  const { data:data1 } = await getInfo({
    coId: "521",
  });
  const { data: data2 } = await getInfo({
    coId: "275",
    pageSize: "14",
    typeId: "131",
  });
  const { data: data3 } = await getInfo({
    coId: "523",
  });
  const { data: data4 } = await getInfo({
    coId: "525",
  });
  const { data: data5 } = await getInfo({
    coId: "527",
  });
  const { data: data6 } = await getInfo({
    coId: "825",
  });
  apiData.value =data1.data;
  apiData1.value = data2.data;
apiData2.value = data3.data;
apiData3.value = data4.data;
apiData4.value = data5.data;
apiData5.value = data6.data;
};

getList();
</script>
  
<style scoped lang="scss">
.signet {
  .pagetit h5,
  .pagetit h6 {
    text-align: center;
  }
  .soluslid {
    height: 4.5rem;
    border-radius: 0;
    .swiper-pagination {
      width: 100%;
    }
    .w1440 {
      padding: 1rem .3rem 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; 
    }
    :deep(.cont) {
      h5 {
        font-size: 0.3rem;
        color: #fff;
      }
      h6 {
        font-size: 0.3rem;
        color: #fff;
        font-family: DIN-Bold;
        letter-spacing: -0.05px;
        opacity: 0.5;
        line-height: 1.1;
        text-transform: uppercase;
      }
      .p {
        max-width: 100%;
        margin-top: 0.2rem;
        p {
          color: #fff;
          font-size: 0.24rem;
          line-height: 0.32rem;
        }
      }
    }
    .img {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    :deep(.van-swipe__indicators) {
      bottom: 0.3rem;
      display: flex;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      justify-content: center;
      transform: none;
      i {
        margin: 0 0.05rem;
        width: 0.52rem;
        height: 0.04rem;
        background: #fff;
        opacity: 0.5;
        border-radius: 0;
        transition: all 0.4s;
      }
    }
  }

  .bim-npm1 {
    padding-top: 90px;
    .consulting {
      width: 110px;
      position: relative;
      z-index: 3;
      line-height: 52px;
      border-radius: 36px;
      font-size: 15px;
      background: #d7000f;
      color: #fff;
      cursor: pointer;
      text-align: center;
      display: inline-block;
    }
    .emcou {
      margin-top: 50px;
    }
    .tabbox {
      display: flex;
      margin-top: 30px;
      justify-content: center;

      .item {
        font-size: 14px;
        color: #808080;
        line-height: 52px;
        padding: 0 20px;
        text-align: center;
        margin: 0 1.5px;
        border-radius: 36px;
        cursor: pointer;

        &.on {
          color: #fff;
          background: #d7000f;
        }
      }
    }

    .mobileTabs{
      display: none;
      margin-top: .4rem;
    }

    .below {

      .liflex {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }

      .li {
        display: none;

        &:nth-child(1) {
          display: block;

          .fr {
            img {
              max-width: none;
              float: right;
            }
          }
        }
      }

      .fl {
        width: 44.0972%;
        position: relative;

        h5 {
          font-size: 22px;
          color: #333333;
          padding-bottom: 20px;
          border-bottom: 1px solid #e1e2e6;
          position: relative;
          z-index: 3;
        }

        .p {
          margin: 25px 0 30px;
          position: relative;
          z-index: 3;
        }

        .ico {
          border-radius: 50%;
          background: #fff;
          width: 84px;
          height: 84px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 3;
        }

        em {
          font-size: 315px;
          top: 75px;
          letter-spacing: -10px;
          background: linear-gradient(to bottom, #e7e8ec, #f2f3f6);
          -webkit-background-clip: text;
          color: transparent;
          font-family: "DIN-Bold";
          position: absolute;
          line-height: 1;
        }
      }

      .fr {
        width: 44.375%;

        img {
          max-width: none;
          float: right;
        }
      }
    }
  }

  .bim-npm2 {
    background: url(https://www.huajiantong.com/images/engineeringsev/bg2.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    padding: 90px 0;

    .pagetit {
      h5 {
        color: #fff;
      }

      h6 {
        color: rgba($color: #ffffff, $alpha: 0.7);
      }
    }

    .box {
      
      .fl {
        width: 49.31%;
      }

      .fr {
        width: 50.69%;
        padding: 0 40px;
        background: #ffffff;

        .item {
          padding: 30px 0 20px;
          position: relative;
          display: flex;
          justify-content: space-between;
          &:last-child {
            &::after {
              opacity: 0;
            }
          }
          &::after {
            content: "";
            width: 89.5%;
            height: 1px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: #e3e5e8;
          }
          .em {
            width: 60px;
            font-size: 60px;
            letter-spacing: -5px;
            line-height: 40px;
            font-family: "DIN-Bold";
            background: linear-gradient(to bottom, #eaeaea, #fdfdfd, #ffffff);
            -webkit-background-clip: text;
            color: transparent;
          }
          .txt {
            width: 89.5%;
            h5 {
              font-size: 18px;
              margin-bottom: 10px;
              color: #333333;
            }
          }
        }
      }
    }
  }

  .constru-npm3 {
    .h5 {
      margin: 10px 0 20px;
      text-align: center;
      font-size: 18px;
      color: #333333;
    }
    ul {
      display: flex;
      justify-content: space-between;

      position: relative;
      flex-wrap: wrap;

      li {
        background: #f3f4f7;
        width: 23.96%;
        padding: 50px 4px 40px;
        transition: all 0.4s;
        margin-bottom: 20px;
        position: relative;
        top: 0;

        &:hover {
          top: -10px;
        }

        .ico {
          border-radius: 50%;
          width: 96px;
          height: 96px;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          border-radius: 50%;
        }

        .txt {
          margin-top: 30px;

          h5 {
            font-size: 18px;
            margin-bottom: 8px;
            text-align: center;
            color: #333333;
            font-weight: bold;
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .material .bim-npm1 .below .fr img {
    transform: scale(0.8);
    transform-origin: right;
  }
}

@media (max-width: 750px) {
  .signet {
    .constru-npm3 {
      padding: 0 0 0.3rem;
      .h5 {
        font-size: 0.28rem;
        margin: 0.15rem 0 0.3rem;
      }
      ul {
        justify-content: space-between;
        li {
          width: 3.3rem;
          padding: 0 0.2rem;
          height: auto;
          margin: 0 0 0.3rem;
          padding: 0.4rem 0.2rem;
          flex-wrap: wrap;
          align-content: flex-start;
          .ico {
            width: 1.2rem;
            height: 1.2rem;
            margin: 0 auto 0.2rem;
          }
          .txt {
            width: 100%;
            margin-top: 0.3rem;
            h5 {
              font-size: 0.28rem;
              line-height: 0.4rem;
            }
            .p {
              margin-top: 0.1rem;
            }
          }
        }
      }
    }
    .bim-npm2 {
      padding: 0.6rem 0;
      .box {
        display: flex;
        margin-top: 0.4rem;
        padding: 0 .4rem;
        flex-wrap: wrap;
        .fl {
          width: 100%;
        }
        .fr {
          width: 100%;
          padding: 0 0.3rem;
          .item {
            padding: 0.3rem 0;
            .em {
              width: 1.2rem;
              font-size: 0.9rem;
              line-height: 0.7;
              letter-spacing: -0.08rem;
            }
            .txt {
              h5 {
                font-size: 0.28rem;
                margin-bottom: 0.1rem;
              }
            }
          }
        }
      }
    }
    .bim-npm1{
      padding-top: .6rem;
      .tabbox {
        display: none;
      }
      .mobileTabs {
        display: block;
      }
      .below {
        height: auto;
        padding: 0.6rem .4rem;
        margin: 0.6rem 0;
        background: #f3f4f7;
        .liflex {
          flex-wrap: wrap;
        }
        .fl {
          width: 100%;
          order: 1;
          h5{
            font-size: .32rem;
            line-height: calc(100% + .1rem);
            padding-bottom: .3rem;
          }
          .p{
            margin: .4rem 0;
          }
          .consulting{
            width: 1.66rem;
            height: 0.76rem;
            line-height: 0.76rem;
            font-size: 0.26rem;
          }
          .ico{
            width: 1.3rem;
            height: 1.3rem;
          }
          em{
            font-size: 4.8rem;
            top: 1.6rem;
          }
        }
        .fr {
          order: 2;
          width: 100%;
          margin-top:.5rem;
          img{
            max-width:100% !important;
          }
        }
      }
      .emcou{
        margin-top: .8rem;
      }
    }
  }
}
</style>
  
  