<template>
  <div class="wrap">
  <div class="profsubcontra">
    <nav-ber :title="'专业分包方'"></nav-ber>
    <main>
      <sub-ban :ban-info="banInfo"></sub-ban>
      <section class="prof-npm1">
        <div class="w1440">
          <div class="pagetit ">
            <h5 v-html="apiData2[0].newsTitle"></h5>
            <div class="p" v-html="apiData2[0].newsContent">
            
            </div>
          </div>

          <div class="ul">
            <a class="li" v-for="(item, i) in apiData3" :key="item.newsId" 
            :target="item.address ? '_blank' : '_self'" 
          :href="item.address ? item.address : `/digital/page?newsId=${item.newsId}`"
            >
              <img
                :src="item.newsAccess"
                alt="">
              <h5 v-html="item.newsTitle"></h5>
            </a>
          
          </div>
        </div>
      </section>
      <div v-html="apiData2[0].newsTags"></div>
    </main>
  </div>
</div>
</template>
  
<script setup >
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const banInfo = {
  banUrl: '',
  tilCn: '',
  tilTxt: '',
  link: '',
  coId: "",
  enlink: true,
  txton: "免费试用",
};
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: "423",  
    typeId:"4365",
  });
  const { data: data2 } = await getInfo({
    coId: "945",  
  });
  const { data: data3 } = await getInfo({
    coId: "947"
  });
  apiData1.value = data1.data;
  apiData2.value = data2.data;
  apiData3.value = data3.data;
  banInfo.banUrl = apiData1.value[0].image;
  banInfo.tilCn = apiData1.value[0].newsTitle;
  banInfo.tilTxt = apiData1.value[0].newsContent;
};
onMounted(async () => {
   getList();
});
</script>
<style lang="scss">
.profsubcontra {
  main{padding: 0;}
  .w1440 {
    max-width: 1150px;
  }

  .bgop {
    background-color: #f3f4f7;
    padding: 90px 0;
  }

  .pagetit {
    h5 {
      text-align: center;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }

    .p {
      margin-top: 20px;

      p {
        text-align: center;
      }
    }
  }

  .prof-npm1 {
    padding: 90px 0;

    .ul {
      margin-top: 45px;
    }

    .li {
      display: inline-block;
      width: 23.6%;
      margin-right: 1.3889%;
      margin-bottom: 1.3889%;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      h5 {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        bottom: 20px;
        position: absolute;
        width: 100%;
        padding: 0 10px;
      }
    }
  }

  .prof-npm2 {
    background-color: #f3f4f7;
    padding: 90px 0;

    .ul {
      margin-top: 45px;
      display: flex;
      justify-content: space-between;

      .li {
        width: 30%;
        background: #fff;
        padding: 50px 20px;
        transition: all .4s;
        position: relative;
        top: 0;

        &:hover {
          top: -10px;
        }

        .i {
          text-align: center;
          max-width: 130px;
          margin: auto;
        }

        h5 {
          font-size: 18px;
          font-weight: 700;
          margin: 20px 0 10px;
          text-align: center;
        }

        p {
          text-align: center;
        }
      }
    }
  }

  .prof-npm3 {
    padding: 90px 0;

    .img {
      margin-top: 45px;
      text-align: center;

      p {
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .prof-npm4 {
    background-color: #f3f4f7;
    padding: 90px 0;

    .ul {
      margin-top: 45px;
      display: flex;
      justify-content: space-between;

      .li {
        width: 19%;
        background: #fff;
        padding: 50px 10px;
        transition: all .4s;
        position: relative;
        top: 0;

        &:hover {
          top: -10px;
        }

        h5 {
          font-size: 18px;
          font-weight: 700;
          margin: 0 0 10px;
          text-align: center;
        }

        p {
          text-align: center;
        }
      }
    }
  }

  .prof-npm5 {
    .ul {
      .li {
        padding: 50px 30px;
      }
    }
  }

  .prof-npm6 {
    padding: 90px 0;

    .ul {
      margin-top: 45px;

      .li {
        display: flex;
        margin-bottom: 20px;

        .img {
          width: 210px;
        }

        .txt {
          margin-left: 40px;
          margin-top: 30px;
          width: calc(100% - 250px);

          h5 {
            font-size: 30px;
            margin-bottom: 10px;
            color: #333333;
          }
        }
      }
    }
  }

  .prof-npm7 {
    background-color: #f3f4f7;

    .img {
      background: #fff;
      padding: 30px 20px;
    }
  }

  .prof-npm8 {
    padding: 90px 0;

    .img {
      margin-top: 45px;
    }

    .ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      .li {
        background: #f3f4f7;
        min-height: 254px;
        padding: 40px 30px;
        width: 32%;
        margin-right: 2%;
        margin-bottom: 2%;
        display: inline-block;

        &:nth-child(3n) {
          margin-right: 0;
        }

        em {
          color: #d7000f;
          font-family: AlimamaShuHeiTi;
          font-size: 30px;
        }

        .p {
          margin-top: 20px;
        }
      }
    }
  }

  .prof-npm9 {
    .box1 {
      margin: 45px auto 0;
      background: rgba(245, 249, 255, .8);
      padding: 30px 30px 50px;
      max-width: 85%;
      border-radius: 5px;

      .fl,
      .fr {
        width: 48%;
      }

      h5 {
        font-size: 18px;
        font-weight: bold;
        margin: 20px 0 10px;
      }
    }
  }

  .prof-npm10 {
    .ul {
      margin-top: 45px;
      display: flex;

      .li {
        display: inline-block;
        width: 23.6%;
        margin-right: 1.3889%;
        margin-bottom: 1.3889%;
        overflow: hidden;
        position: relative;
      }

      .txt {
        background: #fff;
        margin-top: 15px;
        padding: 20px 10px;
        min-height: 150px;

        h5 {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .prof-npm11 {
    h6 {
      border-radius: 36px;
      color: #fff;
      padding: 5px 10px;
      margin-bottom: 10px;

      display: table;
    }

    .clear {
      margin-top: 20px;
    }

    .fl {
      h6 {
        background: #d7000f;
      }
    }

    .fr {

      h6 {
        background: #3cb166;
      }
    }
  }

  .prof-npm12 {
    .img {
      max-width: 80%;
      margin: 45px auto 0;
    }

    .box {
      h5 {
        text-align: center;
        font-size: 28px;
        margin-top: 10px;
        color: #333333;
        font-weight: bold;
      }

      .ul {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .li {
          width: 19%;
          background: #fff;
          padding: 50px 20px;
          transition: all 0.4s;
          position: relative;
          top: 0;

          h6 {
            font-size: 18px;
            font-weight: 700;
            margin: 0 0 10px;
            text-align: center;
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }

  .prof-npm13 {
    padding: 90px 0;

    .img {
      margin-top: 45px;
      text-align: center;
    }
  }

  .prof-npm14 {
    padding: 90px 0;

    .itembox {
      margin-top: 45px;

      .yuanbox {
        background: #d7000f;
        padding: 20px 80px;
        margin: auto;
        display: table;
        color: #fff;
        font-size: 18px;
           
        line-height: 28px;
      }

      .dl {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .dd {
          width: 19%;
          background: #f3f4f7;
          padding: 20px 10px;

          h5 {
            font-size: 18px;
            font-weight: 700;
            margin: 0 0 10px;
            text-align: center;
          }

          p {
            text-align: center;
          }
        }
      }
    }

    .img {
      margin-top: 45px;
    }

    .box {
      .clear {
        margin-top: 45px;
      
        &:nth-child(2n) {
          .fl {
            float: right;
            width: 50%;
          }

          .fr {
            float: left;
            width: 40%;
          }
        }
        &.txqk{
          .fl{
            width: 100%;
            margin-bottom: 20px;
            .ul{
              display: flex;
              justify-content: space-between;
              .li{
                width: 48%;
              }
            }
          }
          .fr{
            width: 100%;
          }
        }
      }
    }

    .fl {
      width: 60%;

      h5 {
        font-size: 18px;
        font-weight: bold;
        margin: 20px 0 10px;
      }

      em {
        border-radius: 36px;
        color: #fff;
        line-height: 1;
        padding: 5px 10px;
        margin-bottom: 10px;
        display: table;
      }

      .li {
        margin-top: 15px;

        h6 {
          margin: 20px 0 5px;
        }

        &.i1 {
          em {
            background: #d7000f;
          }
        }

        &.i2 {
          em {
            background: #3cb166;
          }
        }
      }
    }

    .fr {
      width: 35%;
    }
  }
}

@media (max-width: 600px){
  .profsubcontra{
    .prof-npm1{
      padding: .6rem 0 .4rem;
      .ul{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: .5rem;
        .li{
          display: block;
          width: 3.25rem;
          height: 2.8rem;
          margin: 0 0 .2rem;
          h5{
            left: .3rem;
            right: .3rem;
            width: auto;
            padding: 0;
            font-size: .3rem;
            line-height: .4rem;
          }
        }
      }
    }
    .prof-npm2{
      padding: .6rem 0 .4rem;
      .ul{
        flex-wrap: wrap;
        margin-top: .5rem;
        .li{
          padding: .5rem .3rem;
          width: 100%;
          margin-bottom: .2rem;
          .i{
            width: 2rem;
          }
        }
      }
    }
    .prof-npm3{
      padding: .6rem 0;
      .img{
        margin-top: .5rem;
      }
    }
    .prof-npm4{
      padding: .6rem 0;
      .ul{
        flex-wrap: wrap;
        margin-top: .5rem;
        .li{
          padding: .5rem .3rem;
          width: 3.25rem;
          margin-bottom: .2rem;
        }
      }
    }
    .prof-npm6{
      padding: .6rem 0;
      .ul{
        margin-top: .5rem;
        .li{
          margin-bottom: .2rem;
          .img{
            width: 2.6rem;
          }
          .txt{
            flex: 1;
            width: auto;
            margin: 0 0 0 .4rem;
            h5{
              font-size: .3rem;
              line-height: .4rem;
            }
          }
        }
      }
    }
    .prof-npm8{
      padding: .6rem 0;
      .img{
        margin-top: .5rem;
      }
      .ul{
        justify-content: space-between;
        .li{
          width: 3.25rem;
          height: 4.6rem;
          padding: .5rem .3rem;
          margin: 0 0 .2rem;
          min-height: auto;
          em{
            font-size: .4rem;
            line-height: .6rem;
          }
          .p{
            margin-top: .2rem;
          }
        }
      }
    }
    .prof-npm9{
      .box1{
        margin: .5rem auto 0;
        padding: .5rem .3rem;
        h5{
          margin-top: .4rem;
          margin-bottom: .2rem;
          &:first-child{
            margin-top: 0;
          }
        }
        .fl,.fr{
          width: 100%;
        }
        .fr{
          margin-top: .2rem;
        }
      }
      .img{
        padding: .5rem .3rem;
      }
    }
    .bgop{
      padding: .6rem 0;
    }
    .prof-npm10{
      .ul{
        margin-top: .5rem;
        flex-wrap: wrap;
        justify-content: space-between;
        .li{
          width: 3.25rem;
          margin: 0 0 .2rem;
        }
      }
    }
    .prof-npm12{
      margin-top: .5rem;
      .box{
        h5{
          font-size: .3rem;
          line-height: .4rem;
        }
        .ul{
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: .2rem;
          .li{
            padding: .5rem .3rem;
            width: 3.25rem;
            margin: 0 0 .2rem;
          }
        }
      }
    }
    .prof-npm13{
      padding: .5rem .3rem;
    }
    .prof-npm14{
      padding: .6rem 0;
      .box{
        .clear{
          margin-top: .5rem;
        }
      }
      .img{
        margin-top: .5rem;
      }
      .fl{
        width: 100% !important;
      }
      .fr{
        margin-top: .2rem;
        width: 100% !important;
      }
      .itembox{
        margin-top: .5rem;
        .dl{
          margin-top: .5rem;
          flex-wrap: wrap;
          justify-content: space-between;
          .dd{
            padding: .5rem .3rem;
            width: 3.25rem;
            margin: 0 0 .2rem;
          }
        }
      }
    }
  }
}
</style>
  
  