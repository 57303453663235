<template>
  <div class="wrap">
    <nav-ber :title="'数字服务'"></nav-ber>
    <main>
      <van-tabs class="border" v-model:active="active" color="#f70000" line-width="70" title-inactive-color="#2d322d" @change="changeFn" @click-tab="tabBefore" :swipe-threshold="2">
        <van-tab title="土建与装饰材料专区">
          <iframe class="view" src="https://p.vvupup.com/"></iframe>
        </van-tab>
        <van-tab title="绿色材料与建筑固废专区">
          <iframe class="view" src="https://gfcyh.caihcloud.com/"></iframe>
        </van-tab>
        <van-tab title="智慧工地产品专区">
          <iframe class="view" src="https://hjthc.caihcloud.com/"></iframe>
        </van-tab>
        <van-tab title="中国—东盟二手工程机械交易专区">
          
        </van-tab>
      </van-tabs>

    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { skipOutsideChain } from "@/api/public";
import { ref, onMounted } from "vue";
const active = ref(3);
const changeFn = (i) => {
  let name = 'van-tabs-1-' + i;
  let wid = document.getElementById(name).clientWidth + 'px';
  document.querySelector(".van-tabs__line").style.width = wid;
  
  // if(i === 0){
  //   active.value = 3;
  //   skipOutsideChain('https://p.vvupup.com/');
  // }else if(i === 1){
  //   active.value = 3;
  //   skipOutsideChain('https://gfcyh.caihcloud.com/');
  // }else if(i === 2){
  //   active.value = 3;
  //   skipOutsideChain('https://hjthc.caihcloud.com/');
  // }
}
onMounted(() => {
  let name = 'van-tabs-1-' + active.value;
  let wid = document.getElementById(name).clientWidth + 'px';
  document.querySelector(".van-tabs__line").style.width = wid;
});

</script>

<style scoped lang="scss">
// @import '~@/assets/css/vanTabs.scss';
main {
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/seekBg.jpg) repeat-x;
  background-size: .4rem;
}
.topBox {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  margin-bottom: .6rem;
  .txt{
    position: absolute;
    left: .4rem;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    font-size: 0.38rem;
    color: #fff;
    font-weight: 600;
    line-height: 0.4rem;
    padding-bottom: 0.06rem;
    letter-spacing: .04rem;
    background: linear-gradient(to bottom, #fff, #d9d9d9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h4 {
    font-size: 0.24rem;
    color: rgba(176, 193, 202, 0.4);
    line-height: 1;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
  }
}
.bd{
  padding-top: .3rem;
}
.item{
  border-radius: var(--border-radius);
  background: #fff;
  box-shadow: var(--box-shadow);
  height: 2.75rem;
  display: flex;
  margin-bottom: .3rem;
  align-items: center;
  overflow: hidden;
  .pic{
    width: 2.04rem;
    height: 100%;
    padding: .3rem 0 0 .2rem;
    background: no-repeat center;
    background-size: cover;
    border-radius: var(--border-radius);
    .icon{
      display: flex;
      align-items: center;
      width: .54rem;
      height: .54rem;
      margin-bottom: .2rem;
    }
    h4{
      font-size: .26rem;
      color: #bdb6a7;
      line-height: .34rem;
    }
    .i{
      margin-top: .33rem;
      width: .35rem;
      height: .18rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAASCAYAAADR/2dRAAAAiklEQVRIicXVwQ2AIAwF0I+jcWMDNtQJ9KSr1WCMwUDLif5/JYGXtkAQEZRcx4aJkXfroB0RU8YyU9CJWItemLoiKsizMkOQd5tMkDcGFoiBgQZiYdADhXNfzevmGWZlmni9wFq+rsSUA7My9Xg888PCNBCQMF0ICBgVAmeMCYEjZggpYX6U/wC4AYSsHjWLNp3IAAAAAElFTkSuQmCC) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .txt{
    flex: 1;
    padding: 0 .4rem 0 .3rem;
  }
}
.iconUl{
  .li {
    position: relative;
    padding: 0.46rem 0.3rem .6rem;
    display: flex;
    min-height: 2.7rem;
    align-items: flex-start;
    margin-bottom: 0.3rem;
    border-radius: 0.28rem;
    background: #fff;
    box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
  }
  .icon {
    width: 0.88rem;
    height: 0.88rem;
    border: 0.01rem solid #e1e1e1;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 0.3rem;
  }
  
  .more{
    position: absolute;
    right: .3rem;
    bottom: .2rem;
    font-size: .22rem;
    color: #999;
    line-height: .4rem;
    padding-right: .2rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAPCAYAAADZCo4zAAABBElEQVQYlWWQIUsEURSFv/NmNNosJv+FqMVgE+yistVF1GBYsGgwLiiK4oDFIPoDDIJ/QKPFJIhJsAmCYXhz5A27sjMeeOHd+3HuvUdFUfQljQO9qqp+QgjYrp8kcmDO9iwQgC3AjCgVu8AbsAkc0lICnoEV2x+S9mzvtB3SvKeyLDuSvoE+sN4ABnoANgABF5KW20DSte1tqJe/BBbaADHG8xjjiaRJ4OgfkGXZNDA/+N4lqz/Zngoh3EqaAU6Bg1GHCUlXtlPzBthNE2tAUp5l2ZntReA+hNCVVA6vGJN0nOf5GvAoqQN8DW3TDvu2U8wvklJAn40kbS8B75JWgdfGScAvl3lVYiDwrr0AAAAASUVORK5CYII=) no-repeat right center;
    background-size: .08rem;
  }
  .txt {
    flex: 1;
    .row {
      margin-bottom: 0.19rem;
      * {
        display: inline-block;
        vertical-align: middle;
      }
    }
    h4 {
      font-size: 0.28rem;
      color: #2d322d;
      line-height: 0.42rem;
    }
    p {
      font-size: 0.22rem;
      color: #646464;
      line-height: 0.36rem;
    }
  }
}
.tabItem{
  .box{
    margin: .4rem 0 .16rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .van-button{
      width: 2rem;
      height: .56rem;
      line-height: .56rem;
      margin-bottom: .36rem;
      color: #2d322d !important;
      border: 0 none;
      &:active{
        color: #f70000 !important;
      }
    }
  }
}

.border{
  margin-bottom: .2rem;
  &:after{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    background: #e1e1e1;
    bottom: 0;
  }
  :deep(.van-tabs__nav){
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    justify-content: flex-start;
    .van-tab--grow{
      padding: 0;
      &:first-child{
        margin-left: 0;
      }
    }
    .van-tab{
      flex: none;
      padding: 0;
      font-size: .28rem;
      margin-left: .7rem;
      &:first-child{
        margin-left: 0;
      }
    }
    .van-tab--active{
      font-weight: 500;
    }
  }
  &.van-tabs--line{
    :deep(.van-tabs__wrap){
      height: 1rem;
    }
  }
}
</style>

