<template>
  <div class="wrap">
    <nav-ber :title="'智能建造'"></nav-ber>
    <main>
      <Cbanner :list="apiData0"></Cbanner>
      <van-tabs class="border" v-model:active="active" color="#f70000" line-width="56" title-inactive-color="#2d322d">
        <van-tab title="智能施工"></van-tab>
        <van-tab title="智能设计"></van-tab>
        <van-tab title="智能生产"></van-tab>
        <van-tab title="智慧运维"></van-tab>
        <van-tab title="智慧城市运营"></van-tab>
        <van-tab title="建筑工业化"></van-tab>
      </van-tabs>
      <div class="bd">
        <div class="item" v-show="active === 0">
          <div class="infoUl">
            <router-link class="li" to="/engineering/bim">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic1.jpg" alt=""><h4>BIM解决方案</h4></div>
              <div class="txt">
                <p>提供全面的BIM解决方案优化建筑生命周明管</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/intelligent">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic2.jpg" alt=""><h4>智能硬件解决方案</h4></div>
              <div class="txt">
                <p>整合先进的硬件技术和智能化解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/safety">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic3.jpg" alt=""><h4>项目安全解决方案</h4></div>
              <div class="txt">
                <p>整合先进的安全技术与流程提供安全管理方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/quality">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic4.jpg" alt=""><h4>项目质量解决方案</h4></div>
              <div class="txt">
                <p>结合先选的质量管理工具提供质量解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/schedule">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic5.jpg" alt=""><h4>项目进度解决方案</h4></div>
              <div class="txt">
                <p>提供全面的项目进度解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/cost">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic6.jpg" alt=""><h4>项目成本解决方案</h4></div>
              <div class="txt">
                <p>提供综合的项目成本解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/build">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic7.jpg" alt=""><h4>绿色施工解决方案</h4></div>
              <div class="txt">
                <p>以环境和资源节约为核心提供绿色施工解决...</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/machinery">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic8.jpg" alt=""><h4>项目机械解决方案</h4></div>
              <div class="txt">
                <p>提供全面的项目机械管理解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/supplies">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic9.jpg" alt=""><h4>项目物料解决方案</h4></div>
              <div class="txt">
                <p>提供综合的项目物料管理解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/exhibition">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic10.jpg" alt=""><h4>智慧展厅解决方案</h4></div>
              <div class="txt">
                <p>整合先进技术提供创新的智慧展厅解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/experience">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic11.jpg" alt=""><h4>安全体验馆解决方案</h4></div>
              <div class="txt">
                <p>提供全面的安全体验馆解决方案</p>
              </div>
            </router-link>
            <router-link class="li" to="/engineering/seal">
              <div class="pic"><img src="~@/assets/images/engineering/engineeringPic13.jpg" alt=""><h4>智慧印章解决方案</h4></div>
              <div class="txt">
                <p>在线实时管控的智能印章</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="item" v-show="active !== 0">
          <div class="recruitment">
            <div class="dt">
              <img src="~@/assets/images/engineeringsev/engineeringsevBg2.jpg" alt="">
              <div class="txt">
                <h2>合作伙伴招募</h2>
                <h3>PARTNER RECRUITMENT</h3>
              </div>
            </div>
            <div class="dd">
              <div class="li">
                <div class="icon">
                  <img src="@/assets/images/engineeringsev/engineeringsevRecruitmentIcon1.jpg" alt=""/>
                </div>
                <div class="txt">
                  <h4>城市合伙人</h4>
                  <div class="pBox">
                    <p>享有特定省份独家经营权，与华建通紧密合作，充分发挥各自优势，共同开拓市场，实现共赢发展。</p>
                  </div>
                  <router-link class="btn" to="/engineeringsev/partner"><em>城市合伙人</em></router-link>
                </div>
              </div>
              <div class="li">
                <div class="icon">
                  <img src="@/assets/images/engineeringsev/engineeringsevRecruitmentIcon2.jpg" alt=""/>
                </div>
                <div class="txt">
                  <h4>产品与服务提供商</h4>
                  <div class="pBox">
                    <p>提供产品、方案或技术能力支持的服务商，服务商包括产品/服务/技术伙伴、投融资运营伙伴、人才联盟、供应链伙伴、法律伙伴、社会伙伴和产业伙伴等。</p>
                  </div>
                  <router-link class="btn" to="/engineeringsev/provider"><em>产品与服务提供商</em></router-link>
                </div>
              </div>
              <div class="li">
                <div class="icon">
                  <img src="@/assets/images/engineeringsev/engineeringsevRecruitmentIcon3.jpg" alt=""/>
                </div>
                <div class="txt">
                  <h4>产品与服务渠道商</h4>
                  <div class="pBox">
                    <p>根据意愿选择合作板块，利用其在市场上的广泛网络和资源，与华建通携手将产品或服务推向市场、触达最终用户。</p>
                  </div>
                  <router-link class="btn" to="/engineeringsev/distributor"><em>产品与服务渠道商</em></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);

const modules = [Pagination];
const pagination = {
  clickable: true,
};
const apiData0 = [
  {
    image: 'hjt-door/202405/gw/hjt/2024052618092022956314-d8bd-4dc2-9807-cf94ebea1a14.jpg',
    newsId: 0,
    newsContent: '<h3>汇聚智能建造全场景解决方案</h3><p>让智能建造成就未来城市、智能科技助力高效施工。打造更美好的明天!</p>'
  }
]

</script>

<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
.wrap {
  padding-bottom: 0;
}
:deep(.banner){
  margin-bottom: .2rem;
}

.van-tabs{
  margin-bottom: .6rem;
}

.bd{
  .infoUl{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .li{
      width: 3.25rem;
      margin-bottom: .2rem;
      border-radius: .2rem;
      overflow: hidden;
      background: #f3f4f7;
      .pic{
        position: relative;
        &:after{
          position: absolute;
          content: "";
          display: block;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.2);
        }

        h4{
          position: absolute;
          left: .3rem;
          right: .3rem;
          top: .74rem;
          color: #fff;
          font-size: .3rem;
          line-height: .4rem;
          padding-bottom: .1rem;
          z-index: 5;
          &:after{
            content: "";
            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            width: .6rem;
            height: .04rem;
            background: #f70000;
          }
        }
      }
      .txt{
        padding: .2rem .3rem;
        min-height: 1.1rem;
        display: flex;
        align-items: center;
        p{
          font-size: .22rem;
          color: rgba(0,0,0,.5);
          line-height: .36rem;
        }
      }
    }
  }
}
.recruitment{
  .dt{
    position: relative;
    overflow: hidden;
    border-radius: .2rem;
    margin-bottom: .6rem;
    .txt{
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: .3rem;
      z-index: 5;
      h2{
        font-size: .3rem;
        line-height: .4rem;
        font-weight: 600;
        letter-spacing: .04rem;
        margin-bottom: .1rem;
        color: #fff;
      }
      h3{
        font-size: .22rem;
        line-height: .24rem;
        color: rgba(255,255,255,.4);
        font-family: "Gilroy-Bold";
        text-transform: uppercase;
      }
    }
  }
  .dd{
    .li {
      position: relative;
      padding: 0.27rem 0.3rem;
      display: flex;
      min-height: 2.1rem;
      align-items: center;
      margin-bottom: 0.3rem;
      border-radius: 0.28rem;
      background: #fff;
      box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
    }
    .icon {
      position: absolute;
      left:.3rem;
      top: .5rem;
      width: 0.88rem;
      height: 0.88rem;
      border: 0.01rem solid #e1e1e1;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 0.3rem;
    }
    .txt {
      flex: 1;
      min-height: 1.2rem;
      margin-left: 1.2rem;
      h4 {
        font-size: 0.28rem;
        color: #2d322d;
        line-height: 0.42rem;
      }
      p {
        font-size: 0.22rem;
        color: #646464;
        line-height: 0.36rem;
      }
    }
    .btn {
      float: left;
      padding: 0 .2rem;
      height: 0.48rem;
      font-size: 0;
      line-height: .48rem;
      border-radius: .48rem;
      text-align: center;
      background: #f70000;
      margin-top: .2rem;
      em{
        font-size: 0.22rem;
        color: #fff;
        padding-right: .2rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC) no-repeat right center;
        background-size: .08rem;
      }
    }
  }
}
</style>

