<template>
  <div class="wrap">
    <nav-ber title="产品与服务提供商"></nav-ber>
    <main>
      <Cbanner :list="apiData0"></Cbanner>
      <div class="content">
        <section>
          <h3 class="cTil"><em>产品与服务提供商</em></h3>
          <div class="pBox">
            <p>
              产品与服务提供商是华建通平台生态系统中的重要组成部分。与其他合作伙伴共同构建了一个完整的生态系统，通过提供高质量的产品和服务，为平台的持续发展提供了坚实的基础。
            </p>
          </div>
        </section>

        <section class="lump2">
          <h3 class="cTil"><em>遴选资格</em></h3>
          <div class="ul">
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/providerIcon1.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>产品质量</h4>
                <div class="pBox">
                  <p>
                    1、材料质量：提供建筑材料类，产品必须符合国家或行业的质量标准，具有可靠的物理性能和化学性能。
                  </p>
                  <p>2、设备性能：提供的产品应具有良好的性能和稳定性。</p>
                  <p>
                    3、服务质量：设计、咨询、金融等软服务，其服务应具有专业性、创新性和及时性。
                  </p>
                </div>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/providerIcon2.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>市场声誉与客户评价</h4>
                <div class="pBox">
                  <p>
                    1、市场声誉：市场上应具有良好的声誉和口碑，没有严重的违法违规行为或不良记录。
                  </p>
                  <p>
                    2、客户评价：客户评价应为积极，具有较高的客户满意度和忠诚度。
                  </p>
                </div>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/providerIcon3.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>资质与认证</h4>
                <div class="pBox">
                  <p>
                    1、必须拥有相关的行业资质证书，以证明其具备从事相关业务的合法性和专业能力。
                  </p>
                  <p>
                    2、产品或服务应符合国家或国际的相关认证标准，如ISO
                    9001质量管理体系认证、ISO
                    14001环境管理体系认证等，确保产品或服务的质量和安全。
                  </p>
                </div>
              </div>
            </div>
            <div class="li">
              <div class="icon">
                <img
                  src="@/assets/images/engineeringsev/providerIcon4.png"
                  alt=""
                />
              </div>
              <div class="txt">
                <h4>技术创新能力</h4>
                <div class="pBox">
                  <p>
                    1、研发投入：具有一定的研发投入，能够不断推出新产品、新技术或新服务，满足市场的不断变化。
                  </p>
                  <p>
                    2、技术实力：应具备较强的技术实力，能够解决在使用产品或服务过程中遇到的技术难题。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="lump3">
          <h3 class="cTil"><em>合作权益</em></h3>

          <div class="sildcon">
            
            <!--]-->
        </div>

          <swiper
            :pagination="rightsPagination"
            :modules="rightsModules"
            :loop="true"
            :space-between="20"
            class="cRights"
          >
          <swiper-slide>
                <div class="ico">
                    <img src="      /hjt-door/202405/gw/hjt/20240527104123f5c9867c-a912-4fdb-9b7c-7f72ec000f97.png" alt="">
                </div>
                <div class="txt">
                    <h5>品牌展示与推广</h5>
                    <p>提供品牌展示的机会，如产品详情页、服务商主页、广告、营销活动等方式，提升品牌知名度和曝光率。</p>
                </div>
                <em>01</em>
            </swiper-slide>
            <swiper-slide>
                <div class="ico">
                    <img src="      /hjt-door/202405/gw/hjt/20240527104123fc516365-5029-4795-8445-58632c8dec67.png" alt="">
                </div>
                <div class="txt">
                    <h5>优先推荐与展示</h5>
                    <p>根据产品质量、服务质量、客户满意度等因素进行评级和排序。享受更多的优先推荐和展示机会。</p>
                </div>
                <em>02</em>
            </swiper-slide>
            <swiper-slide>
                <div class="ico">
                    <img src="      /hjt-door/202405/gw/hjt/2024052710412313207b56-5e03-4e5c-babb-f7e9e6dc3e96.png" alt="">
                </div>
                <div class="txt">
                    <h5>数据分析与反馈</h5>
                    <p>提供详细的数据分析和反馈，包括产品销售数据、客户评价、市场需求等信息。</p>
                </div>
                <em>03</em>
            </swiper-slide>
            <swiper-slide>
                <div class="ico">
                    <img src="      /hjt-door/202405/gw/hjt/20240527104123756a384b-4079-4b5c-bc53-1aa772a58ca4.png" alt="">
                </div>
                <div class="txt">
                    <h5>资金结算与安全保障</h5>
                    <p>提供安全、便捷的资金结算服务，保障服务商的合法权益。同时还会采取一系列安全措施，如数据加密、风险控制等，确保供应商的资金安全。</p>
                </div>
                <em>04</em>
            </swiper-slide>
            <swiper-slide>
                <div class="ico">
                    <img src="      /hjt-door/202405/gw/hjt/2024052710412386fca7a5-f067-42e0-bf7d-2c59117a5684.png" alt="">
                </div>
                <div class="txt">
                    <h5>合作与发展机会</h5>
                    <p>建立长期稳定的合作关系，邀请服务商参与平台的研发项目、市场活动等共同推动行业的发展和进步。</p>
                </div>
                <em>05</em>
            </swiper-slide>
          </swiper>
        </section>
        <van-button
          class="cApplyBtn"
          @click="
            $router.push({
              path: '/consultform',
              query: { plateArr: '产品与服务提供商' },
            })
          "
          >申请办理</van-button
        >
      </div>
    </main>
  </div>
</template>
<script setup>
import Cbanner from "@/components/Cbanner.vue";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import NavBer from "@/components/NavBer.vue";
import { ref } from "vue";
const active = ref(0);

const modules = [Pagination];
const pagination = {
  clickable: true,
};

const rightsModules = [Pagination];
const rightsPagination = {
  clickable: true,
};

const apiData0 = [ 
  {
    image: '/hjt-door/202407/gw/hjt/202407171501041741444c-157e-4327-94f4-77016ef0f371.png',
    newsId: 0,
    newsContent: '<h3>产品与服务提供商</h3><p>平台汇聚各类优质的建筑行业产品与服务供应商，为建筑行业提供全面、专业、高效且高质量的产品与服务，满足了建筑行业多元化的需求。</p>'
  }
]
</script>
<style scoped lang="scss">
.content {
  section {
    margin-bottom: 0.6rem;
  }
  .cTil {
    margin-bottom: 0.33rem;
  }
  .pic {
    overflow: hidden;
    border-radius: var(--border-radius-s);
    border: 0.03rem solid #c7c7c7;
    margin-top: 0.3rem;
  }
  .cTil {
    text-align: center;
    em:after {
      right: 0;
      margin: 0 auto;
    }
  }
  .pBox {
    h4 {
      font-size: 0.22rem;
      color: #333;
      line-height: 0.36rem;
    }
    p {
      text-indent: 2em;
      margin-bottom: 0.36rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.lump2 {
  .ul {
    .li {
      position: relative;
      padding: 0.27rem 0.3rem;
      display: flex;
      min-height: 2.1rem;
      align-items: center;
      margin-bottom: 0.3rem;
      border-radius: 0.28rem;
      background: #fff;
      box-shadow: 0 0.02rem 0.5rem rgba(230, 230, 230, 0.6);
    }
    .icon {
      position: absolute;
      left: 0.3rem;
      top: 0.5rem;
      width: 0.88rem;
      height: 0.88rem;
      border-radius: 50%;
      margin-right: 0.3rem;
    }
    .txt {
      flex: 1;
      min-height: 1.2rem;
      margin-left: 1.2rem;
      h4 {
        font-size: 0.28rem;
        color: #2d322d;
        line-height: 0.42rem;
      }
      p {
        text-indent: 0;
        font-size: 0.22rem;
        color: #646464;
        line-height: 0.36rem;
      }
    }
    .btn {
      float: left;
      padding: 0 0.2rem;
      height: 0.48rem;
      font-size: 0;
      line-height: 0.48rem;
      border-radius: 0.48rem;
      text-align: center;
      background: #f70000;
      margin-top: 0.2rem;
      em {
        font-size: 0.22rem;
        color: #fff;
        padding-right: 0.2rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAh0lEQVQokWP4////0////6/9//8/AzYMImBgCi4FZkiKWrEpAGFPJEXZ2BSAcBSSohBsCkC4DEmRGzYFINyBpEgXq9f+///fAlPBxIAf/MKmuxOq+cv////l0SXLkew3QXdkNJIkKFxwBlQEekCZI0kWYQtqGAD5H2tk3fz///9srOHx/z8DAAc+jPS6sG6sAAAAAElFTkSuQmCC)
          no-repeat right center;
        background-size: 0.08rem;
      }
    }
  }
}

.cRights {
  .swiper-slide {
    height: 4rem;
    background: #d7000f;
    padding: 0.6rem 0.4rem;
    position: relative;
    transition: all 0.4s;
    border-radius: 0.28rem;
    .ico {
      height: 0.8rem;
      img {
        max-height: 100%;
      }
    }
    .txt {
      margin-top: 0.4rem;
      position: relative;
      z-index: 3;
      h5 {
        color: #fff;
        font-size: 0.28rem;
        line-height: 0.38rem;
        margin-bottom: 0.2rem;
      }
      p {
        color: #fffc;
        font-size: 0.22rem;
        line-height: 0.36rem;
      }
    }
    em {
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.3), #d7000f);
      -webkit-background-clip: text;
      bottom: 0;
      color: transparent;
      font-family: "Gilroy-Bold";
      font-size: 4rem;
      line-height: 1;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  }
  :deep(.swiper-pagination) {
    position: static;
    .swiper-pagination-bullet {
      width: 0.1rem;
      height: 0.1rem;
      opacity: 1;
      background: #646464;
      margin: 0 0.05rem;
      &.swiper-pagination-bullet-active {
        background: #d7000f;
      }
    }
  }
}
</style>

