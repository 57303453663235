<template>
  <div class="wrap">
    <nav-ber :title="'建筑市场监管云平台'"></nav-ber>
    <main class="buildings">
      <sub-ban :ban-info="banInfo"></sub-ban>
      <section class="constru-npm1">
        <div class="w1440">
          <div class="above clear">
            <div
              class="item wow fadeInUp50"
              v-for="item in apiData1"
              :key="item.newsId"
            >
              <div class="pagetit wow fadeInUp50">
                <h5 v-html="item.newsTitle"></h5>
                <div class="p" v-html="item.newsContent"></div>
              </div>
            </div>
          </div>
          <div
            class="below wow fadeInUp50"
            v-html="apiData2[0].newsContent"
          ></div>
        </div>
      </section>
      <section class="constru-npm2">
            <div class="w1440 ">
                <div class="pagetit wow fadeInUp50">
                    <h5>案例成效</h5> 
                </div>
                <div class="clear">
                    <div class="fl wow fadeInUp50">
                    
                    <div class="img">
                      <van-swipe class="soluslid" :autoplay="3000" indicator-color="#f70000">
        <van-swipe-item >
           <img :src=" apiData3[0].image" alt="">
        </van-swipe-item>
      </van-swipe>
          
                    </div>
                    </div>
                    <div class="fr wow fadeInUp50">
                        <div class="txt">
                            <h5 v-html="apiData3[0].newsTitle"></h5>
                            <div v-html="apiData3[0].newsContent"></div>
                        </div>
                        <ul>
                            <li v-for="item in apiData4" :key="item.newsId" >
                                <em v-html="item.newsTitle"></em>
                                <p v-html="item.newsContent"></p>
                            </li> 
                        </ul>
                    </div>
                </div>
                
            </div>
        </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import subBan from "@/components/subBan.vue";
import { getInfo } from "@/api/http";
import { ref, onMounted, reactive } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const apiData3 = ref([]);
const apiData4 = ref([]);
let banInfo = reactive({
  banUrl: "",
  tilCn: "",
  tilTxt: "",
  txton: "立即咨询",
  enlink:
    "/consultform?plateArr=%E6%95%B0%E5%AD%97%E6%9C%8D%E5%8A%A1,%E6%94%BF%E5%BA%9C%E6%95%B0%E5%AD%97%E5%8C%96",
});
const getList = async () => {
  const { data: data1 } = await getInfo({
    coId: 477,
    typeId: 121,
  });
  const { data: data2 } = await getInfo({
    coId: 427,
  });
  const { data: data3 } = await getInfo({
    coId: 429,
  });
    const { data: data4 } = await getInfo({
    coId: 431,
  });
   const { data: data5 } = await getInfo({
    coId: 435,
  });
  apiData.value = data1.data;
  apiData1.value = data2.data;
  apiData2.value = data3.data;
   apiData3.value = data4.data;
   apiData4.value = data5.data;
  banInfo.banUrl = apiData.value[0].image;
  banInfo.tilCn = apiData.value[0].newsTitle;
  banInfo.tilTxt = apiData.value[0].newsContent;
};

// console.log(apiData3.value, "555");
getList();
onMounted(async () => {});
</script>
  
<style scoped lang="scss">
main{padding: 0;}
.buildings {
  h5,
  h6 {
    line-height: 1.1;
  }
  .w1440 {
    padding: 0 0.3rem;
  }
  .constru-npm1 {
    padding: 0.9rem 0;
    .above {
      display: block;
      .item {
        width: 100%;
        margin-bottom: 0.35rem;
        .p {
          max-width: 100%;
          margin: 0.25rem auto 0;
        }
      }
    }
    :deep(.below) {
      margin-top: 0.4rem;
      .clear{
        &::after{
          content: '';
          clear: both;
          display: block;
        }
      }
      .biti1 {
        min-width: 1.8rem;
        padding: 0 0.2rem;
        line-height: 0.8rem;
        font-size: 0.26rem;
        border-radius: 1rem;
        background: #d7000f;
        color: #fff;
        text-align: center;
        margin: auto;
        display: table;
      }
      .btafter {
        &::after {
          width: 90%;
          background-repeat: no-repeat;
          height: 0.5rem;
          background-size: 100% auto;
        }
      }
      .topitem {
        padding-bottom: 0.5rem;
        position: relative;
        .biti1 {
          margin: 0 auto 0.2rem;
        }
        .biti2 {
          width: 3.5rem;
          color: #333333;
          text-align: center;
          background: #f3f4f7;
          margin: auto;
          line-height: 0.8rem;
          border-radius: 1rem;
          font-size: 0.24rem;
        }
      }
      .conitem {
        margin-bottom: 0.1rem;
        background: #f3f4f7;
        padding: 0.6rem 0.3rem;
        .bt1 {
          padding-bottom: 0.5rem;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            line-height: 0.8rem;
            color: #808080;
            text-align: center;
            background: #fff;
            width: 31.6984%;
            font-size: 0.24rem;
            margin-bottom: 0.17rem;
            border-radius: 1rem;
          }
        }
        .fl {
          width: 100%;
          position: relative;
          ul{
            margin-bottom: .6rem;
          }
          &::after {
            content: '';
            width: 2rem;
            bottom: 1rem;
            height: 0.5rem;
             background: url(~@/assets/images/labor/line3.png) no-repeat;
                        background-size: 100%;
                        left: 0;
                        right: 0;
                        position: absolute;
                        margin: auto;
          }
          ul {
            li{
                  width: 48.05%;
            }
            &::after {
              opacity: 0;
            }
          }
        }
        .fr {
          width: 100%;
          margin-top: 0.45rem;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            margin: auto;
                left: 0;
    right: 0;
            width: 80%;
            height: 0.5rem;
            bottom: 1rem;
                background: url(~@/assets/images/labor/line4.png) no-repeat;
    background-size: 100%;
          }
          ul {
            margin-bottom: .6rem;
            li{
               width: 23.57%;
              &:last-child{
                                width: 49.043%;
                            }
            }
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
  }
   .constru-npm2{
            padding: .9rem 0 .7rem;
            background: linear-gradient(to bottom, #f3f4f7, #ffffff);
            .clear{
                margin-top: .45rem;
            }
            .fl{
                width: 100%;
                 position: relative;
                .img{
                    margin: 0;
                }
            }
            .fr{
                width: 100%;
                .txt{
                    h5{
                        font-size: .26rem;
                        margin-bottom: .15rem;
                        color: #333333;
                    }
                }
                ul{
                    margin-top: .5rem;
                    display: flex;
                flex-wrap: wrap;
                    li{
                        margin-right: .6rem;
                        em{
                            font-size: .4rem;
                            letter-spacing: -.02rem;
                            color: #d7000f;
                        font-family: 'DIN-Bold';
                        }
                        p{
                        color: #333333;
                    }
                    }
                }
            }
        }
}
</style>
  
  