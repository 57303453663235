<template>
  <div class="wrap">
    <nav-ber title="办理意向填写" homeUrl="/financial"></nav-ber>
    <main class="financialIntentionToInsure">
      <div class="content">
        <h2 class="cTil"><em>办理意向信息填写</em></h2>
        <form class="form">
          <div class="hint">提示：带<em>*</em> 的内容为必填项</div>
          <div class="ipt">
            <label for="enterprise">类型</label>
            <input type="text" id="enterprise" :value="insuranceType" readonly/>
          </div>
          <div class="ipt">
            <label for="enterprise" class="must">企业名称</label>
            <input type="text" id="enterprise" placeholder="请输入企业名称"/>
          </div>
          <div class="ipt">
            <label for="code">统一社会信用代码”：</label>
            <input type="text" id="code" placeholder="请输入统一社会信用代码"/>
          </div>
          <div class="ipt">
            <label for="project" class="must">投保工程名称</label>
            <input type="text" id="project" placeholder="请输入工程名称"/>
          </div>
          <div class="ipt">
            <label class="must">出函机构选择</label>
            <span @click="showPicker = true" :class="{ 'on': selected }">{{ letter }}</span>
          </div>
          <div class="ipt">
            <label for="name" class="must">经办人</label>
            <input type="text" id="name" placeholder="请输入经办人姓名"/>
          </div>
          <div class="ipt">
            <label for="phone" class="must">经办人联系电话</label>
            <input type="text" id="phone" placeholder="请输入经办人联系电话"/>
          </div>
          <div class="ipt">
            <label for="mailbox" class="must">经办人邮箱</label>
            <input type="text" id="mailbox" placeholder="请输入经办人邮箱"/>
          </div>
          <van-button class="submit" type="primary" size="large" color="#f70000" @click="skipLink('/financial/transactResult')">提交意向</van-button>
        </form>
      </div>
    </main>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer.vue';
import { usePublic } from '@/api/public';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const insuranceType = route.query.type;
const {skipLink} = usePublic();
const showPicker = ref(false);
const letter = ref('请选择出函机构');
const selected = ref(false);
const columns = [
  { text: '杭州', value: 'Hangzhou' },
  { text: '宁波', value: 'Ningbo' },
  { text: '温州', value: 'Wenzhou' },
  { text: '绍兴', value: 'Shaoxing' },
  { text: '湖州', value: 'Huzhou' },
];
const onConfirm = ({selectedOptions}) => {
  letter.value = selectedOptions[0].text;
  showPicker.value = false;
  selected.value = true;
};
</script>

<style scoped lang="scss">
main{
  padding: 0;
}
.financialIntentionToInsure{
  padding-top: 2.7rem;
  background: url(~@/assets/images/financial/financialIntentionToInsureBg.jpg) no-repeat top;
  background-size: 100%;
}
.content{
  border-radius: .28rem .28rem 0 0;
  padding: .6rem .4rem;
  background: #fff url(~@/assets/images/financial/financialIntentionToInsureCntBg.jpg) repeat-x top;
  background-size: .4rem;
  .cTil{
    height: .8rem;
    line-height: .56rem;
    font-size: .36rem;
    text-align: center;
    margin-bottom: .2rem;
    border-bottom: .06rem solid #e1e1e1;
    em{
      display: block;
      position: relative;
      height: .8rem;
      &:after{
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        content: "";
        display: block;
        height: .06rem;
        width: 1.4rem;
        background: #f70000;
      }
    }
  }
}
.form{
  .hint{
    font-size: .24rem;
    color: #646464;
    line-height: .4rem;
    text-align: center;
    margin-bottom: .42rem;
    em{
      color: #f70000;
    }
  }
  .ipt{
    display: flex;
    margin-bottom: .2rem;
    height: 1.2rem;
    padding: .3rem;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    label{
      font-size: .26rem;
      color: #000;
      line-height: .6rem;
      margin-right: .3rem;
      &.must:after{
        content: "*";
        color: #f70000;
        font-size: .26rem;
        line-height: .6rem;
      }
    }
    input{
      flex: 1;
      border: 0 none;
      font-size: .22rem;
      color: #333;
      text-align: right;
      &::placeholder{
        color: #b7b7b7;
      }
    }
    span{
      margin-left: auto;
      font-size: .22rem;
      color: #b7b7b7;
      line-height: .6rem;
      padding-right: .18rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAAUElEQVQokYXSMQ4AIAgDwOJX+f8bdDAmFVvsSC6UgcjMiZ2AyaDx7FD84NnUQq6zkJGFFUmo0AMduuIQHx4KXUDd9ICKJGBkwUEtqHX6VQAsLewPSU/aZZkAAAAASUVORK5CYII=) no-repeat right center;
      background-size: .09rem;
      &.on{
        color: #333;
      }
    }
  }
}
.van-button{
  margin-top: .4rem;
  height: 1.2rem;
  font-size: .28rem;
  border-radius: var(--border-radius);
}
</style>

