<template>
  <div class="wrap">
    <nav-ber :title="'模块标题'"></nav-ber>
    <van-loading vertical type="spinner" color="#1989fa" v-show="typeLoad">
      加载中...
    </van-loading>
    <main class="equipment">
    <div class="inside-top">
    <div class="sub-ban" >
      <div
        class="bg"
        :style="'background-image:url(' +     apiData1[0].image + ');'"
      ></div>

      <div class="w1440" >
        <div class="con">
          <h5 class="wow fadeInUp50" v-html="apiData1[0].newsTitle"></h5>
        <div  class="h6 wow fadeInUp50" v-html="apiData1[0].email"></div>
        <div class="linka wow fadeInUp50" >
          <a class="a" >租赁咨询</a>
        </div>
        </div>
      </div>
    </div>
  </div>
       <section class="equ-npm1 wow fadeInUp50">
      <div class="w1440">
        <div class="ul ">
          <div class="arrbox">全部类型:</div>
         <div class="libox">
          <div @click="liList" class="li" :data-newsId="item.newsId" v-for="(item, i) in apiData" :key="item.newsId">{{
            item.newsTitle }}</div>
         </div>
        </div>
      </div>
    </section>
      <section class="equ-npm2 wow fadeInUp50">
      <div class="w1440">
        <div class="ulbox ulbox1">
          <div class="itembox clear itembox1">
            <a  :href="`/equipment/page?newsId=${item.newsId}`" class="item" v-for="(item, i) in apiData2.data" :key="item.newsId">
              <div class="img"><img :src="      item.image" alt=""></div>
              <div class="txt">
                <div class="h5">
                  <h5 v-html="item.newsTitle"></h5>
                  <p v-html="item.newsTags"></p>
                </div>
              </div>
            </a>

          </div>
          <div class=" homemore"   v-if="apiData2.total > pageSize"  v-show="apiData2.data.length < apiData2.total"
        @click="moreList"> 查看更多 </div>
        </div>
        <div class="ulbox ulbox2">
          <div class="itembox clear ">
            <a :href="`/equipment/page?newsId=${item.newsId}`" class="item" v-for="(item, i) in newsList" :key="item.newsId">
              <div class="img"><img :src="      item.image" alt=""></div>
              <div class="txt">
                <div class="h5">
                  <h5 v-html="item.newsTitle"></h5>
                  <p v-html="item.newsTags"></p>
                </div>
                <div class="p" v-html="item.newsContent"> 
                </div>
                <div class="homemore" :href="`/equipment/page?newsId=${item.newsId}`">查看详情</div>
              </div>
            </a>
           
          </div>
        </div>
      </div>
      <div class="w1440">
        <div class="leasing  wow fadeInUp50">
          <i></i>
          <div class="on">租赁咨询</div>
          <i></i>
        </div>
      </div>
    </section>
    </main>
  </div>
</template>
<script setup>
import NavBer from "@/components/NavBer.vue";
import { getInfo } from "@/api/http";
import { ref } from "vue";
const apiData = ref([]);
const apiData1 = ref([]);
const apiData2 = ref([]);
const getList = async () => {
  const { data:data1 } = await getInfo({
    coId: 317,
  });
  const { data: data2 } = await getInfo({
    coId: 315,
  });
  const { data: data3 } = await getInfo({
    coId: 1007,
  });
  apiData.value =data1.data;
  apiData1.value = data2.data;
apiData2.value = data3.data;
};
console.log(apiData1,"555")
getList();
</script>
  
<style scoped lang="scss">
.equipment {
  h5,h6{
    line-height: 1.1;
  }
  .w1440{
    padding: 0 .3rem;
  }
    .sub-ban{
    height: 4rem; 
    position: relative;
      .bg {
    height: 100%;
    background: no-repeat 64%; 
    background-size: cover; 
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 0;
    height: 100%; 
  }
   .w1440 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 5;
    .con{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
    h5{
      font-size: .36rem;
      margin-bottom: .15rem;
      color: #fff;
    }
    .h6{
      font-size: .24rem;
      line-height: .32rem;
        color: #fff;
    }
    .w1440 {
      .con{
        padding: 0 .3rem;
      }
    }
    .linka {
    margin-top: 0.7rem;
      .a{
        border-radius: 1rem;
    color: #fff;
    line-height: .8rem;
    width: 1.8rem;
    font-size: .24rem;
        background: #d7000f;
        text-align: center;
      }
    }
  }
.equ-npm1 {
    padding-top: .8rem;
    .ul {
      padding-bottom: .3rem;
      display: flex;
      border-bottom: 1px solid #e6e7eb;
      justify-content: space-between;
      .arrbox{
        font-size: .28rem;
      }
      .libox{
        width: calc(100% - 1.5rem);
            display: -webkit-box; 
    overflow-x: scroll;
         flex-wrap: initial;
    overflow-y: hidden;
      }
      .li{
        font-size: .24rem;
        margin-right: .25rem;
      }
    }
}
.equ-npm2 {
  padding-bottom: .9rem;
  .leasing{
    margin-top: .5rem;
    .on{
      padding: 0 .25rem 0 .56rem;
    border-radius: .6rem; 
    font-size: .24rem;
    line-height: .8rem;
        background-position: .25rem center;
        background-size: .21rem;
    }
    i{
      width: calc(50% - 1.2rem);
    }
  }
  .ulbox{
    margin-top: .3rem;
  }
  .item{
    width: 49%;
    margin: 0 2% 2% 0;
  
    &:hover{
      img{
        transform: scale(1);
      }
    }
    &:nth-child(4n){
      margin: 0 2% 2% 0;
    }
    &:nth-child(2n){
      margin: 0 0 2% 0;
    }
    .txt{
      height: auto;
    padding: .5rem .15rem;
    min-height: auto;
    h5{
      font-size: .28rem;
      margin-bottom: .1rem;
    }
    p{
      font-size: .24rem;
      height: auto;
      line-height: .32rem;
    }
    }
    .img{
      height: 2.5rem;
      padding: .2rem 0;
    }
  }
  .homemore{
    margin:.5rem auto 0 ;
  }
  .ulbox2 {
      .item {
        margin-bottom: .2rem;
        height: 2rem;

        .img {
          width: 32.36%;
        }

        .txt {
          width: 67.64%;
          height: auto;
          padding: .4rem .2rem;
        }

        .h5 {
          border-bottom: 0px solid #e1e2e6;
          padding-bottom: .15rem;
        }

        .p {
          margin-top: .2rem;
          display: none;
        }
      }

      .homemore {
        margin: .5rem 0 0;
        display: none;
      }
    }
}
}
</style>
  
  