<template>
  <div class="wrap">
    <!-- <van-button type="primary" @click="getUserLocation">获取地址</van-button> -->
    <div ref="mapContainer"></div>
    <main class="home">
      <div class="topBox" :style="{ marginTop: '-' + $store.state.safetyTop + 'px',height: 'calc(5.34rem + ' + $store.state.safetyTop + 'px)', paddingTop: 'calc(1.75rem + ' + $store.state.safetyTop + 'px)'}">
        <div class="place" @click="openCitySelection" :style="{ top: 'calc(.46rem + ' + $store.state.safetyTop + 'px)'}">{{ city }}</div>
        <div class="cutEditionBtn" @click="cutEditionFn" :style="{ top: 'calc(.46rem + ' + $store.state.safetyTop + 'px)'}">切换身份</div>
        <h2>华建通建筑产业<br/>
          互联网平台</h2>
          <p>建筑业一站式资源与服务平台，聚天下资源为建筑业所用，让建筑人工作更轻松、更有尊严</p>
      </div>
      <div class="content">
        <section class="stock" v-if="false">
          <div class="btn" @click="skip('bridge:/base/openRealNameCollect/pageInit')">
            <div class="icon"><img src="~@/assets/images/home/homeStockIcon1.png" alt=""></div>
            <div class="p">实名采集</div>
          </div>
          <div class="btn" @click="skip('bridge:/base/openAttendanceStatistics/pageInit')">
            <div class="icon"><img src="~@/assets/images/home/homeStockIcon2.png" alt=""></div>
            <div class="p">考勤查询</div>
          </div>
          <div class="btn" @click="skip('bridge:/base/openPersonnelStatistics/pageInit')">
            <div class="icon"><img src="~@/assets/images/home/homeStockIcon3.png" alt=""></div>
            <div class="p">人员查询</div>
          </div>
          <div class="btn" @click="skip('bridge:/base/openQuickEntrance/pageInit')">
            <div class="icon"><img src="~@/assets/images/home/homeStockIcon4.png" alt=""></div>
            <div class="p">快速入场</div>
          </div>
        </section>
        <section class="homeSeek" @click="$router.push({path: '/seek'})">
          <input type="text" placeholder="请输入你想要的内容">
          <div class="btn"></div>
          <!-- <router-link class="btn" :to="{path: '/seek', query: {'key': value}}"></router-link> -->
        </section>
        <section class="btnList">
          <div class="li" @click="loginJump(0, '/financial')" v-if="!$store.state.iosAudit">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon1.png" alt=""></div>
            <div class="p">金融服务</div>
          </div>
          <div class="li" @click="loginJump(0, '/labor')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon2.png" alt=""></div>
            <div class="p">人力劳务</div>
          </div>
          <div class="li" @click="loginJump(0, '/buildingMaterials')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon3.png" alt=""></div>
            <div class="p">交易商城</div>
          </div>
          <div class="li" @click="loginJump(0, '/equipment')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon4.png" alt=""></div>
            <div class="p">租赁商城</div>
          </div>
          <div class="li" @click="loginJump(0, '/service')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon5.png" alt=""></div>
            <div class="p">企业服务</div>
          </div>
          <div class="li" @click="loginJump(0, '/digital')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon6.png" alt=""></div>
            <div class="p">数字服务</div>
          </div>
          <div class="li" @click="loginJump(2, `${escape('pages/index/page')}&appId=gh_1c62528a197b&pubAppId=wx34e8004a2b6c8f4b`)">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon7.png" alt=""></div>
            <div class="p">项目资源</div>
          </div>
          <div class="li" @click="loginJump(0, '/engineeringsev')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon8.png" alt=""></div>
            <div class="p">智能建造</div>
          </div>
          <div class="li" @click="loginJump(0, '/knowledge')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon9.png" alt=""></div>
            <div class="p">专业知识</div>
          </div>
          <div class="li" @click="loginJump(0, '/industrialbrain')">
            <div class="icon"><img src="~@/assets/images/home/homeUlIcon10.png" alt=""></div>
            <div class="p">产业分析</div>
          </div>
        </section>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#f70000">
          <van-swipe-item>
            <img src="~@/assets/images/home/homeBan1.jpg" alt="">
            <div class="txt">
              <h2>开启企业数字化<br>
              新空间</h2>
              <h3>Open up a new space for enterprise<br>
              digitalization</h3>
            </div>
          </van-swipe-item>
          
          <van-swipe-item class="it2">
            <!-- <div @click="skipOutsideChain('https://sci.aupup.com/')"> -->
            <div @click="toWeChat('&appId=gh_d1ac80085321&pubAppId=wx22ba5ecedbc2f3ce')">
              <img src="~@/assets/images/home/homeBan2.jpg" alt="">
              <div class="txt">
                <h2>供应商<br>
                核心大数据分析平台</h2>
                <h4>塑评价体系 引领供采合作</h4>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <section class="lump hotspot">
          <div class="til">
            <em>热点活动</em>
            <router-link class="more" to="/hotNews">查看全部</router-link>
          </div>
          <div class="hotspotSwiper">
            <div class="swiper-container">  
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in rdhdList" :key="item.newsId">
                  <div class="a" @click="skipOutsideChain(item.address)" target="_blank" v-if="item.address && item.address.trim !== ''">
                    <div class="pic" v-if="item.newsAccess"><img :src="'https://www.huajiantong.com/' + item.newsAccess" alt=""></div>
                    <div class="txt">
                      <h4>{{ item.newsTitle }}</h4>
                      <div class="time">{{ item.newsAddTime.split(' ')[0] }}</div>
                    </div>
                  </div>
                  <router-link class="a" v-else :to="`/newsdetail/${item.newsId}`">
                    <div class="pic" v-if="item.newsAccess"><img :src="'https://www.huajiantong.com/' + item.newsAccess" alt=""></div>
                    <div class="txt">
                      <h4>{{ item.newsTitle }}</h4>
                      <div class="time">{{ item.newsAddTime.split(' ')[0] }}</div>
                    </div>
                  </router-link>
                </div>
              </div>  
              <!-- Swiper Pagination -->  
              <div class="user-pagination">
                <div :class="['dot', {'on': swiperAct === 0}]"></div>
                <div :class="['dot', {'on': swiperAct === 1}]"></div>
              </div>  
            </div>
          </div>
        </section>
        <section class="lump financial" v-if="!$store.state.iosAudit">
          <div class="til">
            <em>金融服务</em>
            <router-link class="more" to="/financial">查看全部</router-link>
          </div>
          <ul class="clearfix">
            <li class="one le" @click="$router.push('/financial/lwgzd')">
              <div class="icon"><img src="~@/assets/images/home/homeFinancialIcon1.png" alt=""></div>
              <div class="h2">劳务金融贷</div>
              <div class="h3">单笔借支额度:每个工人单笔贷款额度不超5000(每月一次)</div>
              <div class="h4">借款要求：劳务公司质押产值单合同等相关资料,项目有使用一号工班劳务管理系统</div>
              <div class="score">
                <div class="num">5000<em> +</em></div>
                <div class="txt">已服务用户</div>
              </div>
              <div class="btn">去看看</div>
            </li>
            <li @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': '1167', 'type': '安全生产责任保险'}})">
              <div class="tilBox">
                <div class="h3">安全生产责任保险</div>
              </div>
              <p>减轻企业风险<br/>
              提供事故预防服务</p>
              <div class="score">
                <div class="num">300<em> +</em></div>
                <div class="txt">已购买企业</div>
              </div>
            </li>
            <li @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': '1347', 'type': '农民工工资支付保证保险-保函'}})">
              <div class="tilBox">
                <div class="h3">工资支付</div>
                <div class="label i1">保险</div>
                <div class="label i2">保函</div>
              </div>
              <p>保障合法权益<br>
              促进和谐劳动关系</p>
              <div class="score">
                <div class="num">3000<em> +</em></div>
                <div class="txt">企业正在使用</div>
              </div>
            </li>
          </ul>
          <ul class="clearfix">
            <li class="le" @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': '1331', 'type': '建设工程完工履约保证保险-保函'}})">
              <div class="tilBox">
                <div class="h3">完工履约担保</div>
                <div class="label i1">保险</div>
                <div class="label i2">保函</div>
              </div>
              <p>保障项目顺利完成<br/>
              树立企业良好口碑</p>
              <div class="score">
                <div class="num">3000<em> +</em></div>
                <div class="txt">企业正在使用</div>
              </div>
            </li>
            <li @click="$router.push({'path': '/financial/insuranceData', 'query': {'newsId': '1361', 'type': '建筑工程团意险'}})">
              <div class="tilBox">
                <div class="h3">建筑工程团意险</div>
              </div>
              <p>保障建筑工程<br/>
              让风险无处藏身</p>
              <div class="score">
                <div class="num">500<em> +</em></div>
                <div class="txt">已服务工程项目</div>
              </div>
            </li>
          </ul>
        </section>
        <section class="lump deal">
          <div class="til">
            <em>交易商城</em>
          </div>
          <div class="part">
            <div class="til2">
              <em>土建与装饰材料专区</em>
              <div class="more" @click="loginJump(1, 'bridge:/webview/open/pageInit?url=http://p.vvupup.com/')">查看全部</div>
            </div>
            <div class="dealSwiper">
              <div class="swiper-container">  
                <div class="swiper-wrapper">
                  <div class="swiper-slide" @click="loginJump(1, 'bridge:/webview/open/pageInit?url=http://p.vvupup.com/')">
                    <div class="pic"><img src="~@/assets/images/home/dealPic1.jpg" alt=""></div>
                    <div class="txt">
                      <h4>OFJOYT奥飞久通多功能智能系统单元门</h4>
                      <div class="price"><div class="cn">奥飞久通</div></div>
                    </div>
                  </div>
                  <div class="swiper-slide" @click="loginJump(1, 'bridge:/webview/open/pageInit?url=http://p.vvupup.com/')">
                    <div class="pic"><img src="~@/assets/images/home/dealPic2.jpg" alt=""></div>
                    <div class="txt">
                      <h4>杰傲+节能中空玻璃</h4>
                      <div class="price"><div class="cn">贵州杰傲</div></div>
                    </div>
                  </div>
                  <div class="swiper-slide" @click="loginJump(1, 'bridge:/webview/open/pageInit?url=http://p.vvupup.com/')">
                    <div class="pic"><img src="~@/assets/images/home/dealPic3.png" alt=""></div>
                    <div class="txt">
                      <h4>绿羽EPS预制沟槽干式地暖模块（龙骨型）</h4>
                      <div class="price"><div class="cn">绿羽</div></div>
                    </div>
                  </div>
                  <div class="swiper-slide" @click="loginJump(1, 'bridge:/webview/open/pageInit?url=http://p.vvupup.com/')">
                    <div class="pic"><img src="~@/assets/images/home/dealPic4.jpg" alt=""></div>
                    <div class="txt">
                      <h4>东方雨虹高密度聚乙烯预铺反粘自粘胶膜防水卷材</h4>
                      <div class="price"><div class="cn">东方雨虹</div></div>
                    </div>
                  </div>
                </div>  
                <!-- Swiper Pagination -->  
                <div class="user-pagination">
                  <div :class="['dot', {'on': swiperAct === 0}]"></div>
                  <div :class="['dot', {'on': swiperAct === 1}]"></div>
                </div>  
              </div>
            </div>
          </div>
          <div class="part green">
            <div class="til2">
              <em>绿色材料与建筑固废专区</em>
              <div class="more" @click="comingSoon">查看全部</div>
            </div>
            <div class="ul">
              <div class="li" @click="comingSoon">
                <div class="pic"><img src="~@/assets/images/home/homeGreenPic1.jpg" alt=""></div>
                <div class="txt">
                  <h4>【路面砖】南宁市再生路面砖</h4>
                  <div class="price"><em>¥</em>230.00/1000<div class="cn"> 块</div></div>
                </div>
              </div>
              <div class="li" @click="comingSoon">
                <div class="pic"><img src="~@/assets/images/home/homeGreenPic2.jpg" alt=""></div>
                <div class="txt">
                  <h4>【透水砖】优质混凝土透水砖</h4>
                  <div class="price"><em>¥</em>340/1000<div class="cn"> 块</div></div>
                </div>
              </div>
              <div class="li" @click="comingSoon">
                <div class="pic"><img src="~@/assets/images/home/homeGreenPic3.jpg" alt=""></div>
                <div class="txt">
                  <h4>再生粗骨料</h4>
                  <div class="price"><em>¥</em>60.00/<div class="cn"> 吨</div></div>
                </div>
              </div>
              <div class="li" @click="comingSoon">
                <div class="pic"><img src="~@/assets/images/home/homeGreenPic4.jpg" alt=""></div>
                <div class="txt">
                  <h4>【烧结空心砖】工程弃土烧结空心砖</h4>
                  <div class="price"><em>¥</em>650/1000<div class="cn"> 块</div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="part wisdom">
            <div class="til2">
              <em>智慧工地产品专区</em>
              <div class="more" @click="toWeChat('pages/home/main-shipper&appId=gh_c72ddec85d4d&pubAppId=wxf6b2a87d20b9dbb2')">查看全部</div>
            </div>
            <div class="ul">
              <div class="li" @click="toWeChat('pages/home/main-shipper&appId=gh_c72ddec85d4d&pubAppId=wxf6b2a87d20b9dbb2')">
                <div class="pic"><img src="~@/assets/images/home/homeWisdomPic1.jpg" alt=""></div>
                <div class="txt">
                  <h4>智能双门全高转闸</h4>
                  <div class="price"><em>¥</em>15,600</div>
                </div>
              </div>
              <div class="li" @click="toWeChat('pages/home/main-shipper&appId=gh_c72ddec85d4d&pubAppId=wxf6b2a87d20b9dbb2')">
                <div class="pic"><img src="~@/assets/images/home/homeWisdomPic2.jpg" alt=""></div>
                <div class="txt">
                  <h4>人脸机</h4>
                  <div class="price"><em>¥</em>8,900</div>
                </div>
              </div>
              <div class="li" @click="toWeChat('pages/home/main-shipper&appId=gh_c72ddec85d4d&pubAppId=wxf6b2a87d20b9dbb2')">
                <div class="pic"><img src="~@/assets/images/home/homeWisdomPic3.png" alt=""></div>
                <div class="txt">
                  <h4>升降机安全管理系统</h4>
                  <div class="price"><em>¥</em>21,800</div>
                </div>
              </div>
              <div class="li" @click="toWeChat('pages/home/main-shipper&appId=gh_c72ddec85d4d&pubAppId=wxf6b2a87d20b9dbb2')">
                <div class="pic"><img src="~@/assets/images/home/homeWisdomPic4.jpg" alt=""></div>
                <div class="txt">
                  <h4>智能分析服务器/DH-IVD501AI-2M/L2</h4>
                  <div class="price"><em>¥</em>13,500</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="lump bigData" v-if="false">
          <div class="til">
            <em>大数据分析平台</em>
            <div class="more">查看全部</div>
          </div>
          <div class="pic"><img src="~@/assets/images/home/homeBigDataPic1.jpg" alt=""></div>
        </section>
        <section class="lump adhibition">
          <div class="til">
            <em>应用商城</em>
            <div class="more" @click="yysc">查看全部</div>
          </div>
          <ul>
            <li @click="yysc">
              <div class="row1">
                <h4>华建劳务</h4>
                <div class="user">已服务<em>5000+</em>用户</div>
              </div>
              <p>验真简历，招聘更精准，减少不必要消耗，干万建筑产业工人覆盖全国，工种齐全，招聘又快又准</p>
              <div class="label">
                <div class="i">按次售卖</div>
              </div>
              <div class="bom">
                <div class="price"><em>¥</em>5000</div>
                <div class="btn">立即咨询</div>
              </div>
            </li>
            <li @click="yysc">
              <div class="row1">
                <h4>建筑施工企业信息化系统</h4>
                <div class="user">已服务<em>5000+</em>用户</div>
              </div>
              <p>强化项目进度、成本、质量、安全、沟通和资料管理，将好的项目管理方式以模板方式整合进企业的日常生产经营活动中</p>
              <div class="label">
                <div class="i">按订购周期</div>
              </div>
              <div class="bom">
                <div class="price"><em>¥</em>50000<div class="p">/年</div></div>
                <div class="btn">立即咨询</div>
              </div>
            </li>
            <li @click="yysc">
              <div class="row1">
                <h4>安全培训教育方案</h4>
                <div class="user">已服务<em>5000+</em>用户</div>
              </div>
              <p>全力专注于供应各类职业认证培训相关知识与资源的综合性平台，学员能够系统性地学习职业认证所必备的知识和技能</p>
              <div class="label">
                <div class="i">按次售卖</div>
              </div>
              <div class="bom">
                <div class="price"><em>¥</em>5000</div>
                <div class="btn">立即咨询</div>
              </div>
            </li>
          </ul>
        </section>
        <section class="lump manpower">
          <div class="til">
            <em>劳务资源专区</em>
            <div class="more" @click="skipOutsideChain('https://youm.huajiantong.com')">查看全部</div>
          </div>
          <ul>
            <li @click="skipOutsideChain('https://youm.huajiantong.com')">
              <div class="row1">
                <h4>公明地块优质产业空间试点项目外脚手架专业分包工程招标</h4>
              </div>
              <div class="strong">6461856招标控制价</div>
              <p>项目用地面积约 22281.52 平方米，建筑面积约 131786平方米，其中厂房总计容面积 92535 平方米。</p>
              <div class="bom">
                <div class="label">
                  <div class="i i1">湖北省-襄阳市</div>
                  <div class="i i2">22281.52㎡</div>
                </div>
                <div class="btn">立即咨询</div>
              </div>
            </li>
            <li @click="skipOutsideChain('https://youm.huajiantong.com')">
              <div class="row1">
                <h4>坪深数字物流园项目施工总承包项目-电梯工程专业分包二次招标</h4>
              </div>
              <div class="strong">2008000招标控制价</div>
              <p>建筑面积: 276563.27平方米 结构形式:框架剪力墙结构</p>
              <div class="bom">
                <div class="label">
                  <div class="i i1">江西省-南昌市</div>
                  <div class="i i2">276563.27㎡</div>
                </div>
                <div class="btn">立即咨询</div>
              </div>
            </li>
            <li @click="skipOutsideChain('https://youm.huajiantong.com')">
              <div class="row1">
                <h4>深圳园创智云谷项目施工总承包工程(1标段)主体劳务招标</h4>
              </div>
              <div class="strong">60000000招标控制价</div>
              <p>本次招标分南北两个区域，7#楼、8#楼、9#楼、10#楼、14#楼、15#楼、16#楼、17#楼、18#楼、19#楼、23#架空层</p>
              <div class="bom">
                <div class="label">
                  <div class="i i1">广东省-深圳市</div>
                  <div class="i i2">55400㎡</div>
                </div>
                <div class="btn">立即咨询</div>
              </div>
            </li>
          </ul>
        </section>
        <section class="lump lease">
          <div class="til">
            <em>租赁商城</em>
          </div>
          <div class="part zzc">
            <div class="til2">
              <em>周转材租赁专区</em>
              <div class="more" @click="skipOutsideChain('https://zzc.huajiantong.com/mallh5/#/pages/mall/index')">查看全部</div>
            </div>
            <ul>
              <li class="it1" @click="skipOutsideChain('https://zzc.huajiantong.com/mallh5/#/pages/mall/index')">
                <h4>钢管扣件</h4>
              </li>
              <li class="it2" @click="skipOutsideChain('https://zzc.huajiantong.com/mallh5/#/pages/mall/index')">
                <h4>盘扣</h4>
              </li>
              <li class="it3" @click="skipOutsideChain('https://zzc.huajiantong.com/mallh5/#/pages/mall/index')">
                <h4>爬架</h4>
              </li>
              <li class="it4" @click="skipOutsideChain('https://zzc.huajiantong.com/mallh5/#/pages/mall/index')">
                <h4>模板</h4>
              </li>
            </ul>
          </div>
          <div class="part machinery" v-if="false">
            <div class="til2">
              <em>工程机械租赁专区</em>
              <div class="more">查看全部</div>
            </div>
            <div class="wr">
              <div class="user-tabs">
                <div ref="tab" :class="['tab', {'on': active === 0}]" @click="cutFn(0)">求租</div>
                <div ref="tab" :class="['tab', {'on': active === 1}]" @click="cutFn(1)">市政</div>
                <div ref="tab" :class="['tab', {'on': active === 2}]" @click="cutFn(2)">司机招聘</div>
                <div ref="tab" :class="['tab', {'on': active === 3}]" @click="cutFn(3)">出售</div>
                <div ref="tab" :class="['tab', {'on': active === 4}]" @click="cutFn(4)">求购</div>
                <div class="line" ref="line" :style="{width: actWid + 'px', left: actLeft + 'px'}"></div>
              </div>
              <div class="bd">
                <div class="box">
                  <div class="li">
                    <div class="row1">
                      <div class="state">求租</div>
                      <h4>明天早上南宁那马羊湛江再来4.2车</h4>
                    </div>
                    <div class="label">
                      <div class="i">冷藏车/货车/箱车</div>
                    </div>
                    <div class="line"></div>
                    <div class="bom">
                      <div class="site">广西-南宁</div>
                      <div class="time">05月04日 15:02</div>
                    </div>
                  </div>
                  <div class="li">
                    <div class="row1">
                      <div class="state">求租</div>
                      <h4>明天早上南宁那马羊湛江再来4.2车</h4>
                    </div>
                    <div class="label">
                      <div class="i">冷藏车/货车/箱车</div>
                    </div>
                    <div class="line"></div>
                    <div class="bom">
                      <div class="site">广西-南宁</div>
                      <div class="time">05月04日 15:02</div>
                    </div>
                  </div>
                  <div class="li">
                    <div class="row1">
                      <div class="state">求租</div>
                      <h4>明天早上南宁那马羊湛江再来4.2车</h4>
                    </div>
                    <div class="label">
                      <div class="i">冷藏车/货车/箱车</div>
                    </div>
                    <div class="line"></div>
                    <div class="bom">
                      <div class="site">广西-南宁</div>
                      <div class="time">05月04日 15:02</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="newsColumn" v-if="newsTypeArr.length">
          <van-tabs class="border" color="#f70000" line-width="56" title-inactive-color="#2d322d">
            <van-tab title="新闻资讯"></van-tab>
            <!-- <van-tab title="招标公告"></van-tab>
            <van-tab title="招工找活"></van-tab>
            <van-tab title="机械租售"></van-tab> -->
          </van-tabs>                             
          <div class="newsList">
            <div class="li" v-for="item in list" :key="item.newsId">
              <div class="a" @click="skipOutsideChain(item.address)" target="_blank" v-if="item.address && item.address.trim !== ''">
                <div class="pic" v-if="item.newsAccess"><img :src="item.newsAccess" alt=""></div>
                <div class="txt">
                  <h4>{{ item.newsTitle }}</h4>
                  <div class="p">
                    发布时间：<em>{{item.newsAddTime}}</em><br/>
                    <em>{{item.newsNum}}</em>人观看
                  </div>
                </div>
              </div>
              <router-link class="a" v-else :to="`/newsdetail/${item.newsId}`">
                <div class="pic" v-if="item.newsAccess"><img :src="item.newsAccess" alt=""></div>
                <div class="txt">
                  <h4>{{ item.newsTitle }}</h4>
                  <div class="p">
                    发布时间：<em>{{item.newsAddTime}}</em><br/>
                    <em>{{item.newsNum}}</em>人观看
                  </div>
                </div>
              </router-link>
            </div>
            <van-loading vertical  type="spinner" color="#1989fa" v-show="listLoad">
              加载中...
            </van-loading>
          </div>
          <van-button plain hairline type="primary" block color="#9f9f9f" style="height: .9rem; border-color: #e6e6e6; margin-top: .3rem;" @click="$router.push('/news')">查看更多</van-button>
        </section>
      </div>
      <!-- 选择城市 -->
      <city-selection :showOverlay="isCitySelectionOpen" @getCity="setCity"></city-selection>
    </main>
  </div>
</template>
<script setup>
import Swiper, { Pagination } from 'swiper';  
import 'swiper/swiper-bundle.css';  
import CitySelection from '@/components/CitySelection.vue';
import { getNewsTypes, getNews } from '@/api/http';
import { skipOutsideChain } from '@/api/public';
import { showDialog } from 'vant';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();

const value = ref('');
const active = ref(0);
const active2 = ref(0);
const actWid = ref(0);
const actLeft = ref(0);
const city = ref('南宁');
const isCitySelectionOpen = ref(0);
const newsTypeArr = ref([]);
const list = ref([]);
const rdhdList = ref([]);
const typeLoad = ref(false);
const listLoad = ref(false);
const setCity = val => city.value = val[1].text;
const openCitySelection = () => {
  isCitySelectionOpen.value++;
}
// 切换到工人端
const cutEditionFn = () => {
  window.location.href = "bridge:/base/changeAppSystem/pageInit?type=1";
}

const swiperAct = ref(0);
const mapContainer = ref('mapContainer');
let map = null;
const showMap = ref(false);
const prestoreId = ref(-1);
const prestoreUrl = ref('');


// 获取新闻类型
const newsType = async () => {
    typeLoad.value = true;
    const res = await getNewsTypes()
    newsTypeArr.value = res.data.data;
    const num =  newsTypeArr.value[0].typeId;
    typeLoad.value = false;
    newsList(num);
}
newsType();

// 根据类型获取新闻列表
const newsList = async (typeId) => {
    listLoad.value = true;
    const res = await getNews({
      pageIndex: 1,
      pageSize: 4,
      typeId
    })
    list.value = res.data.data;
    listLoad.value = false;
}

// 新闻类型切换
const newsChange = (i) => {
  const num =  newsTypeArr.value[i].typeId;
  newsList(num);
}

// 热点活动
const getRDHD = async() => {
  const { data: res } = await getNews({
    pageIndex: 1,
    pageSize: 4,
    typeId: 4551
  })
  rdhdList.value = res.data
  console.log('测试', res.data)
}
getRDHD();

// app跳转链接
function skip(url){
  window.location.href = url;
}

// 获取地址
const loadTianDiTuScript = async () => {
  const script = document.createElement('script');
  script.src = `https://api.tianditu.gov.cn/api?v=4.0&tk=e9b7bd908527d7dc3286b2d2b81fa129&type=text/javascript`;
  script.async = true;
  
  script.onload = () => {
    initializeMap();
    showMap.value = true;
  };
  script.onerror = () => {
    console.error('加载天地图API失败');
  };
  document.head.appendChild(script);
};

const initializeMap = async () => {
  if (window.T) {
    map = new window.T.Map(mapContainer.value, {
      center: [116.404, 39.915], // 默认坐标，实际应使用用户位置
      zoom: 15,
      layer: window.T.MapType.NORMAL_MAP,
    });
    // 获取用户本地位置
    const position = await new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('浏览器不支持地理定位'));
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    }).catch(error => {
      console.error('获取位置失败:', error);
      return null;
    });

    if (position) {
      const { coords: { latitude, longitude } } = position;
      const userLocation = [longitude, latitude];
      map.centerAndZoom(userLocation, 15); // 根据用户位置调整地图中心
      // 示例：在地图上添加标记
      const marker = new window.T.Marker(userLocation).addTo(map);
    }
  } else {
    console.error('天地图API未正确加载');
  }
};

// 业务按钮跳转
const loginJump = (num, url) => {
  if(num == 1){
    window.location.href = url
  }else if(num == 2){
    window.location.href = `bridge:/native/openMiniApp/pageInit?url=${url}`
  }else{
    router.push(url)
  }
  // prestoreId.value = num
  // prestoreUrl.value = url
  // window.location.href = "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
}

const escape = (url) => {
  return encodeURIComponent(url);
}

function setLine(){
  let tabList = document.querySelectorAll(".tab");
  actWid.value = tabList[active2.value].clientWidth;
  actLeft.value = tabList[active2.value].offsetLeft;
}
function cutFn(num){
  active2.value = num;
  setLine();
}
function toWeChat(url){
  const appIdMatch = url.match(/appId=(.*?)(?=&|$)/);
  const pubAppIdMatch = url.match(/pubAppId=(.*?)(?=&|$)/);
  const cleanedUrl = url.replace(/&?(appId|pubAppId)=[^&]*/g, '');
  let link = encodeURIComponent(cleanedUrl)
  window.location.href = `bridge:/native/openMiniApp/pageInit?url=${link}&${appIdMatch[0]}&${pubAppIdMatch[0]}`
}

// 敬请期待
function comingSoon(){
  showDialog({
    title: '即将开放，敬请期待'
  }).then(() => {
    // on close
  });
}
// 应用商城
let yysc = () => {
  showDialog({
    title: '如需了解，请移步电脑端访问'
  }).then(() => {
    // on close
  });
}
onMounted(function(){
  // 登录状态
  window.callbackLoginEvent = (isLogin) => {
    // isLogin 1 已登录 0 未登录
    if (isLogin == '1') {
      if(prestoreId.value == 1){
        window.location.href = prestoreUrl.value
      }else if(prestoreId.value == 0){
        router.push(prestoreUrl.value)
      }
      prestoreId.value = -1
      prestoreUrl.value = ""
    }else {
      prestoreId.value = -1
      prestoreUrl.value = ""
      // *****  调起登录页面的协议
      window.location.href = "bridge:/base/openLoginView/pageInit?callback=callbackLoginEvent";
    }
  }
  
  // setLine();

  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    spaceBetween : 13,
    pagination: {  
      el: '.swiper-pagination',  
      clickable: true,
    },
    on: {
      slideChangeTransitionStart: function(){
        swiperAct.value = this.activeIndex;
      }
    }
  });

  // 获取位置
  loadTianDiTuScript();
})

console.log('测试33333', store.state.safetyTop)
</script>
<style scoped lang="scss">
@import '~@/assets/css/vanTabs.scss';
.wrap{padding-top: 0;}
.home{
  padding: 0;
  background: url(~@/assets/images/home/homeTopBg.jpg) no-repeat top center;
  background-size: 100%;
}
.topBox{
  position: relative;
  // padding: 1.75rem .4rem 0;
  padding: 1.2rem .4rem 0;
  height: 5.34rem;
  .place{
    position: absolute;
    left: .4rem;
    top: .46rem;
    height: .6rem;
    line-height: .6rem;
    font-size: .28rem;
    color: #fff;
    padding: 0 .28rem 0 .4rem;
    background: url(~@/assets/images/iconPlace.png) no-repeat left center / .23rem,
    url(~@/assets/images/iconDown.png) no-repeat right center / .14rem;
  }
  .cutEditionBtn{
    position: absolute;
    right: .4rem;
    top: .46rem;
    padding-left: .3rem;
    font-size: .26rem;
    height: .6rem;
    line-height: .6rem;
    color: #fff;
    background: url(~@/assets/images/iconCut.png) no-repeat left center / .22rem;
  }
  h2{
    font-size: .56rem;
    line-height: .66rem;
    color: #0d5fb2;
    font-weight: 600;
    margin-bottom: .2rem;
  }
  p{
    font-size: .24rem;
    color: #7c8ea6;
    line-height: .4rem;
  }
}
.content{
  position: relative;
  z-index: 5;
  padding: .6rem .4rem .7rem;
  border-radius: .28rem .28rem 0 0;
  background: #fff;
  overflow: hidden;
  &:after{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 9.2rem;
    content: "";
    display: block;
    background: linear-gradient(to bottom, #fff, #e5e8ee);
  }
}
.stock{
  display: flex;
  justify-content: space-between;
  .btn{
    position: relative;
    z-index: 5;
    width:1.44rem;
    height: 1.72rem;
    padding: .24rem .1rem 0;
    overflow: hidden;
    border-radius: .28rem;
    border: .01rem solid #e7e7e7;
    .icon{
      width: .78rem;
      margin: 0 auto .15rem;
    }
    .p{
      font-size: .24rem;
      line-height: 1;
      color: #646464;
      text-align: center;
      font-weight: 600;
    }
  }
}
.homeSeek{
  position: relative;
  margin: .6rem 0;
  border-radius: .28rem;
  overflow: hidden;
  border: .03rem solid #fff;
  box-shadow: 0 0 .4rem rgba(206,190,189,.3);
  input{
    border-radius: .27rem;
    border: 0 none;
    width: 100%;
    height: 1rem;
    padding: .2rem 1rem .2rem .3rem;
    line-height: .6rem;
    font-size: .24rem;
    color: #666;
    background: #f4f4f4;
    box-shadow: inset 0 0 .2rem rgba(185,171,171,.2);
  }
  .btn{
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    background: url(~@/assets/images/iconSeek.png) no-repeat center;
    background-size: .36rem;
  }
}
.btnList{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.21rem;
  padding-bottom: .34rem;
  .li{
    position: relative;
    width: 1.38rem;
    height: 1.5rem;
    margin:0 .02rem .1rem;
    .p{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: .24rem;
      color: #666;
      text-align: center;
      white-space: nowrap;
    }
  }
}
.my-swipe{
  overflow: hidden;
  border-radius: .28rem;
  margin-bottom: .1rem;
  .van-swipe-item{
    position: relative;
    .txt{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 1.2rem .5rem 0;
    }
    h2{
      font-size: .36rem;
      line-height: .44rem;
      color: #ffbb00;
      margin-bottom: .16rem;
      letter-spacing: .1rem;
      font-weight: 600;
    }
    h3{
      font-size: .22rem;
      color: #b0c1ca;
      line-height: 1;
      font-family: 'Gilroy-Bold';
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .it2{
    h2{
      background: linear-gradient(to left bottom, #bbf1f9, #22e5a4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: .1rem;
    }
    h4{
      font-size: .24rem;
      color: #a1c5c2;
      line-height: .4rem;
    }
  }
}
.lump{
  margin-left: -.4rem;
  margin-right: -.4rem;
  padding: .6rem .4rem 0;
  .til{
    position: relative;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .3rem;
    em{
      border-left: .06rem solid #f70000;
      padding-left: .16rem;
      font-size: .34rem;
      line-height: 1;
    }
  }
  .til2{
    position: relative;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .4rem;
    em{
      font-size: .28rem;
      line-height: .38rem;
    }
  }
  .more{
    font-size: .22rem;
    color: #999;
    line-height: .6rem;
    padding-right: .18rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAAUElEQVQokYXSMQ4AIAgDwOJX+f8bdDAmFVvsSC6UgcjMiZ2AyaDx7FD84NnUQq6zkJGFFUmo0AMduuIQHx4KXUDd9ICKJGBkwUEtqHX6VQAsLewPSU/aZZkAAAAASUVORK5CYII=) no-repeat right center;
    background-size: .08rem;
  }
}

.hotspot{
  padding-bottom: .4rem;
  border-bottom: .06rem solid #efefef;
}

.hotspotSwiper{
  .swiper-container{
    overflow: visible;
  }
  .swiper-slide{
    width:3.8rem;
    .pic{
      overflow: hidden;
      border-radius: var(--border-radius-s);

    }
    .txt{
      height: 1.6rem;
      padding-top: .25rem;
    }
    h4{
      font-size: .26rem;
      color: #333;
      line-height: .36rem;
      margin-bottom: .15rem;
      display: -webkit-box;  
      -webkit-line-clamp: 2; /* 设置显示的行数 */  
      -webkit-box-orient: vertical;  
      overflow: hidden;  
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .time{
      font-size: .2rem;
      color: #999;
      line-height: .36rem;
    }
  }
}

.callForBids{
  padding-bottom: .4rem;
  .til{
    margin-bottom: 0;
  }
  .newsList{
    .a{
      display: flex;
    }
    .pic{
      width: 2.5rem;
      border-radius: var(--border-radius-s);
      margin-right: .25rem;
    }
    .txt{
      flex: 1;
      padding-top: 0;
      margin-top: -.05rem;
      h4{
        height: 1.1rem;
        -webkit-line-clamp: 3;
        font-size: .26rem;
        line-height: .36rem;
        height: 1.1rem;
        margin-bottom: .2rem;
      }
      .p{
        display: flex;
        flex-wrap: wrap;
        .em{
          position: relative;
          font-size: .2rem;
          color: #999;
          line-height: .36rem;
          padding: 0 .18rem;
          &:before{
            position: absolute;
            left: 0;
            top: .12rem;
            content: "";
            display: block;
            height: .12rem;
            border-left: .01rem solid #e5e5e5;
          }
          &:first-child{
            padding-left: 0;
            &:before{
              display: none;
            }
          }
        }
      }
    }
  }
}

.purchase{
  padding-bottom: .4rem;
  .ul{
    overflow: hidden;
  }
  .li{
    margin: 0 0 .3rem;
    float: left;
    width: 3.24rem;
    overflow: hidden;
    background: #fff;
    border: .01rem solid #eee;
    border-radius: .1rem;
    box-shadow: 0 0 .24rem rgba(51,51,51,.04);
    &:nth-child(2n){
      float: right;
    }
    .dt{
      overflow: hidden;
    }
    .dd{
      height: 2.3rem;
      padding: .25rem .2rem 0;
      h4{
        font-size: .26rem;
        color: #333;
        line-height: .36rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: .16rem;
      }
      .price{
        display: flex;
        justify-content: space-between;
        .new{
          font-size: .29rem;
          color: #e62a2b;
          line-height: .36rem;
        }
        .used{
          font-size: .2rem;
          color: #999;
          line-height: .36rem;
          text-decoration:line-through;
        }
      }
      .btn{
        margin-top: .2rem;
      }
    }
  }
}

.labourService{
  padding-bottom: .3rem;
  border-bottom: 0 none;
  .filtrate{
    display: flex;
    margin-bottom: .3rem;
    .btn{
      font-size: .26rem;
      color: #999;
      height: .5rem;
      line-height: .5rem;
      width: 1.42rem;
      text-align: center;
      border-radius: var(--border-radius-s);
      margin-right: .16rem;
      background: #ededed;
      &.on{
        color: #fff;
        background: #2295ed;
      }
    }
  }
  .li{
    position: relative;
    background: url(~@/assets/images/home/labourServiceBg.jpg) repeat-x;
    background-size: .2rem;
    border: .01rem solid #eee;
    padding: .3rem;
    border-radius: .2rem;
    margin-bottom: .3rem;
    box-shadow: 0 0 .4rem rgba(206,190,189,.3);
    .price{
      position: absolute;
      right: .3rem;
      height: .36rem;
      line-height: .36rem;
      font-size: .18rem;
      color: #e62a2b;
      em{
        font-family: "Gilroy-Bold";
        font-size: .3rem;
      }
    }
    h4{
      position: relative;
      padding-left: .3rem;
      margin-left: -.3rem;
      font-size: .26rem;
      color: #333;
      line-height: .36rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 5rem;
      margin-bottom: .1rem;
      &:before{
        content: "";
        display: block;
        position: absolute;
        top: .07rem;
        left: 0;
        width: .04rem;
        height: .22rem;
        background: #e62a2b;
      }
    }
    p{
      height: .9rem;
    }
    .bom{
      border-top: .01rem solid #eee;
      padding-top: .3rem;
      .time{
        font-size: .2rem;
        color: #999;
        line-height: 1;
      }
    }
  }
}

.newsColumn{
  .four{
    :deep(.van-tabs__nav){
      justify-content: space-between;
    }
  }
}

.newsList{
  a, .a{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: .3rem 0;
    border-top: 1px dashed #dfdfdf;
    &:first-child{
      border-top: 0 none;
    }
  }
  .pic{
    width: 2.9rem;
    overflow: hidden;
    border-radius: .28rem;
    margin-right: .36rem;
  }
  .txt{
    flex: 1;
    padding: .12rem 0 0;
    h4{
      font-size: .28rem;
      line-height: .4rem;
      margin-bottom: .32rem;
      display: -webkit-box;  
      -webkit-line-clamp: 2; /* 设置显示的行数 */  
      -webkit-box-orient: vertical;  
      overflow: hidden;  
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .p{
      font-size: .22rem;
      line-height: .3rem;
      color: rgba(45,50,45,.4);
      em{
        font-family: 'Gilroy-Bold';
      }
    }
  }
}
.border{
  :deep(.van-tabs__nav){
    .van-tab{
      margin-left: .3rem;
    }
  }
}
.financial{
  padding-bottom: .36rem;
  li{
    float: right;
    width: 3.22rem;
    height: 2.44rem;
    padding: .2rem .3rem;
    background: #f8f8f8;
    border-radius: .2rem;
    border: .01rem solid #eee;
    margin-bottom: .24rem;
    &.le{
      float: left;
    }
    .tilBox{
      display: flex;
      align-items: center;
      margin-bottom: .04rem;
      .h3{
        font-size: .26rem;
        color: #333;
        font-weight: 600;
        line-height: .36rem;
      }
      .label{
        margin-left: .05rem;
        height: .26rem;
        line-height: .26rem;
        border-radius: .04rem;
        font-size: .18rem;
        &.i1{
          color: #1192fc;
          background: #e0edf8;
        }
        &.i2{
          color: #30bb3b;
          background: #e4f2e5;
        }
      }
    }
    p{
      font-size: .22rem;
      color: #666;
      line-height: .4rem;
    }
    .score{
      margin-top: .1rem;
      .num{
        font-size: .34rem;
        line-height: .38rem;
        color: #f70000;
        font-family: "Gilroy-Bold";
        em{
          font-size: .26rem;
        }
      }
      .txt{
        font-size: .22rem;
        color: #f71515;
        line-height: .3rem;
      }
    }
    &.one{
      height: 5.12rem;
      padding: .3rem;
      background: url(~@/assets/images/home/financialOneBg.jpg) no-repeat center;
      background-size: 100% 100%;
      border: 0 none;
      .icon{
        width: .5rem;
        margin-bottom: .18rem;
      }
      .h2{
        font-size: .26rem;
        color:#bb9779;
        list-style: .36rem;
        font-weight: 600;
      }
      .h3{
        font-size: .22rem;
        color: #bb9779; 
        line-height: .3rem;
        margin-bottom: .12rem;
      }
      .h4{
        font-size: .21rem;
        color: #999; 
        line-height: .3rem;
      }
      .score{
        font-size: .48rem;
        .num{
          font-size: .28rem;
        }
      }
      .btn{
        margin-top: .16rem;
        width: 1.1rem;
        height: .5rem;
        text-align: center;
        line-height: .5rem;
        font-size: .2rem;
        color: #fff;
        border-radius: .5rem;
        background: #d19f68;
      }
    }
  }
}
.deal{
  padding-bottom: .36rem;
  background: #f8f8f8;
  .dealSwiper{
    .swiper-slide{
      width: 3.24rem;
      box-shadow: 0 0 .24rem rgba(0,0,0,.04);
      .pic{
        overflow: hidden;
        border-radius: .1rem .1rem 0 0;
      }
      .txt{
        height: 1.4rem;
        padding: .25rem .2rem;
        border: .01rem solid #eee;
        border-top: 0 none;
        border-radius: 0 0 .1rem .1rem;
        background: #fff;
        h4{
          font-size: .26rem;
          color: #333;
          line-height: 1;
          margin-bottom: .1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price{
          font-size: .29rem;
          color: #e62a2b;
          font-family: "Gilroy-Bold";
          em{
            font-size: .23rem;
          }
          .cn{
            display: inline-block;
            font-size: .26rem;
            font-family: '微软雅黑';
            font-weight: 600;
          }
        }
      }
    }
  }
  .part{
    margin-bottom: .6rem;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .green, .wisdom{
    margin-bottom: .36rem;
  }
  .ul{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .li{
    width: 3.24rem;
    margin-bottom: .24rem;
    box-shadow: 0 0 .24rem rgba(0,0,0,.04);
    .pic{
      overflow: hidden;
      border-radius: .1rem .1rem 0 0;
    }
    .txt{
      height: 1.4rem;
      padding: .25rem .2rem;
      border: .01rem solid #eee;
      border-top: 0 none;
      border-radius: 0 0 .1rem .1rem;
      background: #fff;
      h4{
        font-size: .26rem;
        color: #333;
        line-height: 1;
        margin-bottom: .1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price{
        font-size: .29rem;
        color: #e62a2b;
        font-family: "Gilroy-Bold";
        em{
          font-size: .23rem;
        }
        .cn{
          display: inline-block;
          font-size: .26rem;
          font-family: '微软雅黑';
          font-weight: 600;
        }
      }
    }
  }
}
.bigData{
  padding-bottom: .6rem;
  .pic{
    margin: 0 -.4rem;
  }
}
.adhibition{
  padding-bottom: .3rem;
  background: #fff;
  li{
    padding: .3rem;
    background: #f8f8f8;
    border-radius: .2rem;
    margin-bottom: .3rem;
    box-shadow: 0 0 .24rem rgba(0,0,0,.04);
    .row1{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .1rem;
      h4{
        position: relative;
        padding-left: .24rem;
        font-size: .26rem;
        color: #333;
        line-height: .4rem;
        font-weight: 600;
        &:after{
          position: absolute;
          left: 0;
          top: .13rem;
          content: "";
          display: block;
          width: .14rem;
          height: .14rem;
          border: .04rem solid #f70000;
          border-radius: 50%;
        }
      }
      .user{
        font-size: .22rem;
        color: #999;
        em{
          color: #f70000;
          font-family: "Gilroy-Bold";
        }
      }
    }
    p{
      font-size: .22rem;
      line-height: .3rem;
      color: #666;
    }
    .label{
      margin-top: .14rem;
      display: flex;
      .i{
        float: left;
        height: .4rem;
        line-height: .38rem;
        border: .01rem solid #f70000;
        padding: 0 .1rem;
        font-size: .22rem;
        color: #f70000;
        margin-right: .2rem;
      }
    }
    .bom{
      margin-top: .2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price{
        display: flex;
        font-size: .36rem;
        line-height: 1;
        color: #f70000;
        font-family: "Gilroy-Bold";
        align-items: center;
        em{
          font-size: .23rem;
        }
        .p{
          font-size: .2rem;
          margin-left: .1rem;
        }
      }
      .btn{
        width: 1.22rem;
        height: .5rem;
        line-height: .5rem;
        font-size: .22rem;
        color: #fff;
        text-align: center;
        border-radius: .5rem;
        background: #f70000;
      }
    }
  }
}
.manpower{
  padding-bottom: .3rem;
  background: #f8f8f8;
  li{
    margin-bottom: .3rem;
    background: #fff;
    border-radius: .2rem;
    padding: .3rem;
    .row1{
      margin-bottom: .14rem;
    }
    h4{
      position: relative;
      padding-left: .24rem;
      font-size: .26rem;
      color: #333;
      line-height: .4rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:after{
        position: absolute;
        left: 0;
        top: .13rem;
        content: "";
        display: block;
        width: .14rem;
        height: .14rem;
        border: .04rem solid #f70000;
        border-radius: 50%;
      }
    }
    .strong{
      font-size: .22rem;
      color: #f70000;
      line-height: .3rem;
      margin-bottom: .14rem;
    }
    p{
      font-size: .22rem;
      line-height: .3rem;
      color: #666;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 设置显示的行数 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .bom{
      margin-top: .2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .label{
      display: flex;
      .i{
        height: .4rem;
        line-height: .4rem;
        padding: 0 .1rem;
        font-size: .22rem;
        margin-right: .2rem;
        border-radius: .04rem;
        &:last-child{
          margin-right: 0;
        }
        &.i1{
          color: #098cfd;
          background: #e0edf8;
        }
        &.i2{
          color: #ee783f;
          background: #f7ebe5;
        }
      }
    }
    .btn{
      width: 1.22rem;
      height: .5rem;
      line-height: .5rem;
      font-size: .22rem;
      color: #fff;
      text-align: center;
      border-radius: .5rem;
      background: #f70000;
    }
  }
}
.lease{
  .zzc{
    padding-bottom: .36rem;
    ul{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    li{
      position: relative;
      width: 3.24rem;
      height: 2.8rem;
      margin-bottom: .24rem;
      border-radius: .1rem;
      box-shadow: 0 .1rem .24rem rgba(51,51,51,.2);
      background: no-repeat center;
      background-size: cover;
      &.it1{background-image: url(~@/assets/images/home/zzcPic1.jpg);}
      &.it2{background-image: url(~@/assets/images/home/zzcPic2.jpg);}
      &.it3{background-image: url(~@/assets/images/home/zzcPic3.jpg);}
      &.it4{background-image: url(~@/assets/images/home/zzcPic4.jpg);}
      h4{
        position: absolute;
        left: .3rem;
        top: .3rem;
        font-size: .26rem;
        color: #fff;
        line-height: .4rem;
      }
    }
  }
  .machinery{
    background: #f8f8f8;
    margin: 0 -.4rem;
    padding: .4rem .4rem .3rem;
    .til2{
      margin-bottom: .2rem;
    }
    .li{
      background: #fff;
      border-radius: .2rem;
      padding: .3rem .3rem .2rem;
      box-shadow: 0 0 .24rem rgba(0,0,0,.04);
      margin-bottom: .3rem;
      .row1{
        display: flex;
        align-items: center;
        margin-bottom: .2rem;
        .state{
          height: .28rem;
          line-height: .28rem;
          padding: 0 .05rem;
          font-size: .2rem;
          color: #f70000;
          border-radius: .04rem;
          background: #fee5e5;
          margin-right: .15rem;
        }
        h4{
          font-size: .26rem;
          color: #333;
          line-height: .36rem;
        }
      }
      .label{
        display: flex;
        .i{
          padding: 0 .1rem;
          height: .4rem;
          line-height: .4rem;
          font-size: .22rem;
          color: #999;
          background: #e5e5e5;
          border-radius: .04rem;
          margin-right: .2rem;
        }
      }
      .line{
        margin: .3rem 0 .2rem;
        border-bottom: .01rem solid #dcdcdc;
      }
      .bom{
        display: flex;
        justify-content: space-between;
        .site{
          height: 0.4rem;
          line-height: 0.4rem;
          padding: 0 0.1rem;
          font-size: 0.22rem;
          margin-right: 0.2rem;
          border-radius: 0.04rem;
          color: #098cfd;
          background: #e0edf8;
        }
        .time{
          font-size: .22rem;
          color: #999;
          line-height: .4rem;
        }
      }
    }
  }
}
.user-tabs{
  position: relative;
  display: flex;
  height: 1rem;
  border-bottom: .06rem solid #e1e1e1;
  margin-bottom: .4rem;
  .tab{
    position: relative;
    margin-right: .4rem;
    height: .94rem;
    line-height: .94rem;
    font-size: .28rem;
    color: #2d322d;
  }
  .line{
    position: absolute;
    height: .06rem;
    width: 1rem;
    bottom: -.06rem;
    background: #f70000;
    transition: all .4s;
  }
}
.newsColumn{
  padding-top: .3rem;
}
</style>

